import React from "react";
import Modal from "react-responsive-modal";
import { translate } from "react-internationalization";
import ReactTable from "react-table";
import * as session from "../SessionValidator";
import { SUPPLIER_SERVICE_URL } from "../../settings";
import { getRequest } from "../../service/RequestService";

export default class ModalAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addressOpen: false,
    };
  }

  async componentDidMount() {
    //se passar o "supplier" como parametro
    if (this.props.supplier) {
      this.setState({ supplier: this.props.supplier });
      this.forceUpdate();
    } else {
      this.getSupplier();
    }
  }

  async getSupplier() {
    if (this.props.supplierId && this.props.supplierId > 0) {
      this.setState({ loading: true });

      await getRequest(
        SUPPLIER_SERVICE_URL + "/api/supplier/" + this.props.supplierId,
      ).then((res) => {
        this.setState({ loading: false });

        if (res.data) {
          if (this.props.address != null) {
            this.state.address = this.props.address;
          } else if (res.data.address && res.data.address.length > 0) {
            let address = res.data.address.find((x) => {
              return x.isMaster;
            });
            this.state.address = address ? address : res.data.address[0];

            this.props.handleChangeAddress(this.state.address);
          } else {
            this.state.address = {
              name: "",
              legalName: "",
              document: "",
              documentType: 0,
              country: {},
              city: "",
              district: "",
              street: "",
              number: "",
              complement: "",
              zipCode: "",
              isMaster: true,
              phoneNumber: "",
              email: "",
              stateRegistration: "",
              state: {
                name: "",
              },
            };
          }
        }

        this.setState({ supplier: res.data });
        this.forceUpdate();
      });
    } else {
      let address = {
        name: "",
        legalName: "",
        document: "",
        documentType: 0,
        country: {},
        city: "",
        district: "",
        street: "",
        number: "",
        complement: "",
        zipCode: "",
        isMaster: true,
        phoneNumber: "",
        email: "",
        stateRegistration: "",
        state: {
          name: "",
        },
      };

      this.setState({
        address: address,
        supplier: {},
      });

      this.forceUpdate();
    }
  }

  handleEditAddress() {
    this.state.addressOpen = true;

    this.forceUpdate();
  }

  handleReturnAddress() {
    this.state.addressOpen = false;

    this.forceUpdate();
  }

  handleChangeAddress(address) {
    this.props.handleChangeAddress(address);

    this.state.address = address;
    this.forceUpdate();

    this.handleReturnAddress();
  }

  render() {
    var fetched =
      this.state != null &&
      this.state.supplier != null &&
      this.state.address != null;

    if (fetched) {
      return (
        <div>
          <div className="col-md-12">
            <div
              className="m-b-5"
              style={{ padding: "15px", border: "1px solid #ddd" }}
            >
              <div>
                <h4>
                  {translate("transaction.transactionSupplierAddressTitle")}
                </h4>
              </div>

              <p>
                <b>{translate("supplier.supplierLegalName")}: </b>
                {this.state.address.legalName}
                <b> {translate("supplier.supplierDocument")}: </b>
                {this.state.address.document}
              </p>

              <p>
                <b>{translate("supplier.supplierAddressState")}: </b>
                {this.state.address.state.name}
                <b> {translate("supplier.supplierAddressCity")}: </b>
                {this.state.address.city}
                <b> {translate("supplier.supplierAddressZipCode")}: </b>
                {this.state.address.zipCode}
                <b> {translate("supplier.supplierAddressStreet")}: </b>
                {this.state.address.street}
                <b> {translate("supplier.supplierAddressDistrict")}: </b>
                {this.state.address.district}
                <b> {translate("supplier.supplierAddressNumber")}: </b>
                {this.state.address.number}
              </p>

              {(session.isAdmin() ||
                session.isOperator() ||
                session.isSupplier()) &&
                (this.props.transactionStatus == 0 ||
                  this.props.transactionStatus == 7) && (
                  <button
                    className="btn bg-deep-purple waves-effect"
                    type="button"
                    disabled={this.props.loading}
                    onClick={() => {
                      this.handleEditAddress();
                    }}
                  >
                    <i className="material-icons">&#xe8d5;</i>
                    <span>
                      {translate("transaction.transactionChangeAddress")}
                    </span>
                  </button>
                )}
            </div>
          </div>

          <Modal
            open={this.state.addressOpen}
            closeOnEsc={false}
            onClose={() => {}}
            onEntered={() => {
              this.forceUpdate();
            }}
            closeIconSize={0}
            center={true}
            styles={{ modal: { maxWidth: "80%" } }}
          >
            <div>
              <div style={{ width: 9999 }}></div>

              <div>
                <h4>{translate("supplier.supplierAddressNameTitle")}</h4>
                <hr />
              </div>

              <div>
                <ReactTable
                  data={this.state.supplier.address}
                  columns={[
                    {
                      columns: [
                        {
                          Header: translate("supplier.supplierAddressName"),
                          accessor: "name",
                        },
                        {
                          Header: translate("supplier.supplierDocument"),
                          accessor: "document",
                        },
                        {
                          Header: translate("supplier.supplierAddressState"),
                          accessor: "state.name",
                        },
                        {
                          Header: translate("supplier.supplierAddressCity"),
                          accessor: "city",
                        },
                        {
                          Header: translate("supplier.supplierAddressDistrict"),
                          accessor: "district",
                        },
                        {
                          Header: translate("supplier.supplierAddressIsMaster"),
                          Cell: (row) => {
                            return (
                              <div className="align-center">
                                {row.original.isMaster && (
                                  <i className="material-icons color-success">
                                    &#xe86c;
                                  </i>
                                )}
                                {!row.original.isMaster && (
                                  <i className="material-icons color-danger">
                                    &#xe5c9;
                                  </i>
                                )}
                              </div>
                            );
                          },
                          maxWidth: 100,
                        },
                      ],
                    },
                  ]}
                  defaultPageSize={5}
                  className="-striped -highlight"
                  loading={this.props.loading}
                  previousText={translate("forms.previousText")}
                  nextText={translate("forms.nextText")}
                  noDataText={translate("forms.noDataText")}
                  pageText={translate("forms.pageText")}
                  ofText={translate("forms.ofText")}
                  rowsText={translate("forms.rowsText")}
                  getTrProps={(state, row, column) => {
                    return {
                      onClick: () => {
                        if (!this.props.loading)
                          this.handleChangeAddress(row.original);
                      },
                      style: { cursor: "pointer" },
                    };
                  }}
                />
              </div>

              <div className="row m-t-20">
                <div className="col-xs-12 align-right">
                  <button
                    type="button"
                    className="btn btn-default waves-effect"
                    disabled={this.props.loading}
                    onClick={() => {
                      this.handleReturnAddress();
                    }}
                  >
                    <i className="material-icons">&#xe5c4;</i>
                    <span>{translate("forms.buttonReturn")}</span>
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    } else {
      return (
        <div className="col-md-12">
          <div
            className="m-b-5"
            style={{ padding: "15px", border: "1px solid #ddd" }}
          >
            <div className="preloader pl-size-lg align-center">
              <div className="spinner-layer">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div>

                <div className="circle-clipper right">
                  <div className="circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
