import { post } from "axios";
import $ from "jquery";
import React from "react";
import { translate } from "react-internationalization";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { toast, ToastContainer } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { cnpj, cpf } from "../../components/cpf-cnpj-validator";
import RCDatePicker from "../../components/datePicker";
import * as enumsSignatory from "../../components/enums/signatoryType";
import * as enums from "../../components/enums/user";
import { cpf_cnpj_Mask } from "../../components/mask";
import RCSelect from "../../components/select";
import { confirmAlert } from "react-confirm-alert";
import * as session from "../../components/SessionValidator";
import { DOCUMENT_SERVICE_URL, TRANSACTION_SERVICE_URL } from "../../settings";
import moment from "moment";
import { getRequest, postRequest } from "../../service/RequestService";
class DocumentPage extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.setValidators();
    this.setBinds();

    this.state = {
      signatoryList: [],
      tabNumber: 0,
      document: {},
      signatory: {
        id: 0,
        documentId: 0,
        signatoryIdTransaction: 0,
        type: null,
        name: "",
        email: "",
        document: "",
        documentType: 1,
        birthDate: "",
        confirmEmail: "",
      },
      loading: false,
    };
  }

  setBinds() {
    this.handleChange = this.handleChange.bind(this);
    this.handlechangeMask = this.handlechangeMask.bind(this);
    this.handleNextTab = this.handleNextTab.bind(this);

    this.handleAddDocument = this.handleAddDocument.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetSignatory = this.resetSignatory.bind(this);
    this.saveSignatory = this.saveSignatory.bind(this);

    this.handleSendMail = this.handleSendMail.bind(this);
    this.deleteSignatory = this.deleteSignatory.bind(this);
  }

  setValidators() {
    this.importDocumentValidator = new SimpleReactValidator({
      attachmentInvalid: {
        rule: () => {
          if ($("#fileAttachments").length <= 0) return false;

          let files = $("#fileAttachments")[0].files;

          if (files.length <= 0) return false;

          if (/(.pdf)/i.test(files[0].name.toLowerCase()) === false)
            return false;

          return true;
        },
      },

      attachmentRequired: {
        rule: () => {
          if ($("#fileAttachments").length <= 0) return false;

          let files = $("#fileAttachments")[0].files;

          if (files.length <= 0) return false;

          return true;
        },
      },
    });

    this.validator = new SimpleReactValidator({
      emails: {
        rule: function (val) {
          if (val === "") return true;
          var emails = val.replace(new RegExp(",", "g"), ";").split(";");
          for (var i = 0, len = emails.length; i < len; i++)
            if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emails[i].trim())
            )
              return false;

          return true;
        },
      },

      confirmEmail: {
        rule: (val) => {
          let email = this.state.signatory.email;

          return val === email;
        },
      },

      documentType: {
        rule: (value) => {
          return ["", null, undefined].indexOf(value) < 0;
        },
      },

      document: {
        rule: (value) => {
          let documentType = this.state.signatory.documentType;

          if (documentType == 0) {
            //cnpj
            return cnpj.isValid(value);
          } else if (documentType == 1) {
            //cpf
            return cpf.isValid(value);
          } else {
            return false;
          }
        },
      },

      birthDateOver18Years: {
        rule: (value) => {
          let documentType = this.state.signatory.documentType;

          if (documentType == 0) {
            //cnpj
            return true;
          } else if (documentType == 1) {
            //cpf
            return moment(
              this.state.signatory.birthDate,
              "YYYY-MM-DD",
            ).isSameOrBefore(
              moment(new Date(), "YYYY-MM-DD")
                .add("years", -18)
                .format("YYYY-MM-DD"),
            );
          }
        },
      },
    });
  }

  async componentDidMount() {
    var _this = this;

    $("body")
      .off("change", ".js-change")
      .on("change", ".js-change", function (event) {
        _this.handleChange(event);
      });

    $(".js-integer").inputmask({
      greedy: false,
      mask: "9",
      repeat: "*",
      showMaskOnHover: false,
    });

    let signatoryList = [];

    if (this.id) {
      await getRequest(
        DOCUMENT_SERVICE_URL + "/api/Document/" + this.id + "?withFile=false",
      )
        .then((res) => {
          this.setState({ submitLoading: false });
          switch (res.code) {
            case 200:
              if (res.data && res.data.signatory)
                signatoryList = res.data.signatory.sort(function (a, b) {
                  return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                });

              this.setState({
                signatoryList: signatoryList,
                loading: false,
                document: res.data,
              });

              this.forceUpdate();

              break;

            case 400:
            case 409:
              var messages = res.data;
              messages.forEach((ex) => toast.warn(ex.message));
              break;

            case 500:
              toast.error("Erro na comunicação com o servidor. 500");
              break;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  handleTypingEmail(e) {
    e.target.value = e.target.value.toLowerCase();
  }

  handlechangeMask(event) {
    event.target.value = cpf_cnpj_Mask(event.target.value);

    this.handleChange(event);
  }

  async handleChange(event) {
    session.validateSession();

    if (event.target.type === "checkbox") {
      this.state.signatory[event.target.name] = event.target.checked;
    } else {
      this.state.signatory[event.target.name] = event.target.value;
    }

    this.setState(this.state);
  }

  handleNextTab(tabNumber) {
    if (this.state.document.id) this.state.tabNumber = tabNumber;

    this.forceUpdate();
  }

  saveSignatory() {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();

      return;
    }

    this.handleSubmit();
  }

  handleSubmit() {
    var signatory = this.state.signatory;

    signatory.documentId = this.state.document.id;

    postRequest(DOCUMENT_SERVICE_URL + "/api/Signatory/Create", signatory)
      .then((res) => {
        switch (res.code) {
          case 200:
            var signatory = JSON.parse(JSON.stringify(this.state.signatory));

            this.state.signatoryList.push(signatory);

            this.resetSignatory();

            this.forceUpdate();

            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("Erro na comunicação com o servidor. 500");
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  handleSendMail() {
    this.setState({ loading: true });

    postRequest(
      TRANSACTION_SERVICE_URL +
        "/api/Transaction/SendMailSignDocument/" +
        this.state.document.fileHash,
      "",
    )
      .then((res) => {
        switch (res.code) {
          case 200:
            this.props.history.push(
              "/documentView/" + this.state.document.fileHash,
            );

            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("Erro na comunicação com o servidor. 500");
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  deleteSignatory(rowIndex) {
    confirmAlert({
      buttons: [
        {
          label: translate("forms.buttonYes"),
          onClick: () => {
            let signatory = JSON.parse(
              JSON.stringify(this.state.signatoryList[rowIndex]),
            );
            let signatoryId = signatory.id;
            let signatoryType = signatory.type;

            this.handleDeleteSignatory(signatoryId, signatoryType, rowIndex);
          },
        },
        {
          label: translate("forms.buttonNo"),
          onClick: () => {
            window.close();
          },
        },
      ],
      message: translate("signatory.signatoryDeleteQuestion"),
      title: translate("signatory.signatoryInDeleteTitle"),
    });
  }

  handleDeleteSignatory(signatoryId, signerType, rowIndex) {
    var status = 0;

    this.setState({ loading: true });

    getRequest(
      DOCUMENT_SERVICE_URL +
        "/api/Signatory/Delete/" +
        signatoryId +
        "/" +
        signerType,
    ).then((res) => {
      this.setState({ loading: false });
      switch (res.code) {
        case 200:
          var list = this.state.signatoryList;
          list.splice(rowIndex, 1);
          this.state.signatoryList = list;

          this.forceUpdate();
          break;

        case 400:
        case 409:
          var messages = res.data;
          messages.forEach((ex) => toast.warn(ex.message));
          break;

        case 500:
          toast.error("Erro na comunicação com o servidor. 500");
          break;
      }
    });
  }

  handleAddDocument() {
    if (!this.importDocumentValidator.allValid()) {
      this.importDocumentValidator.showMessages();
      this.forceUpdate();

      return;
    } else {
      this.setState({ loading: true, resultError: [] });

      let status;

      const url = DOCUMENT_SERVICE_URL + "/api/Document/CreateDocument";
      const formData = new FormData();
      formData.append("file", this.file.files[0]);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: this.token,
        },
      };

      var self = this;

      post(url, formData, config)
        .then(function (res) {
          self.setState({ loading: false });
          status = res.status;

          switch (status) {
            case 200:
              var response = res.data.data;

              self.setState({
                document: response,
                id: response.id,
              });

              $("#btnNext").trigger("click");

              self.setState({
                tabNumber: 1,
              });

              break;
            case 401:
              session.sessionExpired();
              res = [];

              return res;

            case 403:
              session.accessDenied();
              res = [];
              return res;

              break;
          }
        })
        .catch(function (error) {
          self.setState({ loading: false });

          console.log(error);

          if (error.response === undefined) {
            toast.error("Erro na comunicação com o servidor.");
            return;
          }

          status = error.response.status;

          switch (status) {
            case 401:
              session.sessionExpired();
              error = [];
              return error;
            case 403:
              session.accessDenied();
              error = [];
              return error;
            case 400:
            case 409:
              var messages = error.response.data.data;
              if (messages)
                messages.forEach((ex) => self.state.resultError.push(ex));
              break;

            case 500:
              toast.error("Erro na comunicação com o servidor. 500");
              break;
          }
        });

      self.forceUpdate();
    }
  }

  resetSignatory() {
    this.state.signatory = {
      id: 0,
      documentId: 0,
      signatoryIdTransaction: 0,
      type: 0,
      name: "",
      email: "",
      document: "",
      documentType: 1,
      birthDate: "",
      confirmEmail: "",
    };

    this.setState(this.state);
  }

  render() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="card">
            <div className="header">
              <h2>
                <ul className="nav nav-tabs tab-nav-right" role="tablist">
                  <li className="active" role="presentation">
                    <a href="#transactionPanel" data-toggle="tab">
                      {translate("documents.documentTitle")}
                    </a>
                  </li>
                </ul>
              </h2>

              <div className="header-dropdown">
                <div
                  className="preloader pl-size-xs"
                  style={{ display: this.state.loading ? "block" : "none" }}
                >
                  <div className="spinner-layer">
                    <div className="circle-clipper left">
                      <div className="circle"></div>
                    </div>

                    <div className="circle-clipper right">
                      <div className="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="body">
              <div
                id="wizard_horizontal"
                role="application"
                className="wizard clearfix"
              >
                <div className="steps clearfix">
                  <ul className="nav nav-tabs tab-nav-right" role="tablist">
                    <li
                      role="presentation"
                      className={this.state.tabNumber === 0 ? "active" : ""}
                      aria-disabled="false"
                      aria-selected={this.state.tabNumber === 0}
                    >
                      <a
                        id="wizard_horizontal-t-0"
                        href="#documentUpload"
                        onClick={() => {
                          this.handleNextTab(0);
                        }}
                        aria-controls="wizard_horizontal-p-0"
                        data-toggle="tab"
                      >
                        <span className="current-info audible">
                          current step:{" "}
                        </span>
                        <span className="number">1.</span>
                        {translate("forms.uploadDocument")}
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className={this.state.tabNumber === 1 ? "active" : ""}
                      aria-disabled="true"
                      aria-selected={
                        this.state.tabNumber === 1 && this.state.id
                      }
                    >
                      <a
                        id="wizard_horizontal-t-1"
                        href={
                          this.state.document.fileHash || this.state.id
                            ? "#addSignatary"
                            : "#"
                        }
                        onClick={() => {
                          this.handleNextTab(1);
                        }}
                        aria-controls="wizard_horizontal-p-1"
                        data-toggle="tab"
                      >
                        <span className="number">2.</span>
                        {translate("signatory.signatoryAddNew")}
                      </a>
                    </li>
                  </ul>
                </div>

                <div
                  id="documentUpload"
                  className="tab-pane fade active in"
                  role="tabpanel"
                >
                  {!this.state.document.fileHash && (
                    <div>
                      <input
                        className="js-change"
                        name="fileAttachments"
                        id="fileAttachments"
                        type="file"
                        ref={(x) => (this.file = x)}
                        accept=".pdf"
                        disabled={
                          this.state.document.file ||
                          this.state.document.fileHash
                        }
                      />

                      {this.importDocumentValidator.message(
                        "fileAttachments",
                        this.state.file,
                        "attachmentRequired|attachmentInvalid",
                        false,
                        {
                          default: translate(
                            "importNF.importNFAttachmentsRequired",
                          ),
                          attachmentInvalid: translate(
                            "importNF.importNFAttachmentsInvalid",
                          ),
                        },
                      )}

                      <button
                        className="btn btn-success m-t-15 waves-effect m-l-15"
                        onClick={this.handleAddDocument}
                        type="button"
                        disabled={this.state.loading || this.state.id}
                      >
                        <i className="material-icons">&#xe873;</i>
                        <span>
                          {translate("documents.documentSendDocument")}
                        </span>
                      </button>
                    </div>
                  )}

                  <div>
                    <label htmlFor="txtDocumentName">
                      {translate("documents.documentFileName")}:{" "}
                    </label>

                    <label> {this.state.document.fileName} </label>
                  </div>
                </div>

                <div
                  id="addSignatary"
                  className="tab-pane fade"
                  role="tabpanel"
                >
                  {this.state.document.status === 0 && (
                    <div>
                      <div className="row clearfix">
                        <div className="col-md-12">
                          <div className="input-group">
                            <label
                              className="validationRequired"
                              htmlFor="txtSignataryName"
                            >
                              {translate("signatory.signatoryName")}
                            </label>

                            <div className="form-line">
                              <input
                                id="txtSignataryName"
                                name="name"
                                className="form-control"
                                value={this.state.signatory.name}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="100"
                                placeholder={translate(
                                  "signatory.signatoryNamePlaceHolder",
                                )}
                                type="text"
                              />
                            </div>

                            {this.validator.message(
                              "name",
                              this.state.signatory.name,
                              "required",
                              "validationMessage--error",
                              { default: translate("user.userNameRequired") },
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row clearfix">
                        <div className="col-md-3">
                          <div className="m-b-20">
                            <label
                              htmlFor="cmbDocumentType"
                              className="validationRequired"
                            >
                              {translate("supplier.supplierDocumentType")}
                            </label>

                            <RCSelect
                              id="cmbDocumentType"
                              name="documentType"
                              hasEmptyOption={true}
                              isInt={true}
                              options={enums.getDocumentType()}
                              optionValue="index"
                              optionLabel="text"
                              placeholder={translate(
                                "supplier.supplierDocumentTypePlaceHolder",
                              )}
                              onChange={(event) => {
                                this.handleChange(event);
                              }}
                              value={this.state.signatory.documentType}
                            />

                            {this.validator.message(
                              "documentType",
                              this.state.signatory.documentType,
                              "required|documentType",
                              false,
                              {
                                default: translate(
                                  "supplier.supplierDocumentTypeRequired",
                                ),
                              },
                            )}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="input-group">
                            <label
                              htmlFor="txtDocument"
                              className="validationRequired"
                            >
                              {" "}
                              {translate("supplier.supplierDocument")}{" "}
                            </label>

                            <div className="form-line">
                              <input
                                id="txtDocument"
                                name="document"
                                maxLength="18"
                                value={this.state.signatory.document}
                                onChange={this.handlechangeMask}
                                className="form-control js-change"
                                autoComplete="off"
                                placeholder={translate(
                                  "supplier.supplierDocumentPlaceHolder",
                                )}
                                type="text"
                              />
                            </div>

                            {this.validator.message(
                              "document",
                              this.state.signatory.document,
                              "required|document",
                              false,
                              {
                                default: translate(
                                  "supplier.supplierDocumentRequired",
                                ),
                                document:
                                  this.state.signatory.documentType === 0
                                    ? translate("supplier.supplierCNPJInvalid")
                                    : translate("supplier.supplierCPFInvalid"),
                              },
                            )}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="input-group">
                            <label htmlFor="dtBirthDate">
                              {translate("user.userBirthDate")}
                            </label>

                            <div className="form-line">
                              <RCDatePicker
                                id="dtBirthDate"
                                name="birthDate"
                                value={this.state.signatory.birthDate}
                                onChange={this.handleChange}
                              />
                            </div>

                            {this.validator.message(
                              "birthDate",
                              this.state.signatory.birthDate,
                              "required|birthDateOver18Years",
                              false,
                              {
                                default: translate(
                                  "user.userBirthDateRequired",
                                ),
                                birthDateOver18Years: translate(
                                  "user.userBirthDateOver18Years",
                                ),
                              },
                            )}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="m-b-20">
                            <label
                              htmlFor="cmbSignType"
                              className="validationRequired"
                            >
                              {translate("signatory.signatorySignAs")}
                            </label>

                            <RCSelect
                              id="cmbSignType"
                              name="type"
                              hasEmptyOption={true}
                              isInt={true}
                              options={enumsSignatory.getTypes()}
                              optionValue="index"
                              optionLabel="text"
                              placeholder={translate(
                                "signatory.signatoryTypePlaceHolder",
                              )}
                              onChange={(event) => {
                                this.handleChange(event);
                              }}
                              value={this.state.signatory.type}
                            />

                            {this.validator.message(
                              "cmbSignType",
                              this.state.signatory.type,
                              "required",
                              false,
                              {
                                default: translate(
                                  "signatory.signatoryTypeRequired",
                                ),
                              },
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-6">
                          <div className="input-group">
                            <label
                              className="validationRequired"
                              htmlFor="txtSignataryEmail"
                            >
                              {translate("signatory.signatoryEmail")}
                            </label>

                            <div className="form-line">
                              <input
                                type="email"
                                id="txtSignatoryEmail"
                                name="email"
                                className="form-control"
                                value={this.state.signatory.email}
                                onChange={this.handleChange}
                                onKeyUp={this.handleTypingEmail}
                                placeholder={translate(
                                  "signatory.signatoryEmailPlaceHolder",
                                )}
                                maxLength="100"
                                autoComplete="off"
                              />
                            </div>

                            {this.validator.message(
                              "email",
                              this.state.signatory.email,
                              "required|emails",
                              false,
                              {
                                default: translate("user.userEmailRequired"),
                                emails: translate("user.userEmailInvalid"),
                              },
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-group">
                            <label
                              className="validationRequired"
                              htmlFor="txtSignatoryConfirmEmail"
                            >
                              {translate("signatory.signatoryConfirmEmail")}
                            </label>

                            <div className="form-line">
                              <input
                                type="email"
                                id="txtSignatoryConfirmEmail"
                                name="confirmEmail"
                                className="form-control"
                                value={this.state.signatory.confirmEmail}
                                onChange={this.handleChange}
                                onKeyUp={this.handleTypingEmail}
                                placeholder={translate(
                                  "signatory.signatoryConfirmEmail",
                                )}
                                maxLength="100"
                                autoComplete="off"
                              />
                            </div>

                            {this.validator.message(
                              "email",
                              this.state.signatory.confirmEmail,
                              "required|confirmEmail",
                              false,
                              {
                                default: translate(
                                  "signatory.signatoryConfirmEmailRequired",
                                ),
                                confirmEmail: translate(
                                  "signatory.signatoryConfirmEmailNotMatch",
                                ),
                              },
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-3 m-t-20">
                          <button
                            type="button"
                            className="btn btn-block btn-primary right"
                            onClick={() => {
                              this.saveSignatory();
                            }}
                          >
                            <i className="material-icons">&#xe145;</i>
                            <span>
                              {translate("signatory.signatoryAddNew")}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <ReactTable
                    data={this.state.signatoryList}
                    columns={[
                      {
                        columns: [
                          {
                            Header: translate(
                              "signatory.signatoryNamePlaceHolder",
                            ),
                            id: "name",
                            accessor: (data) => {
                              return (
                                <span title={data.name}> {data.name} </span>
                              );
                            },
                          },
                          {
                            Header: translate("signatory.signatoryEmail"),
                            id: "email",
                            accessor: (data) => {
                              return (
                                <span title={data.email}> {data.email} </span>
                              );
                            },
                          },
                          {
                            Header: translate("signatory.signatoryDocument"),
                            id: "document",
                            accessor: (data) => {
                              return (
                                <span title={data.document}>
                                  {" "}
                                  {data.document}{" "}
                                </span>
                              );
                            },
                          },
                          {
                            Header: translate("signatory.signatoryTypeSign"),
                            id: "type",
                            accessor: (data) => {
                              return (
                                <span
                                  title={
                                    enumsSignatory.getTypes().find((x) => {
                                      return x.index === parseInt(data.type);
                                    }).text
                                  }
                                >
                                  {" "}
                                  {
                                    enumsSignatory.getTypes().find((x) => {
                                      return x.index === parseInt(data.type);
                                    }).text
                                  }{" "}
                                </span>
                              );
                            },
                          },
                          {
                            Cell: (row) => (
                              <div className="align-center">
                                <button
                                  className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10"
                                  type="button"
                                  disabled={
                                    this.state.document.sendEmail ||
                                    this.state.document.status === 1
                                  }
                                  onClick={() => {
                                    this.deleteSignatory(row.index);
                                  }}
                                >
                                  <i className="material-icons">&#xe872;</i>
                                </button>
                              </div>
                            ),
                            maxWidth: 150,
                          },
                        ],
                      },
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight m-b-40"
                    previousText={translate("forms.previousText")}
                    nextText={translate("forms.nextText")}
                    noDataText={translate("forms.noDataText")}
                    pageText={translate("forms.pageText")}
                    ofText={translate("forms.ofText")}
                    rowsText={translate("forms.rowsText")}
                  />
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-md-11 formActions">
                  <Link
                    to="/documents"
                    className="btn btn-default waves-effect"
                  >
                    <i className="material-icons">&#xe5c4;</i>
                    <span>{translate("forms.buttonReturn")}</span>
                  </Link>

                  <button
                    className="btn btn-default waves-effect"
                    href="#documentUpload"
                    role="menuitem"
                    onClick={() => {
                      this.handleNextTab(0);
                    }}
                    disabled={this.state.tabNumber === 0}
                    data-toggle="tab"
                  >
                    <i className="material-icons">&#xe314;</i>
                    <span>
                      {translate("document.documentPaginationPrevious")}
                    </span>
                  </button>

                  <button
                    id="btnNext"
                    className="btn btn-default waves-effect"
                    href={
                      this.state.document.fileHash ||
                      this.state.id ||
                      this.state.tabNumber === 1
                        ? "#addSignatary"
                        : "#"
                    }
                    disabled={
                      this.state.tabNumber === 1 ||
                      !this.state.document.fileHash
                    }
                    role="menuitem"
                    onClick={() => {
                      this.handleNextTab(1);
                    }}
                    data-toggle="tab"
                  >
                    <i className="material-icons">&#xe315;</i>
                    <span>{translate("document.documentPaginationNext")}</span>
                  </button>

                  <button
                    className="btn btn-default waves-effect"
                    role="menuitem"
                    disabled={
                      this.state.tabNumber === 0 ||
                      this.state.signatoryList.length === 0 ||
                      this.state.loading ||
                      this.state.document.status > 0
                    }
                    onClick={() => {
                      this.handleSendMail();
                    }}
                    data-toggle="tab"
                  >
                    <i className="material-icons">&#xe315;</i>
                    <span>{translate("documents.documentSendDocument")}</span>
                  </button>
                </div>
                <div className="col-md-1 formActions">
                  <div className="header-dropdown" style={{ float: "right" }}>
                    <div
                      className="preloader pl-size-xs"
                      style={{ display: this.state.loading ? "block" : "none" }}
                    >
                      <div className="spinner-layer">
                        <div className="circle-clipper left">
                          <div className="circle"></div>
                        </div>
                        <div className="circle-clipper right">
                          <div className="circle"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer hideProgressBar />
      </div>
    );
  }
}

export default DocumentPage;
