import React from "react";
import Modal from "react-responsive-modal";
import { translate } from "react-internationalization";
import * as session from "../SessionValidator";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { TRANSACTION_SERVICE_URL } from "../../settings";
import { postRequest } from "../../service/RequestService";

export default class ModalSendEmail extends React.Component {
  constructor(props) {
    super(props);

    this.handleReturn = this.handleReturn.bind(this);
    this.handleSendEmail = this.handleSendEmail.bind(this);

    this.state = {
      email: {
        message: "",
        transactionId: 0,
        sinatoryId: 0,
        signerType: 0,
      },
    };
  }

  async componentDidMount() {
    let _this = this;

    $("body")
      .off("change", ".js-change")
      .on("change", ".js-change", function (event) {
        _this.setValue(event);
      });
  }

  setValue(event) {
    var target = this.state,
      childs = event.target.name.split(".");

    childs.forEach(function (child) {
      if (typeof target[child] === "object") {
        target = target[child];
      } else {
        target[child] =
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value;
      }
    });

    this.setState(this.state);
  }

  handleReturn() {
    this.state.email.message = "";
    this.props.closeEmail();
  }

  handleSendEmail() {
    this.setState({ loading: true });
    this.forceUpdate();

    this.state.email.transactionId = this.props.transaction.id;
    this.state.email.sinatoryId = this.props.signer.id;
    this.state.email.signerType = this.props.signerType;

    postRequest(
      TRANSACTION_SERVICE_URL + "/api/transaction/sendMailSignContractRemember",
      this.state.email,
    ).then((res) => {
      this.state.loading = false;
      this.forceUpdate();

      switch (res.code) {
        case 200:
          if (res.data) {
            this.state.email.message = "";
            this.props.closeEmail();
          } else toast.error("Erro no envio do e-mail.");

          break;

        case 400:
        case 409:
          var messages = res.data;
          messages.forEach((ex) => toast.warn(ex.message));
          break;

        case 500:
          toast.error("Erro no envio do token.");
          break;
      }
    });
  }

  render() {
    var fetched =
      this.state != null &&
      this.props.transaction != null &&
      this.props.signer != null;

    if (fetched) {
      return (
        <div>
          <Modal
            open={this.props.openEmail}
            closeOnEsc={true}
            onClose={() => {}}
            onEntered={() => {
              this.forceUpdate();
            }}
            closeIconSize={0}
            center={true}
            styles={{ modal: { maxWidth: "50%" } }}
          >
            <div>
              <div style={{ width: 9999 }}></div>

              <div>
                <h4>{translate("transaction.transactionSendEmailTitle")}</h4>
                <hr />
              </div>

              <div className="col-md-12">
                <div className="input-group">
                  <b>{translate("transaction.transactionSendEmailTo")}: </b>
                  {this.props.signer.signatureEmail}
                </div>
                <div className="input-group">
                  <b>
                    {translate("transaction.transactionSendEmailSubject")}:{" "}
                  </b>
                  {translate("transaction.transactionSendEmailDescription")}{" "}
                  {this.props.transaction.id}.pdf
                </div>
              </div>

              <div className="col-md-12">
                <div className="input-group">
                  <label htmlFor="txtMessage">
                    {translate("transaction.transactionSendEmailMessage")}
                  </label>

                  <div className="form-line">
                    <textarea
                      rows="4"
                      id="txtMessage"
                      className="form-control js-change"
                      name="email.message"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="row m-t-20">
                <div className="col-xs-12 align-right">
                  <div
                    className="preloader pl-size-xs form-preloader"
                    style={{
                      display: this.state.loading ? "inline-block" : "none",
                      position: "relative",
                      top: "7px",
                      marginRight: "20px",
                    }}
                  >
                    <div className="spinner-layer">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-default waves-effect m-r-10"
                    disabled={this.props.loading}
                    onClick={() => {
                      this.handleReturn();
                    }}
                  >
                    <i className="material-icons">&#xe5c4;</i>
                    <span>{translate("forms.buttonCancel")}</span>
                  </button>

                  <button
                    type="button"
                    className="btn btn-success waves-effect"
                    disabled={this.state.loading}
                    onClick={this.handleSendEmail}
                  >
                    <i className="material-icons">&#xe163;</i>
                    <span>{translate("forms.buttonSend")}</span>
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          <ToastContainer hideProgressBar />
        </div>
      );
    }
  }
}
