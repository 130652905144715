import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { translate } from "react-internationalization";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as enums from "../../components/enums/user";
import * as session from "../../components/SessionValidator";
import QueryString from "query-string";
import { cpf_cnpj_Mask } from "../../components/mask";
import { ACCOUNT_SERVICE_URL } from "../../settings";
import { getRequest } from "../../service/RequestService";

class UsersPage extends Component {
  displayName = UsersPage.name;

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.loadNextResults = this.loadNextResults.bind(this);
    this.handleSearchButton = this.handleSearchButton.bind(this);
    this.handlechangeMask = this.handlechangeMask.bind(this);

    this.state = {
      users: [],
      loading: true,

      searchName: "",
      searchDocument: "",

      query: {},
      tablePage: 0,
      tablePageSize: 10,
      tablePageCount: 0,
      numberOfResults: 0,
      currentPage: 0,
      rowCount: 0,
    };
  }

  async componentDidMount() {
    this.loadUsers();
  }

  handlechangeMask(event) {
    event.target.value = cpf_cnpj_Mask(event.target.value);
    this.change(event);
  }

  handleChange(event) {
    session.validateSession();
    this.change(event);
  }

  change(event) {
    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }
        property[key] = value;
      }
    });

    this.setState(this.state);
  }

  loadNextResults(page, pageSize) {
    if (pageSize === undefined) pageSize = this.state.tablePageSize;

    this.setState({ tablePage: page, tablePageSize: pageSize });
    let numberOfResults = this.state.numberOfResults;
    let numberOfRows = pageSize * (page + 1);
    let currentPage = this.state.currentPage;

    if (numberOfResults <= numberOfRows) {
      let nextPage = currentPage + 1;
      this.setState({ currentPage: nextPage });
      this.loadUsers();
    }

    let tablePageCount = Math.ceil(this.state.rowCount / pageSize);
    this.setState({ tablePageCount: tablePageCount });
  }

  handleSearchButton() {
    this.state.users = [];
    this.state.currentPage = 0;
    this.state.numberOfResults = 0;
    this.state.tablePage = 0;
    this.state.tablePageSize = 10;
    this.state.tablePageCount = 0;
    this.state.rowCount = 0;

    this.setState(this.state);
    this.forceUpdate();

    this.loadUsers();
  }

  loadUsers() {
    let numberOfResults = this.state.numberOfResults;
    let users = this.state.users;
    this.state.query.page = this.state.currentPage + 1;
    this.state.query.pageSize = this.state.tablePageSize;
    this.state.query.name = this.state.searchName;
    this.state.query.document = this.state.searchDocument;

    this.setState({ loading: true });

    getRequest(
      ACCOUNT_SERVICE_URL +
        "/api/Account/List/?" +
        QueryString.stringify(this.state.query),
    ).then((res) => {
      switch (res.code) {
        case 200:
          if (!res.data) res.data = [];

          if (res.data)
            res.data.result = res.data.result.sort(function (a, b) {
              return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            });

          users = users.concat(res.data.result);

          numberOfResults =
            numberOfResults +
            (res.data.result == null ? 0 : res.data.result.length);

          this.setState({
            users: users,
            numberOfResults: numberOfResults,
            currentPage: res.data.currentPage,
            tablePageCount: res.data.pageCount,
            rowCount: res.data.rowCount,
          });

          break;

        case 400:
        case 409:
          var messages = res.data;
          messages.forEach((ex) => toast.warn(ex.message));
          break;

        case 500:
          toast.error("error");
          break;
      }

      this.setState({ loading: false });
    });
  }

  render() {
    var fetched = this.state != null && this.state.users != null;

    if (fetched) {
      var searchName = this.state.searchName.toLowerCase();

      var userItems = [];

      if (this.state.users != null) {
        var userItems = this.state.users.filter(function (user) {
          return (
            user.id.toString().toLowerCase().indexOf(searchName) >= 0 ||
            user.name.toString().toLowerCase().indexOf(searchName) >= 0 ||
            user.email.toString().toLowerCase().indexOf(searchName) >= 0
          );
        });
      }

      return (
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="card">
              <div className="header">
                <h2>
                  {translate("user.usersTitle")}
                  <small>{translate("user.usersTitleDescription")}</small>
                </h2>
              </div>
              <div className="body">
                <div className="row clearfix m-t-20">
                  <div className="col-md-3">
                    <div className="input-group">
                      <label htmlFor="txtName">
                        {translate("user.userName")}
                      </label>

                      <div className="form-line">
                        <input
                          id="txtName"
                          name="searchName"
                          className="form-control"
                          type="text"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="input-group">
                      <label htmlFor="txtDocument">
                        {translate("user.userDocumentPlaceHolder")}
                      </label>

                      <div className="form-line">
                        <input
                          id="txtDocument"
                          name="searchDocument"
                          maxLength="18"
                          autoComplete="off"
                          className="form-control"
                          type="text"
                          onChange={this.handlechangeMask}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 pull-left m-t-20">
                    <button
                      className="btn btn-block btn-primary"
                      disabled={this.state.loading}
                      type="button"
                      onClick={(event) => {
                        this.handleSearchButton(event);
                      }}
                    >
                      <i className="material-icons">&#xe8b6;</i>
                      <span>{translate("forms.buttonSearch")}</span>
                    </button>
                  </div>

                  {(session.isAdmin() ||
                    session.isOperator() ||
                    session.isCustomer()) &&
                    session.auth([{ type: "Account", value: "Save" }]) && (
                      <div className="col-md-3 m-t-20">
                        <Link to={"/user"}>
                          <button
                            type="button"
                            className="btn btn-block btn-primary right"
                          >
                            <i className="material-icons">&#xe145;</i>
                            <span>{translate("user.userAddNew")}</span>
                          </button>
                        </Link>
                      </div>
                    )}
                </div>

                <ReactTable
                  data={userItems}
                  columns={[
                    {
                      columns: [
                        {
                          Header: translate("user.userId"),
                          accessor: "id",
                          maxWidth: 60,
                        },
                        {
                          Header: translate("user.userName"),
                          accessor: "name",
                        },
                        {
                          Header: translate("user.userEmail"),
                          accessor: "email",
                        },
                        {
                          Header: translate("user.userType"),
                          id: "type",
                          accessor: (data) => {
                            return (
                              <span
                                title={
                                  enums.getTypes().find((x) => {
                                    return x.index === parseInt(data.type);
                                  }).text
                                }
                              >
                                {" "}
                                {
                                  enums.getTypes().find((x) => {
                                    return x.index === parseInt(data.type);
                                  }).text
                                }{" "}
                              </span>
                            );
                          },
                        },
                        {
                          Header: translate("user.userIsActive"),
                          Cell: (row) => {
                            return (
                              <div className="align-center">
                                {row.original.isActive && (
                                  <i className="material-icons color-success">
                                    &#xe86c;
                                  </i>
                                )}
                                {!row.original.isActive && (
                                  <i className="material-icons color-danger">
                                    &#xe5c9;
                                  </i>
                                )}
                              </div>
                            );
                          },
                          maxWidth: 100,
                        },
                        {
                          Header: translate("user.userIsBlocked"),
                          Cell: (row) => {
                            return (
                              <div className="align-center">
                                {row.original.isBlocked && (
                                  <i className="material-icons color-success">
                                    &#xe86c;
                                  </i>
                                )}
                                {!row.original.isBlocked && (
                                  <i className="material-icons color-danger">
                                    &#xe5c9;
                                  </i>
                                )}
                              </div>
                            );
                          },
                          maxWidth: 100,
                        },
                        {
                          Cell: (row) => (
                            <div className="align-right">
                              <Link to={"/user/" + row.original.id}>
                                <button
                                  type="button"
                                  className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10"
                                >
                                  <i className="material-icons">&#xe3c9;</i>
                                </button>
                              </Link>
                            </div>
                          ),
                        },
                      ],
                    },
                  ]}
                  defaultPageSize={10}
                  className="-striped -highlight"
                  loading={this.state.loading}
                  previousText={translate("forms.previousText")}
                  nextText={translate("forms.nextText")}
                  noDataText={translate("forms.noDataText")}
                  pageText={translate("forms.pageText")}
                  ofText={translate("forms.ofText")}
                  rowsText={translate("forms.rowsText")}
                  pages={this.state.tablePageCount}
                  page={this.state.tablePage}
                  pageSize={this.state.tablePageSize}
                  onPageSizeChange={(pageSize, page) =>
                    this.loadNextResults(page, pageSize)
                  }
                  onPageChange={(page) => this.loadNextResults(page)}
                />
              </div>
            </div>
          </div>
          <ToastContainer hideProgressBar />
        </div>
      );
    } else {
      return (
        <div className="preloader pl-size-lg align-center">
          <div className="spinner-layer">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>

            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default UsersPage;
