/* eslint-disable default-case */
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import RCDatePicker from "../../components/datePicker";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { translate } from "react-internationalization";
import ReactTable from "react-table";
import SimpleReactValidator from "simple-react-validator";
import $ from "jquery";
import RCSelect from "../../components/select";
import * as session from "../../components/SessionValidator";
import { submitReportDailyLog } from "../helper";
import NumberFormat from "react-number-format";
import { BANK_SERVICE_URL, CUSTOMER_SERVICE_URL } from "../../settings";
import moment from "moment";
import { getRequest, postRequest } from "../../service/RequestService";
import { formatNumber } from "../../components/util";

class BankCustomersPage extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.customerNames = {};

    this.setValidators();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitDelete = this.handleSubmitDelete.bind(this);

    this.addBankCustomer = this.addBankCustomer.bind(this);
    this.cancelBankCustomer = this.cancelBankCustomer.bind(this);
    this.resetBankCustomer = this.resetBankCustomer.bind(this);

    this.state = {
      bank: {},

      customers: null,

      bankCustomers: {
        rowIndex: -1,
        bankCustomer: {
          balanceConsumed: 0.0,
          bankId: 0,
        },
      },

      reportDailyLog: {
        startDate: moment().format(),
        objectAfter: "",
        objectBefore: "",
      },

      currentBalanceAvailable: 0,
      currentBalanceDueDate: "",
    };
  }

  async componentDidMount() {
    let _this = this;

    $("body")
      .off("change", ".js-change")
      .on("change", ".js-change", function (event) {
        _this.handleChange(event);
      });

    await getRequest(CUSTOMER_SERVICE_URL + "/api/Customer/ListActive").then(
      (res) => {
        if (res.data) {
          res.data = res.data.sort(function (a, b) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          });

          //copy names to table
          this.customerNames = JSON.parse(JSON.stringify(res.data));
        }

        this.setState({ customers: res.data });
        this.forceUpdate();
      }
    );

    if (this.id) {
      await getRequest(BANK_SERVICE_URL + "/api/bank/ListCustomer/" + this.id).then(
        (res) => {
          if (res.data) {
            if (res.data.bankCustomer && this.state.customers) {
              this.state.customers = this.state.customers.filter(function (
                item
              ) {
                return !res.data.bankCustomer.find((x) => {
                  return x.customerId === item.id;
                });
              });
            }

            this.setState({ bank: res.data });
          }

          this.forceUpdate();
        }
      );
    } else {
      let bank = {
        bankCustomer: [],
      };

      this.setState({ bank: bank });
      this.forceUpdate();
    }
  }

  async componentDidUpdate() {
    let _this = this;

    $(".js-change").each(function () {
      _this.setValue(this);
    });
    $(".js-decimal").inputmask("decimal", {
      digits: 4,
      digitsOptional: false,
      max: 999999999,
      placeholder: "0.00",
      rightAlign: false,
      showMaskOnHover: false,
    });
  }

  async handleChange(event) {
    session.validateSession();

    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    this.setState(this.state);
  }

  setValue(element) {
    let keys = element.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      property = property[key];
      if (Array.isArray(property)) property = property[element.dataset.index];
    });

    $(element).val(property);
  }

  async handleSubmit() {
    var bankCustomer = JSON.parse(
      JSON.stringify(this.state.bankCustomers.bankCustomer)
    );
    bankCustomer.bankId = this.id;

    //format
    bankCustomer.balanceAvailable = parseFloat(
      bankCustomer.balanceAvailable
        .toString()
        .replace("R$ ", "")
        .replace(/\./g, "")
        .replace(",", ".")
    );

    var itemBankCustomer = $.extend(true, {}, bankCustomer),
      status = 0;

    let reportDailyLog = this.state.reportDailyLog;

    this.setState({ submitLoading: true });

    await postRequest(
      BANK_SERVICE_URL + "/api/Bank/UpdateBankCustomer",
      itemBankCustomer
    )
      .then((res) => {
        this.setState({ submitLoading: false });

        switch (res.code) {
          case 200:
            reportDailyLog.action = 21; //21 = UpdateBankCustomer
            reportDailyLog.objectAfter = JSON.stringify(itemBankCustomer);
            submitReportDailyLog(reportDailyLog);

            ////////////////////////remover item do combo

            let customerId = parseInt(
              this.state.bankCustomers.bankCustomer.customerId
            );
            if (isNaN(customerId) || customerId <= 0) return;

            var customers = this.state.customers;
            var customer = customers.splice(
              customers.findIndex((item) => {
                return item.id === customerId;
              }),
              1
            )[0];

            this.state.customers = customers;

            ////////////////////////remover item do combo

            var bankCustomer = JSON.parse(
              JSON.stringify(this.state.bankCustomers.bankCustomer)
            );
            bankCustomer.id = res.data.id;
            bankCustomer.name = customer.name;
            bankCustomer.balanceConsumed = 0;

            //format
            bankCustomer.balanceAvailable = parseFloat(
              bankCustomer.balanceAvailable
                .toString()
                .replace("R$ ", "")
                .replace(/\./g, "")
                .replace(",", ".")
            );

            if (this.state.bankCustomers.rowIndex < 0) {
              this.state.bank.bankCustomer.push(bankCustomer);
            } else {
              this.state.bank.bankCustomer[this.state.bankCustomers.rowIndex] =
                bankCustomer;
            }

            this.resetBankCustomer();

            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("error");
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async handleSubmitDelete(bankCustomer, rowIndex) {
    bankCustomer.bankId = this.id;

    //format
    bankCustomer.balanceAvailable = parseFloat(
      bankCustomer.balanceAvailable
        .toString()
        .replace("R$ ", "")
        .replace(/\./g, "")
        .replace(",", ".")
    );

    if (bankCustomer.balanceDueDate)
      bankCustomer.balanceDueDate = moment(
        bankCustomer.balanceDueDate,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");

    var itemBankCustomer = $.extend(true, {}, bankCustomer),
      status = 0;

    let reportDailyLog = this.state.reportDailyLog;

    this.setState({ submitLoading: true });

    await postRequest(
      BANK_SERVICE_URL + "/api/Bank/DeleteBankCustomer",
      itemBankCustomer
    )
      .then((res) => {
        this.setState({ submitLoading: false });

        switch (res.code) {
          case 200:
            reportDailyLog.action = 22; //22 = deleteBankCustomer
            reportDailyLog.objectAfter = JSON.stringify(itemBankCustomer);
            submitReportDailyLog(reportDailyLog);

            ////////////////////////

            var items = this.state.bank.bankCustomer;
            items.splice(rowIndex, 1);

            this.state.bank.bankCustomer = items;

            ////////////////////////2

            let customerId = parseInt(bankCustomer.customerId);
            if (isNaN(customerId) || customerId <= 0) return;

            var customers = JSON.parse(JSON.stringify(this.customerNames));

            var customer = customers.splice(
              customers.findIndex((item) => {
                return item.id === customerId;
              }),
              1
            )[0];

            this.state.customers.push(customer);
            this.state.customers.sort(function (a, b) {
              return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            });

            ////////////////////////2

            if (this.state.bankCustomers.rowIndex === rowIndex) {
              this.resetBankCustomer();
            } else {
              this.setState(this.state);
            }

            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("error");
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  setValidators() {
    this.validator = new SimpleReactValidator({
      balanceDueDateLaterThanCurrentDate: {
        rule: () => {
          return (
            !this.state.bankCustomers.bankCustomer.balanceDueDate ||
            moment(
              this.state.bankCustomers.bankCustomer.balanceDueDate,
              "YYYY-MM-DD"
            ).isSameOrAfter(moment(new Date(), "YYYY-MM-DD"))
          );
        },
      },
    });
  }

  addBankCustomer() {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();

      return;
    }

    //salvar na base de dados
    this.handleSubmit();
  }

  editBankCustomer(rowIndex) {
    if (this.state.bankCustomers.rowIndex == -1) {
      this.state.reportDailyLog.objectBefore = JSON.stringify(
        this.state.bank.bankCustomer[rowIndex]
      );

      var bankCustomer = this.state.bank.bankCustomer[rowIndex];

      this.state.bankCustomers.bankCustomer = JSON.parse(
        JSON.stringify(bankCustomer)
      );
      this.state.bankCustomers.rowIndex = rowIndex;

      let balanceAvailable =
        this.state.bankCustomers.bankCustomer.balanceAvailable;

      this.state.currentBalanceAvailable = balanceAvailable;
      this.state.currentBalanceDueDate =
        this.state.bankCustomers.bankCustomer.balanceDueDate;

      ///////////////////////

      let customerId = parseInt(
        this.state.bankCustomers.bankCustomer.customerId
      );
      if (isNaN(customerId) || customerId <= 0) return;

      var customers = JSON.parse(JSON.stringify(this.customerNames));

      var customer = customers.splice(
        customers.findIndex((item) => {
          return item.id === customerId;
        }),
        1
      )[0];

      customer = !customers.find((x) => {
        return x.id === customerId;
      })
        ? Object.create({
            id: bankCustomer.customerId,
            name: bankCustomer.name,
          })
        : customer;

      this.state.customers.push(customer);

      ////////////////////////

      this.setState(this.state);
    }
  }

  deleteBankCustomer(rowIndex) {
    confirmAlert({
      buttons: [
        {
          label: translate("forms.buttonYes"),
          onClick: () => {
            let bankCustomer = JSON.parse(
              JSON.stringify(this.state.bank.bankCustomer[rowIndex])
            );

            this.handleSubmitDelete(bankCustomer, rowIndex);
          },
        },
        {
          label: translate("forms.buttonNo"),
          onClick: () => {
            window.close();
          },
        },
      ],
      message: translate("bank.bankCustomerDeleteTitle"),
      title: translate("bank.bankCustomerDeleteQuestion"),
    });
  }

  cancelBankCustomer() {
    ////////////////////////

    let customerId = parseInt(this.state.bankCustomers.bankCustomer.customerId);
    if (isNaN(customerId) || customerId <= 0) return;

    var customers = this.state.customers;
    var customer = customers.splice(
      customers.findIndex((item) => {
        return item.id === customerId;
      }),
      1
    )[0];

    this.state.customers = customers;

    ////////////////////////

    this.resetBankCustomer();
  }

  resetBankCustomer() {
    this.state.bankCustomers = {
      rowIndex: -1,
      bankCustomer: {
        balanceConsumed: 0.0,
        balanceAvailable: null,
      },
    };

    this.state.currentBalanceAvailable = null;
    this.state.currentBalanceDueDate = null;

    if (this.validator !== undefined) {
      this.validator.hideMessages();
    }

    this.state.reportDailyLog = {
      startDate: moment().format(),
      objectAfter: "",
      objectBefore: "",
    };

    this.setState(this.state);
  }

  render() {
    var fetched =
      this.state != null &&
      this.state.bank != null &&
      this.state.customers != null;

    if (fetched) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <div className="card">
              <div className="header">
                <h2>
                  <ul className="nav nav-tabs tab-nav-right" role="tablist">
                    <li className="active" role="presentation">
                      <a href="#customerPanel" data-toggle="tab">
                        {translate("bank.bankCustomerTitle")} -{" "}
                        {this.state.bank.legalName}
                      </a>
                    </li>
                  </ul>
                </h2>

                <div className="header-dropdown">
                  <div
                    className="preloader pl-size-xs"
                    style={{
                      display: this.state.submitLoading ? "block" : "none",
                    }}
                  >
                    <div className="spinner-layer">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>

                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="body">
                <div className="tab-content">
                  <div
                    id="customerPanel"
                    className="tab-pane fade in active"
                    role="tabpanel"
                  >
                    <div>
                      <div className="m-b-40">
                        <small>
                          {translate("bank.bankCustomerTitleDescription")}
                        </small>
                      </div>
                    </div>

                    <div>
                      <div className="row clearfix">
                        <div className="col-md-6">
                          <div className="m-b-20">
                            <label
                              className="validationRequired"
                              htmlFor="cmbCustomer"
                            >
                              {translate("bank.bankCustomer")}
                            </label>

                            <RCSelect
                              id="cmbCustomer"
                              name="bankCustomers.bankCustomer.customerId"
                              hasEmptyOption={true}
                              isDisabled={
                                this.state.bankCustomers.rowIndex >= 0
                              }
                              isInt={true}
                              options={this.state.customers}
                              optionValue="id"
                              optionLabel="name"
                              placeholder={translate(
                                "bank.bankCustomerPlaceHolder"
                              )}
                              onChange={(event) => {
                                this.handleChange(event);
                              }}
                              value={
                                this.state.bankCustomers.bankCustomer.customerId
                              }
                            />

                            {this.validator.message(
                              "bankCustomers.bankCustomer.customerId",
                              this.state.bankCustomers.bankCustomer.customerId,
                              "gt: 0",
                              false,
                              {
                                default: translate("bank.bankCustomerRequired"),
                              }
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-4">
                          <div className="input-group">
                            <label>
                              {translate("bank.bankBalanceAvailable")}
                              <b>
                                {this.state.currentBalanceAvailable
                                  ? translate(
                                      "bank.bankBalanceAvailableCurrent"
                                    ) +
                                    new Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    }).format(
                                      this.state.currentBalanceAvailable
                                    )
                                  : ""}
                              </b>
                            </label>

                            <div className="form-line">
                              <NumberFormat
                                prefix="R$ "
                                placeholder={translate(
                                  "bank.bankBalanceAvailablePlaceHolder"
                                )}
                                className="form-control"
                                value={
                                  this.state.bankCustomers.bankCustomer
                                    .balanceAvailable
                                }
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                onValueChange={(values) => {
                                  const { formattedValue, value } = values;

                                  let bankCustomers = this.state.bankCustomers;
                                  bankCustomers.bankCustomer.balanceAvailable =
                                    formattedValue;

                                  // formattedValue = $2,223
                                  // value ie, 2223
                                  this.setState({
                                    bankCustomers: bankCustomers,
                                  });
                                }}
                              />
                            </div>

                            {this.validator.message(
                              "bankCustomers.bankCustomer.balanceAvailable",
                              this.state.bankCustomers.bankCustomer
                                .balanceAvailable,
                              "required",
                              false,
                              {
                                default: translate(
                                  "bank.bankBalanceAvailableRequired"
                                ),
                              }
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-group">
                            <label>
                              {translate("bank.bankBalanceDueDate")}
                              <b>
                                {this.state.currentBalanceDueDate
                                  ? translate(
                                      "bank.bankBalanceDueDateCurrent"
                                    ) +
                                    moment(
                                      this.state.currentBalanceDueDate,
                                      "YYYY-MM-DD"
                                    ).format("DD-MM-YYYY")
                                  : ""}
                              </b>
                            </label>

                            <div className="form-line">
                              <RCDatePicker
                                id="dtBalanceDueDate"
                                name="bankCustomers.bankCustomer.balanceDueDate"
                                value={
                                  this.state.bankCustomers.bankCustomer
                                    .balanceDueDate
                                }
                                onChange={this.handleChange}
                              />
                            </div>

                            {this.validator.message(
                              "bankCustomers.bankCustomer.balanceDueDate",
                              this.state.bankCustomers.bankCustomer
                                .balanceDueDate,
                              "required|balanceDueDateLaterThanCurrentDate",
                              false,
                              {
                                default: translate(
                                  "bank.bankBalanceDueDateRequired"
                                ),
                                balanceDueDateLaterThanCurrentDate: translate(
                                  "bank.bankBalanceDueDateLaterThanEndDate"
                                ),
                              }
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input-group">
                            <label
                              className="validationRequired"
                              htmlFor="txtCustomerRate"
                            >
                              {translate("bank.bankCustomerRate")}
                            </label>

                            <div className="form-line">
                              <input
                                id="txtCustomerRate"
                                className="form-control js-change js-decimal"
                                name="bankCustomers.bankCustomer.rate"
                                placeholder={translate(
                                  "bank.bankCustomerRatePlaceHolder"
                                )}
                                type="text"
                              />
                            </div>

                            {this.validator.message(
                              "bankCustomers.bankCustomer.rate",
                              this.state.bankCustomers.bankCustomer.rate,
                              "required",
                              false,
                              {
                                default: translate(
                                  "bank.bankCustomerRateRequired"
                                ),
                              }
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-12">
                          <button
                            type="button"
                            className="btn m-r-10"
                            onClick={this.cancelBankCustomer}
                            style={{
                              display:
                                this.state.bankCustomers.rowIndex >= 0
                                  ? "inline-block"
                                  : "none",
                            }}
                          >
                            <i className="material-icons">&#xe5c4;</i>
                            <span>{translate("forms.buttonCancel")}</span>
                          </button>

                          <button
                            type="button"
                            className="btn btn-primary"
                            disabled={this.state.submitLoading}
                            onClick={this.addBankCustomer}
                          >
                            <i className="material-icons">&#xe145;</i>
                            <span>
                              {translate(
                                this.state.bankCustomers.rowIndex < 0
                                  ? "bank.bankCustomerAddNew"
                                  : "bank.bankCustomerUpdate"
                              )}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <ReactTable
                      data={this.state.bank.bankCustomer}
                      columns={[
                        {
                          columns: [
                            {
                              Header: translate("bank.bankCustomer"),
                              id: "name",
                              accessor: "name",
                            },
                            {
                              Header: translate("bank.bankBalanceConsumed"),
                              id: "balanceConsumed",
                              accessor: (data) => {
                                return new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(data.balanceConsumed);
                              },
                            },
                            {
                              Header: translate("bank.bankBalanceAvailable"),
                              id: "balanceAvailable",
                              accessor: (data) => {
                                return new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(data.balanceAvailable);
                              },
                            },
                            {
                              Header: translate("bank.bankBalanceDueDate"),
                              id: "balanceDueDate",
                              accessor: (data) => {
                                return moment(
                                  data.balanceDueDate,
                                  "YYYY-MM-DD"
                                ).format("DD-MM-YYYY");
                              },
                            },
                            {
                              Header: translate("bank.bankCustomerRate"),
                              id: "rate",
                              accessor: (data) => {
                                return formatNumber(data.rate) + "%";
                              },
                            },
                            {
                              Cell: (row) => (
                                <div className="align-center">
                                  <button
                                    disabled={
                                      this.state.submitLoading ||
                                      this.state.bankCustomers.rowIndex >= 0
                                    }
                                    className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10"
                                    type="button"
                                    onClick={() => {
                                      this.deleteBankCustomer(row.index);
                                    }}
                                  >
                                    <i className="material-icons">&#xe872;</i>
                                  </button>

                                  <button
                                    disabled={
                                      this.state.submitLoading ||
                                      this.state.bankCustomers.rowIndex >= 0
                                    }
                                    type="button"
                                    className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10"
                                    onClick={() => {
                                      this.editBankCustomer(row.index);
                                    }}
                                  >
                                    <i className="material-icons">&#xe3c9;</i>
                                  </button>
                                </div>
                              ),
                              maxWidth: 150,
                            },
                          ],
                        },
                      ]}
                      defaultPageSize={10}
                      className="-striped -highlight m-b-40"
                      previousText={translate("forms.previousText")}
                      nextText={translate("forms.nextText")}
                      noDataText={translate("forms.noDataText")}
                      pageText={translate("forms.pageText")}
                      ofText={translate("forms.ofText")}
                      rowsText={translate("forms.rowsText")}
                    />
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-12 formActions">
                    <Link to="/banks" className="btn btn-default waves-effect">
                      <i className="material-icons">&#xe5c4;</i>
                      <span>{translate("forms.buttonReturn")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer hideProgressBar />
        </div>
      );
    } else {
      return (
        <div className="preloader pl-size-lg align-center">
          <div className="spinner-layer">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>

            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const ToastRedirect = ({ tabToShow, message, closeToast }) => {
  function handleClick() {
    tabToShow.trigger("click");
    closeToast();
  }

  return <div onClick={handleClick}>{message}</div>;
};

export default BankCustomersPage;
