import React from "react";
import ReactDOM from "react-dom";

import { CookiesProvider } from "react-cookie";

import {
  InternationalizationProvider,
  internationalize,
} from "react-internationalization";

import * as languages from "../src/translations";

import "./include/bootstrap";
import "./components/admin";
import "./App.css";

import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

const InternationalizedApp = internationalize(App);

ReactDOM.render(
  <InternationalizationProvider
    defaultLanguage="pt"
    languages={languages}
    dynamicImports
  >
    <CookiesProvider>
      <InternationalizedApp />
    </CookiesProvider>
  </InternationalizationProvider>,

  document.getElementById("root"),
);

registerServiceWorker();
