import React from "react";
import Modal from "react-responsive-modal";
import { translate } from "react-internationalization";
import $ from "jquery";
import * as session from "../SessionValidator";

import { Document, Page, pdfjs } from "react-pdf";
import { DOCUMENT_SERVICE_URL } from "../../settings";
import { getRequest } from "../../service/RequestService";
pdfjs.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js";

export default class ModalContract extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfPages: null,
      pageNumber: 1,
      scale: 1.5,
      document: null,
    };
  }

  handleReturn() {
    this.props.close();
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numberOfPages: numPages });
  };

  nextPageDocument() {
    if (this.state.pageNumber < this.state.numberOfPages) {
      this.state.pageNumber = this.state.pageNumber + 1;
      this.setState(this.state);
    }
  }

  previousPageDocument() {
    if (this.state.pageNumber > 1) {
      this.state.pageNumber = this.state.pageNumber - 1;
      this.setState(this.state);
    }
  }

  changeScale(plus) {
    let scale = this.state.scale;

    if (plus && scale < 5) {
      scale += 0.5;
      this.setState({ scale: scale });
    } else if (!plus && scale > 1.5) {
      scale -= 0.5;
      this.setState({ scale: scale });
    }
  }

  scrollDocument() {
    $(".react-pdf__Page")
      .off("mousedown")
      .on("mousedown", function (evt) {
        let start = evt.pageX,
          elm = $(this),
          startPos = elm.scrollLeft();
        elm.mousemove(function (evt) {
          elm.scrollLeft(startPos + (start - evt.pageX));
          return false;
        });

        elm.one("mouseup", function () {
          elm.off("mousemove");
        });
        evt.stopPropagation();

        return false;
      });
  }

  async componentDidMount() {
    if (!this.props.fileHash) return;

    if (!this.props.document) {
      await getRequest(
        DOCUMENT_SERVICE_URL +
          "/api/document/" +
          this.props.fileHash +
          "?withFile=true",
      ).then((res) => {
        this.setState({ document: res.data });
      });
    } else {
      this.setState({ document: this.props.document });
    }
  }

  componentDidUpdate() {
    this.scrollDocument();
  }

  render() {
    if (this.state.document !== null) {
      return (
        <div>
          <Modal
            open={this.props.open}
            closeOnEsc={true}
            onClose={() => {
              this.props.close();
            }}
            onEntered={() => {
              this.forceUpdate();
            }}
            closeIconSize={25}
            center={true}
            styles={{ modal: { maxWidth: "80%" } }}
          >
            <div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="card">
                    <div className="header">
                      <h2>
                        {translate("transaction.transactionContractTitle")}
                        <small>
                          {translate(
                            "transaction.transactionContractVisualize",
                          )}
                        </small>
                      </h2>
                    </div>

                    <div className="body">
                      <div className="row clearfix">
                        <div className="col-md-12">
                          <div className="align-center">
                            <div className="m-b-10">
                              <button
                                type="button"
                                className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10"
                                onClick={() => {
                                  this.previousPageDocument();
                                }}
                              >
                                <i className="material-icons">&#xe408;</i>
                              </button>

                              <button
                                type="button"
                                className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-5"
                                onClick={() => {
                                  this.changeScale(false);
                                }}
                              >
                                <i className="material-icons">&#xe15b;</i>
                              </button>

                              <button
                                type="button"
                                className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-l-5"
                                onClick={() => {
                                  this.changeScale(true);
                                }}
                              >
                                <i className="material-icons">&#xe145;</i>
                              </button>

                              <button
                                type="button"
                                className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-l-10"
                                onClick={() => {
                                  this.nextPageDocument();
                                }}
                              >
                                <i className="material-icons">&#xe409;</i>
                              </button>
                            </div>

                            {this.state.document && (
                              <Document
                                file={`data:application/pdf;base64,${this.state.document.file}`}
                                onLoadSuccess={this.onDocumentLoad}
                              >
                                <Page
                                  pageNumber={this.state.pageNumber}
                                  scale={this.state.scale}
                                />
                              </Document>
                            )}

                            {this.state.numberOfPages && (
                              <p className="m-t-20">
                                {translate("document.documentPaginationPage") +
                                  " " +
                                  this.state.pageNumber +
                                  " " +
                                  translate("document.documentPaginationOf") +
                                  " " +
                                  this.state.numberOfPages}
                              </p>
                            )}

                            <div className="m-t-10">
                              <button
                                type="button"
                                className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10"
                                onClick={() => {
                                  this.previousPageDocument();
                                }}
                              >
                                <i className="material-icons">&#xe408;</i>
                              </button>

                              <button
                                type="button"
                                className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-5"
                                onClick={() => {
                                  this.changeScale(false);
                                }}
                              >
                                <i className="material-icons">&#xe15b;</i>
                              </button>

                              <button
                                type="button"
                                className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-l-5"
                                onClick={() => {
                                  this.changeScale(true);
                                }}
                              >
                                <i className="material-icons">&#xe145;</i>
                              </button>

                              <button
                                type="button"
                                className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-l-10"
                                onClick={() => {
                                  this.nextPageDocument();
                                }}
                              >
                                <i className="material-icons">&#xe409;</i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row m-t-20">
                  <div className="col-xs-12 align-right">
                    <button
                      type="button"
                      className="btn btn-default waves-effect m-r-15"
                      disabled={this.props.loading}
                      onClick={() => {
                        this.handleReturn();
                      }}
                    >
                      <i className="material-icons">&#xe5c4;</i>
                      <span>{translate("forms.buttonReturn")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    } else {
      return <div className="col-md-12"></div>;
    }
  }
}
