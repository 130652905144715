export const cpf_cnpj_Mask = (document) => {
  document = document.replace(/\D/g, "");

  if (document.length <= 11)
    return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  return document.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5",
  );
};
