export const removeSession = (localStorage) => {
  localStorage.removeItem("authp");
  localStorage.removeItem("userId");
  localStorage.removeItem("email");
  localStorage.removeItem("name");
  localStorage.removeItem("customerId");
  localStorage.removeItem("supplierId");
  localStorage.removeItem("bankId");
  localStorage.removeItem("ia"); // is admin
  localStorage.removeItem("io"); // is operator
  localStorage.removeItem("ut"); // isUserTerm
  localStorage.removeItem("t"); // type user
  localStorage.removeItem("r"); // roles
};

export const logoutSession = (localStorage) => {
  removeSession(localStorage);
  localStorage.removeItem("sessionTimeOut");
  localStorage.removeItem("clickHour");
};

export const loginSession = (localStorage, response) => {
  localStorage.setItem("userId", response.userId);
  localStorage.setItem("email", response.email);
  localStorage.setItem("name", response.name);

  localStorage.setItem("customerId", response.customerId);
  localStorage.setItem("supplierId", response.supplierId);
  localStorage.setItem("bankId", response.bankId);

  localStorage.setItem("ia", response.isAdmin); // is admin
  localStorage.setItem("io", response.isOperator); // is operator
  localStorage.setItem("ut", response.isUseTerm); // UseTerm

  localStorage.setItem("t", response.type); // type user

  localStorage.setItem("r", JSON.stringify(response.roles)); // roles
};
