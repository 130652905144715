////Server dev
// export const ACCOUNT_SERVICE_URL = 'http://localhost:4001/account/service/v1'
// export const BANK_SERVICE_URL = 'http://localhost:4002/bank/service/v1'
// export const CUSTOMER_SERVICE_URL = "http://localhost:8080/customer/service/v1";
// export const DOCUMENT_SERVICE_URL = 'http://localhost:4004/document/service/v1'
// export const LOG_SERVICE_URL = 'http://localhost:4005/log/service/v1'
// export const SUPPLIER_SERVICE_URL = 'http://localhost:4006/supplier/service/v1'
// export const TRANSACTION_SERVICE_URL = 'http://localhost:4007/transaction/service/v1'

////Server prod
export const ACCOUNT_SERVICE_URL =
  "https://sacadoapi.simpleonetech.com/account/service/v1";
export const BANK_SERVICE_URL =
  "https://sacadoapi.simpleonetech.com/bank/service/v1";
export const CUSTOMER_SERVICE_URL =
  "https://sacadoapi.simpleonetech.com/customer/service/v1";
export const DOCUMENT_SERVICE_URL =
  "https://sacadoapi.simpleonetech.com/document/service/v1";
export const LOG_SERVICE_URL =
  "https://sacadoapi.simpleonetech.com/log/service/v1";
export const SUPPLIER_SERVICE_URL =
  "https://sacadoapi.simpleonetech.com/supplier/service/v1";
export const TRANSACTION_SERVICE_URL =
  "https://sacadoapi.simpleonetech.com/transaction/service/v1";

export const CLIENT_ID = "PortalDefault";

export const APP_TITLE = "SacadoPay";
export const APP_URL = "https://portal.simpleonetech.com/";
