import React, { Component } from "react";
import * as session from "../components/SessionValidator";
import { LOG_SERVICE_URL } from "../settings";
import moment from "moment";
import { postRequest } from "../service/RequestService";

class Helper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
}

export function submitReportDailyLog(reportDailyLog) {
  reportDailyLog.endDate = moment().format();

  postRequest(
    LOG_SERVICE_URL + "/api/ReportDailyLog/Create",
    reportDailyLog,
  ).then();
}

export default Helper;
