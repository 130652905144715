/* eslint-disable default-case */
import React from "react";
import { translate } from "react-internationalization";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import SimpleReactValidator from "simple-react-validator";
import $ from "jquery";
import * as session from "../../components/SessionValidator";
import { post } from "axios";
import ReactTable from "react-table";
import { SUPPLIER_SERVICE_URL } from "../../settings";

class ImportSuppliersPage extends React.Component {
  constructor(props) {
    super(props);

    this.setBinds();
    this.setValidators();

    this.state = {
      loading: false,
      importSupplier: {
        file: [],
      },
      resultError: [],
    };
  }

  setValidators() {
    this.importSuppliersValidator = new SimpleReactValidator({
      attachmentInvalid: {
        rule: () => {
          if ($("#fileAttachments").length <= 0) return false;

          let files = $("#fileAttachments")[0].files;

          if (files.length <= 0) return false;

          if (/(.xls|.xlsx)/i.test(files[0].name.toLowerCase()) === false)
            return false;

          return true;
        },
      },

      attachmentRequired: {
        rule: () => {
          if ($("#fileAttachments").length <= 0) return false;

          let files = $("#fileAttachments")[0].files;

          if (files.length <= 0) return false;

          return true;
        },
      },
    });
  }

  setBinds() {
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    if (!this.importSuppliersValidator.allValid()) {
      this.importSuppliersValidator.showMessages();
      this.forceUpdate();

      return;
    } else {
      this.setState({ loading: true, resultError: [] });

      let status;

      const url = SUPPLIER_SERVICE_URL + "/api/Supplier/ImportFile";
      const formData = new FormData();
      formData.append("file", this.file.files[0]);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: this.token,
        },
      };

      var self = this;

      post(url, formData, config)
        .then(function (res) {
          self.setState({ loading: false });
          status = res.status;
          switch (status) {
            case 200:
              confirmAlert({
                buttons: [
                  {
                    label: translate("forms.buttonYes"),
                    onClick: () => {
                      self.file.value = null;

                      self.setState({ resultError: [] });

                      self.forceUpdate();
                    },
                  },
                  {
                    label: translate("forms.buttonNo"),
                    onClick: () => {
                      self.props.history.push("/");
                    },
                  },
                ],
                message: translate(
                  "importSuppliers.importSupplierDocumentProcessedQuestion",
                ),
                title: translate(
                  "importSuppliers.importSupplierDocumentProcessedQuestionTitle",
                ),
                closeOnClickOutside: false,
                closeOnEscape: false,
              });
              break;
            case 401:
              session.sessionExpired();
              res = [];

              return res;

            case 403:
              session.accessDenied();
              res = [];
              return res;
          }
        })
        .catch(function (error) {
          self.setState({ loading: false });

          console.log(error);

          if (error.response === undefined) {
            toast.error("Erro na comunica��o com o servidor.");
            return;
          }

          status = error.response.status;

          switch (status) {
            case 401:
              session.sessionExpired();
              error = [];
              return error;
            case 403:
              session.accessDenied();
              error = [];
              return error;
            case 400:
            case 409:
              var messages = error.response.data.data;
              if (messages) {
                messages.forEach((ex) => self.state.resultError.push(ex));
                self.forceUpdate();
              }
              break;

            case 500:
              toast.error("Erro na comunica��o com o servidor. 500");
              break;
          }
        });
    }
  }

  render() {
    var fetched = true;

    if (fetched) {
      return (
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="card">
              <div className="header">
                <h2>
                  {translate("importSuppliers.importSupplierTitle")}
                  <small>
                    {translate(
                      "importSuppliers.importSupplierTitleDescription",
                    )}
                  </small>
                </h2>

                <h5>
                  <a download href="/import-suppliers.xlsx">
                    {translate("importSuppliers.importSupplierFileExample")}
                  </a>
                </h5>

                <ul className="header-dropdown">
                  <div
                    className="preloader pl-size-xs"
                    style={{ display: this.state.loading ? "block" : "none" }}
                  >
                    <div className="spinner-layer">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>

                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>

              <div className="body">
                <div className="row clearfix">
                  <div className="col-md-6">
                    <div className="row clearfix">
                      <div className="col-md-8">
                        <div className="row clearfix">
                          <div className="col-md-12">
                            <div className="fallback">
                              <input
                                className="js-change"
                                name="fileAttachments"
                                id="fileAttachments"
                                type="file"
                                ref={(x) => (this.file = x)}
                                accept=".xls, .xlsx"
                              />
                              {this.importSuppliersValidator.message(
                                "fileAttachments",
                                this.state.importSupplier.file,
                                "attachmentRequired|attachmentInvalid",
                                false,
                                {
                                  default: translate(
                                    "importSuppliers.importSupplierAttachmentsRequired",
                                  ),
                                  attachmentInvalid: translate(
                                    "importSuppliers.importSupplierAttachmentsInvalid",
                                  ),
                                },
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <button
                        className="btn btn-success m-t-15 waves-effect m-l-15"
                        disabled={this.state.loading}
                        onClick={this.handleSubmit}
                        type="button"
                      >
                        <i className="material-icons">&#xe873;</i>
                        <span>{translate("forms.buttonProcessFileExcel")}</span>
                      </button>
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-md-12">
                      <ReactTable
                        style={{
                          width: "100%",
                          display:
                            this.state.resultError.length > 0
                              ? "inline-block"
                              : "none",
                        }}
                        data={this.state.resultError}
                        columns={[
                          {
                            columns: [
                              {
                                Header: translate(
                                  "importSuppliers.importSupplierError",
                                ),
                                accessor: "message",
                              },
                            ],
                          },
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight m-b-40"
                        previousText={translate("forms.previousText")}
                        nextText={translate("forms.nextText")}
                        noDataText={translate("forms.noDataText")}
                        pageText={translate("forms.pageText")}
                        ofText={translate("forms.ofText")}
                        rowsText={translate("forms.rowsText")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer hideProgressBar />
        </div>
      );
    } else {
      return (
        <div className="preloader pl-size-lg align-center">
          <div className="spinner-layer">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>

            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ImportSuppliersPage;
