import $ from "jquery";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { translate } from "react-internationalization";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { toast, ToastContainer } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import RCDatePicker from "../../components/datePicker";
import * as enums from "../../components/enums/invoice";
import RCSelect from "../../components/select";
import * as session from "../../components/SessionValidator";
import ModalSupplierSearch from "../../components/supplier/modal-supplier-search";
import ModalAddress from "../../components/transaction/modal-address";
import ModalBankAccount from "../../components/transaction/modal-bank-account";
import ModalSummaryValues from "../../components/transaction/summary-values";
import { submitReportDailyLog } from "../helper";
import UseTermComponent from "../../components/UseTermComponent";
import { CUSTOMER_SERVICE_URL, TRANSACTION_SERVICE_URL } from "../../settings";
import moment from "moment";
import { getRequest, postRequest } from "../../service/RequestService";
import xmlFormat from "xml-formatter";

class TransactionPage extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.setValidators();

    this.setBinds();

    this.state = {
      searchText: "",

      search: {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().add(3, "M").format("YYYY-MM-DD"),
        customerId: this.id != null ? this.id : 0,
        supplierId: 0,
        bankId: 0,
        invoiceNumber: "",
        status: 1,
      },

      customers: [],
      suppliers: [],
      invoices: [],
      selectedInvoices: [],

      selected: {},
      selectAll: 0,

      totalSum: 0,
      totalDiscountValue: 0,
      totalNetValue: 0,

      reportDailyLog: {
        startDate: moment().format(),
        objectAfter: "",
        objectBefore: "",
      },

      step02: false,
      bankAccount: null,
      address: {
        name: "",
        legalName: "",
        document: "",
        documentType: 0,
        country: {},
        city: "",
        district: "",
        street: "",
        number: "",
        complement: "",
        zipCode: "",
        isMaster: true,
        phoneNumber: "",
        email: "",
        stateRegistration: "",
        state: {
          name: "",
        },
      },
      supplier: {},
      scrollEnd: null,

      supplierSearchOpen: false,
      supplierLoading: false,
      loading: false,
      modalOpen: false,
    };
  }

  setBinds() {
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.toggleRow = this.toggleRow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleChangeBankAccount = this.handleChangeBankAccount.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);

    this.handleChangeSupplierSearch =
      this.handleChangeSupplierSearch.bind(this);
    this.handleReturnSupplierSearch =
      this.handleReturnSupplierSearch.bind(this);
    this.handleResultSupplierSearch =
      this.handleResultSupplierSearch.bind(this);
  }

  download(filename, xml) {
    if (!xml) {
      return;
    }

    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/xml;charset=utf-8," +
        encodeURIComponent(xmlFormat(xml, { collapseContent: true }))
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  selectCalculate(invoiceId, isSelected) {
    var selectedInvoices = JSON.parse(
      JSON.stringify(this.state.selectedInvoices)
    );

    var invoices = JSON.parse(JSON.stringify(this.state.invoices));

    //add
    if (isSelected) {
      selectedInvoices.push(
        invoices.find((x) => {
          return x.id === invoiceId;
        })
      );
    } else {
      //remove
      var itemDeleted = selectedInvoices.splice(
        selectedInvoices.findIndex((item) => {
          return item.id === invoiceId;
        }),
        1
      )[0];
    }

    const totalSum = selectedInvoices.reduce(
      (result, item) => result + item.amount,
      0
    );
    const totalDiscountValue = selectedInvoices.reduce(
      (result, item) => result + item.discountValue,
      0
    );
    const totalNetValue = selectedInvoices.reduce(
      (result, item) => result + item.netValue,
      0
    );

    this.setState({
      selectedInvoices: selectedInvoices,
      totalSum: totalSum,
      totalDiscountValue: totalDiscountValue,
      totalNetValue: totalNetValue,
    });
  }

  selectAllCalculate(isSelectAll) {
    var selectedInvoices = JSON.parse(
      JSON.stringify(this.state.selectedInvoices)
    );

    var invoices = JSON.parse(JSON.stringify(this.state.invoices));

    var totalSum = 0;
    var totalDiscountValue = 0;
    var totalNetValue = 0;

    //add
    if (isSelectAll) {
      selectedInvoices = invoices;

      totalSum = selectedInvoices.reduce(
        (result, item) => result + item.amount,
        0
      );
      totalDiscountValue = selectedInvoices.reduce(
        (result, item) => result + item.discountValue,
        0
      );
      totalNetValue = selectedInvoices.reduce(
        (result, item) => result + item.netValue,
        0
      );
    } else {
      //remove
      selectedInvoices = [];
    }

    this.setState({
      selectedInvoices: selectedInvoices,
      totalSum: totalSum,
      totalDiscountValue: totalDiscountValue,
      totalNetValue: totalNetValue,
    });
  }

  toggleRow(invoiceId) {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[invoiceId] = !this.state.selected[invoiceId];

    this.setState({
      selected: newSelected,
      selectAll: 2,
    });

    this.selectCalculate(invoiceId, newSelected[invoiceId]);
  }

  toggleSelectAll() {
    let newSelected = {};

    if (this.state.selectAll === 0) {
      this.state.invoices.forEach((x) => {
        newSelected[x.id] = true;
      });
    }

    this.selectAllCalculate(this.state.selectAll === 0 ? 1 : 0);

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
    });
  }

  setValidators() {
    let _this = this;

    _this.validator = new SimpleReactValidator({
      startDateLaterThanEndDate: {
        rule: () => {
          return (
            !_this.state.search.startDate ||
            !_this.state.search.endDate ||
            moment(_this.state.search.startDate, "YYYY-MM-DD").isSameOrBefore(
              moment(_this.state.search.endDate, "YYYY-MM-DD")
            )
          );
        },
      },

      supplierSelected: {
        rule: function () {
          var status = true;

          if (
            !session.isSupplier() &&
            _this.state.search &&
            _this.state.search.supplierId <= 0
          ) {
            status = false;
          }

          return status;
        },
      },
    });
  }

  async componentDidMount() {
    var _this = this;
    var customerId;

    this.setState({ customerLoading: true });

    $("body")
      .off("change", ".js-change")
      .on("change", ".js-change", function (event) {
        _this.handleChange(event);
      });

    $(".js-integer").inputmask({
      greedy: false,
      mask: "9",
      repeat: "*",
      showMaskOnHover: false,
    });

    await getRequest(CUSTOMER_SERVICE_URL + "/api/Customer/ListActive").then(
      (res) => {
        if (res.data) {
          res.data = res.data.sort(function (a, b) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          });
          customerId = res.data[0].id;
        }

        this.setState({ customers: res.data });
        this.setState({ customerLoading: false });

        this.forceUpdate();
      }
    );

    if (this.id) {
      this.loadSuppliers(this.id); // Combo Fornecedores
    }

    if (session.isSupplier()) {
      this.state.search.customerId = customerId;
      this.state.search.supplierId = session.getSupplierId(); // O Id do Fornecedor quando se entra como fornecedor
      this.validator.fields["cmbSupplier"] = true;
      this.handleSearch();
    }
  }

  async handleChange(event) {
    session.validateSession();

    if (event.target.id === "cmbCustomer" && !session.isSupplier()) {
      this.state.search.supplierId = null;
      this.state.suppliers = [];

      if (event.target.value > 0) this.loadSuppliers(event.target.value);
    } else if (event.target.id === "cmbCustomer" && session.isSupplier()) {
      this.state.search.customerId = event.target.value;
      this.validator.fields["cmbCustomer"] = true;
      await this.handleSearch();
    }

    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    if (event.target.id === "cmbSupplier") {
      this.state.step02 = false;
      this.state.selectedInvoices = [];
      this.state.invoices = [];
      this.state.totalSum = 0;
      this.state.totalDiscountValue = 0;
      this.state.totalNetValue = 0;
    }

    this.setState(this.state);
  }

  handleChangeSupplierSearch(supplierId) {
    this.state.search.supplierId = supplierId;
    this.state.supplierSearchOpen = false;
    this.forceUpdate();
  }

  handleReturnSupplierSearch() {
    this.state.supplierSearchOpen = false;
    this.forceUpdate();
  }

  handleResultSupplierSearch(suppliers) {
    this.setState({ suppliers: suppliers, supplierLoading: false });
    this.forceUpdate();
  }

  async loadSuppliers(customerId) {
    this.state.supplierLoading = true;
    this.state.search.customerId = customerId;
    this.forceUpdate();
  }

  async handleSearch() {
    if (!this.validator.allValid()) {
      this.validator.showMessages();

      this.forceUpdate();

      return;
    }

    let resStatus = 0;
    this.setState({ loading: true });

    let supplierId = this.state.search.supplierId;

    if (session.isSupplier()) {
      supplierId = session.getSupplierId();
    }

    await getRequest(
      CUSTOMER_SERVICE_URL +
        "/api/Invoice/ListReceivables/?customerId=" +
        this.state.search.customerId +
        "&supplierId=" +
        supplierId +
        "&invoiceNumber=" +
        this.state.search.invoiceNumber +
        "&startDate=" +
        this.state.search.startDate +
        "&endDate=" +
        this.state.search.endDate
    )
      .then((res) => {
        let messages = {};

        switch (res.code) {
          case 200:
            this.setState({ invoices: res.data, selectAll: 0 });

            this.toggleSelectAll();
            break;

          case 409:
            messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));

            break;
          case 400:
            messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;
          case 500:
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });

    this.setState({ loading: false });
  }

  async handleSubmit() {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();

      return;
    }

    let _this = this;

    if (_this.state.step02) {
      if (!this.state.address || this.state.address.id <= 0) {
        toast.error(
          <ToastRedirect
            message={translate("transactions.transactionAddressesRequired")}
          />
        );
        return;
      }

      if (!this.state.bankAccount || this.state.bankAccount.id <= 0) {
        toast.error(
          <ToastRedirect
            message={translate("transactions.transactionBankAccountsRequired")}
          />
        );
        return;
      }

      confirmAlert({
        buttons: [
          {
            label: translate("forms.buttonYes"),
            onClick: () => {
              var status = 0;

              let reportDailyLog = _this.state.reportDailyLog;

              _this.setState({ loading: true });

              let bankAccountId = _this.state.bankAccount.id;
              let addressId = _this.state.address.id;
              let invoiceIds = _this.state.selectedInvoices.map((n) => {
                return n.id;
              });

              postRequest(
                TRANSACTION_SERVICE_URL +
                  "/api/Transaction/Create?customerId=" +
                  _this.state.search.customerId +
                  "&supplierId=" +
                  _this.state.search.supplierId +
                  "&bankAccountId=" +
                  bankAccountId +
                  "&addressId=" +
                  addressId,
                invoiceIds
              )
                .then((res) => {
                  switch (res.code) {
                    case 200:
                      reportDailyLog.action = _this.id ? 18 : 17; //17 = createTransaction 18 = updateTransaction
                      reportDailyLog.objectAfter = JSON.stringify(res.data);
                      submitReportDailyLog(reportDailyLog);

                      confirmAlert({
                        buttons: [
                          {
                            label: translate("forms.buttonGoToTransactions"),
                            onClick: () => {
                              _this.props.history.push("/transactions");
                            },
                          },
                        ],
                        message: translate(
                          "transactions.transactionSuccessQuestion"
                        ),
                        title: translate(
                          "transactions.transactionSuccessTitle"
                        ),
                        closeOnClickOutside: false,
                        closeOnEscape: false,
                      });

                      break;
                    case 400:
                    case 409:
                      var messages = res.data;
                      messages.forEach((ex) => toast.warn(ex.message));

                      if ((res.data[0].parameter = "IsUseTerm")) {
                        this.setState({ modalOpen: true });
                      }
                      break;

                    case 500:
                      toast.error("error");
                      break;
                  }

                  _this.setState({ loading: false });
                })
                .catch((err) => {
                  console.error(err);
                });
            },
          },
          {
            label: translate("forms.buttonNo"),
            onClick: () => {
              return;
            },
          },
        ],
        message: translate("transactions.transactionConfirmQuestion"),
        title: translate("transactions.transactionConfirmTitle"),
      });
    } else {
      this.setState({ step02: true });
      this.forceUpdate();
    }
  }

  scrollToBottom = () => {
    this.state.scrollEnd.scrollIntoView({ behavior: "smooth" });
  };

  handleChangeAddress(address) {
    this.state.address = address;
    this.forceUpdate();
  }

  handleChangeBankAccount(bankAccount) {
    this.state.bankAccount = bankAccount;
    this.forceUpdate();
  }

  handleReturn() {
    this.state.modalOpen = false;
    this.forceUpdate();
  }

  render() {
    var fetched = this.state != null && this.state.customers != null;

    if (fetched) {
      var search = this.state.searchText.toLowerCase();

      var invoiceItems = [];

      if (this.state.invoices != null) {
        var invoiceItems = this.state.invoices.filter(function (item) {
          return (
            item.invoiceNumber.toString().toLowerCase().indexOf(search) >= 0 ||
            item.dueDate.toString().toLowerCase().indexOf(search) >= 0 ||
            item.amount.toString().toLowerCase().indexOf(search) >= 0 ||
            item.discountValue.toString().toLowerCase().indexOf(search) >= 0 ||
            item.netValue.toString().toLowerCase().indexOf(search) >= 0 ||
            item.daysExpiration.toString().toLowerCase().indexOf(search) >= 0
          );
        });
      }

      return (
        <div className="row">
          <div className="col-xs-12">
            <div className="card">
              <div className="header">
                <h2>
                  <ul className="nav nav-tabs tab-nav-right" role="tablist">
                    <li className="active" role="presentation">
                      <a href="#transactionPanel" data-toggle="tab">
                        {translate("transaction.transactionTitle")}
                      </a>
                    </li>
                  </ul>
                </h2>

                <div className="header-dropdown">
                  <div
                    className="preloader pl-size-xs"
                    style={{ display: this.state.loading ? "block" : "none" }}
                  >
                    <div className="spinner-layer">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>

                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <UseTermComponent
                modalOpen={this.state.modalOpen}
                handleReturn={() => {
                  this.handleReturn();
                }}
              />
              <div className="body">
                <div className="tab-content">
                  <div
                    id="transactionPanel"
                    className="tab-pane fade in active"
                    role="tabpanel"
                  >
                    <div className="m-t-10">
                      <div className="row">
                        <div
                          className={
                            session.isSupplier() ? "col-md-6" : "col-md-3"
                          }
                        >
                          <div className="m-b-5">
                            <label
                              className="validationRequired"
                              htmlFor="cmbCustomer"
                            >
                              {" "}
                              {translate("invoice.invoiceCustomer")}
                            </label>

                            <div
                              className="preloader pl-size-xs form-preloader"
                              style={{
                                display: this.state.customerLoading
                                  ? "inline-block"
                                  : "none",
                              }}
                            >
                              <div className="spinner-layer">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>

                            <RCSelect
                              id="cmbCustomer"
                              name="search.customerId"
                              hasEmptyOption={true}
                              isInt={true}
                              options={this.state.customers}
                              optionValue="id"
                              optionLabel="name"
                              placeholder={translate(
                                "invoice.invoiceCustomerPlaceHolder"
                              )}
                              onChange={(event) => {
                                this.handleChange(event);
                              }}
                              value={this.state.search.customerId}
                              isDisabled={this.state.loading}
                            />

                            {this.validator.message(
                              "cmbCustomer",
                              this.state.search.customerId,
                              "gt: 0",
                              false,
                              {
                                default: translate(
                                  "invoice.invoiceCustomerRequired"
                                ),
                              }
                            )}
                          </div>
                        </div>

                        <div
                          className="col-md-4"
                          style={{
                            display: session.isSupplier() ? "none" : "block",
                          }}
                        >
                          <div className="m-b-5">
                            <label
                              htmlFor="cmbSupplier"
                              className={
                                this.state.customers &&
                                this.state.customers.length > 0
                                  ? "validationRequired"
                                  : ""
                              }
                            >
                              {translate("invoice.invoiceSupplier")}
                            </label>

                            <div
                              className="preloader pl-size-xs form-preloader"
                              style={{
                                display: this.state.supplierLoading
                                  ? "inline-block"
                                  : "none",
                              }}
                            >
                              <div className="spinner-layer">
                                <div className="circle-clipper left">
                                  <div className="circle"></div>
                                </div>
                              </div>
                            </div>

                            <RCSelect
                              id="cmbSupplier"
                              name="search.supplierId"
                              hasEmptyOption={true}
                              isInt={true}
                              options={this.state.suppliers}
                              optionValue="id"
                              optionLabel="name"
                              placeholder={translate(
                                "invoice.invoiceSupplierPlaceHolder"
                              )}
                              onChange={(event) => {
                                this.handleChange(event);
                              }}
                              value={this.state.search.supplierId}
                              isDisabled={
                                !this.state.search.customerId ||
                                this.state.search.customerId <= 0
                              }
                            />

                            {this.validator.message(
                              "cmbSupplier",
                              this.state.search.supplierId,
                              "gt: 0|supplierSelected",
                              false,
                              {
                                default: translate(
                                  "invoice.invoiceSupplierRequired"
                                ),
                              }
                            )}
                          </div>
                        </div>

                        <div
                          className="col-md-1 m-t-20 p-l-0"
                          style={{
                            display: session.isSupplier() ? "none" : "block",
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-default waves-effect"
                            disabled={
                              !this.state.search.customerId ||
                              this.state.search.customerId <= 0
                            }
                            onClick={() => {
                              this.state.supplierSearchOpen = true;
                              this.forceUpdate();
                            }}
                          >
                            <i className="material-icons">&#xe8b6;</i>
                          </button>

                          {this.state.search.customerId > 0 && (
                            <ModalSupplierSearch
                              customerId={this.state.search.customerId}
                              SearchOpen={this.state.supplierSearchOpen}
                              handleChange={(event) => {
                                this.handleChangeSupplierSearch(event);
                              }}
                              handleResult={(event) => {
                                this.handleResultSupplierSearch(event);
                              }}
                              handleReturn={() => {
                                this.handleReturnSupplierSearch();
                              }}
                            />
                          )}
                        </div>

                        <div className="col-md-2">
                          <div className="input-group">
                            <label htmlFor="dtStartDate">
                              {translate("invoice.invoiceStartDate")}
                            </label>

                            <div className="form-line">
                              <RCDatePicker
                                disablePast="true"
                                id="dtStartDate"
                                name="search.startDate"
                                value={this.state.search.startDate}
                                onChange={this.handleChange}
                              />
                            </div>

                            {this.validator.message(
                              "search.startDate",
                              this.state.search.startDate,
                              "required|startDateLaterThanEndDate",
                              false,
                              {
                                default: translate(
                                  "invoice.invoiceStartDateRequired"
                                ),
                                startDateLaterThanEndDate: translate(
                                  "invoice.invoiceStartDateLaterThanEndDate"
                                ),
                              }
                            )}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="input-group">
                            <label htmlFor="dtEndDate">
                              {translate("invoice.invoiceEndDate")}
                            </label>

                            <div className="form-line">
                              <RCDatePicker
                                disablePast="true"
                                id="dtEndDate"
                                name="search.endDate"
                                value={this.state.search.endDate}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row m-t-5">
                        <div className="col-md-6 m-t-20">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <i className="material-icons">&#xe8b6;</i>
                            </span>

                            <div className="form-line">
                              <input
                                name="searchText"
                                className="form-control"
                                autoComplete="off"
                                disabled={this.state.loading}
                                placeholder={translate("forms.searchBar")}
                                type="text"
                                value={this.state.searchText}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="input-group">
                            <label htmlFor="txtInvoiceNumber">
                              {translate("invoice.invoiceNumber")}
                            </label>

                            <div className="form-line">
                              <input
                                id="txtInvoiceNumber"
                                name="search.invoiceNumber"
                                className="form-control js-change js-integer"
                                type="text"
                                placeholder={translate(
                                  "invoice.invoiceNumberPlaceHolder"
                                )}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 m-t-20">
                          <button
                            className="btn btn-block btn-primary right"
                            disabled={this.state.loading}
                            type="button"
                            onClick={this.handleSearch}
                          >
                            <i className="material-icons">&#xe8b6;</i>
                            <span>{translate("forms.buttonSearch")}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <ModalSummaryValues
                    invoiceCount={this.state.selectedInvoices.length}
                    totalSum={this.state.totalSum}
                    totalDiscountValue={this.state.totalDiscountValue}
                    totalNetValue={this.state.totalNetValue}
                  />
                </div>

                <div className="m-b-40">
                  <ReactTable
                    columns={[
                      {
                        columns: [
                          {
                            Cell: ({ row }) => {
                              return (
                                <div>
                                  <input
                                    id={row.id}
                                    type="checkbox"
                                    checked={
                                      this.state.selected[row.id] === true
                                    }
                                    onChange={() => this.toggleRow(row.id)}
                                  />
                                  <label htmlFor={row.id}></label>
                                </div>
                              );
                            },
                            Header: (x) => {
                              return (
                                <div>
                                  <input
                                    className="chk-col-white-border"
                                    id="selectAll"
                                    type="checkbox"
                                    checked={this.state.selectAll === 1}
                                    ref={(input) => {
                                      if (input) {
                                        input.indeterminate =
                                          this.state.selectAll === 2;
                                      }
                                    }}
                                    onChange={() => this.toggleSelectAll()}
                                  />
                                  <label htmlFor="selectAll"></label>
                                </div>
                              );
                            },
                            sortable: false,
                            width: 45,
                          },
                          {
                            Header: translate("invoice.invoiceNumber"),
                            id: "invoiceNumber",
                            maxWidth: 90,
                            accessor: (data) => {
                              return (
                                <span
                                  title={
                                    translate("invoice.invoiceInstallment") +
                                    " " +
                                    data.installment +
                                    " / " +
                                    data.totalInstallment
                                  }
                                >
                                  {" "}
                                  {data.invoiceNumber}
                                </span>
                              );
                            },
                          },
                          {
                            accessor: "id",
                            show: false,
                          },
                          {
                            Header: translate("invoice.invoiceDueDate"),
                            id: "dueDate",
                            accessor: (data) => {
                              return moment(data.dueDate, "YYYY-MM-DD").format(
                                "DD-MM-YYYY"
                              );
                            },
                            width: 105,
                          },
                          {
                            Header: (
                              <span
                                title={translate(
                                  "invoice.invoiceDaysExpirationTitle"
                                )}
                              >
                                {" "}
                                {translate(
                                  "invoice.invoiceDaysExpiration"
                                )}{" "}
                              </span>
                            ),
                            accessor: "daysExpiration",
                            width: 125,
                          },
                          {
                            Header: translate("invoice.invoiceAmount"),
                            id: "amount",
                            accessor: (data) => {
                              return new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(data.amount);
                            },
                            width: 140,
                          },
                          {
                            Header: (
                              <span
                                title={translate("invoice.invoiceRateTitle")}
                              >
                                {" "}
                                {translate("invoice.invoiceRate")}{" "}
                              </span>
                            ),
                            id: "rate",
                            accessor: (data) => {
                              return data.rate + "%";
                            },
                            width: 80,
                          },
                          {
                            Header: translate("invoice.invoiceDiscountValue"),
                            id: "discountValue",
                            accessor: (data) => {
                              return new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(data.discountValue);
                            },
                            width: 140,
                          },
                          {
                            Header: translate("invoice.invoiceNetValue"),
                            id: "netValue",
                            accessor: (data) => {
                              return new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(data.netValue);
                            },
                            width: 125,
                          },
                          {
                            Header: translate("invoice.invoiceStatus"),
                            id: "status",
                            accessor: (data) => {
                              return (
                                <span
                                  title={
                                    enums.getStatus().find((x) => {
                                      return x.index === parseInt(data.status);
                                    }).text
                                  }
                                >
                                  {" "}
                                  {
                                    enums.getStatus().find((x) => {
                                      return x.index === parseInt(data.status);
                                    }).text
                                  }{" "}
                                </span>
                              );
                            },
                          },
                          {
                            Header: translate("invoice.invoiceXml"),
                            id: "nfeXml",
                            accessor: (data) => {
                              return (
                                <button
                                  label
                                  onClick={() =>
                                    this.download(data.nfeKey, data.nfeXml)
                                  }
                                >
                                  {translate("invoice.invoiceDownloadXml")}
                                </button>
                              );
                            },
                          },
                        ],
                      },
                    ]}
                    data={invoiceItems}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    loading={this.state.loading}
                    previousText={translate("forms.previousText")}
                    nextText={translate("forms.nextText")}
                    noDataText={translate("forms.noDataText")}
                    pageText={translate("forms.pageText")}
                    ofText={translate("forms.ofText")}
                    rowsText={translate("forms.rowsText")}
                  />
                </div>

                <div
                  id="step02"
                  style={{ display: this.state.step02 ? "block" : "none" }}
                >
                  <div className="row">
                    {this.state.step02 && (
                      <ModalBankAccount
                        transactionStatus={0}
                        supplierId={this.state.search.supplierId}
                        supplier={null}
                        bankAccount={null}
                        loading={this.state.loading}
                        handleChangeBankAccount={(event) => {
                          this.handleChangeBankAccount(event);
                        }}
                      />
                    )}
                  </div>

                  <div className="row m-b-20">
                    {this.state.step02 && (
                      <ModalAddress
                        transactionStatus={0}
                        supplierId={this.state.search.supplierId}
                        supplier={null}
                        address={null}
                        loading={this.state.loading}
                        handleChangeAddress={(event) => {
                          this.handleChangeAddress(event);
                        }}
                      />
                    )}
                  </div>
                </div>

                <div
                  className="row clearfix"
                  ref={(el) => {
                    this.state.scrollEnd = el;
                  }}
                >
                  <div className="col-md-11 formActions">
                    <Link
                      to="/transactions"
                      className="btn btn-default waves-effect"
                    >
                      <i className="material-icons">&#xe5c4;</i>
                      <span>{translate("forms.buttonReturn")}</span>
                    </Link>

                    <button
                      type="button"
                      className="btn btn-success waves-effect m-l-10"
                      disabled={
                        this.state.selectedInvoices.length == 0 ||
                        this.state.loading
                      }
                      onClick={this.handleSubmit}
                    >
                      {!this.state.step02 && (
                        <i className="material-icons">&#xe5c8;</i>
                      )}
                      {this.state.step02 && (
                        <i className="material-icons">&#xe53e;</i>
                      )}

                      <span>
                        {!this.state.step02
                          ? translate("transaction.transactionButtonSaveStep2")
                          : translate("transaction.transactionButtonSave")}
                      </span>
                    </button>
                  </div>
                  <div className="col-md-1 formActions">
                    <div className="header-dropdown" style={{ float: "right" }}>
                      <div
                        className="preloader pl-size-xs"
                        style={{
                          display: this.state.loading ? "block" : "none",
                        }}
                      >
                        <div className="spinner-layer">
                          <div className="circle-clipper left">
                            <div className="circle"></div>
                          </div>
                          <div className="circle-clipper right">
                            <div className="circle"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer hideProgressBar />
        </div>
      );
    } else {
      return (
        <div className="preloader pl-size-lg align-center">
          <div className="spinner-layer">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>

            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const ToastRedirect = ({ tabToShow, message, closeToast }) => {
  function handleClick() {
    tabToShow.trigger("click");
    closeToast();
  }

  return <div onClick={handleClick}>{message}</div>;
};

export default TransactionPage;
