import React from "react";
import UseTermComponent from "../components/UseTermComponent";

class UseTermPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: true,
    };
  }

  handleReturn() {
    this.state.modalOpen = false;
    this.forceUpdate();
  }
  render() {
    return (
      <UseTermComponent
        modalOpen={this.state.modalOpen}
        handleReturn={() => {
          this.handleReturn();
        }}
      />
    );
  }
}

export default UseTermPage;
