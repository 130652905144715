import React from "react";
import { Link } from "react-router-dom";
import { translate } from "react-internationalization";
import { ToastContainer, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import $ from "jquery";
import RCSelect from "../../components/select";
import * as session from "../../components/SessionValidator";
import { cnpj } from "../../components/cpf-cnpj-validator";
import { cpf_cnpj_Mask } from "../../components/mask";
import { submitReportDailyLog } from "../helper";
import * as enums from "../../components/enums/bank";
import AddressBankComponent from "../../components/bank/AddressBankComponent";
import ContactBankComponent from "../../components/bank/ContactBankComponent";
import { BANK_SERVICE_URL } from "../../settings";
import moment from "moment";
import { getRequest, postRequest } from "../../service/RequestService";

class BankPage extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.setValidators();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlechangeMask = this.handlechangeMask.bind(this);

    this.state = {
      id: this.props.match.params.id,

      bank: {
        cnpj: "",
        phoneNumber: "",
        email: "",
        stateRegistration: "",
      },

      reportDailyLog: {
        startDate: moment().format(),
        objectAfter: "",
        objectBefore: "",
      },
    };
  }

  handlechangeMask(e) {
    let bank = this.state.bank;

    bank.cnpj = cpf_cnpj_Mask(e.target.value);

    this.setState({ bank: bank });
  }
  async componentDidMount() {
    let _this = this;
    let reportDailyLog = this.state.reportDailyLog;

    $("body").on("change", ".js-change", function (event) {
      _this.handleChange(event);
    });

    if (this.id) {
      await getRequest(BANK_SERVICE_URL + "/api/bank/GetSimpleById/" + this.id).then(
        (res) => {
          reportDailyLog.objectBefore = JSON.stringify(res.data);

          this.setState({ bank: res.data, reportDailyLog: reportDailyLog });
          this.forceUpdate();
        },
      );
    } else {
      let bank = {
        isActive: true,
        address: [],
        contact: [],
        phoneNumber: "",
        email: "",
        stateRegistration: "",
      };

      this.setState({ bank: bank });
      this.forceUpdate();
    }
  }

  async componentDidUpdate() {
    let _this = this;

    $(".js-change").each(function () {
      _this.setValue(this);
    });
    $(".js-phone-number").inputmask({
      greedy: false,
      mask: "(99) 9999[9]-9999",
      showMaskOnHover: false,
    });
    $(".js-integer").inputmask({
      greedy: false,
      mask: "9",
      repeat: "*",
      showMaskOnHover: false,
    });
    $(".js-decimal").inputmask("decimal", {
      digits: 4,
      digitsOptional: false,
      max: 999999999,
      placeholder: "0.00",
      rightAlign: false,
      showMaskOnHover: false,
    });
  }

  async handleChange(event) {
    session.validateSession();

    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    this.setState(this.state);
  }

  handleTypingEmail(e) {
    e.target.value = e.target.value.toLowerCase();
  }

  setValue(element) {
    let keys = element.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      property = property[key];
      if (Array.isArray(property)) property = property[element.dataset.index];
    });

    $(element).val(property);
  }

  handleSubmit() {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();

      return;
    }

    let reportDailyLog = this.state.reportDailyLog;

    var bank = $.extend(true, {}, this.state.bank),
      method = this.id ? "Update" : "Create",
      status = 0;

    this.setState({ submitLoading: true });

    postRequest(BANK_SERVICE_URL + "/api/Bank/" + method, bank)
      .then((res) => {
        this.setState({ submitLoading: false });
        switch (res.code) {
          case 200:
            toast.success(
              translate(!this.id ? "bank.bankCreated" : "bank.bankUpdated"),
            );

            reportDailyLog.action = this.id ? 15 : 14; //14 = createBank 15 = updateBank
            reportDailyLog.objectAfter = JSON.stringify(bank);
            submitReportDailyLog(reportDailyLog);

            this.state.id = res.data.id;
            this.forceUpdate();

            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("error");
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  setValidators() {
    this.validator = new SimpleReactValidator({
      cnpj: {
        rule: (value) => {
          return cnpj.isValid(value);
        },
      },

      bankNumber: {
        rule: (value) => {
          return ["", null, undefined].indexOf(value) < 0;
        },
      },

      email: {
        rule: function (val) {
          if (val === "") return true;

          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(val.trim()))
            return false;

          return true;
        },
      },
    });
  }

  render() {
    var fetched = this.state != null && this.state.bank != null;

    if (fetched) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <div className="card">
              <div className="header">
                <h2>
                  <ul className="nav nav-tabs tab-nav-right" role="tablist">
                    <li className="active" role="presentation">
                      <a href="#bankPanel" data-toggle="tab">
                        {translate("bank.bankTitle")}
                      </a>
                    </li>

                    <li
                      role="presentation"
                      className={this.state.id ? "" : "disabled"}
                    >
                      <a
                        id="addresses-tab-link"
                        href={this.state.id ? "#addressPanel" : "#"}
                        data-toggle="tab"
                      >
                        {translate("bank.bankAddressNameTitle")}
                      </a>
                    </li>

                    <li
                      role="presentation"
                      className={this.state.id ? "" : "disabled"}
                    >
                      <a
                        href={this.state.id ? "#contactsPanel" : "#"}
                        data-toggle="tab"
                      >
                        {translate("bank.bankContactsTitle")}
                      </a>
                    </li>
                  </ul>
                </h2>

                <div className="header-dropdown">
                  <div
                    className="preloader pl-size-xs"
                    style={{
                      display: this.state.submitLoading ? "block" : "none",
                    }}
                  >
                    <div className="spinner-layer">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>

                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="body">
                <div className="tab-content">
                  <div
                    id="bankPanel"
                    className="tab-pane fade in active"
                    role="tabpanel"
                  >
                    <div>
                      <div className="m-b-40">
                        <small>{translate("bank.bankTitleDescription")}</small>
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-4">
                          <div className="m-b-20">
                            <label
                              htmlFor="cmbBankNumber"
                              className="validationRequired"
                            >
                              {translate("bank.bankNumber")}
                            </label>

                            <RCSelect
                              id="cmbBankNumber"
                              name="bank.bankNumber"
                              hasEmptyOption={true}
                              isInt={false}
                              options={enums.getBankNumber()}
                              optionValue="index"
                              optionLabel="text"
                              placeholder={translate(
                                "bank.bankNumberPlaceHolder",
                              )}
                              onChange={(event) => {
                                this.handleChange(event);
                              }}
                              value={this.state.bank.bankNumber}
                              isDisabled={
                                !session.auth([{ type: "Bank", value: "Save" }])
                              }
                            />

                            {this.validator.message(
                              "cmbBankNumber",
                              this.state.bank.bankNumber,
                              "required|bankNumber",
                              false,
                              { default: translate("bank.bankNumberRequired") },
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-group">
                            <label
                              className="validationRequired"
                              htmlFor="txtName"
                            >
                              {translate("bank.bankName")}
                            </label>

                            <div className="form-line">
                              <input
                                id="txtName"
                                disabled={
                                  !session.auth([
                                    { type: "Bank", value: "Save" },
                                  ])
                                }
                                className="form-control js-change"
                                name="bank.legalName"
                                placeholder={translate(
                                  "bank.bankNamePlaceHolder",
                                )}
                                type="text"
                              />
                            </div>

                            {this.validator.message(
                              "bank.legalName",
                              this.state.bank.legalName,
                              "required",
                              "validationMessage--error",
                              { default: translate("bank.bankNameRequired") },
                            )}
                          </div>
                        </div>

                        {(session.isAdmin() || session.isOperator()) && (
                          <div className="col-md-2">
                            <div className="m-t-30">
                              <input
                                id="chkIsActive"
                                name="bank.isActive"
                                type="checkbox"
                                checked={this.state.bank.isActive}
                                onChange={this.handleChange}
                              />
                              <label htmlFor="chkIsActive">
                                {translate("bank.bankIsActive")}
                              </label>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-3">
                          <div className="input-group">
                            <label htmlFor="txtCnpj">
                              {translate("bank.bankCNPJ")}
                            </label>

                            <div className="form-line">
                              <input
                                id="txtCnpj"
                                disabled={
                                  !session.auth([
                                    { type: "Bank", value: "Save" },
                                  ])
                                }
                                value={this.state.bank.cnpj}
                                maxLength="18"
                                onChange={this.handlechangeMask}
                                className="form-control"
                                name="bank.cnpj"
                                placeholder={translate(
                                  "bank.bankCNPJPlaceHolder",
                                )}
                                type="text"
                              />
                            </div>

                            {this.validator.message(
                              "bank.cnpj",
                              this.state.bank.cnpj,
                              "required|cnpj",
                              "validationMessage--error",
                              {
                                default: translate("bank.bankCNPJRequired"),
                                cnpj: translate("bank.bankCNPJInvalid"),
                              },
                            )}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="input-group">
                            <label htmlFor="txtAgencyNumber">
                              {translate("bank.bankAgencyNumber")}
                            </label>

                            <div className="form-line">
                              <input
                                id="txtAgencyNumber"
                                disabled={
                                  !session.auth([
                                    { type: "Bank", value: "Save" },
                                  ])
                                }
                                maxLength="4"
                                className="form-control js-change js-integer"
                                name="bank.agencyNumber"
                                placeholder={translate(
                                  "bank.bankAgencyNumberPlaceHolder",
                                )}
                                type="text"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="input-group">
                            <label htmlFor="txtAccountNumber">
                              {translate("bank.bankAccountNumber")}
                            </label>

                            <div className="form-line">
                              <input
                                id="txtAccountNumber"
                                disabled={
                                  !session.auth([
                                    { type: "Bank", value: "Save" },
                                  ])
                                }
                                maxLength="20"
                                className="form-control js-change js-integer"
                                name="bank.accountNumber"
                                placeholder={translate(
                                  "bank.bankAccountNumberPlaceHolder",
                                )}
                                type="text"
                              />
                            </div>
                          </div>
                        </div>

                        {(session.isAdmin() || session.isOperator()) && (
                          <div className="col-md-3">
                            <div className="input-group">
                              <label htmlFor="txtPortalRate">
                                {translate("bank.bankPortalRate")}
                              </label>

                              <div className="form-line">
                                <input
                                  id="txtPortalRate"
                                  className="form-control js-change js-decimal"
                                  name="bank.portalRate"
                                  placeholder={translate(
                                    "bank.bankPortalRatePlaceHolder",
                                  )}
                                  type="text"
                                />
                              </div>

                              {this.validator.message(
                                "bank.portalRate",
                                this.state.bank.portalRate,
                                "required",
                                false,
                                {
                                  default: translate(
                                    "bank.bankPortalRateRequired",
                                  ),
                                },
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-3">
                          <div className="input-group">
                            <label
                              className="validationRequired"
                              htmlFor="txtPhoneNumber"
                            >
                              {translate("bank.bankContactPhoneNumber")}
                            </label>

                            <div className="form-line">
                              <input
                                id="txtPhoneNumber"
                                disabled={
                                  !session.auth([
                                    { type: "Bank", value: "Save" },
                                  ])
                                }
                                maxLength="15"
                                className="form-control js-change js-phone-number"
                                name="bank.phoneNumber"
                                placeholder={translate(
                                  "bank.bankContactPhoneNumberPlaceHolder",
                                )}
                                type="text"
                              />
                            </div>

                            {this.validator.message(
                              "bank.phoneNumber",
                              this.state.bank.phoneNumber,
                              "required",
                              false,
                              {
                                default: translate(
                                  "bank.bankContactPhoneNumberRequired",
                                ),
                              },
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-group">
                            <label htmlFor="txtEmail">
                              {translate("bank.bankEmail")}
                            </label>

                            <div className="form-line">
                              <input
                                id="txtEmail"
                                disabled={
                                  !session.auth([
                                    { type: "Bank", value: "Save" },
                                  ])
                                }
                                className="form-control js-change"
                                name="bank.email"
                                placeholder={translate(
                                  "bank.bankEmailPlaceHolder",
                                )}
                                onKeyUp={this.handleTypingEmail}
                                type="text"
                              />
                            </div>

                            {this.validator.message(
                              "bank.email",
                              this.state.bank.email,
                              "email",
                              false,
                              { default: translate("bank.bankEmailInvalid") },
                            )}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="input-group">
                            <label htmlFor="txtStateRegistration">
                              {" "}
                              {translate("bank.bankStateRegistration")}{" "}
                            </label>

                            <div className="form-line">
                              <input
                                id="txtStateRegistration"
                                disabled={
                                  !session.auth([
                                    { type: "Bank", value: "Save" },
                                  ])
                                }
                                name="bank.stateRegistration"
                                className="form-control js-change"
                                autoComplete="off"
                                placeholder={translate(
                                  "bank.bankStateRegistrationPlaceHolder",
                                )}
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row clearfix">
                        <div className="col-md-12 formActions">
                          <Link
                            to="/banks"
                            className="btn btn-default waves-effect"
                          >
                            <i className="material-icons">&#xe5c4;</i>
                            <span>{translate("forms.buttonReturn")}</span>
                          </Link>

                          {session.auth([{ type: "Bank", value: "Save" }]) && (
                            <button
                              type="button"
                              className="btn btn-success waves-effect"
                              disabled={this.state.submitLoading}
                              onClick={this.handleSubmit}
                            >
                              <i className="material-icons">&#xe161;</i>
                              <span>{translate("forms.buttonSave")}</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="addressPanel"
                    className="tab-pane fade in"
                    role="tabpanel"
                  >
                    <div>
                      <AddressBankComponent
                        bankId={this.state.id}
                        handleChange={(event) => {
                          this.forceUpdate();
                        }}
                      />

                      <div className="row clearfix">
                        <div className="col-md-12 formActions">
                          <Link
                            to="/customers"
                            className="btn btn-default waves-effect"
                          >
                            <i className="material-icons">&#xe5c4;</i>
                            <span>{translate("forms.buttonReturn")}</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="contactsPanel"
                    className="tab-pane fade in"
                    role="tabpanel"
                  >
                    <div>
                      <ContactBankComponent
                        bankId={this.state.id}
                        handleChange={(event) => {
                          this.forceUpdate();
                        }}
                      />

                      <div className="row clearfix">
                        <div className="col-md-12 formActions">
                          <Link
                            to="/customers"
                            className="btn btn-default waves-effect"
                          >
                            <i className="material-icons">&#xe5c4;</i>
                            <span>{translate("forms.buttonReturn")}</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer hideProgressBar />
        </div>
      );
    } else {
      return (
        <div className="preloader pl-size-lg align-center">
          <div className="spinner-layer">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>

            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const ToastRedirect = ({ tabToShow, message, closeToast }) => {
  function handleClick() {
    tabToShow.trigger("click");
    closeToast();
  }

  return <div onClick={handleClick}>{message}</div>;
};

export default BankPage;
