import moment from "moment";
export const util = {
  getEnumOption: function (list, index, prop, defaultOption, isInt) {
    if (prop === undefined) prop = "index";
    if (defaultOption === undefined) defaultOption = {};
    if (isInt === undefined) isInt = true;
    if (isInt) index = parseInt(index);

    let option = list.find((x) => {
      return x[prop] === index;
    });
    return option ? option : defaultOption;
  },

  isNullUndefinedOrEmpty: function (value) {
    if (value === null) return true;
    if (value === undefined) return true;
    if (value.trim() === "") return true;

    return false;
  },

  getNextWeek: function (startDate, days) {
    startDate = new Date(moment(startDate));
    let i = 0;

    while (i < days) {
      startDate.setDate(startDate.getDate() + 1);

      let dayOfWeek = startDate.getDay();
      if (!(dayOfWeek === 1 || dayOfWeek === 2)) i += 1;
    }

    return new Date(moment(startDate));
  },
};

export const formatNumber = (value) => {
  if (value === undefined || value === null) return "0.00";

  return value.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};
