import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import { translate } from "react-internationalization";
import { ToastContainer, toast } from "react-toastify";
import ReactTable from "react-table";
import * as session from "../../components/SessionValidator";
import { submitReportDailyLog } from "../helper";
import * as enums from "../../components/enums/invoice";
import * as enumsTransaction from "../../components/enums/transaction";
import ModalBankAccount from "../../components/transaction/modal-bank-account";
import ModalAddress from "../../components/transaction/modal-address";
import ModalCustomerAddress from "../../components/transaction/modal-customer-address";
import ModalSummaryValues from "../../components/transaction/summary-values";
import ModalSendEmail from "../../components/transaction/modal-send-email";
import ModalContract from "../../components/transaction/modal-contract";
import ModalMoneySource from "../../components/transaction/modal-money-source";
import { DOCUMENT_SERVICE_URL, TRANSACTION_SERVICE_URL } from "../../settings";
import moment from "moment";
import { getRequest, postRequest } from "../../service/RequestService";

class transactionViewPage extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.setBinds();

    this.state = {
      searchText: "",

      transaction: null,
      invoices: [],

      reportDailyLog: {
        startDate: moment().format(),
        objectAfter: "",
        objectBefore: "",
      },

      bankAccount: null,
      address: null,
      customerAddress: null,

      scrollEnd: null,

      openEmail: false,
      signer: {},
      signerType: 0,

      showContract: false,
      document: null,

      assigneeId: 0,
      moneySource: 0,

      cnab: null,
    };
  }

  setBinds() {
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleProcess = this.handleProcess.bind(this);
    this.handleApprove = this.handleApprove.bind(this);

    this.handleChangeBankAccount = this.handleChangeBankAccount.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
  }

  async handleChange(event) {
    session.validateSession();

    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    this.setState(this.state);
  }

  async componentDidMount() {
    let reportDailyLog = this.state.reportDailyLog;

    if (this.id) {
      await getRequest(TRANSACTION_SERVICE_URL + "/api/transaction/" + this.id).then(
        (res) => {
          this.setState({ loading: false });

          reportDailyLog.objectBefore = JSON.stringify(res.data);

          if (res.data) {
            if (!res.data.balanceDueDate) {
              res.data.balanceDueDate = moment().format();
            }

            this.state.assigneeId = res.data.bankId;

            this.state.bankAccount = Object.create({
              id: 0,
              bankNumber: res.data.assignor[0].bankNumber,
              agencyNumber: res.data.assignor[0].agencyNumber,
              accountNumber: res.data.assignor[0].accountNumber,
            });

            this.state.address = Object.create({
              id: 0,
              legalName: res.data.assignor[0].legalName,
              document: res.data.assignor[0].document,

              country: { name: res.data.assignor[0].country },
              state: { name: res.data.assignor[0].state },

              city: res.data.assignor[0].city,
              zipCode: res.data.assignor[0].zipCode,
              street: res.data.assignor[0].street,
              district: res.data.assignor[0].district,
              number: res.data.assignor[0].number,
            });

            this.state.customerAddress = Object.create({
              id: 0,
              legalName: res.data.debtor[0].legalName,
              cnpj: res.data.debtor[0].document,

              country: { name: res.data.debtor[0].country },
              state: { name: res.data.debtor[0].state },

              city: res.data.debtor[0].city,
              zipCode: res.data.debtor[0].zipCode,
              street: res.data.debtor[0].street,
              district: res.data.debtor[0].district,
              number: res.data.debtor[0].number,
            });

            this.setState({
              transaction: res.data,
              invoices: res.data.invoices,
              reportDailyLog: reportDailyLog,
            });
          }

          this.forceUpdate();
        },
      );
    } else {
      this.props.history.push("/transactions");
    }
  }

  async handleCancel() {
    let _this = this;

    confirmAlert({
      buttons: [
        {
          label: translate("forms.buttonYes"),
          onClick: () => {
            var status = 0;

            let reportDailyLog = this.state.reportDailyLog;

            this.setState({ loading: true });

            postRequest(
              TRANSACTION_SERVICE_URL +
                "/api/Transaction/Cancel?transactionId=" +
                this.id,
              "",
            )
              .then((res) => {
                this.setState({ loading: false });

                switch (res.code) {
                  case 200:
                    reportDailyLog.action = 18; //updateTransaction
                    reportDailyLog.objectAfter = JSON.stringify(res.data);
                    submitReportDailyLog(reportDailyLog);

                    confirmAlert({
                      buttons: [
                        {
                          label: translate("forms.buttonGoToTransactions"),
                          onClick: () => {
                            _this.props.history.push("/transactions");
                          },
                        },
                      ],
                      message: translate(
                        "transactions.transactionSuccessQuestion",
                      ),
                      title: translate(
                        "transactions.transactionCancelSuccessTitle",
                      ),
                      closeOnClickOutside: false,
                      closeOnEscape: false,
                    });

                    break;

                  case 400:
                  case 409:
                    var messages = res.data;
                    messages.forEach((ex) => toast.warn(ex.message));
                    break;

                  case 500:
                    toast.error("error");
                    break;
                }
              })
              .catch((err) => {
                console.error(err);
              });
          },
        },
        {
          label: translate("forms.buttonNo"),
          onClick: () => {
            return;
          },
        },
      ],
      message: translate("transactions.transactionConfirmCancelQuestion"),
      title: translate("transactions.transactionConfirmCancelTitle"),
    });
  }

  async handleSubmit() {
    var status = 0;

    let reportDailyLog = this.state.reportDailyLog;

    let bankAccountId = this.state.bankAccount.id;
    let supplierAddressId = this.state.address.id;
    let customerAddressId = this.state.customerAddress.id;

    this.setState({ loading: true });

    await postRequest(
      TRANSACTION_SERVICE_URL +
        "/api/Transaction/Update?transactionId=" +
        this.id +
        "&bankAccountId=" +
        bankAccountId +
        "&supplierAddressId=" +
        supplierAddressId +
        "&customerAddressId=" +
        customerAddressId,
    )
      .then((res) => {
        this.setState({ loading: false });

        switch (res.code) {
          case 200:
            reportDailyLog.action = 18; //updateTransaction
            reportDailyLog.objectAfter = JSON.stringify(res.data);
            submitReportDailyLog(reportDailyLog);

            toast.success(translate("transaction.transactionUpdated"));

            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("error");
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async handleProcess() {
    let _this = this;

    confirmAlert({
      buttons: [
        {
          label: translate("forms.buttonYes"),
          onClick: () => {
            var status = 0;

            let reportDailyLog = _this.state.reportDailyLog;

            let assigneeId = _this.state.assigneeId;
            let moneySource = _this.state.moneySource;

            let bankAccountId = _this.state.bankAccount.id;
            let supplierAddressId = _this.state.address.id;
            let customerAddressId = _this.state.customerAddress.id;

            _this.setState({ loading: true });

            postRequest(
              TRANSACTION_SERVICE_URL +
                "/api/Transaction/Process?transactionId=" +
                _this.id +
                "&assigneeId=" +
                assigneeId +
                "&moneySource=" +
                moneySource +
                "&bankAccountId=" +
                bankAccountId +
                "&supplierAddressId=" +
                supplierAddressId +
                "&customerAddressId=" +
                customerAddressId,
            )
              .then((res) => {
                _this.setState({ loading: false });

                switch (res.code) {
                  case 200:
                    reportDailyLog.action = 24; //processTransaction
                    reportDailyLog.objectAfter = JSON.stringify(res.data);
                    submitReportDailyLog(reportDailyLog);

                    _this.setState({
                      transaction: res.data,
                      document: res.data.document,
                    });
                    _this.forceUpdate();

                    confirmAlert({
                      buttons: [
                        {
                          label: translate("forms.buttonOk"),
                          onClick: () => {},
                        },
                      ],
                      message: translate("transaction.transactionProcessed"),
                      title: translate("transaction.transactionButtonProcess"),
                    });

                    break;

                  case 400:
                  case 409:
                    var messages = res.data;
                    messages.forEach((ex) => toast.warn(ex.message));
                    break;

                  case 500:
                    toast.error("error");
                    break;
                }
              })
              .catch((err) => {
                console.error(err);
              });
          },
        },
        {
          label: translate("forms.buttonNo"),
          onClick: () => {
            return;
          },
        },
      ],
      message: translate("transaction.transactionConfirmProcessQuestion"),
      title: translate("transaction.transactionConfirmProcessTitle"),
    });
  }

  async handleApprove() {
    let _this = this;

    confirmAlert({
      buttons: [
        {
          label: translate("forms.buttonYes"),
          onClick: () => {
            var status = 0;
            let reportDailyLog = _this.state.reportDailyLog;

            _this.setState({ loading: true });

            postRequest(
              TRANSACTION_SERVICE_URL +
                "/api/Transaction/Approve?transactionId=" +
                _this.id,
            )
              .then((res) => {
                _this.setState({ loading: false });

                switch (res.code) {
                  case 200:
                    reportDailyLog.action = 25; //approveTransaction
                    reportDailyLog.objectAfter = JSON.stringify(res.data);
                    submitReportDailyLog(reportDailyLog);

                    _this.setState({ transaction: res.data });
                    _this.forceUpdate();

                    confirmAlert({
                      buttons: [
                        {
                          label: translate("forms.buttonOk"),
                          onClick: () => {},
                        },
                      ],
                      message: translate("transaction.transactionApproved"),
                      title: translate("transaction.transactionButtonApprove"),
                    });

                    break;

                  case 400:
                  case 409:
                    var messages = res.data;
                    messages.forEach((ex) => toast.warn(ex.message));
                    break;

                  case 500:
                    toast.error("error");
                    break;
                }
              })
              .catch((err) => {
                console.error(err);
              });
          },
        },
        {
          label: translate("forms.buttonNo"),
          onClick: () => {
            return;
          },
        },
      ],
      message: translate("transaction.transactionConfirmApproveQuestion"),
      title: translate("transaction.transactionConfirmApproveTitle"),
    });
  }

  handleChangeAddress(address) {
    this.state.address = address;
    this.forceUpdate();
  }

  handleChangeCustomerAddress(address) {
    this.state.customerAddress = address;
    this.forceUpdate();
  }

  handleChangeBankAccount(bankAccount) {
    this.state.bankAccount = bankAccount;
    this.forceUpdate();
  }

  handleChangeMoneySource(assignee) {
    this.state.assigneeId = assignee.id;
    this.state.moneySource = assignee.moneySource;

    this.forceUpdate();
  }

  cnabGenerateFile() {
    let _this = this;

    var status = 0;

    let origin = _this.state.transaction.moneySource; //Customer: 0, bank: 1
    let originId = _this.state.assigneeId;

    let transactions = [];
    transactions.push(_this.id);

    _this.setState({ loading: true });

    postRequest(
      TRANSACTION_SERVICE_URL +
        "/api/CNAB/Create?origin=" +
        origin +
        "&originId=" +
        originId,
      transactions,
    )
      .then((res) => {
        _this.setState({ loading: false });

        switch (res.code) {
          case 200:
            _this.state.transaction.cnab = res.data;
            _this.forceUpdate();

            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("error");
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  downloadTxtFile = () => {
    let _this = this;

    const element = document.createElement("a");
    const file = new Blob([_this.state.transaction.cnab.file], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = _this.state.transaction.cnab.fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  downloadSignedContract() {
    confirmAlert({
      buttons: [
        {
          label: translate("forms.buttonOk"),
          onClick: () => {},
        },
      ],
      title: translate("transaction.transactionDownloadContractTitle"),
      message: translate("transaction.transactionDownloadContractMessage"),
    });
  }

  handleChangeShowContract() {
    this.state.showContract = true;
    this.forceUpdate();
  }

  handleChangeOpenEmail(signer, signerType) {
    this.state.openEmail = true;
    this.state.signer = signer[0];
    this.state.signerType = signerType;
    this.forceUpdate();
  }

  render() {
    var fetched = this.state != null && this.state.transaction != null;

    if (fetched) {
      var search = this.state.searchText.toLowerCase();

      var invoiceItems = [];

      if (this.state.invoices != null) {
        var invoiceItems = this.state.invoices.filter(function (item) {
          return (
            item.invoiceNumber.toString().toLowerCase().indexOf(search) >= 0 ||
            item.dueDate.toString().toLowerCase().indexOf(search) >= 0 ||
            item.amount.toString().toLowerCase().indexOf(search) >= 0 ||
            item.discountValue.toString().toLowerCase().indexOf(search) >= 0 ||
            item.netValue.toString().toLowerCase().indexOf(search) >= 0 ||
            item.daysExpiration.toString().toLowerCase().indexOf(search) >= 0
          );
        });
      }

      return (
        <div className="row">
          <div className="col-xs-12">
            <div className="card">
              <div className="header">
                <h2>
                  <ul className="nav nav-tabs tab-nav-right" role="tablist">
                    <li className="active" role="presentation">
                      <a href="#transactionPanel" data-toggle="tab">
                        {translate("transaction.transactionTitle")}{" "}
                      </a>
                    </li>

                    {(session.isAdmin() ||
                      session.isOperator() ||
                      session.isBank() ||
                      session.isCustomer()) &&
                      session.auth([{ type: "CNAB", value: "Save" }]) &&
                      this.state.transaction.status == 5 && (
                        <li role="presentation">
                          <a
                            id="cnab-tab-link"
                            href="#CNABPanel"
                            data-toggle="tab"
                          >
                            {translate("cnab.cnabTitle")}
                          </a>
                        </li>
                      )}
                  </ul>
                </h2>

                <div className="header-dropdown">
                  <div
                    className="preloader pl-size-xs"
                    style={{ display: this.state.loading ? "block" : "none" }}
                  >
                    <div className="spinner-layer">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>

                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="body">
                <div className="tab-content">
                  <div
                    id="transactionPanel"
                    className="tab-pane fade in active"
                    role="tabpanel"
                  >
                    <div className="m-t-10">
                      <div className="row">
                        <div
                          className={
                            session.isSupplier() ? "col-md-5" : "col-md-3"
                          }
                        >
                          <div className="m-b-5">
                            <label htmlFor="customerName">
                              {" "}
                              {translate("invoice.invoiceCustomer")}{" "}
                            </label>
                          </div>
                          <div className="text">
                            {this.state.transaction.customerName}
                          </div>
                        </div>

                        <div
                          className="col-md-2"
                          style={{
                            display: session.isSupplier() ? "none" : "block",
                          }}
                        >
                          <div className="m-b-5">
                            <label htmlFor="supplierName">
                              {" "}
                              {translate("invoice.invoiceSupplier")}{" "}
                            </label>
                          </div>
                          <div className="text">
                            {this.state.transaction.supplierName}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="col-md-6 p-r-0 p-l-0">
                            <div className="m-b-5">
                              <label>
                                {" "}
                                {translate(
                                  "transactions.transactionStatus",
                                )}{" "}
                              </label>
                            </div>
                            <div className="text">
                              {
                                enumsTransaction.getStatus().find((x) => {
                                  return (
                                    x.index ===
                                    parseInt(this.state.transaction.status)
                                  );
                                }).text
                              }
                            </div>
                          </div>

                          <div className="col-md-6 p-r-0 p-l-0">
                            <div className="m-b-5">
                              <label>
                                {" "}
                                {translate("transactions.transactionRate")}{" "}
                              </label>
                            </div>
                            <div className="text">
                              {this.state.transaction.rate + "%"}{" "}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="m-b-5">
                            <label>
                              {" "}
                              {translate("transactions.transactionDate")}{" "}
                            </label>
                          </div>
                          <div className="text">
                            {moment(
                              this.state.transaction.createDate,
                              "YYYY-MM-DD hh:mm:ss a",
                            ).format("DD-MM-YYYY hh:mm:ss a")}
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.state.transaction.documentIdOriginalContract && (
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="m-b-10"
                            style={{
                              padding: "15px",
                              border: "1px solid #ddd",
                            }}
                          >
                            <div className="m-b-10">
                              <h4>
                                {translate(
                                  "transaction.transactionContractTitle",
                                )}
                              </h4>
                            </div>

                            <div className="row">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <th style={{ width: "20px" }}>
                                      {this.state.transaction.debtor[0]
                                        .signatureStatus && (
                                        <i
                                          className={
                                            this.state.transaction.debtor[0]
                                              .signatureStatus
                                              ? "material-icons col-green"
                                              : "material-icons col-orange"
                                          }
                                        >
                                          &#xe86c;
                                        </i>
                                      )}
                                      {!this.state.transaction.debtor[0]
                                        .signatureStatus && (
                                        <i
                                          className={
                                            this.state.transaction.debtor[0]
                                              .signatureStatus
                                              ? "material-icons col-green"
                                              : "material-icons col-orange"
                                          }
                                        >
                                          &#xe924;
                                        </i>
                                      )}
                                    </th>
                                    <td>
                                      <span style={{ display: "flex" }}>
                                        {this.state.transaction.customerName}
                                      </span>
                                      <span style={{ color: "#909098" }}>
                                        {this.state.transaction.typeApproval ===
                                        1
                                          ? translate(
                                              "transaction.transactionAutomaticApproval",
                                            )
                                          : this.state.transaction.debtor[0]
                                                .signatureStatus
                                            ? translate(
                                                "transaction.transactionSignedDebtor",
                                              )
                                            : translate(
                                                "transaction.transactionSignDebtor",
                                              )}
                                      </span>
                                    </td>

                                    <td style={{ width: "20px" }}>
                                      {this.state.transaction.typeApproval ===
                                        0 &&
                                        !this.state.transaction.debtor[0]
                                          .signatureStatus && (
                                          <button
                                            title={
                                              this.state.transaction.debtor[0]
                                                .signatureOpenEmailDate
                                                ? "Abertura de e-mail " +
                                                  moment(
                                                    this.state.transaction
                                                      .debtor[0]
                                                      .signatureOpenEmailDate,
                                                    "YYYY-MM-DD h:mm:ss a",
                                                  ).format(
                                                    "DD-MM-YYYY h:mm:ss a",
                                                  )
                                                : ""
                                            }
                                            type="button"
                                            onClick={() => {
                                              this.handleChangeOpenEmail(
                                                this.state.transaction.debtor,
                                                1,
                                              );
                                            }}
                                            className="btn btn-default btn-circle waves-effect waves-circle waves-float"
                                          >
                                            {this.state.transaction.debtor[0]
                                              .signatureOpenEmail && (
                                              <i className="material-icons">
                                                &#xe151;
                                              </i>
                                            )}
                                            {!this.state.transaction.debtor[0]
                                              .signatureOpenEmail && (
                                              <i className="material-icons">
                                                &#xe0be;
                                              </i>
                                            )}
                                          </button>
                                        )}
                                    </td>
                                    <td style={{ width: "110px" }}>
                                      <span style={{ display: "flex" }}>
                                        {this.state.transaction.debtor[0]
                                          .signatureDate
                                          ? moment(
                                              this.state.transaction.debtor[0]
                                                .signatureDate,
                                              "YYYY-MM-DD hh:mm:ss a",
                                            ).format("DD-MM-YYYY")
                                          : ""}
                                      </span>
                                      <span>
                                        {this.state.transaction.debtor[0]
                                          .signatureDate
                                          ? moment(
                                              this.state.transaction.debtor[0]
                                                .signatureDate,
                                              "YYYY-MM-DD hh:mm:ss a",
                                            ).format("hh:mm:ss a")
                                          : ""}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th style={{ width: "20px" }}>
                                      {this.state.transaction.assignor[0]
                                        .signatureStatus && (
                                        <i
                                          className={
                                            this.state.transaction.assignor[0]
                                              .signatureStatus
                                              ? "material-icons col-green"
                                              : "material-icons col-orange"
                                          }
                                        >
                                          &#xe86c;
                                        </i>
                                      )}
                                      {!this.state.transaction.assignor[0]
                                        .signatureStatus && (
                                        <i
                                          className={
                                            this.state.transaction.assignor[0]
                                              .signatureStatus
                                              ? "material-icons col-green"
                                              : "material-icons col-orange"
                                          }
                                        >
                                          &#xe924;
                                        </i>
                                      )}
                                    </th>
                                    <td>
                                      <span style={{ display: "flex" }}>
                                        {this.state.transaction.supplierName}
                                      </span>
                                      <span style={{ color: "#909098" }}>
                                        {this.state.transaction.assignor[0]
                                          .signatureStatus
                                          ? translate(
                                              "transaction.transactionSignedAssignor",
                                            )
                                          : translate(
                                              "transaction.transactionSignAssignor",
                                            )}
                                      </span>
                                    </td>

                                    <td style={{ width: "20px" }}>
                                      {!this.state.transaction.assignor[0]
                                        .signatureStatus && (
                                        <button
                                          title={
                                            this.state.transaction.assignor[0]
                                              .signatureOpenEmailDate
                                              ? "Abertura de e-mail " +
                                                moment(
                                                  this.state.transaction
                                                    .assignor[0]
                                                    .signatureOpenEmailDate,
                                                  "YYYY-MM-DD h:mm:ss a",
                                                ).format("DD-MM-YYYY h:mm:ss a")
                                              : ""
                                          }
                                          type="button"
                                          onClick={() => {
                                            this.handleChangeOpenEmail(
                                              this.state.transaction.assignor,
                                              2,
                                            );
                                          }}
                                          className="btn btn-default btn-circle waves-effect waves-circle waves-float"
                                        >
                                          {this.state.transaction.assignor[0]
                                            .signatureOpenEmail && (
                                            <i className="material-icons">
                                              &#xe151;
                                            </i>
                                          )}
                                          {!this.state.transaction.assignor[0]
                                            .signatureOpenEmail && (
                                            <i className="material-icons">
                                              &#xe0be;
                                            </i>
                                          )}
                                        </button>
                                      )}
                                    </td>
                                    <td style={{ width: "110px" }}>
                                      <span style={{ display: "flex" }}>
                                        {this.state.transaction.assignor[0]
                                          .signatureDate
                                          ? moment(
                                              this.state.transaction.assignor[0]
                                                .signatureDate,
                                              "YYYY-MM-DD hh:mm:ss a",
                                            ).format("DD-MM-YYYY")
                                          : ""}
                                      </span>
                                      <span>
                                        {this.state.transaction.assignor[0]
                                          .signatureDate
                                          ? moment(
                                              this.state.transaction.assignor[0]
                                                .signatureDate,
                                              "YYYY-MM-DD hh:mm:ss a",
                                            ).format("hh:mm:ss a")
                                          : ""}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th style={{ width: "20px" }}>
                                      {this.state.transaction.assignee.length >
                                        0 &&
                                        this.state.transaction.assignee[0]
                                          .signatureStatus && (
                                          <i
                                            className={
                                              this.state.transaction.assignee
                                                .length > 0 &&
                                              this.state.transaction.assignee[0]
                                                .signatureStatus
                                                ? "material-icons col-green"
                                                : "material-icons col-orange"
                                            }
                                          >
                                            &#xe86c;
                                          </i>
                                        )}
                                      {!(
                                        this.state.transaction.assignee.length >
                                          0 &&
                                        this.state.transaction.assignee[0]
                                          .signatureStatus
                                      ) && (
                                        <i
                                          className={
                                            this.state.transaction.assignee
                                              .length > 0 &&
                                            this.state.transaction.assignee[0]
                                              .signatureStatus
                                              ? "material-icons col-green"
                                              : "material-icons col-orange"
                                          }
                                        >
                                          &#xe924;
                                        </i>
                                      )}
                                    </th>
                                    <td>
                                      <span style={{ display: "flex" }}>
                                        {this.state.transaction.bankName
                                          ? this.state.transaction.bankName
                                          : translate(
                                              "transaction.transactionAssignee",
                                            )}
                                      </span>
                                      <span style={{ color: "#909098" }}>
                                        {this.state.transaction.assignee
                                          .length > 0 &&
                                        this.state.transaction.assignee[0]
                                          .signatureStatus
                                          ? translate(
                                              "transaction.transactionSignedAssignee",
                                            )
                                          : translate(
                                              "transaction.transactionSignAssignee",
                                            )}
                                      </span>
                                    </td>

                                    <td style={{ width: "20px" }}>
                                      {this.state.transaction.assignee.length >
                                        0 &&
                                        !this.state.transaction.assignee[0]
                                          .signatureStatus && (
                                          <button
                                            title={
                                              this.state.transaction.assignee
                                                .length > 0 &&
                                              this.state.transaction.assignee[0]
                                                .signatureOpenEmailDate
                                                ? "Abertura de e-mail " +
                                                  moment(
                                                    this.state.transaction
                                                      .assignee[0]
                                                      .signatureOpenEmailDate,
                                                    "YYYY-MM-DD h:mm:ss a",
                                                  ).format(
                                                    "DD-MM-YYYY h:mm:ss a",
                                                  )
                                                : ""
                                            }
                                            type="button"
                                            onClick={() => {
                                              this.handleChangeOpenEmail(
                                                this.state.transaction.assignee,
                                                3,
                                              );
                                            }}
                                            className="btn btn-default btn-circle waves-effect waves-circle waves-float"
                                          >
                                            {this.state.transaction.assignee
                                              .length > 0 &&
                                              this.state.transaction.assignee[0]
                                                .signatureOpenEmail && (
                                                <i className="material-icons">
                                                  &#xe151;
                                                </i>
                                              )}
                                            {!(
                                              this.state.transaction.assignee
                                                .length > 0 &&
                                              this.state.transaction.assignee[0]
                                                .signatureOpenEmail
                                            ) && (
                                              <i className="material-icons">
                                                &#xe0be;
                                              </i>
                                            )}
                                          </button>
                                        )}
                                    </td>
                                    <td style={{ width: "110px" }}>
                                      <span style={{ display: "flex" }}>
                                        {this.state.transaction.assignee
                                          .length > 0 &&
                                        this.state.transaction.assignee[0]
                                          .signatureDate
                                          ? moment(
                                              this.state.transaction.assignee[0]
                                                .signatureDate,
                                              "YYYY-MM-DD hh:mm:ss a",
                                            ).format("DD-MM-YYYY")
                                          : ""}
                                      </span>
                                      <span>
                                        {this.state.transaction.assignee
                                          .length > 0 &&
                                        this.state.transaction.assignee[0]
                                          .signatureDate
                                          ? moment(
                                              this.state.transaction.assignee[0]
                                                .signatureDate,
                                              "YYYY-MM-DD hh:mm:ss a",
                                            ).format("hh:mm:ss a")
                                          : ""}
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <button
                              className="btn bg-deep-purple waves-effect m-r-10"
                              type="button"
                              onClick={() => {
                                this.handleChangeShowContract();
                              }}
                            >
                              <i className="material-icons">&#xe873;</i>
                              <span>{translate("forms.toViewContract")}</span>
                            </button>

                            <div className="btn-group" role="group">
                              <button
                                id="btnDownload"
                                type="button"
                                className="btn bg-deep-purple waves-effect dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                <i className="material-icons">&#xe2c0;</i>
                                <span>
                                  {translate("forms.downloadContract")}
                                </span>
                                <span className="caret"></span>
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="btnDownload"
                              >
                                <li>
                                  <a
                                    href={
                                      DOCUMENT_SERVICE_URL +
                                      "/api/Document/Download/" +
                                      this.state.transaction.fileHashEncrypted
                                    }
                                    className="waves-effect waves-block"
                                  >
                                    {translate(
                                      "forms.downloadOriginalContract",
                                    )}
                                  </a>
                                </li>
                                <li>
                                  {!this.state.transaction
                                    .fileHashSignedContract && (
                                    <a
                                      href="javascript:void(0);"
                                      onClick={() => {
                                        this.downloadSignedContract();
                                      }}
                                      className="waves-effect waves-block"
                                    >
                                      {translate(
                                        "forms.downloadSignedContract",
                                      )}
                                    </a>
                                  )}

                                  {this.state.transaction
                                    .fileHashSignedContract && (
                                    <a
                                      href={
                                        DOCUMENT_SERVICE_URL +
                                        "/api/Document/Download/" +
                                        this.state.transaction
                                          .fileHashEncryptedSigned
                                      }
                                      className="waves-effect waves-block"
                                    >
                                      {translate(
                                        "forms.downloadSignedContract",
                                      )}
                                    </a>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <ModalSummaryValues
                        invoiceCount={this.state.transaction.invoiceCount}
                        totalSum={this.state.transaction.totalGross}
                        totalDiscountValue={this.state.transaction.totalRate}
                        totalNetValue={this.state.transaction.totalNet}
                      />
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="material-icons">&#xe8b6;</i>
                          </span>

                          <div className="form-line">
                            <input
                              name="searchText"
                              className="form-control"
                              autoComplete="off"
                              disabled={this.state.loading}
                              placeholder={translate("forms.searchBar")}
                              type="text"
                              value={this.state.searchText}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="m-b-40">
                      <ReactTable
                        columns={[
                          {
                            columns: [
                              {
                                Header: translate("invoice.invoiceNumber"),
                                id: "invoiceNumber",
                                maxWidth: 90,
                                accessor: (data) => {
                                  return (
                                    <span
                                      title={
                                        translate(
                                          "invoice.invoiceInstallment",
                                        ) +
                                        " " +
                                        data.installment +
                                        " / " +
                                        data.totalInstallment
                                      }
                                    >
                                      {" "}
                                      {data.invoiceNumber}
                                    </span>
                                  );
                                },
                              },
                              {
                                Header: translate("invoice.invoiceDueDate"),
                                id: "dueDate",
                                accessor: (data) => {
                                  return moment(
                                    data.dueDate,
                                    "YYYY-MM-DD",
                                  ).format("DD-MM-YYYY");
                                },
                                width: 105,
                              },
                              {
                                Header: (
                                  <span
                                    title={translate(
                                      "invoice.invoiceDaysExpirationTitle",
                                    )}
                                  >
                                    {" "}
                                    {translate(
                                      "invoice.invoiceDaysExpiration",
                                    )}{" "}
                                  </span>
                                ),
                                accessor: "daysExpiration",
                                width: 125,
                              },
                              {
                                Header: translate("invoice.invoiceAmount"),
                                id: "amount",
                                accessor: (data) => {
                                  return new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }).format(data.amount);
                                },
                                width: 140,
                              },
                              {
                                Header: translate(
                                  "invoice.invoiceDiscountValue",
                                ),
                                id: "discountValue",
                                accessor: (data) => {
                                  return new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }).format(data.discountValue);
                                },
                                width: 140,
                              },
                              {
                                Header: translate("invoice.invoiceNetValue"),
                                id: "netValue",
                                accessor: (data) => {
                                  return new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }).format(data.netValue);
                                },
                                width: 125,
                              },
                              {
                                Header: translate("invoice.invoiceStatus"),
                                id: "status",
                                accessor: (data) => {
                                  return (
                                    <span
                                      title={
                                        enums.getStatus().find((x) => {
                                          return (
                                            x.index === parseInt(data.status)
                                          );
                                        }).text
                                      }
                                    >
                                      {" "}
                                      {
                                        enums.getStatus().find((x) => {
                                          return (
                                            x.index === parseInt(data.status)
                                          );
                                        }).text
                                      }{" "}
                                    </span>
                                  );
                                },
                              },
                            ],
                          },
                        ]}
                        data={invoiceItems}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        loading={this.state.loading}
                        previousText={translate("forms.previousText")}
                        nextText={translate("forms.nextText")}
                        noDataText={translate("forms.noDataText")}
                        pageText={translate("forms.pageText")}
                        ofText={translate("forms.ofText")}
                        rowsText={translate("forms.rowsText")}
                      />
                    </div>

                    <div className="row">
                      <ModalBankAccount
                        transactionStatus={this.state.transaction.status}
                        supplierId={this.state.transaction.supplierId}
                        supplier={null}
                        bankAccount={this.state.bankAccount}
                        loading={this.state.loading}
                        handleChangeBankAccount={(event) => {
                          this.handleChangeBankAccount(event);
                        }}
                      />
                    </div>

                    <div className="row">
                      <ModalAddress
                        transactionStatus={this.state.transaction.status}
                        supplierId={this.state.transaction.supplierId}
                        supplier={null}
                        address={this.state.address}
                        loading={this.state.loading}
                        handleChangeAddress={(event) => {
                          this.handleChangeAddress(event);
                        }}
                      />
                    </div>

                    <div className="row">
                      {(session.isAdmin() || session.isOperator()) &&
                        this.state.transaction.status != 4 && (
                          <ModalMoneySource
                            transaction={this.state.transaction}
                            loading={this.state.loading}
                            handleChangeMoneySource={(event) => {
                              this.handleChangeMoneySource(event);
                            }}
                          />
                        )}
                    </div>

                    <div className="row m-b-20">
                      {this.state.transaction.status != 4 && (
                        <ModalCustomerAddress
                          transactionStatus={this.state.transaction.status}
                          customerId={this.state.transaction.customerId}
                          customer={null}
                          address={this.state.customerAddress}
                          loading={this.state.loading}
                          handleChangeAddress={(event) => {
                            this.handleChangeCustomerAddress(event);
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div
                    id="CNABPanel"
                    className="tab-pane fade in"
                    role="tabpanel"
                  >
                    {this.state.transaction.cnab && (
                      <div className="m-t-10">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="m-b-5">
                              <label htmlFor="cnabFileName">
                                {" "}
                                {translate("cnab.cnabFileName")}{" "}
                              </label>
                            </div>
                            <div className="text">
                              {this.state.transaction.cnab.fileName}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="m-b-5">
                              <label htmlFor="cnabLayout">
                                {" "}
                                {translate("cnab.cnabLayout")}{" "}
                              </label>
                            </div>
                            <div className="text">
                              {this.state.transaction.cnab.layout}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="m-b-5">
                              <label htmlFor="cnabStatus">
                                {" "}
                                {translate("cnab.cnabStatus")}{" "}
                              </label>
                            </div>
                            <div className="text">
                              {this.state.transaction.cnab.status}
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="m-b-5">
                              <label> {translate("cnab.cnabDate")} </label>
                            </div>
                            <div className="text">
                              {moment(
                                this.state.transaction.cnab.createDate,
                                "YYYY-MM-DD hh:mm:ss a",
                              ).format("DD-MM-YYYY hh:mm:ss a")}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row clearfix">
                      <div className="col-md-12">
                        <button
                          className="btn bg-deep-purple waves-effect m-r-10"
                          type="button"
                          disabled={this.state.assigneeId == 0}
                          style={{
                            display: this.state.transaction.cnab
                              ? "none"
                              : "inline-block",
                          }}
                          onClick={() => {
                            this.cnabGenerateFile();
                          }}
                        >
                          <i className="material-icons">&#xe8b8;</i>
                          <span>{translate("forms.cnabGenerateFile")}</span>
                        </button>

                        <button
                          className="btn bg-deep-purple waves-effect m-r-10"
                          type="button"
                          style={{
                            display: this.state.transaction.cnab
                              ? "inline-block"
                              : "none",
                          }}
                          onClick={this.downloadTxtFile}
                        >
                          <i className="material-icons">&#xe2c0;</i>
                          <span>{translate("forms.cnabDownloadFile")}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="row clearfix"
                  ref={(el) => {
                    this.state.scrollEnd = el;
                  }}
                >
                  <div className="col-md-11 formActions">
                    <Link
                      to="/transactions"
                      className="btn btn-default waves-effect"
                    >
                      <i className="material-icons">&#xe5c4;</i>
                      <span>{translate("forms.buttonReturn")}</span>
                    </Link>

                    {(session.isAdmin() || session.isOperator()) &&
                      (this.state.transaction.status == 0 ||
                        this.state.transaction.status == 7) && (
                        <button
                          type="button"
                          className="btn btn-danger waves-effect m-l-10"
                          disabled={this.state.loading}
                          onClick={this.handleCancel}
                        >
                          <i className="material-icons">&#xe5c9;</i>
                          <span>{translate("forms.buttonCancel")}</span>
                        </button>
                      )}

                    {(session.isAdmin() ||
                      session.isOperator() ||
                      session.isSupplier()) &&
                      (this.state.transaction.status == 0 ||
                        this.state.transaction.status == 7) && (
                        <button
                          type="button"
                          className="btn btn-success waves-effect"
                          disabled={this.state.loading}
                          onClick={this.handleSubmit}
                        >
                          <i className="material-icons">&#xe161;</i>
                          <span>{translate("forms.buttonSave")}</span>
                        </button>
                      )}

                    {((session.isAdmin() &&
                      (this.state.transaction.status == 0 ||
                        this.state.transaction.status == 7)) ||
                      (session.isOperator() &&
                        this.state.transaction.status == 0)) && (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect"
                        disabled={this.state.loading}
                        onClick={this.handleProcess}
                      >
                        <i className="material-icons">&#xe863;</i>
                        <span>
                          {translate("transaction.transactionButtonProcess")}
                        </span>
                      </button>
                    )}

                    {session.isCustomer() &&
                      this.state.transaction.status == 7 && (
                        <button
                          type="button"
                          className="btn btn-primary waves-effect"
                          disabled={this.state.loading}
                          onClick={this.handleApprove}
                        >
                          <i className="material-icons">&#xe876;</i>
                          <span>
                            {translate("transaction.transactionButtonApprove")}
                          </span>
                        </button>
                      )}
                  </div>
                  <div className="col-md-1 formActions">
                    <div className="header-dropdown" style={{ float: "right" }}>
                      <div
                        className="preloader pl-size-xs"
                        style={{
                          display: this.state.loading ? "block" : "none",
                        }}
                      >
                        <div className="spinner-layer">
                          <div className="circle-clipper left">
                            <div className="circle"></div>
                          </div>

                          <div className="circle-clipper right">
                            <div className="circle"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <ModalSendEmail
                  transaction={this.state.transaction}
                  signer={this.state.signer}
                  signerType={this.state.signerType}
                  openEmail={this.state.openEmail}
                  loading={this.state.loading}
                  closeEmail={() => {
                    this.setState({ openEmail: false });
                    this.setState({ loading: false });
                    this.forceUpdate();
                  }}
                />

                {this.state.transaction.documentIdOriginalContract && (
                  <ModalContract
                    fileHash={this.state.transaction.fileHashOriginalContract}
                    document={this.state.document}
                    open={this.state.showContract}
                    close={() => {
                      this.setState({ showContract: false });
                      this.forceUpdate();
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <ToastContainer hideProgressBar />
        </div>
      );
    } else {
      return (
        <div className="preloader pl-size-lg align-center">
          <div className="spinner-layer">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>

            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const ToastRedirect = ({ tabToShow, message, closeToast }) => {
  function handleClick() {
    tabToShow.trigger("click");
    closeToast();
  }

  return <div onClick={handleClick}>{message}</div>;
};

export default transactionViewPage;
