import { translate } from "react-internationalization";

export function getTypes() {
  let list = [
    { text: translate("enums.signatoryType.others"), index: 0 },
    { text: translate("enums.signatoryType.customer"), index: 1 },
    { text: translate("enums.signatoryType.supplier"), index: 2 },
    { text: translate("enums.signatoryType.bank"), index: 3 },
    { text: translate("enums.signatoryType.operator"), index: 4 },
    { text: translate("enums.signatoryType.anonymous"), index: 5 },
    { text: translate("enums.signatoryType.part"), index: 6 },
    { text: translate("enums.signatoryType.witness"), index: 7 },
    { text: translate("enums.signatoryType.contractor"), index: 8 },
    { text: translate("enums.signatoryType.hired"), index: 9 },
    {
      text: translate("enums.signatoryType.signToAcknowledgeReceipt"),
      index: 10,
    },
    { text: translate("enums.signatoryType.signToApprove"), index: 11 },
    { text: translate("enums.signatoryType.signToHomologate"), index: 12 },
    { text: translate("enums.signatoryType.guarantor"), index: 13 },
    { text: translate("enums.signatoryType.issuer"), index: 14 },
    { text: translate("enums.signatoryType.endorsing"), index: 15 },
    { text: translate("enums.signatoryType.endorser"), index: 16 },
    { text: translate("enums.signatoryType.sponsor"), index: 17 },
    { text: translate("enums.signatoryType.manager"), index: 18 },
    { text: translate("enums.signatoryType.intervening"), index: 19 },
    { text: translate("enums.signatoryType.locator"), index: 20 },
    { text: translate("enums.signatoryType.tenant"), index: 21 },
    { text: translate("enums.signatoryType.buyingParty"), index: 22 },
    { text: translate("enums.signatoryType.sellingParty"), index: 23 },
    { text: translate("enums.signatoryType.procurator"), index: 24 },
    { text: translate("enums.signatoryType.legalRepresentative"), index: 25 },
    { text: translate("enums.signatoryType.validator"), index: 26 },
  ];

  list = list.sort(function (a, b) {
    return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
  });

  return list;
}
