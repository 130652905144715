import React from "react";
import { submitReportDailyLog } from "./helper";
import { logoutSession } from "../utils/localStorageUtils";
import moment from "moment";
class LogoutPage extends React.Component {
  displayName = LogoutPage.name;
  constructor(props) {
    super(props);

    this.state = {
      reportDailyLog: {
        startDate: moment().format(),
        objectAfter: "",
        objectBefore: "",
      },
    };

    let reportDailyLog = this.state.reportDailyLog;

    reportDailyLog.accountId = localStorage.getItem("userId");
    reportDailyLog.action = 1;
    reportDailyLog.objectBefore = localStorage.getItem("email");
    reportDailyLog.startDate = moment().format();

    submitReportDailyLog(reportDailyLog);

    // expires the token passing a past date
    logoutSession(localStorage);

    window.location.href = "/login";
  }

  render() {
    return <div></div>;
  }
}
export default LogoutPage;
