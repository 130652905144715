import React from "react";
import { translate } from "react-internationalization";

export default class ModalSummaryValues extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
      <div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div className="info-box-4 hover-expand-effect">
            <div className="icon">
              <i className="material-icons col-teal">&#xe86d;</i>
            </div>
            <div className="contentbox">
              <div className="text">{translate("invoice.invoiceQtde")}</div>
              <div className="number">{this.props.invoiceCount}</div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div className="info-box-4 hover-expand-effect">
            <div className="icon">
              <i className="material-icons col-green">&#xe227;</i>
            </div>
            <div className="contentbox">
              <div className="text">
                {translate("invoice.invoiceTotalAmount")}
              </div>
              <div className="number">
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(this.props.totalSum)}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div className="info-box-4 hover-expand-effect">
            <div className="icon">
              <i className="material-icons col-light-green">&#xe227;</i>
            </div>
            <div className="contentbox">
              <div className="text">
                {translate("invoice.invoiceDiscountValue")}
              </div>
              <div className="number">
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(this.props.totalDiscountValue)}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
          <div className="info-box-4 hover-expand-effect">
            <div className="icon">
              <i className="material-icons col-blue">&#xe227;</i>
            </div>
            <div className="contentbox">
              <div className="text">{translate("invoice.invoiceNetValue")}</div>
              <div className="number">
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(this.props.totalNetValue)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
