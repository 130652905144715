import React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { translate } from "react-internationalization";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as session from "../components/SessionValidator";
import Modal from "react-responsive-modal";
import { ACCOUNT_SERVICE_URL } from "../settings";
import { putRequest } from "../service/RequestService";

export default class UseTermComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      useTerm: false,
    };
  }

  handleReturn() {
    this.props.handleReturn();
  }

  handleSubmit() {
    putRequest(ACCOUNT_SERVICE_URL + "/api/Account/UpdateUserTerm", "")
      .then((res) => {
        switch (res.code) {
          case 200:
            localStorage.setItem("ut", res.data);
            this.handleReturn();
            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("error");
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async handleChange(event) {
    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    this.setState(this.state);
  }

  onClose() {
    if (session.isUseTerm()) this.props.handleReturn();
  }

  render() {
    return (
      <div>
        <Modal
          open={this.props.modalOpen}
          closeOnEsc={session.isUseTerm()}
          onClose={() => {
            this.onClose();
          }}
          onEntered={() => {
            this.forceUpdate();
          }}
          closeIconSize={session.isUseTerm() ? 25 : 0}
          center={true}
          styles={{ modal: { maxWidth: "80%" } }}
          close={() => {}}
        >
          <div>
            <div className="row">
              <div className="col-xs-12">
                <div className="card">
                  <div className="header">
                    <h2>Termos de Uso da AntecipaPay</h2>
                    <small>Atualizada em 06 de Outubro de 2021.</small>
                  </div>

                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-md-12">
                        <div className="align-left">
                          <p>
                            Estes Termos de Uso, incluindo a Política de
                            Privacidade - LGPD aqui incorporada (doravante
                            designados, conjuntamente, “Termo”), regulam o
                            acesso e uso da Plataforma.
                          </p>
                          <strong>1. DEFINIÇÕES</strong>
                          <p>
                            As seguintes definições são aplicáveis em conjunto
                            com outros termos definidos neste instrumento:
                            "AntecipaPay": AntecipaPay antecipação de
                            Recebíveis, inscrita no CNPJ/ME sob o nº
                            52.301.844/0001-76, proprietária e operadora da
                            Plataforma, que pode ser contatada através de
                            contato@antecipapay.com.br, ou conforme indicado na
                            Plataforma. “Cliente”: pessoa que utiliza a
                            Plataforma para, dependendo do grau de acesso,
                            efetuar upload, criar, enviar, receber, acessar,
                            arquivar, apagar, efetuar download e gerenciar
                            Documentos. "Conta": meio através do qual o Cliente
                            acessa e utiliza a Plataforma. Uma Conta pode ser
                            movimentada por um ou mais Clientes. Um Cliente pode
                            movimentar múltiplas Contas. “Documento”: arquivo
                            carregado na Plataforma para ser assinado
                            eletronicamente. "Plataforma": sistemas acessíveis
                            através do site portal.antecipapay.com.br, bem como
                            de aplicativos associados, e redes administradas
                            pela AntecipaPay. "Signatário": pessoa que utiliza a
                            Plataforma para assinar um Documento. "Usuário":
                            Cliente, Signatário e demais pessoas que navegam na
                            Plataforma.
                          </p>
                          <p>
                            <strong>
                              <div>
                                2. ACEITAÇÃO E MODIFICAÇÃO DOS TERMOS DE USO
                              </div>
                              2.1.
                            </strong>
                            Ao utilizar quaisquer das funcionalidades da
                            Plataforma, o Usuário confirma que leu, entendeu e
                            aceitou o presente Termo em sua totalidade, sem
                            qualquer modificação. Caso não concorde com este
                            Termo, o Usuário não deverá utilizar a Plataforma.
                          </p>
                          <p>
                            <strong>2.2.</strong>A AntecipaPay poderá alterar
                            qualquer parte deste Termo em boa-fé, mediante
                            notificação ao Cliente. Caso o Cliente, ou qualquer
                            Usuário, continue utilizando e/ou de qualquer forma
                            acessando a Plataforma, será considerado para todos
                            os fins e efeitos de direito, que o Cliente, ou
                            qualquer Usuário, concordou com a nova versão do
                            Termos de Uso.
                          </p>
                          <strong>
                            3. CONDIÇÕES PARA O ACESSO E USO DA PLATAFORMA
                          </strong>
                          <p>
                            <strong>3.1.</strong>A AntecipaPay concede ao
                            Usuário o direito não exclusivo, intransferível, não
                            sublicenciável, limitado e revogável de acessar e
                            usar a Plataforma, condicionado ao cumprimento das
                            regras da AntecipaPay estabelecidas neste documento,
                            observadas as limitações comerciais aplicáveis.
                          </p>
                          <p>
                            <strong>3.2.</strong>A Plataforma é oferecida em
                            condição "como está" e "onde está". A AntecipaPay
                            poderá, a seu exclusivo critério, atualizar,
                            alterar, acrescentar ou remover qualquer parte,
                            total ou parcialmente, da Plataforma, a qualquer
                            tempo.
                          </p>
                          <p>
                            <strong>3.3.</strong>
                            AO UTILIZAR E ACESSAR A PLATAFORMA O USUÁRIO DECLARA
                            EXPRESSAMENTE SER MAIOR DE 18 (DEZOITO) ANOS OU SER
                            MENOR EMANCIPADO, OU ESTAR DE POSSE DE AUTORIZAÇÃO
                            LEGAL DOS PAIS OU DE TUTORES, E SER PLENAMENTE CAPAZ
                            DE CONSENTIR COM OS TERMOS, CONDIÇÕES, OBRIGAÇÕES,
                            AFIRMAÇÕES, REPRESENTAÇÕES E GARANTIAS DESCRITAS
                            NESTE INSTRUMENTO, OBEDECÊ-LOS E CUMPRI-LOS. Os
                            pais, tutores ou representantes legais serão
                            plenamente responsáveis no caso de acesso à
                            Plataforma por parte de Usuário que tiver menos de
                            18 (dezoito) anos e não for emancipado ou não tiver
                            a devida autorização.
                          </p>
                          <p>
                            <strong>3.4.</strong>
                            Para utilizar a Plataforma na condição de Cliente,
                            será necessário enviar os dados solicitados para
                            criar uma Conta. Após a criação da Conta, o Cliente
                            passará a ter acesso às funcionalidades da
                            Plataforma por meio de login e senha, dados esses
                            que se compromete a não divulgar a terceiros,
                            ficando sob sua exclusiva responsabilidade qualquer
                            uso do login e senha de sua titularidade.
                          </p>
                          <p>
                            <strong>3.5.</strong> A AntecipaPay não se
                            responsabilizará por qualquer prejuízo decorrente do
                            uso não autorizado do login e senha do Cliente, com
                            ou sem o seu conhecimento. O Cliente deverá
                            notificar imediatamente a AntecipaPay caso verifique
                            o uso indevido e não autorizado de sua Conta.
                          </p>
                          <p>
                            <strong> 3.6. </strong>
                            As funcionalidades da Plataforma colocadas à
                            disposição do Cliente podem variar de acordo com o
                            plano de serviços contratado pelo Cliente.
                          </p>
                          <p>
                            <strong>3.7.</strong>O Cliente é o único e exclusivo
                            responsável pelas informações de cadastro inseridas
                            na Plataforma. O Cliente obriga-se por garantir a
                            plena exatidão e veracidade das informações
                            prestadas, bem como por mantê-las sempre
                            atualizadas.
                          </p>
                          <p>
                            <strong> 3.8.</strong>
                            Em caso de dúvida sobre a exatidão e veracidade das
                            informações comunicadas pelo Usuário ou nos casos em
                            que tais dúvidas sejam levadas a seu conhecimento
                            por terceiros, a AntecipaPay se reserva o direito de
                            suspender o acesso do Cliente à sua Conta para
                            verificação e autenticação de sua identidade.
                          </p>
                          <p>
                            <strong>3.9.</strong>O Cliente reconhece e concorda
                            que:
                            <p>
                              <strong>(I)</strong> os procedimentos de
                              autenticação das assinaturas eletrônicas
                              disponibilizados pela Plataforma são adequados
                              para os seus propósitos;
                            </p>
                            <p>
                              <strong>(II)</strong> tem a responsabilidade de
                              ser diligente e verificar a identidade de cada
                              Signatário;
                            </p>
                            <p>
                              <strong>(III)</strong> a Plataforma provê um grau
                              de confidencialidade, integridade, disponibilidade
                              e autenticidade adequados para os seus propósitos;
                            </p>
                            <p>
                              <strong>(IV</strong>) os Documentos deverão ser
                              considerados como dotados de integridade e deverão
                              ser qualificados como originais; e
                            </p>
                            <p>
                              <strong>(V)</strong> a Plataforma provê um relato
                              confiável sobre fluxo, conteúdo, e ações
                              concernentes a qualquer Documento.
                            </p>
                          </p>
                          <p>
                            <strong>3.10.</strong>O Cliente concorda que é o
                            único e exclusivo responsável por garantir que o
                            Documento carregado na Plataforma está livre de
                            erros, vírus ou problemas similares, e foi
                            adequadamente carregado de forma a refletir a
                            totalidade do conteúdo de forma acurada, dentro da
                            sua expectativa.
                          </p>
                          <p>
                            <strong>3.11. </strong> O Usuário se compromete a
                            utilizar a Plataforma apropriadamente, obrigando-se
                            a:
                          </p>
                          <p>
                            <strong>(I) </strong> não infringir direitos de
                            terceiros, tais como direitos autorais, direitos de
                            propriedade industrial, privacidade, entre outros;
                          </p>

                          <p>
                            <strong>(II) </strong> abster-se de praticar, por
                            meio da Plataforma, qualquer crime, incluindo, mas
                            não se limitando à difamação, injúria, calúnia,
                            provocação, com caráter racista, discriminatório ou
                            xenofóbico, apologia aos crimes de guerra ou crimes
                            contra a humanidade;
                          </p>
                          <p>
                            <strong>(III) </strong> comunicar a AntecipaPay
                            imediatamente todas as vezes que notar movimentação
                            estranha em sua Conta ou caso tenha divulgado sua
                            senha publicamente, por qualquer motivo; e
                          </p>
                          <p>
                            <strong>(IV) </strong> não compartilhar Documentos
                            cujo conteúdo sugira pornografia, violência,
                            discriminação, de qualquer tipo, atos ilegais,
                            extremismo político, ódio e informar imediatamente à
                            AntecipaPay, caso receba este tipo de conteúdo de
                            outro Usuário.
                          </p>
                          <p>
                            <strong>4. AUTORIZAÇÕES PARA ASSINATURA </strong>
                          </p>
                          <p>
                            <strong>4.1. </strong> O Signatário reconhece e
                            concorda que, ao executar os procedimentos de
                            autenticação com a finalidade de assinar
                            eletronicamente um Documento na Plataforma
                            ("Assinar"), autoriza a AntecipaPay a registrar de
                            forma permanente seus pontos de autenticação (tais
                            como nome, e-mail, endereço de IP) e outras
                            informações relevantes como horário e eventos, a um
                            arquivo logicamente associado ao referido Documento
                            (“Log”). O Log é exclusivo ao, e é considerado parte
                            integrante do seu respectivo Documento.
                          </p>
                          <p>
                            <strong>4.2. </strong> O Signatário reconhece e
                            concorda que sua assinatura significa que:
                          </p>
                          <p>
                            <strong>(I) </strong> teve acesso para, e a
                            oportunidade de examinar o Documento e seu teor e
                            formato;
                          </p>
                          <p>
                            <strong>(II) </strong> a sua capacidade como
                            Signatário está corretamente refletida na
                            qualificação da sua assinatura; e
                          </p>
                          <p>
                            <strong>(III) </strong> teve a intenção de assinar
                            para todos os fins indicados no Documento.
                          </p>
                          <p>
                            <strong>4.3. </strong> O Signatário reconhece e
                            concorda que todas as informações fornecidas são
                            verdadeiras e que possui todos os direitos e poderes
                            para realizar a assinatura do Documento. Caso a
                            assinatura seja para a representação de um menor de
                            idade, o Signatário declara que possui autorização
                            legítima para tal.
                          </p>
                          <p>
                            <strong>5. PAGAMENTO </strong>
                          </p>
                          <p>
                            <strong>5.1. </strong> A AntecipaPay enviará ao
                            Cliente faturas periódicas conforme o seu plano de
                            serviço contratado, que poderá ser alterado de
                            tempos em tempos. Se um ciclo de faturamento cobrir
                            menos que um mês inteiro, o valor a ser cobrado
                            poderá ser calculado de forma pro-rata. A
                            AntecipaPay poderá acumular os valores a serem
                            faturados, durante um ciclo de faturamento mensal, e
                            apresentá-los como valor agregado ao final de outro
                            ciclo.
                          </p>
                          <p>
                            <strong>5.2. </strong> O Cliente é responsável por
                            pagar o montante descrito na fatura à AntecipaPay,
                            bem como os tributos aplicáveis no prazo de
                            vencimento indicado na fatura.
                          </p>
                          <p>
                            <strong>5.3. </strong> Caso o Cliente opte por
                            utilizar cartão de crédito para realizar o pagamento
                            do preço do plano contratado, o Cliente autoriza a
                            AntecipaPay, ou terceiros contratados para realizar
                            a cobrança, a cobrar de seu cartão de crédito o
                            montante devido e impostos incidentes. A cada uso da
                            Plataforma, o Cliente reafirma que a AntecipaPay
                            está autorizada a cobrar o preço do plano contratado
                            de seu cartão de crédito. O Cliente é o responsável
                            exclusivo por todas e quaisquer taxas debitadas em
                            seu cartão de crédito pelo emitente do cartão, e
                            concorda em notificar a AntecipaPay sobre quaisquer
                            problemas ou discrepâncias de faturamento dentro de
                            60 (sessenta) dias a contar da data em que
                            aparecerem pela primeira vez em sua fatura.
                          </p>
                          <p>
                            <strong>5.4. </strong> Na hipótese de o Cliente não
                            efetuar o pagamento de quaisquer quantias devidas, a
                            AntecipaPay poderá se utilizar de qualquer medida
                            judicial ou extrajudicial para receber o pagamento
                            devido, com o acréscimo de atualização monetária
                            pelo IGP-M, juros de 1% (um por cento) ao mês e
                            multa de 2% (dois por cento) sobre o total do
                            débito.
                          </p>
                          <p>
                            <strong>6. PROPRIEDADE INTELECTUAL </strong>
                          </p>
                          <p>
                            <strong>6.1. </strong> O Usuário reconhece e
                            concorda que todos os recursos tecnológicos da
                            Plataforma são e permanecerão sendo de propriedade
                            única e exclusiva da AntecipaPay.
                          </p>
                          <p>
                            <strong>6.2. </strong> O Usuário não deverá utilizar
                            a Plataforma:
                          </p>
                          <p>
                            <strong>(I) </strong> para qualquer uso ilegal;
                          </p>
                          <p>
                            <strong>(II) </strong> para realizar engenharia
                            reversa, tentar copiar ou de qualquer forma tentar
                            reproduzir elementos da Plataforma; e
                          </p>
                          <p>
                            <strong>(III) </strong> de qualquer forma que
                            infrinja o disposto neste Termo, bem como direitos
                            de terceiros.
                          </p>
                          <p>
                            <strong>6.3. </strong> Toda mídia, software, texto,
                            imagem, gráfico, interface de usuário, música,
                            vídeo, fotografia, marca registrada, marca,
                            logotipo, arte, tecnologia e outras propriedades
                            intelectuais da Plataforma são e continuarão a ser
                            de exclusiva propriedade da AntecipaPay ou de seus
                            licenciadores. É estritamente proibido o uso de
                            qualquer propriedade intelectual sem o expresso
                            consentimento por escrito da AntecipaPay. Qualquer
                            uso sem o prévio e expresso consentimento da
                            AntecipaPay sujeitará o Usuário à responsabilização
                            cível e/ou criminal, conforme aplicável.
                          </p>
                          <p>
                            <strong>6.4. </strong> Qualquer feedback, sugestão,
                            ou comentário será considerado livre de direitos
                            autorais, voluntário, não confidencial e não
                            exclusivo. A AntecipaPay tem a liberdade de usar
                            esse feedback, sugestão ou comentário à sua
                            conveniência e sem qualquer obrigação.
                          </p>
                          <p>
                            <strong>7. GARANTIAS E RESPONSABILIDADE </strong>
                          </p>
                          <p>
                            <strong>7.1. </strong> A EXCLUSÃO DE GARANTIAS,
                            LIMITAÇÃO DE RESPONSABILIDADE E EXCLUSÃO DE DANOS
                            NESTE TERMO CONSTITUEM UM ELEMENTO FUNDAMENTAL DO
                            MODELO DE NEGÓCIO E MODELO DE PRECIFICAÇÃO DA
                            AntecipaPay. A PLATAFORMA NÃO SERIA DISPONIBILIZADA
                            SEM ESSAS LIMITAÇÕES.
                          </p>
                          <p>
                            <strong>7.2. </strong> O USUÁRIO É EXCLUSIVAMENTE
                            RESPONSÁVEL POR DETERMINAR SE O USO DA PLATAFORMA É
                            ADEQUADO E SUFICIENTE PARA OS SEUS OBJETIVOS. O
                            USUÁRIO ASSUME QUAISQUER RISCOS DECORRENTES DO USO
                            DA PLATAFORMA, INCLUINDO, MAS NÃO SE LIMITANDO, À
                            FALTA DE VALOR PROBATÓRIO DE CERTOS DOCUMENTOS. A
                            AntecipaPay NÃO É RESPONSÁVEL POR ASSEGURAR QUE OS
                            DOCUMENTOS SEJAM CONSIDERADOS VÁLIDOS, POSSUAM VALOR
                            PROBATÓRIO, OU SEJAM EXEQUÍVEIS DE ACORDO COM AS
                            LEIS DE QUALQUER JURISDIÇÃO. EM CASO DE DÚVIDA SOBRE
                            A VALIDADE OU EXEQUIBILIDADE DE QUALQUER DOCUMENTO,
                            O USUÁRIO DEVE OBTER A DEVIDA ORIENTAÇÃO JURÍDICA DE
                            UM ADVOGADO HABILITADO.
                          </p>
                          <p>
                            <strong>7.3. </strong> A AntecipaPay NÃO EFETUA
                            CONTROLE PRÉVIO NEM MONITORA O CONTEÚDO DE QUALQUER
                            DOCUMENTO. PORTANTO, NÃO TEM CONTROLE SOBRE A
                            EXATIDÃO, QUALIDADE, SEGURANÇA, LEGALIDADE,
                            VERACIDADE OU PRECISÃO DE DOCUMENTO OU DE QUALQUER
                            OUTRO CONTEÚDO SUBMETIDO PELO USUÁRIO.
                          </p>
                          <p>
                            <strong>7.4. </strong> A AntecipaPay NÃO PODERÁ SER
                            RESPONSABILIZADA POR ATOS DE TERCEIROS, INCLUINDO
                            ATRASOS CAUSADOS POR FATORES TAIS COMO QUEDA DA REDE
                            DE INTERNET E CONGESTIONAMENTO DA REDE DE INTERNET.
                          </p>
                          <p>
                            <strong>7.5. </strong> A AntecipaPay, PER SE, NÃO É
                            TESTEMUNHA, GARANTIDORA, NEM PARTE DE QUALQUER
                            TERMO, ACORDO, NEGÓCIO, OU QUALQUER OUTRO TIPO DE
                            RELAÇÃO FORMALIZADA EM UM DOCUMENTO, E NÃO TERÁ
                            QUALQUER RESPONSABILIDADE DE QUALQUER NATUREZA COM
                            RELAÇÃO À VALIDADE, AO DESEMPENHO, AO RESULTADO OU À
                            EXEQUIBILIDADE DESSE DOCUMENTO.
                          </p>
                          <p>
                            <strong>7.6. </strong> EM NENHUMA HIPÓTESE A
                            AntecipaPay PODERÁ SER RESPONSABILIZADA PELA OMISSÃO
                            OU INCAPACIDADE DE UMA PARTE EM ASSINAR
                            ELETRONICAMENTE QUALQUER DOCUMENTO E/OU POR QUALQUER
                            PERDA OU DANO CORRESPONDENTE. SE ALGUMA LEI, REGRA,
                            TERMO OU OUTRA OBRIGAÇÃO EXIGIR O CUMPRIMENTO DE
                            DETERMINADAS CONDIÇÕES OU A PULGAÇÃO DE DETERMINADAS
                            INFORMAÇÕES SOBRE UM DOCUMENTO ELETRÔNICO, MENSAGEM
                            ELETRÔNICA, OU ASSINATURA ELETRÔNICA (COLETIVAMENTE,
                            “ITENS”), QUE NÃO SEJAM CONTEMPLADOS OU EFETUADOS
                            PELA PLATAFORMA, O USUÁRIO CONCORDA EM NÃO UTILIZAR
                            A PLATAFORMA PARA A ASSINATURA DE TAIS DOCUMENTOS. A
                            PLATAFORMA NÃO TEM A PRETENSÃO DE CUMPRIR COM TODAS
                            AS CONDIÇÕES QUE PODEM SER APLICÁVEIS A TODOS OS
                            TIPOS DE DOCUMENTOS. O USUÁRIO É RESPONSÁVEL POR
                            DETERMINAR OS DOCUMENTOS PARA OS QUAIS A ASSINATURA
                            ELETRÔNICA OFERECIDA PELA PLATAFORMA É ADEQUADA.
                          </p>
                          <p>
                            <strong>7.7. </strong> A AntecipaPay NÃO TERÁ
                            QUALQUER RESPONSABILIDADE DE QUALQUER NATUREZA COM
                            RELAÇÃO A QUALQUER CONTROVÉRSIA ENTRE QUAISQUER
                            PARTES DE UM DOCUMENTO QUE FOI ASSINADO DENTRO DA
                            PLATAFORMA. SEM RESTRINGIR O CARÁTER GERAL DO ACIMA
                            PREVISTO, A AntecipaPay NÃO TERÁ QUALQUER OBRIGAÇÃO
                            DE PRESTAR APOIO AO USUÁRIO COM RELAÇÃO AO
                            CUMPRIMENTO POR QUALQUER PARTE DE QUALQUER DOCUMENTO
                            ASSINADO DENTRO DA PLATAFORMA. ALÉM DISSO, A
                            AntecipaPay NÃO TERÁ QUALQUER OBRIGAÇÃO DE FACILITAR
                            OU INTERMEDIAR QUALQUER CONTROVÉRSIA ENTRE USUÁRIOS.
                          </p>
                          <p>
                            <strong>7.8. </strong> A AntecipaPay NÃO SERÁ
                            RESPONSÁVEL POR QUALQUER PERDA QUE O USUÁRIO POSSA
                            SOFRER EM DECORRÊNCIA DE OUTRA PESSOA USAR SUA
                            CONTA, COM OU SEM SEU CONHECIMENTO. O USUÁRIO PODERÁ
                            SER RESPONSABILIZADO POR PERDAS SOFRIDAS PELA
                            AntecipaPay, OU QUALQUER USUÁRIO OU ORGANIZAÇÃO,
                            DEVIDO AO USO DE SUA CONTA POR OUTRA PESSOA.
                          </p>
                          <p>
                            <strong>7.9. </strong> O USUÁRIO RECONHECE E
                            CONCORDA QUE EM NENHUMA HIPÓTESE A AntecipaPay SERÁ
                            RESPONSÁVEL POR RECLAMAÇÕES, PERDAS OU DANOS
                            ORIUNDOS DE OU RELATIVOS:
                          </p>
                          <p>
                            <strong>(I) </strong> AO SEU USO DA PLATAFORMA OU
                            SUA INCAPACIDADE DE USAR A PLATAFORMA;
                          </p>
                          <p>
                            <strong>(II) </strong> AO ACESSO NÃO AUTORIZADO A
                            PLATAFORMA OU USO NÃO AUTORIZADO DA PLATAFORMA;
                          </p>
                          <p>
                            <strong>(III) </strong> AO USO DE EQUIPAMENTO,
                            NAVEGADOR E SISTEMAS DE COMUNICAÇÃO PARA ACESSAR OU
                            USAR A PLATAFORMA;
                          </p>
                          <p>
                            <strong>(IV) </strong> A ERROS, VíRUS, OU PROBLEMAS
                            SIMILARES QUE POSSAM SER TRANSMITIDOS À PLATAFORMA
                            OU ATRAVÉS DA PLATAFORMA POR USUÁRIOS OU TERCEIROS;
                            E
                          </p>
                          <p>
                            <strong>(V) </strong> A OMISSÃO DE SUA PARTE EM
                            CUMPRIR O DISPOSTO NESTE TERMOS DE USO.
                          </p>
                          <p>
                            <strong>7.10. </strong> O USUÁRIO RECONHECE E
                            CONCORDA QUE EM NENHUMA HIPÓTESE A AntecipaPay SERÁ
                            RESPONSÁVEL PERANTE O USUÁRIO OU QUAISQUER TERCEIROS
                            POR QUAISQUER DANOS IMPREVISTOS, INCIDENTES,
                            INDIRETOS, ESPECIAIS, PUNITIVOS, MORAIS OU OUTRA
                            PERDA OU DANO DE QUALQUER NATUREZA, NEM POR PERDA DE
                            OPORTUNIDADES DE NEGÓCIO, LUCROS CESSANTES,
                            INTERRUPÇÃO DE NEGÓCIO, FALHA DE COMPUTADOR, PERDA
                            DE INFORMAÇÕES COMERCIAIS, OU OUTRAS PERDAS OU DANOS
                            SEMELHANTES, À EXCEÇÃO DE DANOS NÃO EXCLUÍDOS ACIMA,
                            DECORRENTES EXCLUSIVAMENTE DO NÃO CUMPRIMENTO DAS
                            DISPOSIÇÕES DESTE TERMO PELA AntecipaPay. SEM
                            PREJUÍZO DAS RESTRIÇÕES ACIMA PREVISTAS, A
                            RESPONSABILIDADE TOTAL DA AntecipaPay POR QUALQUER
                            RECLAMAÇÃO, PERDA OU DANO – ORIUNDO DE OU RELATIVO A
                            ESTE TERMO OU À PLATAFORMA, INDEPENDENTEMENTE DO
                            FORO E INDEPENDENTEMENTE DA RECLAMAÇÃO OU AÇÃO SER
                            BASEADA EM ILÍCITO CIVIL OU OUTRA CONDIÇÃO – NÃO
                            PODERÁ EXCEDER, EM NENHUMA HIPÓTESE, O VALOR TOTAL
                            PAGO PELA CONTA DURANTE OS 6 (SEIS) MESES ANTERIORES
                            À DATA DO FATO OU ATO QUE DEU ORIGEM À RECLAMAÇÃO,
                            PERDA OU DANO. AS EXCLUSÕES E LIMITAÇÕES DE DANO
                            INDICADAS ACIMA APLICAR-SE-ÃO AINDA QUE QUALQUER
                            REPARAÇÃO NÃO ALCANCE O SEU PROPÓSITO ESSENCIAL.
                          </p>
                          <p>
                            <strong>7.11. </strong> O USUÁRIO OBRIGA-SE A
                            ISENTAR E INDENIZAR A AntecipaPay DE QUALQUER
                            RECLAMAÇÃO OU EXIGÊNCIA, PERDAS, DANOS E/OU DESPESAS
                            (INCLUINDO DESPESAS DE INVESTIGAÇÃO, DEFESA,
                            HONORÁRIOS ADVOCATÍCIOS RAZOÁVEIS, BEM COMO CUSTAS
                            DE PROCESSO E JUDICIAIS) DECORRENTES DE OU RELATIVOS
                            A QUALQUER VIOLAÇÃO DO USUÁRIO A ESTE TERMOS DE USO,
                            LEI, REGULAMENTO, OU A DIREITO DE TERCEIROS.
                          </p>
                          <p>
                            <strong>8. RESCISÃO </strong>
                          </p>
                          <p>
                            <strong>8.1. </strong> O Cliente poderá cancelar o
                            uso da Plataforma a qualquer momento, observadas
                            condições comerciais aplicáveis.
                          </p>
                          <p>
                            <strong>8.2. </strong> A AntecipaPay poderá
                            suspender uma Conta imediatamente para investigar um
                            possível risco de segurança, ou uma possível
                            violação a este Termo. A suspensão será comunicada
                            na primeira oportunidade, caso não haja aparente
                            risco de dano à AntecipaPay, Cliente ou terceiros.
                          </p>
                          <p>
                            <strong>8.3. </strong> A AntecipaPay poderá
                            suspender uma Conta por motivo de inadimplência,
                            mediante o envio de notificação por escrito com 15
                            (quinze) dias de antecedência.
                          </p>
                          <p>
                            <strong>8.4. </strong> A AntecipaPay poderá fechar
                            uma Conta, com ou sem motivo, a qualquer momento,
                            mediante o envio de notificação por escrito com 60
                            (sessenta) dias de antecedência.
                          </p>
                          <p>
                            <strong>9. CONTROVÉRSIAS </strong>
                          </p>
                          <p>
                            <strong> 9.1. </strong> Ressalvado o disposto em lei
                            ou o acordado pelas partes, todos os processos,
                            decisões e/ou acordos relativos a esta cláusula,
                            independentemente do método de solução da
                            controvérsia, serão mantidos em sigilo.
                          </p>
                          <p>
                            <strong>9.2. </strong> Se uma questão, controvérsia,
                            desavença ou reclamação (“Reclamação”) relativa a
                            este Termo surgir entre o Usuário e a AntecipaPay, a
                            primeira providência é o Usuário entrar em contato
                            com o suporte ao cliente conforme indicado na
                            Plataforma.
                          </p>
                          <p>
                            <strong>9.3. </strong> Se a Reclamação não tiver
                            sido resolvida satisfatoriamente pelo suporte ao
                            cliente, então o Usuário deverá enviar nova
                            notificação para contato@antecipapay.com.br,
                            identificando e incluindo o motivo detalhado da
                            reclamação. A AntecipaPay responderá por escrito
                            dentro de 5 (cinco) dias úteis a contar da data de
                            recebimento do documento de Reclamação.
                          </p>
                          <p>
                            <strong>10. DISPOSIÇÕES GERAIS </strong>
                          </p>
                          <p>
                            <strong>10.1. </strong> Interpretação: a não ser que
                            haja clara intenção em contrário, as palavras
                            denotando o singular também incluem o plural e
                            vice-versa quando exigido pelo contexto. A palavra
                            “incluindo”, e seus derivativos, será considerada
                            como sendo seguida pela expressão “mas não se
                            limitando a”. O que constitui "alteração material"
                            será determinado pela AntecipaPay, em boa fé,
                            utilizando o senso comum, e de forma razoável.
                          </p>
                          <p>
                            <strong>10.2. </strong> Ausência de orientação
                            jurídica: nenhuma parte deste Termo pretende ou
                            poderá ser considerada como sendo orientação
                            jurídica. Nem a AntecipaPay nem qualquer de seus
                            fornecedores de conteúdo será responsável por
                            quaisquer erros ou omissões de conteúdo, ou por
                            quaisquer atos praticados em decorrência de um
                            conteúdo. 10.3. Notificações: nos casos em que a
                            AntecipaPay for obrigada sob este Termo a notificar
                            o Usuário, o Usuário reconhece e concorda que a
                            notificação poderá ser feita mediante a colocação de
                            um banner na Plataforma, o envio de mensagem por
                            e-mail ou por outros meios previamente informados,
                            incluindo redes sociais, celular ou correio.
                          </p>
                          <p>
                            <strong>10.4. </strong> Divisibilidade: se qualquer
                            disposição deste Termo for declarada ou considerada
                            por qualquer tribunal como sendo inexequível ou
                            inválida:
                          </p>
                          <p>
                            <strong>(I) </strong> a validade das demais partes,
                            termos ou disposições não será afetada por essa
                            determinação;
                          </p>
                          <p>
                            <strong>(II) </strong> a parte, o termo ou a
                            disposição inexequível ou inválida não será
                            considerada parte deste Termo; e
                          </p>
                          <p>
                            <strong>(III) </strong> esse tribunal poderá
                            substituir a mesma por uma disposição que seja
                            legal, exequível e seja tão consistente quanto
                            possível com as intenções da disposição original,
                            desde que a disposição substituta preserve a
                            alocação de risco original deste Termo.
                          </p>
                          <p>
                            <strong>10.5. </strong> Renúncia: a omissão da
                            AntecipaPay em agir com relação à violação deste
                            Termo pelo Usuário ou outros não representa renúncia
                            ao direito da AntecipaPay de agir com relação a essa
                            violação ou a violação posterior, semelhante ou não.
                          </p>
                          <p>
                            <strong>11. LEI APLICÁVEL E FORO </strong>
                          </p>
                          <p>
                            <strong>11.1. </strong> O presente Termo é regido
                            pelas leis vigentes na República Federativa do
                            Brasil.
                          </p>
                          <p>
                            <strong>11.2. </strong> Para a solução de qualquer
                            controvérsia ou disputa oriunda deste contrato, as
                            partes elegem o foro da Comarca de São Paulo, Estado
                            de São Paulo, como competente, com exclusão de
                            qualquer outro, por mais privilegiado que seja.
                          </p>
                          <div className="m-b-10">
                            {!session.isUseTerm() && (
                              <div>
                                <div className="col-md-2">
                                  <div className="m-t-30">
                                    <input
                                      id="chkIsAcceptUseTerm"
                                      name="useTerm"
                                      type="checkbox"
                                      checked={this.state.useTerm}
                                      onChange={this.handleChange}
                                    />
                                    <label htmlFor="chkIsAcceptUseTerm">
                                      {translate("user.acceptUseTerm")}
                                    </label>
                                  </div>
                                </div>
                                <div className="align-right">
                                  <button
                                    type="button"
                                    className="btn btn-link waves-effect"
                                    disabled={!this.state.useTerm}
                                    onClick={() => {
                                      this.handleSubmit();
                                    }}
                                  >
                                    Aceitar
                                  </button>
                                </div>
                              </div>
                            )}
                            {session.isUseTerm() && (
                              <div className="row m-t-20">
                                <div className="col-xs-12 align-right">
                                  <button
                                    type="button"
                                    className="btn btn-default waves-effect"
                                    onClick={() => {
                                      this.handleReturn();
                                    }}
                                  >
                                    <i className="material-icons">&#xe5c4;</i>
                                    <span>
                                      {translate("forms.buttonReturn")}
                                    </span>
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
