const $ = window.jQuery;
const jQuery = $;

if (typeof jQuery === "undefined") {
  throw new Error("jQuery plugins need to be before this file");
}

$.AdminBSB = {};
$.AdminBSB.options = {
  leftSideBar: {
    scrollColor: "rgba(0,0,0,0.5)",
    scrollWidth: "4px",
    scrollAlwaysVisible: false,
    scrollBorderRadius: "0",
    scrollRailBorderRadius: "0",
    scrollActiveItemWhenPageLoad: true,
    breakpointWidth: 1170,
  },
  dropdownMenu: {
    effectIn: "fadeIn",
    effectOut: "fadeOut",
  },
};

/* Left Sidebar - Function =================================================================================================
 *  You can manage the left sidebar menu options
 *
 */
$.AdminBSB.leftSideBar = {
  activate: function () {
    var _this = this;
    var $body = $("body");
    var $overlay = $(".overlay");

    //Close sidebar
    $(window).click(function (e) {
      var $target = $(e.target);
      if (e.target.nodeName.toLowerCase() === "i") {
        $target = $(e.target).parent();
      }

      if (
        !$target.hasClass("bars") &&
        _this.isOpen() &&
        $target.parents("#leftsidebar").length === 0
      ) {
        if (!$target.hasClass("js-right-sidebar")) $overlay.fadeOut();
        $body.removeClass("overlay-open");
      }
    });

    $.each($(".menu-toggle.toggled"), function (i, val) {
      $(val).next().slideToggle(0);
    });

    //When page load
    $.each($(".menu .list li.active"), function (i, val) {
      var $activeAnchors = $(val).find("a:eq(0)");

      $activeAnchors.addClass("toggled");
      $activeAnchors.next().show();
    });

    //Collapse or Expand Menu
    $(".menu-toggle").on("click", function (e) {
      var $this = $(this);
      var $content = $this.next();

      if ($($this.parents("ul")[0]).hasClass("list")) {
        var $not = $(e.target).hasClass("menu-toggle")
          ? e.target
          : $(e.target).parents(".menu-toggle");

        $.each($(".menu-toggle.toggled").not($not).next(), function (i, val) {
          if ($(val).is(":visible")) {
            $(val).prev().toggleClass("toggled");
            $(val).slideUp();
          }
        });
      }

      $this.toggleClass("toggled");
      $content.slideToggle(320);
    });

    //Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
    $(window).resize(function () {
      _this.setMenuHeight();
      _this.checkStatuForResize(false);
    });

    //Set Waves
    //Waves.attach('.menu .list a', ['waves-block']);
    //Waves.init();
  },
  setMenuHeight: function (isFirstTime) {
    if (typeof $.fn.slimScroll !== "undefined") {
      var configs = $.AdminBSB.options.leftSideBar;
      var height =
        $(window).height() -
        ($(".legal").outerHeight() +
          $(".user-info").outerHeight() +
          $(".navbar").innerHeight());
      var $el = $(".list");

      $el.slimscroll({
        height: height + "px",
        color: configs.scrollColor,
        size: configs.scrollWidth,
        alwaysVisible: configs.scrollAlwaysVisible,
        borderRadius: configs.scrollBorderRadius,
        railBorderRadius: configs.scrollRailBorderRadius,
      });

      //Scroll active menu item when page load, if option set = true
      if ($.AdminBSB.options.leftSideBar.scrollActiveItemWhenPageLoad) {
        var activeItemOffsetTop = $(".menu .list li.active")[0].offsetTop;
        if (activeItemOffsetTop > 150)
          $el.slimscroll({ scrollTo: activeItemOffsetTop + "px" });
      }
    }
  },
  checkStatuForResize: function (firstTime) {
    var $body = $("body");
    var $openCloseBar = $(".navbar .navbar-header .bars");
    var width = $body.width();

    if (firstTime) {
      $body
        .find(".content, .sidebar")
        .addClass("no-animate")
        .delay(1000)
        .queue(function () {
          $(this).removeClass("no-animate").dequeue();
        });
    }

    if (width < $.AdminBSB.options.leftSideBar.breakpointWidth) {
      $body.addClass("ls-closed");
      $openCloseBar.fadeIn();
    } else {
      $body.removeClass("ls-closed");
      $openCloseBar.fadeOut();
    }
  },
  isOpen: function () {
    return $("body").hasClass("overlay-open");
  },
};
//==========================================================================================================================

/* Right Sidebar - Function ================================================================================================
 *  You can manage the right sidebar menu options
 *
 */
$.AdminBSB.rightSideBar = {
  activate: function () {
    var _this = this;
    var $sidebar = $("#rightsidebar");
    var $overlay = $(".overlay");

    //Close sidebar
    $(window).click(function (e) {
      var $target = $(e.target);
      if (e.target.nodeName.toLowerCase() === "i") {
        $target = $(e.target).parent();
      }

      if (
        !$target.hasClass("js-right-sidebar") &&
        _this.isOpen() &&
        $target.parents("#rightsidebar").length === 0
      ) {
        if (!$target.hasClass("bars")) $overlay.fadeOut();
        $sidebar.removeClass("open");
      }
    });

    $(".js-right-sidebar").on("click", function () {
      $sidebar.toggleClass("open");
      if (_this.isOpen()) {
        $overlay.fadeIn();
      } else {
        $overlay.fadeOut();
      }
    });
  },
  isOpen: function () {
    return $(".right-sidebar").hasClass("open");
  },
};
//==========================================================================================================================

/* Searchbar - Function ================================================================================================
 *  You can manage the search bar
 *
 */
var $searchBar = $(".search-bar");
$.AdminBSB.search = {
  activate: function () {
    var _this = this;

    //Search button click event
    $(".js-search").on("click", function () {
      _this.showSearchBar();
    });

    //Close search click event
    $searchBar.find(".close-search").on("click", function () {
      _this.hideSearchBar();
    });

    //ESC key on pressed
    $searchBar.find('input[type="text"]').on("keyup", function (e) {
      if (e.keyCode === 27) {
        _this.hideSearchBar();
      }
    });
  },
  showSearchBar: function () {
    $searchBar.addClass("open");
    $searchBar.find('input[type="text"]').focus();
  },
  hideSearchBar: function () {
    $searchBar.removeClass("open");
    $searchBar.find('input[type="text"]').val("");
  },
};
//==========================================================================================================================

/* Navbar - Function =======================================================================================================
 *  You can manage the navbar
 *
 */
$.AdminBSB.navbar = {
  activate: function () {
    var $body = $("body");
    var $overlay = $(".overlay");

    //Open left sidebar panel
    $(".bars").on("click", function () {
      $body.toggleClass("overlay-open");
      if ($body.hasClass("overlay-open")) {
        $overlay.fadeIn();
      } else {
        $overlay.fadeOut();
      }
    });

    //Close collapse bar on click event
    $('.nav [data-close="true"]').on("click", function () {
      var isVisible = $(".navbar-toggle").is(":visible");
      var $navbarCollapse = $(".navbar-collapse");

      if (isVisible) {
        $navbarCollapse.slideUp(function () {
          $navbarCollapse.removeClass("in").removeAttr("style");
        });
      }
    });
  },
};
//==========================================================================================================================

/* Input - Function ========================================================================================================
 *  You can manage the inputs(also textareas) with name of class 'form-control'
 *
 */
$.AdminBSB.input = {
  activate: function () {
    //On focus event
    $(".form-control").focus(function () {
      $(this).parent().addClass("focused");
    });

    //On focusout event
    $(".form-control").focusout(function () {
      var $this = $(this);
      if ($this.parents(".form-group").hasClass("form-float")) {
        if ($this.val() === "") {
          $this.parents(".form-line").removeClass("focused");
        }
      } else {
        $this.parents(".form-line").removeClass("focused");
      }
    });

    //On label click
    $("body").on("click", ".form-float .form-line .form-label", function () {
      $(this).parent().find("input").focus();
    });

    //Not blank form
    $(".form-control").each(function () {
      if ($(this).val() !== "") {
        $(this).parents(".form-line").addClass("focused");
      }
    });
  },
};
//==========================================================================================================================

/* Form - Select - Function ================================================================================================
 *  You can manage the 'select' of form elements
 *
 */
$.AdminBSB.select = {
  activate: function () {
    if ($.fn.selectpicker) {
      $("select:not(.ms)").selectpicker();
    }
  },
};
//==========================================================================================================================

/* DropdownMenu - Function =================================================================================================
 *  You can manage the dropdown menu
 *
 */

$.AdminBSB.dropdownMenu = {
  activate: function () {
    var _this = this;

    //$('.dropdown, .dropup, .btn-group').on({
    //    "show.bs.dropdown": function () {
    //        var dropdown = _this.dropdownEffect(this);
    //        _this.dropdownEffectStart(dropdown, dropdown.effectIn);
    //    },
    //    "shown.bs.dropdown": function () {
    //        var dropdown = _this.dropdownEffect(this);
    //        if (dropdown.effectIn && dropdown.effectOut) {
    //            _this.dropdownEffectEnd(dropdown, function () { });
    //        }
    //    },
    //    "hide.bs.dropdown": function (e) {
    //        var dropdown = _this.dropdownEffect(this);
    //        if (dropdown.effectOut) {
    //            e.preventDefault();
    //            _this.dropdownEffectStart(dropdown, dropdown.effectOut);
    //            _this.dropdownEffectEnd(dropdown, function () {
    //                dropdown.dropdown.removeClass('open');
    //            });
    //        }
    //    }
    //});

    //Set Waves
    //Waves.attach('.dropdown-menu li a', ['waves-block']);
    //Waves.init();
  },
  dropdownEffect: function (target) {
    var effectIn = $.AdminBSB.options.dropdownMenu.effectIn,
      effectOut = $.AdminBSB.options.dropdownMenu.effectOut;
    var dropdown = $(target),
      dropdownMenu = $(".dropdown-menu", target);

    if (dropdown.length > 0) {
      var udEffectIn = dropdown.data("effect-in");
      var udEffectOut = dropdown.data("effect-out");
      if (udEffectIn !== undefined) {
        effectIn = udEffectIn;
      }
      if (udEffectOut !== undefined) {
        effectOut = udEffectOut;
      }
    }

    return {
      target: target,
      dropdown: dropdown,
      dropdownMenu: dropdownMenu,
      effectIn: effectIn,
      effectOut: effectOut,
    };
  },
  dropdownEffectStart: function (data, effectToStart) {
    if (effectToStart) {
      data.dropdown.addClass("dropdown-animating");
      data.dropdownMenu.addClass("animated dropdown-animated");
      data.dropdownMenu.addClass(effectToStart);
    }
  },
  dropdownEffectEnd: function (data, callback) {
    var animationEnd =
      "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend";
    data.dropdown.one(animationEnd, function () {
      data.dropdown.removeClass("dropdown-animating");
      data.dropdownMenu.removeClass("animated dropdown-animated");
      data.dropdownMenu.removeClass(data.effectIn);
      data.dropdownMenu.removeClass(data.effectOut);

      if (typeof callback === "function") {
        callback();
      }
    });
  },
};
//==========================================================================================================================

/* Browser - Function ======================================================================================================
 *  You can manage browser
 *
 */
var edge = "Microsoft Edge";
var ie10 = "Internet Explorer 10";
var ie11 = "Internet Explorer 11";
var opera = "Opera";
var firefox = "Mozilla Firefox";
var chrome = "Google Chrome";
var safari = "Safari";

$.AdminBSB.browser = {
  activate: function () {
    var _this = this;
    var className = _this.getClassName();

    if (className !== "") $("html").addClass(_this.getClassName());
  },
  getBrowser: function () {
    var userAgent = navigator.userAgent.toLowerCase();

    if (/edge/i.test(userAgent)) {
      return edge;
    } else if (/rv:11/i.test(userAgent)) {
      return ie11;
    } else if (/msie 10/i.test(userAgent)) {
      return ie10;
    } else if (/opr/i.test(userAgent)) {
      return opera;
    } else if (/chrome/i.test(userAgent)) {
      return chrome;
    } else if (/firefox/i.test(userAgent)) {
      return firefox;
    } else if (navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)) {
      return safari;
    }

    return undefined;
  },
  getClassName: function () {
    var browser = this.getBrowser();

    if (browser === edge) {
      return "edge";
    } else if (browser === ie11) {
      return "ie11";
    } else if (browser === ie10) {
      return "ie10";
    } else if (browser === opera) {
      return "opera";
    } else if (browser === chrome) {
      return "chrome";
    } else if (browser === firefox) {
      return "firefox";
    } else if (browser === safari) {
      return "safari";
    } else {
      return "";
    }
  },
};

//https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, "find", {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this === null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== "function") {
        throw new TypeError("predicate must be a function");
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
    configurable: true,
    writable: true,
  });
}

// https://tc39.github.io/ecma262/#sec-array.prototype.findindex
if (!Array.prototype.findIndex) {
  Object.defineProperty(Array.prototype, "findIndex", {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this === null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== "function") {
        throw new TypeError("predicate must be a function");
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return k.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return k;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return -1.
      return -1;
    },
    configurable: true,
    writable: true,
  });
}

// https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
    padString = String(typeof padString !== "undefined" ? padString : " ");
    if (this.length >= targetLength) {
      return String(this);
    } else {
      targetLength = targetLength - this.length;
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + String(this);
    }
  };
}

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, "includes", {
    value: function (valueToFind, fromIndex) {
      if (this === null) {
        throw new TypeError('"this" is null or not defined');
      }

      // 1. Let O be ? ToObject(this value).
      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      var n = fromIndex | 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return (
          x === y ||
          (typeof x === "number" &&
            typeof y === "number" &&
            isNaN(x) &&
            isNaN(y))
        );
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(valueToFind, elementK) is true, return true.
        if (sameValueZero(o[k], valueToFind)) {
          return true;
        }
        // c. Increase k by 1.
        k++;
      }

      // 8. Return false
      return false;
    },
  });
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes#Polyfill
if (!String.prototype.includes) {
  Object.defineProperty(String.prototype, "includes", {
    value: function (search, start) {
      if (typeof start !== "number") {
        start = 0;
      }

      if (start + search.length > this.length) {
        return false;
      } else {
        return this.indexOf(search, start) !== -1;
      }
    },
  });
}

//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/repeat#Polyfill
if (!String.prototype.repeat) {
  String.prototype.repeat = function (count) {
    "use strict";
    if (this === null) {
      throw new TypeError("can't convert " + this + " to object");
    }
    var str = "" + this;
    // To convert string to integer.
    count = +count;
    if (count != count) {
      count = 0;
    }
    if (count < 0) {
      throw new RangeError("repeat count must be non-negative");
    }
    if (count === Infinity) {
      throw new RangeError("repeat count must be less than infinity");
    }
    count = Math.floor(count);
    if (str.length === 0 || count === 0) {
      return "";
    }
    // Ensuring count is a 31-bit integer allows us to heavily optimize the
    // main part. But anyway, most current (August 2014) browsers can't handle
    // strings 1 << 28 chars or longer, so:
    if (str.length * count >= 1 << 28) {
      throw new RangeError(
        "repeat count must not overflow maximum string size",
      );
    }
    var maxCount = str.length * count;
    count = Math.floor(Math.log(count) / Math.log(2));
    while (count) {
      str += str;
      count--;
    }
    str += str.substring(0, maxCount - str.length);
    return str;
  };
}
//==========================================================================================================================

$(function () {
  $.AdminBSB.browser.activate();
  $.AdminBSB.leftSideBar.activate();
  $.AdminBSB.rightSideBar.activate();
  $.AdminBSB.navbar.activate();
  $.AdminBSB.dropdownMenu.activate();
  $.AdminBSB.input.activate();
  $.AdminBSB.select.activate();
  $.AdminBSB.search.activate();

  setTimeout(function () {
    $(".page-loader-wrapper").fadeOut();
  }, 50);
});
