import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { translate } from "react-internationalization";
import "react-table/react-table.css";
import * as session from "../components/SessionValidator";
import UseTermComponent from "../components/UseTermComponent";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    var auth = localStorage.getItem("authp");

    if (!auth) {
      if (!localStorage.getItem("loginExpired"))
        window.location.href = "/login";
      else session.validateSession();
    }

    this.state = {
      modalOpen: true,
    };
  }

  componentDidUpdate() {
    if (this.props.message && translate("forms.accessDenied") !== "") {
      session.accessDenied();
    }
  }

  isLogged() {
    return localStorage.getItem("name") !== null;
  }

  handleReturn() {
    this.state.modalOpen = false;
    this.forceUpdate();
  }

  render() {
    return (
      <div>
        <ToastContainer hideProgressBar />
        {!session.isUseTerm() && (
          <UseTermComponent
            modalOpen={this.state.modalOpen}
            handleReturn={() => {
              this.handleReturn();
            }}
          />
        )}
      </div>
    );
  }
}

export default HomePage;
