import { translate } from "react-internationalization";

export function getStatus() {
  let list = [
    { text: translate("enums.invoiceStatus.approved"), index: 1 },
    { text: translate("enums.invoiceStatus.pending"), index: 2 },
    { text: translate("enums.invoiceStatus.processed"), index: 3 },
    { text: translate("enums.invoiceStatus.canceled"), index: 4 },
    { text: translate("enums.invoiceStatus.expired"), index: 5 },
    { text: translate("enums.invoiceStatus.paid"), index: 6 },
  ];

  list = list.sort(function (a, b) {
    return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
  });

  return list;
}
