import React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";

class AcceptTermPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    $("#leftsidebar").hide();
    $("#userData").hide();
    $(".content").removeClass("content");
    $("#mainNav").slideDown("fast");
  }

  componentWillUnmount() {}

  render() {
    return (
      <div className="body">
        <nav className="collapse navbar-collapse" id="mainNav">
          <div className="container-fluid">
            <div className="navbar-header">
              <img src="logo.png" width="110" alt="" />
            </div>
          </div>
        </nav>

        <div className="m-t-15 m-b-30">
          <p></p>
          <p>
            <strong>CONHEÇA NOSSA POLÍTICA DE PRIVACIDADE </strong>
          </p>
          <p></p>

          <div>
            <strong>1. QUEM SOMOS</strong>
            <p></p>
            <p>
              Nós, da ANTECIPAPAY - TECNOLOGIA E INTERMEDIACAO DA INFORMACAO
              LTDA, pessoa jurídica de direito privado inscrita no CNPJ sob
              número 52.301.844/0001-76, (“ANTECIPAPAY”, ou “Nós”) somos uma
              licenciadora de franquias com várias agências em todo o Brasil,
              especializadas em oferecer a você soluções financeiras
              representadas por produtos que disponibilizamos através delas.
            </p>
            <p>
              Sabemos o quanto a segurança e a privacidade de seus dados
              pessoais são importantes, e nos preocupamos muito com isso. Para
              garantir sua tranquilidade e demonstrar a transparência como
              lidamos com eles, vamos a seguir detalhar nossa Política de
              Privacidade, em conformidade com a Lei 13.709/2018 (“Lei Geral de
              Proteção de Dados Pessoais”), esclarecendo os procedimentos que
              orientam nossa atuação ao efetuar o tratamento de seus dados e
              seus direitos enquanto titular, bem como a forma como poderá
              exercê-los.
            </p>
            <p>
              Se você acompanha nossos canais de divulgação reserve um tempo
              para conhecer nossas práticas.
            </p>
          </div>

          <p></p>
          <p></p>

          <div>
            <p>
              <strong>2. POR QUAIS MOTIVOS VOCÊ NOS ACESSA? </strong>
            </p>

            <p>
              Quando nos acessa ou interage conosco em nossas mídias sociais,
              você:{" "}
            </p>

            <p>
              a) tem interesse em se tornar um indicador de negócios
              ANTECIPAPAY;
            </p>

            <p>
              b) tem interesse em adquirir sua própria franquia ANTECIPAPAY;
            </p>

            <p>
              c) através do nosso site, solicita algum esclarecimento sobre a
              ANTECIPAPAY Franqueadora, ou
            </p>

            <p>
              d) através do nosso site, busca os serviços de uma de nossas
              agências franqueadas.
            </p>

            <p>
              Como já referimos acima, somos uma licenciadora de franquias.
              Nossa atividade principal é o factoring convencional, além de
              outras soluções financeiras. Cada uma de nossas agências é uma
              pessoa jurídica independente, guardando com a Franqueadora o
              vínculo de uso da marca e know-how, bem como o nome fantasia
              ANTECIPAPAY.
            </p>

            <p>
              Você pode, através do site da Franqueadora ANTECIPAPAY, buscar
              contato com alguma dessas agências franqueadas para utilização dos
              seus serviços: nesse caso, faremos o encaminhamento. O atendimento
              de nossas franquias é direcionado para pessoas jurídicas, mas
              através do acesso alguns de seus dados pessoais poderão ser
              coletados e enviados para a agência mais próxima à sua cidade, no
              intuito de atendê-lo.
            </p>

            <p>
              Em qualquer hipótese, para atendê-lo vamos necessitar que nos
              forneça certas informações de caráter pessoal, sem as quais não
              poderemos satisfazer seu interesse.
            </p>
          </div>

          <p></p>
          <p></p>

          <div>
            <p>
              <strong>
                3. QUAIS SÃO OS DADOS PESSOAIS QUE VOCÊ NOS FORNECE E PARA QUAL
                FINALIDADE UTILIZAMOS?{" "}
              </strong>
            </p>

            <p>
              A seguir mostramos de forma esquematizada quais são esses dados, a
              finalidade e a base legal:
            </p>

            <table>
              <tbody>
                <tr>
                  <td>DADOS PESSOAIS</td>
                  <td>FINALIDADE</td>
                  <td>BASE LEGAL arts. 7º, V e 10, I e II</td>
                </tr>
                <tr>
                  <td>
                    Nome, telefone, e-mail, endereço completo, cidade onde
                    pretende atuar.
                  </td>
                  <td>Interesse em se tornar um indicador de negócios</td>
                  <td>Legítimo interesse do Controlador.</td>
                </tr>
                <tr>
                  <td>
                    Nome, telefone, e-mail, cidade onde tem interesse em atuar,
                    capital disponível
                  </td>
                  <td>Interesse em compra de franquia</td>
                  <td>Legítimo interesse do Controlador</td>
                </tr>
                <tr>
                  <td>Nome, telefone, email</td>
                  <td>
                    Solicitação de algum esclarecimento sobre a Franqueadora,
                    seus produtos e seus franqueados
                  </td>
                  <td>
                    Legitimo interesse/Prestação de serviço em benefício do
                    titular
                  </td>
                </tr>
                <tr>
                  <td>Nome, e- mail </td>
                  <td>Para envio de e mail marketing sobre nossa atividade</td>
                  <td>
                    Legitimo interesse/. Apoio e promoção de atividade do
                    Controlador
                  </td>
                </tr>
                <tr>
                  <td>Nome, e-mail, telefone</td>
                  <td>
                    Para acesso aos produtos da rede. Neste caso direcionamos o
                    contato para agência mais próxima{" "}
                  </td>
                  <td>
                    Legitimo interesse/prestação de serviço em benefício do
                    titular{" "}
                  </td>
                </tr>
              </tbody>
            </table>

            <p></p>
            <p>
              Após o atendimento de sua demanda esses dados pessoais ficarão
              armazenados em nossos registros pelo prazo de 02 (dois) anos para
              fins de envio de propaganda. Caso você não queira recebê-la entre
              em contato através do e-mail: contato@antecipapay.com.br
            </p>

            <p>
              <strong>4. COMO SÃO COLETADOS ESSES DADOS?</strong>
            </p>
            <p></p>
            <p>
              Os dados pessoais são fornecidos pelos próprios titulares, através
              de formulários em nosso site e nos nossos canais de divulgação
              presentes nas diversas mídias sociais. Com relação aos possíveis
              candidatos às Franquias ANTECIPAPAY, inicialmente são captados
              através de “leads”, os quais por sua vez são prospectados por
              empresas parceiras que respeitam os termos de nossa política de
              privacidade, e posteriormente complementados no âmbito da
              Franqueadora pelos próprios titulares, para seguimento em processo
              de candidatura de franqueado e/ou para formação de banco de dados
              e posterior envio de material de divulgação de produtos e notícias
              que avaliarmos atender a seus interesses, dentro do prazo referido
              no item acima, ou seja, 2 (dois) anos. Alguns dados poderão ser
              captados automaticamente através de cookies, desde que você
              concorde com seu uso. Nunca solicitamos dados pessoais sensíveis
              ou de menores de idade.
            </p>

            <p></p>

            <p>
              <strong>
                5. O QUE ACONTECE SE VOCÊ NÃO NOS FORNECER ESSES DADOS?
              </strong>
            </p>
            <p></p>
            <p>
              Você não é obrigado a fornecer seus dados pessoais: neste caso,
              porém, ficaremos impossibilitados de atendê-lo na medida dos seus
              interesses.
            </p>
            <p></p>

            <p>
              <strong>6. SEUS DADOS PESSOAIS SERÃO COMPARTILHADOS? </strong>
            </p>
            <p></p>
            <p>
              Seus dados serão exclusivamente tratados pela Franqueadora
              ANTECIPAPAY através dos setores competentes para atendê-lo ou,
              quando for o caso, compartilhados com a agência franqueada, para a
              qual serão direcionados de acordo com a natureza de sua
              solicitação. Em nenhuma hipótese os dados pessoais que você nos
              fornecer serão compartilhados, vendidos ou utilizados para outras
              finalidades.
            </p>
            <p></p>

            <p>
              <strong>7. COMO PROTEGEMOS SEUS DADOS? </strong>
            </p>
            <p></p>
            <p>
              Seus dados pessoais são armazenados em ambiente físico ou
              eletrônico protegido por sólidas medidas de segurança, que nos
              auxiliam na prevenção de fraudes e acessos não autorizados, bem
              como na integridade dos dados. Eles serão acessados apenas por
              profissionais devidamente autorizados e o tratamento obedecerá aos
              princípios da proporcionalidade, minimização, necessidade,
              transparência e relevância para as nossas finalidades, respeitados
              sempre os compromissos de confidencialidade e preservação da
              privacidade nos termos do que determina a legislação.
            </p>
            <p></p>

            <p>
              <strong>8. POR QUANTO TEMPO SEUS DADOS SERÃO TRATADOS? </strong>
            </p>
            <p></p>
            <p>
              Seus dados pessoais poderão ser mantidos em nossos cadastros pelo
              prazo de 02 (dois) anos, sempre garantidos por nossos sistemas de
              segurança. Após esse período eles poderão apenas ser mantidos pelo
              prazo necessário ao cumprimento de obrigações legais, sendo que
              nesse caso não estarão em uso, mas apenas armazenados para essa
              finalidade específica, após o que serão descartados de acordo com
              nossos procedimentos de segurança.
            </p>
            <p></p>

            <p>
              <strong>9. QUAIS SÃO OS SEUS DIREITOS SOBRE ESSES DADOS?</strong>
            </p>
            <p></p>
            <p>
              A Lei Geral de Proteção de Dados Pessoais – Lei nº 13.709 de 2018
              define em seu artigo 18 quais são seus direitos enquanto titular
              de dados pessoais, entre os quais destacamos DIREITO DE ACESSO: A
              qualquer momento você pode nos solicitar informações sobre quais
              dados seus estamos tratando; DIREITO DE CORREÇÃO: A qualquer
              momento você pode solicitar a correção desses dados, caso entenda
              que estão incompletos, incorretos ou desatualizados; DIREITO DE
              EXCLUSÃO: A qualquer momento você pode solicitar a exclusão de
              dados que entenda desnecessários, excessivos ou tratados de forma
              ilegal. Também pode solicitar a exclusão de seus dados em qualquer
              outra hipótese, sendo alertado sobre a impossibilidade de podermos
              atendê-lo devidamente, e DIREITO DE INFORMAÇÃO: A qualquer momento
              você pode solicitar informações sobre empresas e órgãos públicas
              com os quais compartilhamos seus dados.
            </p>
            <p></p>

            <p>
              <strong>
                10. COMO E QUANDO VOCÊ PODERÁ EXERCER ESSES DIREITOS?{" "}
              </strong>
            </p>
            <p></p>
            <p>
              A qualquer momento você poderá exercer esses direitos, bem como
              solicitar esclarecimentos sobre quaisquer dúvidas que tiver,
              através de correspondência dirigida ao e-mail:
              contato@antecipapay.com.br
            </p>
            <p></p>

            <p>
              <strong>
                11. SOBRE ALTERAÇÕES NESTA POLÍTICA DE PRIVACIDADE
              </strong>
            </p>
            <p></p>
            <p>
              A ANTECIPAPAY reserva-se o direito de, a qualquer momento, alterar
              esta Política de Privacidade atualizando seu conteúdo conforme as
              necessidades de aperfeiçoamento. Nesses casos faremos um
              comunicado em área de destaque no nosso site, por isso é
              importante que você acompanhe sempre nossos alertas. A data da
              versão desta Política de Privacidade aparecerá sempre em seu
              cabeçalho.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AcceptTermPage;
