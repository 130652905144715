import $ from "jquery";
import React from "react";
import { translate } from "react-internationalization";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import RCDatePicker from "../../components/datePicker";
import * as enums from "../../components/enums/document";
import RCSelect from "../../components/select";
import * as session from "../../components/SessionValidator";
import ModalSupplierSearch from "../../components/supplier/modal-supplier-search";
import QueryString from "query-string";
import { confirmAlert } from "react-confirm-alert";
import { CUSTOMER_SERVICE_URL, DOCUMENT_SERVICE_URL } from "../../settings";
import moment from "moment";
import { getRequest } from "../../service/RequestService";

class DocumentsPage extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.loadNextResults = this.loadNextResults.bind(this);

    this.setValidators();

    this.setBinds();

    this.state = {
      searchText: "",

      search: {
        search: "",
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().add(1, "M").format("YYYY-MM-DD"),
        customerId: 0,
        supplierId: 0,
        status: "",
      },

      loading: true,
      query: {},
      tablePage: 0,
      tablePageSize: 10,
      tablePageCount: 0,
      numberOfResults: 0,
      currentPage: 0,
      rowCount: 0,

      customers: [],
      suppliers: [],
      documentList: [],

      currentDocument: {
        rowIndex: -1,
        document: {},
      },

      supplierSearchOpen: false,
      supplierLoading: true,
    };
  }

  setBinds() {
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.handleChangeSupplierSearch =
      this.handleChangeSupplierSearch.bind(this);
    this.handleReturnSupplierSearch =
      this.handleReturnSupplierSearch.bind(this);
    this.handleResultSupplierSearch =
      this.handleResultSupplierSearch.bind(this);

    this.editDocument = this.editDocument.bind(this);
  }

  loadNextResults(page, pageSize) {
    if (pageSize === undefined) pageSize = this.state.tablePageSize;

    this.setState({ tablePage: page, tablePageSize: pageSize });
    let numberOfResults = this.state.numberOfResults;
    let numberOfRows = pageSize * (page + 1);
    let currentPage = this.state.currentPage;

    if (numberOfResults <= numberOfRows) {
      let nextPage = currentPage + 1;
      this.setState({ currentPage: nextPage });
      this.loadDocuments();
    }

    let tablePageCount = Math.ceil(this.state.rowCount / pageSize);
    this.setState({ tablePageCount: tablePageCount });
  }

  setValidators() {
    let _this = this;

    _this.validator = new SimpleReactValidator({
      startDateLaterThanEndDate: {
        rule: () => {
          return (
            !_this.state.search.startDate ||
            !_this.state.search.endDate ||
            moment(_this.state.search.startDate, "YYYY-MM-DD").isSameOrBefore(
              moment(_this.state.search.endDate, "YYYY-MM-DD"),
            )
          );
        },
      },
    });
  }

  async componentDidMount() {
    var _this = this;

    this.setState({ customerLoading: true });

    $("body")
      .off("change", ".js-change")
      .on("change", ".js-change", function (event) {
        _this.handleChange(event);
      });

    $(".js-integer").inputmask({
      greedy: false,
      mask: "9",
      repeat: "*",
      showMaskOnHover: false,
    });

    await getRequest(CUSTOMER_SERVICE_URL + "/api/Customer/ListActive").then(
      (res) => {
        if (res.data)
          res.data = res.data.sort(function (a, b) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          });

        this.setState({ customers: res.data });
        this.setState({ customerLoading: false });
        this.forceUpdate();
      },
    );

    this.loadDocuments();
  }

  async handleChange(event) {
    session.validateSession();

    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    this.setState(this.state);
  }

  handleChangeSupplierSearch(supplierId) {
    this.state.search.supplierId = supplierId;
    this.state.supplierSearchOpen = false;
    this.forceUpdate();
  }

  handleReturnSupplierSearch() {
    this.state.supplierSearchOpen = false;
    this.forceUpdate();
  }

  handleResultSupplierSearch(suppliers) {
    this.setState({ suppliers: suppliers, supplierLoading: false });
    this.forceUpdate();
  }

  editDocument(rowIndex) {
    this.state.currentDocument.document = JSON.parse(
      JSON.stringify(this.state.documentList[rowIndex]),
    );
    this.state.currentDocument.rowIndex = rowIndex;

    this.setState(this.state);
    this.forceUpdate();
  }

  deleteDocument(rowIndex) {
    confirmAlert({
      buttons: [
        {
          label: translate("forms.buttonYes"),
          onClick: () => {
            let document = JSON.parse(
              JSON.stringify(this.state.documentList[rowIndex]),
            );

            this.handleDeleteDocument(document.fileHash, rowIndex);
          },
        },
        {
          label: translate("forms.buttonNo"),
          onClick: () => {
            window.close();
          },
        },
      ],
      message: translate("documents.documentDeleteQuestion"),
      title: translate("documents.documentDeleteTitle"),
    });
  }

  handleDeleteDocument(filehash, rowIndex) {
    this.setState({ loading: true });

    getRequest(DOCUMENT_SERVICE_URL + "/api/Document/Delete/" + filehash).then(
      (res) => {
        this.setState({ loading: false });
        switch (res.code) {
          case 200:
            var items = this.state.documentList;
            items.splice(rowIndex, 1);
            this.state.documentList = items;

            this.forceUpdate();

            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("error");
            break;
        }
      },
    );
  }

  loadDocuments() {
    let resStatus = 0;
    this.setState({ loading: true });

    let supplierId = this.state.search.supplierId
      ? this.state.search.supplierId
      : 0;
    let customerId = this.state.search.customerId
      ? this.state.search.customerId
      : 0;
    let status =
      this.state.search.status === undefined ? "" : this.state.search.status;

    if (session.isCustomer()) {
      customerId = session.getCustomerId();
    }

    if (session.isSupplier()) {
      supplierId = session.getSupplierId();
    }

    let numberOfResults = this.state.numberOfResults;
    let documentList = this.state.documentList;

    this.state.query.page = this.state.currentPage + 1;
    this.state.query.pageSize = this.state.tablePageSize;
    this.state.query.customerId = customerId;
    this.state.query.supplierId = supplierId;
    this.state.query.startDate = this.state.search.startDate;
    this.state.query.endDate = this.state.search.endDate;
    this.state.query.status = status;

    getRequest(
      DOCUMENT_SERVICE_URL +
        "/api/Document/List/?" +
        QueryString.stringify(this.state.query),
    )
      .then((res) => {
        let messages = {};

        switch (res.code) {
          case 200:
            if (!res.data) res.data = [];

            if (res.data)
              res.data.result = res.data.result.sort(function (a, b) {
                return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
              });

            documentList = documentList.concat(res.data.result);

            numberOfResults =
              numberOfResults +
              (res.data.result == null ? 0 : res.data.result.length);

            this.setState({
              documentList: documentList,
              loading: false,
              numberOfResults: numberOfResults,
              currentPage: res.data.currentPage,
              tablePageCount: res.data.pageCount,
              rowCount: res.data.rowCount,
            });

            break;

          case 409:
            messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));

            break;
          case 400:
            messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;
          case 500:
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async handleSearch() {
    if (!this.validator.allValid()) {
      this.validator.showMessages();

      this.forceUpdate();

      return;
    }

    this.state.documentList = [];
    this.state.currentPage = 0;
    this.state.numberOfResults = 0;
    this.state.tablePage = 0;
    this.state.tablePageSize = 10;
    this.state.tablePageCount = 0;
    this.state.rowCount = 0;

    this.setState(this.state);
    this.forceUpdate();

    this.loadDocuments();
  }

  render() {
    var fetched =
      this.state != null &&
      this.state.customers != null &&
      this.state.suppliers != null;

    if (fetched) {
      var search = this.state.searchText.toLowerCase();

      var documentsItems = [];

      if (this.state.documentList != null) {
        var documentsItems = this.state.documentList.filter(function (item) {
          return item.fileName.toString().toLowerCase().indexOf(search) >= 0;
        });
      }

      return (
        <div className="row">
          <div className="col-xs-12">
            <div className="card">
              <div className="header">
                <h2>
                  {translate("documents.documentTitle")}
                  <small>
                    {translate("documents.documentTitleDescription")}
                  </small>
                </h2>

                <div className="header-dropdown">
                  <div
                    className="preloader pl-size-xs"
                    style={{ display: this.state.loading ? "block" : "none" }}
                  >
                    <div className="spinner-layer">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>

                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="body">
                <div className="m-t-10">
                  <div className="row">
                    <div
                      className={session.isSupplier() ? "col-md-6" : "col-md-3"}
                      style={{
                        display: session.isCustomer() ? "none" : "block",
                      }}
                    >
                      <div className="m-b-5">
                        <label htmlFor="cmbCustomer">
                          {" "}
                          {translate("documents.documentCustomer")}{" "}
                        </label>

                        <div
                          className="preloader pl-size-xs form-preloader"
                          style={{
                            display: this.state.customerLoading
                              ? "inline-block"
                              : "none",
                          }}
                        >
                          <div className="spinner-layer">
                            <div className="circle-clipper left">
                              <div className="circle"></div>
                            </div>
                          </div>
                        </div>

                        <RCSelect
                          id="cmbCustomer"
                          name="search.customerId"
                          hasEmptyOption={true}
                          isInt={true}
                          options={this.state.customers}
                          optionValue="id"
                          optionLabel="name"
                          placeholder={translate(
                            "documents.documentCustomerPlaceHolder",
                          )}
                          onChange={(event) => {
                            this.handleChange(event);
                          }}
                          value={this.state.search.customerId}
                        />
                      </div>
                    </div>

                    <div
                      className={session.isCustomer() ? "col-md-6" : "col-md-4"}
                      style={{
                        display: session.isSupplier() ? "none" : "block",
                      }}
                    >
                      <div className="m-b-5">
                        <label htmlFor="cmbSupplier">
                          {translate("documents.documentSupplier")}
                        </label>

                        <div
                          className="preloader pl-size-xs form-preloader"
                          style={{
                            display: this.state.supplierLoading
                              ? "inline-block"
                              : "none",
                          }}
                        >
                          <div className="spinner-layer">
                            <div className="circle-clipper left">
                              <div className="circle"></div>
                            </div>
                          </div>
                        </div>

                        <RCSelect
                          id="cmbSupplier"
                          name="search.supplierId"
                          hasEmptyOption={true}
                          isInt={true}
                          options={this.state.suppliers}
                          optionValue="id"
                          optionLabel="name"
                          placeholder={translate(
                            "documents.documentSupplierPlaceHolder",
                          )}
                          onChange={(event) => {
                            this.handleChange(event);
                          }}
                          value={this.state.search.supplierId}
                        />
                      </div>
                    </div>

                    <div
                      className="col-md-1 m-t-20 p-l-0"
                      style={{
                        display: session.isSupplier() ? "none" : "block",
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-default waves-effect"
                        onClick={() => {
                          this.state.supplierSearchOpen = true;
                          this.forceUpdate();
                        }}
                      >
                        <i className="material-icons">&#xe8b6;</i>
                      </button>

                      <ModalSupplierSearch
                        customerId={null}
                        SearchOpen={this.state.supplierSearchOpen}
                        handleChange={(event) => {
                          this.handleChangeSupplierSearch(event);
                        }}
                        handleResult={(event) => {
                          this.handleResultSupplierSearch(event);
                        }}
                        handleReturn={() => {
                          this.handleReturnSupplierSearch();
                        }}
                      />
                    </div>

                    <div className="col-md-2">
                      <div className="input-group">
                        <label htmlFor="dtStartDate">
                          {translate("documents.documentStartDate")}
                        </label>

                        <div className="form-line">
                          <RCDatePicker
                            id="dtStartDate"
                            name="search.startDate"
                            value={this.state.search.startDate}
                            onChange={this.handleChange}
                          />
                        </div>

                        {this.validator.message(
                          "search.startDate",
                          this.state.search.startDate,
                          "required|startDateLaterThanEndDate",
                          false,
                          {
                            default: translate(
                              "documents.documentStartDateRequired",
                            ),
                            startDateLaterThanEndDate: translate(
                              "documents.documentStartDateLaterThanEndDate",
                            ),
                          },
                        )}
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="input-group">
                        <label htmlFor="dtEndDate">
                          {translate("documents.documentEndDate")}
                        </label>

                        <div className="form-line">
                          <RCDatePicker
                            id="dtEndDate"
                            name="search.endDate"
                            value={this.state.search.endDate}
                            onChange={this.handleChange}
                          />
                        </div>

                        {this.validator.message(
                          "search.endDate",
                          this.state.search.endDate,
                          "required",
                          false,
                          {
                            default: translate(
                              "documents.documentEndDateRequired",
                            ),
                          },
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row m-t-5">
                    <div className="col-md-3 m-t-20">
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="material-icons">&#xe8b6;</i>
                        </span>

                        <div className="form-line">
                          <input
                            name="searchText"
                            className="form-control"
                            autoComplete="off"
                            disabled={this.state.loading}
                            placeholder={translate("forms.searchBar")}
                            type="text"
                            value={this.state.searchText}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="m-b-5">
                        <label htmlFor="cmbStatus">
                          {translate("documents.documentStatus")}
                        </label>

                        <div
                          className="preloader pl-size-xs form-preloader"
                          style={{
                            display: this.state.loadingSites
                              ? "inline-block"
                              : "none",
                          }}
                        >
                          <div className="spinner-layer">
                            <div className="circle-clipper left">
                              <div className="circle"></div>
                            </div>
                          </div>
                        </div>

                        <RCSelect
                          id="cmbStatus"
                          name="search.status"
                          hasEmptyOption={true}
                          isInt={true}
                          options={enums.getStatus()}
                          optionValue="index"
                          optionLabel="text"
                          placeholder={translate(
                            "documents.documentStatusPlaceHolder",
                          )}
                          onChange={(event) => {
                            this.handleChange(event);
                          }}
                          value={this.state.search.status}
                        />
                      </div>
                    </div>

                    <div className="col-md-3 m-t-20">
                      <button
                        className="btn btn-block btn-primary right"
                        disabled={this.state.loading}
                        type="button"
                        onClick={this.handleSearch}
                      >
                        <i className="material-icons">&#xe8b6;</i>
                        <span>{translate("forms.buttonSearch")}</span>
                      </button>
                    </div>
                    {(session.isAdmin() ||
                      session.isOperator() ||
                      session.isCustomer() ||
                      session.isBank()) &&
                      session.auth([{ type: "Document", value: "Save" }]) && (
                        <div className="col-md-3 m-t-20">
                          <Link to={"/document"}>
                            <button
                              type="button"
                              className="btn btn-block btn-primary right"
                            >
                              <i className="material-icons">&#xe145;</i>
                              <span>
                                {translate("documents.documentAddNew")}
                              </span>
                            </button>
                          </Link>
                        </div>
                      )}
                  </div>
                </div>

                <ReactTable
                  columns={[
                    {
                      columns: [
                        {
                          Header: translate("documents.documentFileName"),
                          accessor: "fileName",
                        },
                        {
                          Header: translate("documents.documentStatus"),
                          id: "status",
                          accessor: (data) => {
                            return (
                              <span
                                title={
                                  enums.getStatus().find((x) => {
                                    return x.index === parseInt(data.status);
                                  }).text
                                }
                              >
                                {" "}
                                {
                                  enums.getStatus().find((x) => {
                                    return x.index === parseInt(data.status);
                                  }).text
                                }{" "}
                              </span>
                            );
                          },
                        },
                        {
                          Header: translate("documents.documentDate"),
                          id: "createDate",
                          accessor: (data) => {
                            return moment(data.createDate, "YYYY-MM-DD").format(
                              "DD-MM-YYYY",
                            );
                          },
                          width: 140,
                        },
                        session.auth([
                          { type: "Transaction", value: "Get" },
                        ]) && {
                          Cell: (row) => (
                            <div className="align-center">
                              {!row.original.anticipationTransactionId &&
                                row.original.status === 0 && (
                                  <button
                                    className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10"
                                    type="button"
                                    disabled={
                                      this.state.currentDocument.rowIndex >= 0
                                    }
                                    onClick={() => {
                                      this.deleteDocument(row.index);
                                    }}
                                  >
                                    <i className="material-icons">&#xe872;</i>
                                  </button>
                                )}
                              {!row.original.anticipationTransactionId &&
                                row.original.status === 0 && (
                                  <Link
                                    to={"/document/" + row.original.fileHash}
                                  >
                                    <button
                                      type="button"
                                      className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10"
                                      title={translate("forms.buttonEdit")}
                                    >
                                      <i className="material-icons">&#xe3c9;</i>
                                    </button>
                                  </Link>
                                )}
                              {(row.original.status > 0 ||
                                row.original.anticipationTransactionId) && (
                                <Link
                                  to={"/documentView/" + row.original.fileHash}
                                >
                                  <button
                                    type="button"
                                    className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10"
                                    title={translate("forms.buttonEdit")}
                                  >
                                    <i className="material-icons">&#xe3c9;</i>
                                  </button>
                                </Link>
                              )}
                            </div>
                          ),
                          width: 150,
                        },
                      ],
                    },
                  ]}
                  data={documentsItems}
                  defaultPageSize={10}
                  className="-striped -highlight"
                  loading={this.state.loading}
                  previousText={translate("forms.previousText")}
                  nextText={translate("forms.nextText")}
                  noDataText={translate("forms.noDataText")}
                  pageText={translate("forms.pageText")}
                  ofText={translate("forms.ofText")}
                  rowsText={translate("forms.rowsText")}
                  pages={this.state.tablePageCount}
                  page={this.state.tablePage}
                  pageSize={this.state.tablePageSize}
                  onPageSizeChange={(pageSize, page) =>
                    this.loadNextResults(page, pageSize)
                  }
                  onPageChange={(page) => this.loadNextResults(page)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="preloader pl-size-lg align-center">
          <div className="spinner-layer">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>

            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default DocumentsPage;
