import React from "react";
import { submitReportDailyLog } from "../../pages/helper.js";
import ReactTable from "react-table";
import { translate } from "react-internationalization";
import * as session from "../SessionValidator";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import $ from "jquery";
import { CUSTOMER_SERVICE_URL } from "../../settings.js";
import moment from "moment";
import { getRequest, postRequest } from "../../service/RequestService.js";

export default class ContactCustomerComponent extends React.Component {
  constructor(props) {
    super(props);

    this.setValidators();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTypingEmail = this.handleTypingEmail.bind(this);
    this.saveContact = this.saveContact.bind(this);
    this.editContact = this.editContact.bind(this);
    this.deleteContact = this.deleteContact.bind(this);
    this.resetContact = this.resetContact.bind(this);

    this.state = {
      currentContact: {
        rowIndex: -1,
        contact: {
          id: 0,
          name: "",
          position: "",
          phoneNumber: "",
          emails: "",
          notes: "",
          customerId: 0,
          isActive: true,
          isMaster: true,
        },
      },

      contactList: [],
      loading: false,

      reportDailyLog: {
        startDate: moment().format(),
        objectAfter: "",
        objectBefore: "",
      },
    };
  }

  async componentDidUpdate() {
    let _this = this;

    $(".js-customer-contact-change").each(function () {
      _this.setValue(this);
    });
    $(".js-phone-number").inputmask({
      greedy: false,
      mask: "(99) 9999[9]-9999",
      showMaskOnHover: false,
    });
  }

  async componentDidMount() {
    let _this = this;

    $("body").on("change", ".js-customer-contact-change", function (event) {
      _this.handleChange(event);
    });

    $(".js-phone-number").inputmask({
      greedy: false,
      mask: "(99) 9999[9]-9999",
      showMaskOnHover: false,
    });

    if (this.props.customerId) {
      await getRequest(
        CUSTOMER_SERVICE_URL +
          "/api/Customer/ListCustomerContacts/" +
          this.props.customerId,
      ).then((res) => {
        res.data = res.data.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        });

        this.setState({ contactList: res.data });
        this.forceUpdate();
      });
    } else {
      let currentContact = {
        rowIndex: -1,
        contact: {
          id: 0,
          name: "",
          position: "",
          phoneNumber: "",
          emails: "",
          notes: "",
          isActive: true,
          isMaster: true,
        },
      };

      let contactList = [];

      this.setState({
        currentContact: currentContact,
        contactList: contactList,
      });
      this.forceUpdate();
    }
  }

  async handleChange(event) {
    session.validateSession();

    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    this.setState(this.state);
  }

  setValue(element) {
    let keys = element.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      property = property[key];
      if (Array.isArray(property)) property = property[element.dataset.index];
    });

    $(element).val(property);
  }

  handleTypingEmail(e) {
    e.target.value = e.target.value.toLowerCase();
  }

  resetContact() {
    this.state.currentContact = {
      rowIndex: -1,
      contact: {
        id: 0,
        name: "",
        position: "",
        phoneNumber: "",
        emails: "",
        notes: "",
        isActive: true,
        isMaster: true,
      },
    };

    if (this.contactValidator !== undefined) {
      this.contactValidator.hideMessages();
    }

    this.setState(this.state);
  }

  saveContact() {
    if (!this.contactValidator.allValid()) {
      this.contactValidator.showMessages();
      this.forceUpdate();

      return;
    }

    this.handleSubmit();
  }

  editContact(rowIndex) {
    let reportDailyLog = this.state.reportDailyLog;

    this.state.currentContact.contact = JSON.parse(
      JSON.stringify(this.state.contactList[rowIndex]),
    );
    this.state.currentContact.rowIndex = rowIndex;

    reportDailyLog.objectBefore = JSON.stringify(
      this.state.currentContact.contact,
    );

    this.setState(this.state);
    this.forceUpdate();
  }

  handleSubmit() {
    let reportDailyLog = this.state.reportDailyLog;

    this.setState({ loading: true });

    var method =
      this.state.currentContact.contact.id > 0 ? "UpdateContact" : "AddContact";

    this.state.currentContact.contact.customerId = this.props.customerId;

    var status = 0;

    postRequest(
      CUSTOMER_SERVICE_URL + "/api/customer/" + method,
      this.state.currentContact.contact,
    ).then((res) => {
      switch (res.code) {
        case 200:
          var contact = JSON.parse(
            JSON.stringify(this.state.currentContact.contact),
          );

          reportDailyLog.action = 3; // update customer
          reportDailyLog.objectAfter = JSON.stringify(contact);
          submitReportDailyLog(reportDailyLog);

          contact.id = res.data.id;

          if (contact.isMaster) {
            //desabilita todos
            for (var i = 0; i < this.state.contactList.length; i++) {
              this.state.contactList[i].isMaster = false;
            }
          }

          if (this.state.currentContact.rowIndex < 0) {
            this.state.contactList.push(contact);
          } else {
            this.state.contactList[this.state.currentContact.rowIndex] =
              contact;
          }

          this.resetContact();

          this.setState({ loading: false });

          this.forceUpdate();
          break;

        case 400:
        case 409:
          var messages = res.data;
          messages.forEach((ex) => toast.warn(ex.message));
          break;

        case 500:
          toast.error("error");
          break;
      }
    });
  }

  deleteContact(rowIndex) {
    confirmAlert({
      buttons: [
        {
          label: translate("forms.buttonYes"),
          onClick: () => {
            let contact = JSON.parse(
              JSON.stringify(this.state.contactList[rowIndex]),
            );

            let contactId = contact.id;

            this.handleDeleteContact(contactId, rowIndex);
          },
        },
        {
          label: translate("forms.buttonNo"),
          onClick: () => {
            window.close();
          },
        },
      ],
      message: translate("customer.customerContactDeleteTitle"),
      title: translate("customer.customerContactDeleteQuestion"),
    });
  }

  handleDeleteContact(contactId, rowIndex) {
    let reportDailyLog = this.state.reportDailyLog;
    var status = 0;

    this.setState({ loading: true });

    getRequest(
      CUSTOMER_SERVICE_URL + "/api/customer/DeleteContact/" + contactId,
    ).then((res) => {
      this.setState({ loading: false });

      switch (res.code) {
        case 200:
          let contact = JSON.parse(
            JSON.stringify(this.state.contactList[rowIndex]),
          );

          var items = this.state.contactList;
          items.splice(rowIndex, 1);
          this.state.contactList = items;

          reportDailyLog.action = 3; // updatecustomer
          reportDailyLog.objectAfter = JSON.stringify(contact);
          submitReportDailyLog(reportDailyLog);

          this.forceUpdate();

          break;

        case 400:
        case 409:
          var messages = res.data;
          messages.forEach((ex) => toast.warn(ex.message));
          break;

        case 500:
          toast.error("error");
          break;
      }
    });
  }

  setValidators() {
    this.contactValidator = new SimpleReactValidator({
      emails: {
        rule: function (val) {
          if (val === "") return true;
          var emails = val.replace(new RegExp(",", "g"), ";").split(";");
          for (var i = 0, len = emails.length; i < len; i++)
            if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emails[i].trim())
            )
              return false;

          return true;
        },
      },
    });
  }

  render() {
    return (
      <div>
        {session.auth([{ type: "Customer", value: "Save" }]) && (
          <div>
            <div>
              <div className="m-b-40">
                <small>
                  {translate("customer.customerContactsTitleDescription")}
                </small>
              </div>
            </div>

            <div className="row clearfix">
              <div className="col-md-4">
                <div className="input-group">
                  <label
                    className="validationRequired"
                    htmlFor="txtContactName"
                  >
                    {translate("customer.customerContactName")}
                  </label>

                  <div className="form-line">
                    <input
                      id="txtContactName"
                      className="form-control js-customer-contact-change"
                      name="currentContact.contact.name"
                      placeholder={translate(
                        "customer.customerContactNamePlaceHolder",
                      )}
                      type="text"
                    />
                  </div>

                  {this.contactValidator.message(
                    "currentContact.contact.name",
                    this.state.currentContact.contact.name,
                    "required",
                    false,
                    {
                      default: translate(
                        "customer.customerContactNameRequired",
                      ),
                    },
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="input-group">
                  <label htmlFor="txtContactPosition">
                    {translate("customer.customerContactPosition")}
                  </label>

                  <div className="form-line">
                    <input
                      id="txtContactPosition"
                      className="form-control js-customer-contact-change"
                      name="currentContact.contact.position"
                      placeholder={translate(
                        "customer.customerContactPositionPlaceHolder",
                      )}
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="m-t-30">
                  <input
                    id="chkContactIsMaster"
                    name="currentContact.contact.isMaster"
                    type="checkbox"
                    checked={this.state.currentContact.contact.isMaster}
                    onChange={this.handleChange}
                  />
                  <label htmlFor="chkContactIsMaster">
                    {translate("customer.customerContactIsMaster")}
                  </label>
                </div>
              </div>

              <div className="col-md-2">
                <div className="m-t-30">
                  <input
                    id="chkContactIsActive"
                    name="currentContact.contact.isActive"
                    type="checkbox"
                    checked={this.state.currentContact.contact.isActive}
                    onChange={this.handleChange}
                  />
                  <label htmlFor="chkContactIsActive">
                    {translate("customer.customerIsActive")}
                  </label>
                </div>
              </div>
            </div>

            <div className="row clearfix">
              <div className="col-md-3">
                <div className="input-group">
                  <label
                    className="validationRequired"
                    htmlFor="txtContactPhoneNumber"
                  >
                    {translate("customer.customerContactPhoneNumber")}
                  </label>

                  <div className="form-line">
                    <input
                      id="txtContactPhoneNumber"
                      length="15"
                      className="form-control js-customer-contact-change js-phone-number"
                      name="currentContact.contact.phoneNumber"
                      placeholder={translate(
                        "customer.customerContactPhoneNumberPlaceHolder",
                      )}
                      type="text"
                    />
                  </div>

                  {this.contactValidator.message(
                    "currentContact.contact.phoneNumber",
                    this.state.currentContact.contact.phoneNumber,
                    "required",
                    false,
                    {
                      default: translate(
                        "customer.customerContactPhoneNumberRequired",
                      ),
                    },
                  )}
                </div>
              </div>

              <div className="col-md-5">
                <div className="input-group">
                  <label htmlFor="txtContactEmails">
                    {translate("customer.customerContactEmails")}
                  </label>

                  <div className="form-line">
                    <input
                      id="txtContactEmails"
                      className="form-control js-customer-contact-change"
                      name="currentContact.contact.emails"
                      placeholder={translate(
                        "customer.customerContactEmailsPlaceHolder",
                      )}
                      onKeyUp={this.handleTypingEmail}
                      type="text"
                    />
                  </div>

                  {this.contactValidator.message(
                    "currentContact.contact.emails",
                    this.state.currentContact.contact.emails,
                    "emails",
                    false,
                    {
                      default: translate(
                        "customer.customerContactEmailsInvalid",
                      ),
                    },
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="input-group">
                  <label htmlFor="txtContactNotes">
                    {translate("customer.customerContactNotes")}
                  </label>

                  <div className="form-line">
                    <input
                      id="txtContactNotes"
                      className="form-control js-customer-contact-change"
                      name="currentContact.contact.notes"
                      placeholder={translate(
                        "customer.customerContactNotesPlaceHolder",
                      )}
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row clearfix">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn m-r-10"
                  onClick={this.resetContact}
                  style={{
                    display:
                      this.state.currentContact.rowIndex >= 0
                        ? "inline-block"
                        : "none",
                  }}
                >
                  <i className="material-icons">&#xe5c4;</i>
                  <span>{translate("forms.buttonCancel")}</span>
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.saveContact}
                >
                  <i className="material-icons">&#xe145;</i>
                  <span>
                    {translate(
                      this.state.currentContact.rowIndex < 0
                        ? "customer.customerContactsAddNew"
                        : "customer.customerContactsUpdate",
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}

        <ReactTable
          data={this.state.contactList}
          columns={[
            {
              columns: [
                {
                  Header: translate("customer.customerContactName"),
                  id: "name",
                  accessor: (data) => {
                    return <span title={data.name}> {data.name} </span>;
                  },
                },
                {
                  Header: translate("customer.customerContactPosition"),
                  accessor: "position",
                },
                {
                  Header: translate("customer.customerContactPhoneNumber"),
                  accessor: "phoneNumber",
                },
                {
                  Header: translate("customer.customerContactEmails"),
                  id: "emails",
                  accessor: (data) => {
                    return <span title={data.emails}> {data.emails} </span>;
                  },
                },
                {
                  Header: translate("customer.customerContactNotes"),
                  id: "notes",
                  accessor: (data) => {
                    return <span title={data.notes}> {data.notes} </span>;
                  },
                },
                {
                  Header: translate("customer.customerContactIsMaster"),
                  Cell: (row) => {
                    return (
                      <div className="align-center">
                        {row.original.isMaster && (
                          <i className="material-icons color-success">
                            &#xe86c;
                          </i>
                        )}
                        {!row.original.isMaster && (
                          <i className="material-icons color-danger">
                            &#xe5c9;
                          </i>
                        )}
                      </div>
                    );
                  },
                  maxWidth: 100,
                },
                {
                  Header: translate("customer.customerIsActive"),
                  Cell: (row) => {
                    return (
                      <div className="align-center">
                        {row.original.isActive && (
                          <i className="material-icons color-success">
                            &#xe86c;
                          </i>
                        )}
                        {!row.original.isActive && (
                          <i className="material-icons color-danger">
                            &#xe5c9;
                          </i>
                        )}
                      </div>
                    );
                  },
                  maxWidth: 100,
                },
                session.auth([{ type: "Customer", value: "Save" }]) && {
                  Cell: (row) => (
                    <div className="align-center">
                      <button
                        className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10"
                        type="button"
                        disabled={this.state.currentContact.rowIndex >= 0}
                        onClick={() => {
                          this.deleteContact(row.index);
                        }}
                      >
                        <i className="material-icons">&#xe872;</i>
                      </button>

                      <button
                        type="button"
                        className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10"
                        disabled={this.state.currentContact.rowIndex >= 0}
                        onClick={() => {
                          this.editContact(row.index);
                        }}
                      >
                        <i className="material-icons">&#xe3c9;</i>
                      </button>
                    </div>
                  ),
                  maxWidth: 150,
                },
              ],
            },
          ]}
          defaultPageSize={10}
          className="-striped -highlight m-b-40"
          previousText={translate("forms.previousText")}
          nextText={translate("forms.nextText")}
          noDataText={translate("forms.noDataText")}
          pageText={translate("forms.pageText")}
          ofText={translate("forms.ofText")}
          rowsText={translate("forms.rowsText")}
        />
      </div>
    );
  }
}
