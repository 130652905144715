import React from "react";
import { Link } from "react-router-dom";
import { translate } from "react-internationalization";
import { ToastContainer, toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import $ from "jquery";
import * as session from "../components/SessionValidator";
import { ACCOUNT_SERVICE_URL } from "../settings";
import { postRequest } from "../service/RequestService";

class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.setValidators();

    this.state = { password: {} };
  }

  setValidators() {
    this.validator = new SimpleReactValidator({
      password: {
        rule: (value) => {
          let regex =
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&"'()_+,-./:;<=>?´`{}|\\^~[\]çÇ\ ])[A-Za-z\d$@$!%*#?&"'()_+,-./:;<=>?´`{}|\\^~[\]çÇ\ ]{8,}$/gm;
          return regex.test(value);
        },
      },

      confirmPassword: {
        rule: (value) => {
          let password = this.state.password.password;
          return value === password;
        },
      },
    });
  }

  async componentDidMount() {
    let _this = this;
    $("body").on("change", ".js-change", function (event) {
      _this.handleChange(event);
    });

    let password = this.state.password;
    password.email = localStorage.getItem("email");
    this.setState({ password: password });
  }

  componentDidUpdate() {
    let _this = this;
    $(".js-change").each(function () {
      _this.setValue(this);
    });
  }

  handleChange(event) {
    session.validateSession();

    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    this.setState(this.state);
  }

  setValue(element) {
    let keys = element.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      property = property[key];
      if (Array.isArray(property)) property = property[element.dataset.index];
    });

    $(element).val(property);
  }

  handleSubmit() {
    let invalid = !this.validator.allValid();

    if (invalid) {
      this.validator.showMessages();
      this.forceUpdate();

      return;
    }

    this.setState({ loading: true });
    let password = $.extend(true, {}, this.state.password),
      status;

    postRequest(ACCOUNT_SERVICE_URL + "/api/Account/ChangePassword", password)
      .then((res) => {
        this.setState({ loading: false });

        switch (res.code) {
          case 200:
            this.props.history.push("/");
            toast.success(translate("login.loginChangePasswordSuccess"));
            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("error");
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-xs-12">
            <div className="card">
              <div className="header">
                <h2>
                  {translate("user.userTitle")}
                  <small> {translate("user.changePasswordTitle")}</small>
                </h2>

                <ul className="header-dropdown">
                  <div
                    className="preloader pl-size-xs"
                    style={{ display: this.state.loading ? "block" : "none" }}
                  >
                    <div className="spinner-layer">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>

                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>

              <div className="body">
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group">
                        <label
                          htmlFor="txtEmail"
                          className="validationRequired"
                        >
                          {translate("user.changePasswordEmail")}
                        </label>

                        <div className="form-line">
                          <input
                            id="txtEmail"
                            name="password.email"
                            className="form-control js-change"
                            disabled={true}
                            type="text"
                          />
                        </div>

                        {this.validator.message(
                          "email",
                          this.state.password.email,
                          "required",
                          false,
                          {
                            default: translate(
                              "user.changePasswordEmailRequired",
                            ),
                          },
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-group">
                        <label
                          htmlFor="txtCurrentPassword"
                          className="validationRequired"
                        >
                          {translate("user.changePasswordCurrentPassword")}
                        </label>

                        <div className="form-line">
                          <input
                            id="txtCurrentPassword"
                            name="password.currentPassword"
                            className="form-control js-change"
                            placeholder={translate(
                              "user.changePasswordCurrentPassword",
                            )}
                            type="password"
                          />
                        </div>

                        {this.validator.message(
                          "currentPassword",
                          this.state.password.currentPassword,
                          "required",
                          false,
                          {
                            default: translate(
                              "user.changePasswordCurrentPasswordRequired",
                            ),
                          },
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group">
                        <label
                          htmlFor="txtPassword"
                          className="validationRequired"
                        >
                          {translate("user.changePasswordPassword")}
                        </label>

                        <div className="form-line">
                          <input
                            id="txtPassword"
                            name="password.password"
                            className="form-control js-change"
                            placeholder={translate(
                              "user.changePasswordPassword",
                            )}
                            type="password"
                          />
                        </div>

                        {this.validator.message(
                          "password",
                          this.state.password.password,
                          "required|password",
                          false,
                          {
                            default: translate(
                              "user.changePasswordPasswordRequired",
                            ),
                            password: translate(
                              "user.changePasswordPasswordInvalid",
                            ),
                          },
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input-group">
                        <label
                          htmlFor="txtConfirmPassword"
                          className="validationRequired"
                        >
                          {translate("user.changePasswordConfirmPassword")}
                        </label>

                        <div className="form-line">
                          <input
                            id="txtConfirmPassword"
                            name="password.confirmPassword"
                            className="form-control js-change"
                            placeholder={translate(
                              "user.changePasswordConfirmPassword",
                            )}
                            type="password"
                          />
                        </div>

                        {this.validator.message(
                          "password",
                          this.state.password.confirmPassword,
                          "required|confirmPassword",
                          false,
                          {
                            default: translate(
                              "user.changePasswordConfirmPasswordRequired",
                            ),
                            confirmPassword: translate(
                              "user.changePasswordConfirmPasswordNotMatch",
                            ),
                          },
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12 formActions">
                      <Link className="btn waves-effect" to="/">
                        <i className="material-icons">&#xe88a;</i>
                        <span>{translate("forms.buttonHome")}</span>
                      </Link>

                      <button
                        className="btn btn-success waves-effect"
                        disabled={this.state.loading}
                        type="button"
                        onClick={() => {
                          this.handleSubmit();
                        }}
                      >
                        <i className="material-icons">&#xe161;</i>
                        <span>{translate("forms.buttonSave")}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer hideProgressBar />
      </div>
    );
  }
}

export default ChangePasswordPage;
