import React from "react";
import RCDatePicker from "../components/datePicker";
import { translate } from "react-internationalization";
import SimpleReactValidator from "simple-react-validator";
import ReactTable from "react-table";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import RCSelect from "../components/select";
import * as session from "../components/SessionValidator";
import ModalAccountSearch from "../components/account/modal-account-search";
import { ACCOUNT_SERVICE_URL, LOG_SERVICE_URL } from "../settings";
import moment from "moment";
import { getRequest } from "../service/RequestService";

class DailySummaryPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.searchSummary = this.searchSummary.bind(this);
    this.handleChangeAccountSearch = this.handleChangeAccountSearch.bind(this);
    this.handleResultAccountSearch = this.handleResultAccountSearch.bind(this);
    this.handleReturnAccountSearch = this.handleReturnAccountSearch.bind(this);

    this.setValidators();

    this.state = {
      dailySummaries: [],
      showSummary: false,
      accountId: "",
      action: "",
      loading: false,

      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),

      accountSearchOpen: false,
      accountLoading: true,
    };

    this.users = [];
  }

  setValidators() {
    this.summaryValidator = new SimpleReactValidator({
      startDateLaterThanEndDate: {
        rule: () => {
          return (
            !this.state.startDate ||
            !this.state.endDate ||
            moment(this.state.startDate, "YYYY-MM-DD").isSameOrBefore(
              moment(this.state.endDate, "YYYY-MM-DD"),
            )
          );
        },
      },
    });
  }

  async componentDidMount() {
    var _this = this;

    $("body")
      .off("change", ".js-change")
      .on("change", ".js-change", function (event) {
        _this.handleChange(event);
      });

    var data = await this.loadData();
    data.fetched = true;

    this.setState(data);
    this.forceUpdate();
  }

  async componentDidUpdate() {}

  async handleChange(event) {
    session.validateSession();

    if (event.target.id === "cmbaccountId") {
      this.state.accountId = event.target.value;
    }
    if (event.target.id === "cmbAction") {
      this.state.action = event.target.value;
    }

    var target = this.state,
      childs = event.target.name.split(".");

    childs.forEach(function (child) {
      if (typeof target[child] === "object") {
        target = target[child];
      } else {
        target[child] =
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value;
      }
    });

    this.setState(this.state);
  }

  async loadData() {
    return new Promise((resolve) => {
      let data = Object.assign({}, this.state),
        fetched = { users: false };

      getRequest(ACCOUNT_SERVICE_URL + "/api/Account/List").then((res) => {
        if (!res.data) res.data = [];

        if (res.data) {
          res.data.result = res.data.result.sort(function (a, b) {
            return a.name.toLowerCase() < b.name.toLowerCase()
              ? -1
              : a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : 0;
          });

          this.users = res.data.result;

          this.users.unshift({
            ["name"]: translate("LOG.dailySummaryAllUser"),
            ["id"]: "",
          });
        }

        fetched.users = true;

        if (
          Object.values(fetched).filter(function (item) {
            return !item;
          }) <= 0
        )
          resolve(data);
      });
    });
  }

  searchSummary() {
    if (!this.summaryValidator.allValid()) {
      this.summaryValidator.showMessages();
      this.forceUpdate();
      return;
    }

    this.setState({ loading: true });

    getRequest(
      LOG_SERVICE_URL +
        "/api/reportdailylog/List/?accountId=" +
        this.state.accountId +
        "&actionId=" +
        this.state.action +
        "&startDate=" +
        this.state.startDate +
        "&endDate=" +
        this.state.endDate,
    )
      .then((res) => {
        let messages = {};

        switch (res.code) {
          case 200:
            this.setState({
              dailySummaries: res.data,
              showSummary: true,
              loading: false,
            });
          case 409:
            messages = JSON.parse(res.result).data;
            messages.forEach((ex) => toast.warn(ex.message));

            break;
          case 400:
            messages = JSON.parse(res.result).data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;
          case 500:
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getActions() {
    let actions = [
      { text: translate("enums.logAction.loginUser"), index: 0 },
      { text: translate("enums.logAction.logoutUser"), index: 1 },
      { text: translate("enums.logAction.createCustomer"), index: 2 },
      { text: translate("enums.logAction.updateCustomer"), index: 3 },
      { text: translate("enums.logAction.deleteCustomer"), index: 4 },
      { text: translate("enums.logAction.createAccount"), index: 5 },
      { text: translate("enums.logAction.updateAccount"), index: 6 },
      { text: translate("enums.logAction.deleteAccount"), index: 7 },
      { text: translate("enums.logAction.createDocument"), index: 8 },
      { text: translate("enums.logAction.updateDocument"), index: 9 },
      { text: translate("enums.logAction.deleteDocument"), index: 10 },
      { text: translate("enums.logAction.createSupplier"), index: 11 },
      { text: translate("enums.logAction.updateSupplier"), index: 12 },
      { text: translate("enums.logAction.deleteSupplier"), index: 13 },
      { text: translate("enums.logAction.createBank"), index: 14 },
      { text: translate("enums.logAction.updateBank"), index: 15 },
      { text: translate("enums.logAction.deleteBank"), index: 16 },
      { text: translate("enums.logAction.createTransaction"), index: 17 },
      { text: translate("enums.logAction.updateTransaction"), index: 18 },
      { text: translate("enums.logAction.deleteTransaction"), index: 19 },
      { text: translate("enums.logAction.updateCustomerSupplier"), index: 20 },
      { text: translate("enums.logAction.updateBankCustomer"), index: 21 },
      { text: translate("enums.logAction.deleteBankCustomer"), index: 22 },
      { text: translate("enums.logAction.updateSupplierRate"), index: 23 },
      { text: translate("enums.logAction.processTransaction"), index: 24 },
      { text: translate("enums.logAction.approveTransaction"), index: 25 },
    ];

    actions = actions.sort(function (a, b) {
      return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
    });
    actions.unshift({
      ["text"]: translate("LOG.dailySummaryAllAction"),
      ["index"]: "",
    });
    return actions;
  }

  getAction(index) {
    let action = this.getActions().find((x) => {
      return x.index === parseInt(index);
    });
    return action ? action : {};
  }

  handleChangeAccountSearch(accountId) {
    this.state.accountId = accountId;
    this.state.accountSearchOpen = false;
    this.forceUpdate();
  }

  handleReturnAccountSearch() {
    this.state.accountSearchOpen = false;
    this.forceUpdate();
  }

  handleResultAccountSearch(accounts) {
    this.setState({ accounts: accounts, accountLoading: false });
    this.forceUpdate();
  }

  render() {
    return (
      <div>
        <div style={{ display: this.state.fetched ? "block" : "none" }}>
          <div className="row">
            <div className="col-xs-12">
              <div className="card">
                <div className="header">
                  <h2>
                    {translate("LOG.dailySummaryTitle")}
                    <small>
                      {translate("LOG.dailySummaryTitleDescription")}
                    </small>
                  </h2>

                  <ul className="header-dropdown">
                    <div
                      className="preloader pl-size-xs"
                      style={{ display: this.state.loading ? "block" : "none" }}
                    >
                      <div className="spinner-layer">
                        <div className="circle-clipper left">
                          <div className="circle"></div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
                <div className="body">
                  <div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="m-b-20">
                          <label htmlFor="cmbaccountId">
                            {translate("LOG.dailySummaryUser")}
                          </label>

                          <RCSelect
                            id="cmbaccountId"
                            name=""
                            isInt={true}
                            options={this.users}
                            optionValue="id"
                            optionLabel="name"
                            placeholder={translate(
                              "LOG.dailySummaryUserPlaceholder",
                            )}
                            onChange={(event) => {
                              this.handleChange(event);
                            }}
                            value={this.state.accountId}
                          />
                        </div>
                      </div>

                      <div className="col-md-1 m-t-20 p-l-0">
                        <button
                          type="button"
                          className="btn btn-default waves-effect"
                          onClick={() => {
                            this.state.accountSearchOpen = true;
                            this.forceUpdate();
                          }}
                        >
                          <i className="material-icons">&#xe8b6;</i>
                        </button>

                        <ModalAccountSearch
                          SearchOpen={this.state.accountSearchOpen}
                          handleChange={(event) => {
                            this.handleChangeAccountSearch(event);
                          }}
                          handleResult={(event) => {
                            this.handleResultAccountSearch(event);
                          }}
                          handleReturn={() => {
                            this.handleReturnAccountSearch();
                          }}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="cmbAction">
                          {translate("LOG.dailySummaryAction")}
                        </label>

                        <RCSelect
                          id="cmbAction"
                          name="action"
                          isInt={true}
                          options={this.getActions()}
                          optionValue="index"
                          optionLabel="text"
                          placeholder={translate(
                            "LOG.dailySummaryActionPlaceholder",
                          )}
                          onChange={(event) => {
                            this.handleChange(event);
                          }}
                          value={this.state.action}
                        />
                      </div>

                      <div className="col-md-2">
                        <div className="input-group">
                          <label
                            htmlFor="dtStartDate"
                            className="validationRequired"
                          >
                            {translate("LOG.dailySummaryStartDate")}
                          </label>

                          <div className="form-line">
                            <RCDatePicker
                              id="dtStartDate"
                              name="startDate"
                              value={this.state.startDate}
                              onChange={this.handleChange}
                            />
                          </div>

                          {this.summaryValidator.message(
                            "startDate",
                            this.state.startDate,
                            "required|startDateLaterThanEndDate",
                            false,
                            {
                              default: translate(
                                "LOG.dailySummaryStartDateRequired",
                              ),
                              startDateLaterThanEndDate: translate(
                                "LOG.dailySummaryStartDateLaterThanEndDate",
                              ),
                            },
                          )}
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="input-group">
                          <label
                            htmlFor="dtEndDate"
                            className="validationRequired"
                          >
                            {translate("LOG.dailySummaryEndDate")}
                          </label>

                          <div className="form-line">
                            <RCDatePicker
                              id="dfEndDate"
                              name="endDate"
                              value={this.state.endDate}
                              onChange={this.handleChange}
                            />
                          </div>

                          {this.summaryValidator.message(
                            "endDate",
                            this.state.endDate,
                            "required",
                            false,
                            {
                              default: translate(
                                "LOG.dailySummaryEndDateRequired",
                              ),
                            },
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.searchSummary}
                        >
                          <i className="material-icons">&#xe8b6;</i>
                          <span>{translate("LOG.dailySummarySearch")}</span>
                        </button>
                      </div>
                    </div>

                    <ReactTable
                      data={this.state.dailySummaries}
                      columns={[
                        {
                          columns: [
                            {
                              Header: translate("LOG.dailySummaryUser"),
                              accessor: "name",
                            },
                            {
                              Header: translate("LOG.dailySummaryAction"),
                              id: "action",
                              accessor: (data) => {
                                return this.getAction(data.action).text;
                              },
                            },
                            {
                              Header: translate("LOG.dailySummaryStartDate"),
                              id: "startDate",
                              accessor: (data) => {
                                return moment(data.startDate).format(
                                  "DD-MM-YYYY hh:mm:ss a",
                                );
                              },
                            },
                            {
                              Header: translate("LOG.dailySummaryEndDate"),
                              id: "endDate",
                              accessor: (data) => {
                                return moment(data.endDate).format(
                                  "DD-MM-YYYY hh:mm:ss a",
                                );
                              },
                            },
                          ],
                        },
                      ]}
                      defaultPageSize={10}
                      className="-striped -highlight m-t-10"
                      style={{
                        display: this.state.showSummary ? "block" : "none",
                      }}
                      previousText={translate("forms.previousText")}
                      nextText={translate("forms.nextText")}
                      noDataText={translate("forms.noDataText")}
                      pageText={translate("forms.pageText")}
                      ofText={translate("forms.ofText")}
                      rowsText={translate("forms.rowsText")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: !this.state.fetched ? "block" : "none" }}>
          <div className="preloader pl-size-lg align-center">
            <div className="spinner-layer">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>

              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DailySummaryPage;
