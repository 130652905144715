import $ from "jquery";
import React from "react";
import { translate } from "react-internationalization";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import * as enums from "../../components/enums/supplier";
import RCSelect from "../../components/select";
import * as session from "../../components/SessionValidator";
import AddressSupplierComponent from "../../components/supplier/AddressSupplierComponent";
import BankSupplierComponent from "../../components/supplier/BankSupplierComponent";
import ContactSupplierComponent from "../../components/supplier/ContactSupplierComponent";
import { submitReportDailyLog } from "../helper";
import { CUSTOMER_SERVICE_URL, SUPPLIER_SERVICE_URL } from "../../settings";
import moment from "moment";
import { getRequest, postRequest } from "../../service/RequestService";

class SupplierPage extends React.Component {
  constructor(props) {
    super(props);

    this.setValidators();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSaveRate = this.handleSaveRate.bind(this);

    this.state = {
      id: this.props.match.params.id,

      reportDailyLog: {
        startDate: moment().format(),
        objectAfter: "",
        objectBefore: "",
      },

      rate: {
        open: false,
        customerId: 0,
        customerName: "",
        currentRate: 0,
        customerClassification: {},
      },

      customerSuppliers: [],
    };
  }

  async componentDidMount() {
    let _this = this;
    let reportDailyLog = this.state.reportDailyLog;

    $("body").on("change", ".js-change", function (event) {
      _this.handleChange(event);
    });

    if (this.state.id) {
      await getRequest(
        SUPPLIER_SERVICE_URL + "/api/supplier/GetSimpleById/" + this.state.id,
      ).then((res) => {
        reportDailyLog.objectBefore = JSON.stringify(res.data);

        this.setState({ supplier: res.data, reportDailyLog: reportDailyLog });
        this.forceUpdate();
      });
    } else {
      let supplier = {
        isActive: true,
      };

      this.setState({ supplier: supplier });
      this.forceUpdate();
    }
  }

  async componentDidUpdate() {
    let _this = this;

    $(".js-change").each(function () {
      _this.setValue(this);
    });
    $(".js-decimal").inputmask("decimal", {
      digits: 4,
      digitsOptional: false,
      max: 999999999,
      placeholder: "0.00",
      rightAlign: false,
      showMaskOnHover: false,
    });
  }

  async handleChange(event) {
    session.validateSession();

    if (event.target.id === "cmbCustomer") {
      var customerSupplier = this.state.customerSuppliers.find((item) => {
        return item.customerId === event.target.value;
      });

      if (customerSupplier) {
        this.state.rate.customerClassification =
          customerSupplier.customerClassification;
        this.state.rate.currentRate = customerSupplier.currentRate;
      }
    }

    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    this.setState(this.state);
  }

  setValue(element) {
    let keys = element.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      property = property[key];
      if (Array.isArray(property)) property = property[element.dataset.index];
    });

    $(element).val(property);
  }

  handleSubmit() {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();

      return;
    }

    var supplier = $.extend(true, {}, this.state.supplier),
      method = this.state.id ? "Update" : "Create",
      status = 0;

    let reportDailyLog = this.state.reportDailyLog;

    this.setState({ submitLoading: true });

    postRequest(SUPPLIER_SERVICE_URL + "/api/Supplier/" + method, supplier)
      .then((res) => {
        this.setState({ submitLoading: false });

        switch (res.code) {
          case 200:
            toast.success(
              translate(
                !this.state.id
                  ? "supplier.supplierCreated"
                  : "supplier.supplierUpdated",
              ),
            );

            reportDailyLog.action = this.state.id ? 12 : 11; //11 = createSupplier 12 = updateSupplier
            reportDailyLog.objectAfter = JSON.stringify(supplier);
            submitReportDailyLog(reportDailyLog);

            this.state.id = res.data.id;
            this.forceUpdate();

            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("error");
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  setValidators() {
    this.validator = new SimpleReactValidator({
      typePerson: {
        rule: (value) => {
          return ["", null, undefined].indexOf(value) < 0;
        },
      },
    });

    this.rateValidator = new SimpleReactValidator({
      rate: {
        rule: (value) => {
          let startRate = parseFloat(
            this.state.rate.customerClassification.startRate,
          );
          let endRate = parseFloat(
            this.state.rate.customerClassification.endRate,
          );

          return value >= startRate && value <= endRate;
        },
      },
    });
  }

  handleEditRate() {
    this.state.rate.open = true;

    this.loadCustomerSuppliers();

    this.rateValidator.hideMessages();
    this.forceUpdate();
  }

  async handleSaveRate() {
    let valid = this.rateValidator.allValid();

    if (valid) {
      var customerSupplier = this.state.customerSuppliers.find((item) => {
        return item.customerId === this.state.rate.customerId;
      });

      var newCustomerSupplier = JSON.parse(JSON.stringify(customerSupplier));
      newCustomerSupplier.currentRate = this.state.rate.currentRate;

      var itemCustomerSupplier = $.extend(true, {}, newCustomerSupplier),
        status = 0;

      let reportDailyLog = {
        startDate: moment().format(),
        objectAfter: JSON.stringify(newCustomerSupplier),
        objectBefore: JSON.stringify(customerSupplier),
      };

      this.setState({ submitLoading: true });

      await postRequest(
        CUSTOMER_SERVICE_URL + "/api/Customer/UpdateSupplierRate",
        itemCustomerSupplier,
      )
        .then((res) => {
          this.setState({ submitLoading: false });

          switch (res.code) {
            case 200:
              reportDailyLog.action = 23; //23 = updateSupplierRate
              submitReportDailyLog(reportDailyLog);

              this.state.rate.open = false;
              this.forceUpdate();

              break;

            case 400:
            case 409:
              var messages = res.data;
              messages.forEach((ex) => toast.warn(ex.message));
              break;

            case 500:
              toast.error("error");
              break;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      this.rateValidator.showMessages();
      this.forceUpdate();
    }
  }

  handleReturnRate() {
    this.state.rate.open = false;

    this.forceUpdate();
  }

  async loadCustomerSuppliers() {
    await getRequest(
      CUSTOMER_SERVICE_URL +
        "/api/Customer/ListCustomerSupplier/" +
        this.state.id,
    ).then((res) => {
      if (res.data) {
        for (var i = 0; i < res.data.length; i++) {
          res.data[i].customerName = res.data[i].customer.name;
        }

        res.data = res.data.sort(function (a, b) {
          return a.customerName < b.customerName
            ? -1
            : a.customerName > b.customerName
              ? 1
              : 0;
        });
      }

      this.setState({ customerSuppliers: res.data });
      this.forceUpdate();
    });
  }

  render() {
    var fetched = this.state != null && this.state.supplier != null;

    if (fetched) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <div className="card">
              <div className="header">
                <h2>
                  <ul className="nav nav-tabs tab-nav-right" role="tablist">
                    <li className="active" role="presentation">
                      <a href="#supplierPanel" data-toggle="tab">
                        {translate("supplier.supplierTitle")}
                      </a>
                    </li>

                    <li
                      role="presentation"
                      className={this.state.id ? "" : "disabled"}
                    >
                      <a
                        id="addresses-tab-link"
                        href={this.state.id ? "#addressPanel" : "#"}
                        data-toggle="tab"
                      >
                        {translate("supplier.supplierAddressNameTitle")}
                      </a>
                    </li>

                    <li
                      role="presentation"
                      className={this.state.id ? "" : "disabled"}
                    >
                      <a
                        href={this.state.id ? "#contactsPanel" : "#"}
                        data-toggle="tab"
                      >
                        {translate("supplier.supplierContactsTitle")}
                      </a>
                    </li>

                    <li
                      role="presentation"
                      className={this.state.id ? "" : "disabled"}
                    >
                      <a
                        id="bankAccounts-tab-link"
                        href={this.state.id ? "#bankAccountsPanel" : "#"}
                        data-toggle="tab"
                      >
                        {translate("supplier.supplierSetupAccountTitle")}
                      </a>
                    </li>
                  </ul>
                </h2>

                <div className="header-dropdown">
                  <div
                    className="preloader pl-size-xs"
                    style={{
                      display: this.state.submitLoading ? "block" : "none",
                    }}
                  >
                    <div className="spinner-layer">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>

                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="body">
                <div className="tab-content">
                  <div
                    id="supplierPanel"
                    className="tab-pane fade in active"
                    role="tabpanel"
                  >
                    <div>
                      <div className="m-b-40">
                        <small>
                          {translate("supplier.supplierTitleDescription")}
                        </small>
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-5">
                          <div className="input-group">
                            <label
                              className="validationRequired"
                              htmlFor="txtName"
                            >
                              {translate("supplier.supplierName")}
                            </label>

                            <div className="form-line">
                              <input
                                id="txtName"
                                disabled={
                                  !session.auth([
                                    { type: "Supplier", value: "Save" },
                                  ])
                                }
                                className="form-control js-change"
                                name="supplier.name"
                                placeholder={translate(
                                  "supplier.supplierNamePlaceHolder",
                                )}
                                type="text"
                              />
                            </div>

                            {this.validator.message(
                              "supplier.name",
                              this.state.supplier.name,
                              "required",
                              "validationMessage--error",
                              {
                                default: translate(
                                  "supplier.supplierNameRequired",
                                ),
                              },
                            )}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="m-b-20">
                            <label
                              htmlFor="cmbTypePerson"
                              className="validationRequired"
                            >
                              {translate("supplier.supplierTypePerson")}
                            </label>

                            <RCSelect
                              id="cmbTypePerson"
                              name="supplier.type"
                              hasEmptyOption={true}
                              isInt={true}
                              options={enums.getTypePerson()}
                              optionValue="index"
                              optionLabel="text"
                              placeholder={translate(
                                "supplier.supplierTypePersonPlaceHolder",
                              )}
                              onChange={(event) => {
                                this.handleChange(event);
                              }}
                              value={this.state.supplier.type}
                              isDisabled={
                                !session.auth([
                                  { type: "Supplier", value: "Save" },
                                ])
                              }
                            />

                            {this.validator.message(
                              "cmbTypePerson",
                              this.state.supplier.type,
                              "required|typePerson",
                              false,
                              {
                                default: translate(
                                  "supplier.supplierTypePersonRequired",
                                ),
                              },
                            )}
                          </div>
                        </div>

                        {(session.isAdmin() || session.isOperator()) && (
                          <div className="col-md-2">
                            <div className="m-t-30">
                              <input
                                id="chkIsActive"
                                name="supplier.isActive"
                                type="checkbox"
                                checked={this.state.supplier.isActive}
                                onChange={this.handleChange}
                              />
                              <label htmlFor="chkIsActive">
                                {translate("supplier.supplierIsActive")}
                              </label>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-12">
                          <div className="input-group">
                            <label htmlFor="txtNotes">
                              {translate("supplier.supplierNotes")}
                            </label>

                            <div className="form-line">
                              <textarea
                                id="txtNotes"
                                disabled={
                                  !session.auth([
                                    { type: "Supplier", value: "Save" },
                                  ])
                                }
                                className="form-control js-change"
                                name="supplier.notes"
                                placeholder={translate(
                                  "supplier.supplierNotesPlaceHolder",
                                )}
                                rows="4"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row clearfix">
                        <div className="col-md-12 formActions">
                          <Link
                            to="/suppliers"
                            className="btn btn-default waves-effect"
                          >
                            <i className="material-icons">&#xe5c4;</i>
                            <span>{translate("forms.buttonReturn")}</span>
                          </Link>

                          {(session.isAdmin() || session.isOperator()) &&
                            session.auth([
                              { type: "Supplier", value: "UpdateRate" },
                            ]) && (
                              <button
                                className="btn bg-deep-purple waves-effect m-l-10"
                                style={{
                                  display: this.state.id
                                    ? "inline-block"
                                    : "none",
                                }}
                                type="button"
                                onClick={() => {
                                  this.handleEditRate();
                                }}
                              >
                                <i className="material-icons">&#xe042;</i>
                                <span>
                                  {translate("forms.updateSupplierRate")}
                                </span>
                              </button>
                            )}

                          {session.auth([
                            { type: "Supplier", value: "Save" },
                          ]) && (
                            <button
                              type="button"
                              className="btn btn-success waves-effect"
                              disabled={this.state.submitLoading}
                              onClick={this.handleSubmit}
                            >
                              <i className="material-icons">&#xe161;</i>
                              <span>{translate("forms.buttonSave")}</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="addressPanel"
                    className="tab-pane fade in"
                    role="tabpanel"
                  >
                    <div>
                      <AddressSupplierComponent
                        supplierId={this.state.id}
                        handleChange={(event) => {
                          this.forceUpdate();
                        }}
                      />

                      <div className="row clearfix">
                        <div className="col-md-12 formActions">
                          <Link
                            to="/suppliers"
                            className="btn btn-default waves-effect"
                          >
                            <i className="material-icons">&#xe5c4;</i>
                            <span>{translate("forms.buttonReturn")}</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="contactsPanel"
                    className="tab-pane fade in"
                    role="tabpanel"
                  >
                    <div>
                      <ContactSupplierComponent
                        supplierId={this.state.id}
                        handleChange={(event) => {
                          this.forceUpdate();
                        }}
                      />
                      <div className="row clearfix">
                        <div className="col-md-12 formActions">
                          <Link
                            to="/suppliers"
                            className="btn btn-default waves-effect"
                          >
                            <i className="material-icons">&#xe5c4;</i>
                            <span>{translate("forms.buttonReturn")}</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="bankAccountsPanel"
                    className="tab-pane fade in"
                    role="tabpanel"
                  >
                    <div>
                      <BankSupplierComponent
                        supplierId={this.state.id}
                        handleChange={(event) => {
                          this.forceUpdate();
                        }}
                      />
                      <div className="row clearfix">
                        <div className="col-md-12 formActions">
                          <Link
                            to="/suppliers"
                            className="btn btn-default waves-effect"
                          >
                            <i className="material-icons">&#xe5c4;</i>
                            <span>{translate("forms.buttonReturn")}</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Rate */}
          <Modal
            open={this.state.rate.open}
            closeOnEsc={false}
            onClose={() => {}}
            onEntered={() => {
              this.forceUpdate();
            }}
            closeIconSize={0}
            center={true}
            styles={{ modal: { maxWidth: "60%" } }}
          >
            <div>
              <div style={{ width: 9999 }}></div>

              <div>
                <h4>{translate("forms.updateSupplierRate")}</h4>
                <hr />
              </div>

              <div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="m-b-5">
                      <label
                        className="validationRequired"
                        htmlFor="cmbCustomer"
                      >
                        {" "}
                        {translate("supplier.supplierCustomer")}{" "}
                      </label>

                      <RCSelect
                        id="cmbCustomer"
                        name="rate.customerId"
                        hasEmptyOption={true}
                        isInt={true}
                        options={this.state.customerSuppliers}
                        optionValue="customerId"
                        optionLabel="customerName"
                        placeholder={translate(
                          "supplier.supplierCustomerPlaceHolder",
                        )}
                        onChange={(event) => {
                          this.handleChange(event);
                        }}
                        value={this.state.rate.customerId}
                      />

                      {this.rateValidator.message(
                        "rate.customerId",
                        this.state.rate.customerId,
                        "gt: 0",
                        false,
                        {
                          default: translate(
                            "supplier.supplierCustomerRequired",
                          ),
                        },
                      )}
                    </div>
                  </div>

                  <div
                    className="col-md-4"
                    style={{
                      display:
                        this.state.rate.customerId > 0 ? "block" : "none",
                    }}
                  >
                    <div className="input-group">
                      <label>
                        {" "}
                        {translate("supplier.supplierClassification")}{" "}
                      </label>

                      <div className="form-line">
                        <input
                          id="txtClassification"
                          disabled
                          className="form-control js-change"
                          name="rate.customerClassification.displayName"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-3"
                    style={{
                      display:
                        this.state.rate.customerId > 0 ? "block" : "none",
                    }}
                  >
                    <div className="input-group">
                      <label className="validationRequired" htmlFor="txtRate">
                        {translate("supplier.supplierRate")}
                      </label>

                      <div className="form-line">
                        <input
                          id="txtRate"
                          className="form-control js-change js-decimal"
                          name="rate.currentRate"
                          placeholder={translate(
                            "supplier.supplierRatePlaceHolder",
                          )}
                          type="text"
                        />
                      </div>

                      {this.rateValidator.message(
                        "rate.currentRate",
                        this.state.rate.currentRate,
                        "required|rate",
                        false,
                        {
                          default: translate("supplier.supplierRateRequired"),
                          rate: translate(
                            "supplier.supplierRateClassification",
                          ),
                        },
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="align-right">
                <div className="m-t-20">
                  <button
                    type="button"
                    className="m-r-10 btn btn-default waves-effect"
                    disabled={this.state.submitLoading}
                    onClick={() => {
                      this.handleReturnRate();
                    }}
                  >
                    <i className="material-icons">&#xe5c4;</i>
                    <span>{translate("forms.buttonReturn")}</span>
                  </button>

                  <button
                    type="button"
                    className="btn btn-success waves-effect"
                    disabled={this.state.submitLoading}
                    onClick={() => {
                      this.handleSaveRate();
                    }}
                  >
                    <i className="material-icons">&#xe161;</i>
                    <span>{translate("forms.buttonSave")}</span>
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          <ToastContainer hideProgressBar />
        </div>
      );
    } else {
      return (
        <div className="preloader pl-size-lg align-center">
          <div className="spinner-layer">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>

            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const ToastRedirect = ({ tabToShow, message, closeToast }) => {
  function handleClick() {
    tabToShow.trigger("click");
    closeToast();
  }

  return <div onClick={handleClick}>{message}</div>;
};

export default SupplierPage;
