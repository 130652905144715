import React from "react";
import { submitReportDailyLog } from "../../pages/helper.js";
import ReactTable from "react-table";
import { translate } from "react-internationalization";
import * as session from "../SessionValidator";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import RCSelect from "../select";
import $, { post } from "jquery";
import { BANK_SERVICE_URL, CUSTOMER_SERVICE_URL } from "../../settings.js";
import moment from "moment";
import { getRequest, postRequest } from "../../service/RequestService.js";

export default class AddressBankComponent extends React.Component {
  constructor(props) {
    super(props);

    this.setValidators();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTypingEmail = this.handleTypingEmail.bind(this);
    this.saveAddress = this.saveAddress.bind(this);
    this.editAddress = this.editAddress.bind(this);
    this.deleteAddress = this.deleteAddress.bind(this);
    this.resetAddress = this.resetAddress.bind(this);

    this.state = {
      currentAddress: {
        rowIndex: -1,
        address: {
          id: 0,
          name: "",
          bankId: 0,
          countryId: 403,
          stateId: null,
          city: "",
          district: "",
          street: "",
          number: "",
          complement: "",
          zipCode: "",
          isMaster: true,
          countryName: "",
          stateName: "",
        },
      },

      countries: null,
      addressStates: null,

      addressList: [],
      loading: false,

      reportDailyLog: {
        startDate: moment().format(),
        objectAfter: "",
        objectBefore: "",
      },
    };
  }

  async componentDidUpdate() {
    let _this = this;

    $(".js-bank-address-change").each(function () {
      _this.setValue(this);
    });
    $(".js-phone-number").inputmask({
      greedy: false,
      mask: "(99) 9999[9]-9999",
      showMaskOnHover: false,
    });
  }

  async componentDidMount() {
    let _this = this;

    $("body").on("change", ".js-bank-address-change", function (event) {
      _this.handleChange(event);
    });

    await getRequest(CUSTOMER_SERVICE_URL + "/api/Country/List").then((res) => {
      res.data = res.data.sort(function (a, b) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });

      this.setState({ countries: res.data });

      let countrySelected = this.state.countries.find((item) => {
        return item.id === this.state.currentAddress.address.countryId;
      });

      if (countrySelected)
        this.state.currentAddress.address.countryName = countrySelected.name;

      this.loadStates("addressStates", "addressStatesLoading", 403); // 403 = Brasil

      this.forceUpdate();
    });

    if (this.props.bankId) {
      await getRequest(
        BANK_SERVICE_URL + "/api/bank/ListBankAddress/" + this.props.bankId,
      ).then((res) => {
        res.data = res.data.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        });

        this.setState({ addressList: res.data });
        this.forceUpdate();
      });
    } else {
      let currentAddress = {
        rowIndex: -1,
        address: {
          id: 0,
          name: "",
          bankId: 0,
          countryId: 403,
          stateId: null,
          city: "",
          district: "",
          street: "",
          number: "",
          complement: "",
          zipCode: "",
          isMaster: true,
          countryName: "",
          stateName: "",
        },
      };

      let addressList = [];

      this.setState({
        currentAddress: currentAddress,
        addressList: addressList,
      });
      this.forceUpdate();
    }
  }

  async handleChange(event) {
    session.validateSession();

    let keys = event.target.name.split("."),
      property = this.state;

    if (event.target.id === "cmbAddressCountry") {
      this.state.addressStates = null;

      if (event.target.value > 0)
        this.loadStates(
          "addressStates",
          "addressStatesLoading",
          event.target.value,
        );

      let countrySelected = this.state.countries.find((item) => {
        return item.id === event.target.value;
      });

      if (countrySelected)
        this.state.currentAddress.address.countryName = countrySelected.name;
    }

    if (event.target.id === "cmbAddressState") {
      let stateSelected = this.state.addressStates.find((item) => {
        return item.id === event.target.value;
      });

      if (stateSelected)
        this.state.currentAddress.address.stateName = stateSelected.name;
    }

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    this.forceUpdate();

    this.setState(this.state);
  }

  setValue(element) {
    let keys = element.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      property = property[key];
      if (Array.isArray(property)) property = property[element.dataset.index];
    });

    $(element).val(property);
  }

  handleTypingEmail(e) {
    e.target.value = e.target.value.toLowerCase();
  }

  resetAddress() {
    this.state.currentAddress = {
      rowIndex: -1,
      address: {
        id: 0,
        name: "",
        bankId: 0,
        countryId: 403,
        stateId: null,
        city: "",
        district: "",
        street: "",
        number: "",
        complement: "",
        zipCode: "",
        isMaster: true,
        countryName: "",
        stateName: "",
      },
    };

    if (this.addressValidator !== undefined) {
      this.addressValidator.hideMessages();
    }

    this.setState(this.state);
  }

  saveAddress() {
    if (!this.addressValidator.allValid()) {
      this.addressValidator.showMessages();
      this.forceUpdate();
      return;
    }

    this.handleSubmit();
  }

  editAddress(rowIndex) {
    let reportDailyLog = this.state.reportDailyLog;

    this.state.currentAddress.address = JSON.parse(
      JSON.stringify(this.state.addressList[rowIndex]),
    );
    this.state.currentAddress.rowIndex = rowIndex;

    if (this.state.addressList[rowIndex].countryId > 0)
      this.loadStates(
        "addressStates",
        "addressStatesLoading",
        this.state.addressList[rowIndex].countryId,
      );

    reportDailyLog.objectBefore = JSON.stringify(
      this.state.currentAddress.address,
    );

    this.setState(this.state);
    this.forceUpdate();
  }

  async loadStates(states, statesLoading, countryId) {
    this.state[statesLoading] = true;
    this.setState(this.state);

    await getRequest(
      CUSTOMER_SERVICE_URL + "/api/State/List?countryId=" + countryId,
    ).then((res) => {
      this.state[states] = res.data;
      this.state[statesLoading] = false;

      this.setState(this.state);

      this.forceUpdate();
    });
  }

  handleSubmit() {
    let reportDailyLog = this.state.reportDailyLog;

    this.setState({ loading: true });

    var method =
      this.state.currentAddress.address.id > 0 ? "UpdateAddress" : "AddAddress";

    this.state.currentAddress.address.bankId = this.props.bankId;

    var status = 0;

    postRequest(
      BANK_SERVICE_URL + "/api/bank/" + method,
      this.state.currentAddress.address,
    ).then((res) => {
      switch (res.code) {
        case 200:
          var address = JSON.parse(
            JSON.stringify(this.state.currentAddress.address),
          );

          reportDailyLog.action = 15; // updateBank
          reportDailyLog.objectAfter = JSON.stringify(address);
          submitReportDailyLog(reportDailyLog);

          address.id = res.data.id;

          if (address.isMaster) {
            //desabilita todos
            for (var i = 0; i < this.state.addressList.length; i++) {
              this.state.addressList[i].isMaster = false;
            }
          }

          if (this.state.currentAddress.rowIndex < 0) {
            this.state.addressList.push(address);
          } else {
            this.state.addressList[this.state.currentAddress.rowIndex] =
              address;
          }

          this.resetAddress();

          this.setState({ loading: false });

          this.forceUpdate();
          break;

        case 400:
        case 409:
          var messages = res.data;
          messages.forEach((ex) => toast.warn(ex.message));
          break;

        case 500:
          toast.error("error");
          break;
      }
    });
  }

  deleteAddress(rowIndex) {
    confirmAlert({
      buttons: [
        {
          label: translate("forms.buttonYes"),
          onClick: () => {
            let address = JSON.parse(
              JSON.stringify(this.state.addressList[rowIndex]),
            );

            let addressId = address.id;

            this.handleDeleteAddress(addressId, rowIndex);
          },
        },
        {
          label: translate("forms.buttonNo"),
          onClick: () => {
            window.close();
          },
        },
      ],
      message: translate("customer.customerAddressDeleteTitle"),
      title: translate("customer.customerAddressDeleteQuestion"),
    });
  }

  handleDeleteAddress(addressId, rowIndex) {
    let reportDailyLog = this.state.reportDailyLog;
    var status = 0;

    this.setState({ loading: true });

    getRequest(BANK_SERVICE_URL + "/api/bank/DeleteAddress/" + addressId).then(
      (res) => {
        status = res.status;
        this.setState({ loading: false });

        switch (status) {
          case 200:
            let address = JSON.parse(
              JSON.stringify(this.state.addressList[rowIndex]),
            );

            var items = this.state.addressList;
            items.splice(rowIndex, 1);
            this.state.addressList = items;

            reportDailyLog.action = 15; //updateBank
            reportDailyLog.objectAfter = JSON.stringify(address);
            submitReportDailyLog(reportDailyLog);

            this.forceUpdate();

            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("error");
            break;
        }
      },
    );
  }

  setValidators() {
    this.addressValidator = new SimpleReactValidator({
      state: {
        rule: (value) => {
          return (
            !this.state.addressStates ||
            this.state.addressStates.length <= 0 ||
            parseInt(value) > 0
          );
        },
      },
    });
  }

  render() {
    return (
      <div>
        {session.auth([{ type: "Bank", value: "Save" }]) && (
          <div>
            <div>
              <div className="m-b-40">
                <small>{translate("bank.bankAddressTitleDescription")}</small>
              </div>
            </div>

            <div>
              <div className="row clearfix">
                <div className="col-md-10">
                  <div className="input-group">
                    <label
                      className="validationRequired"
                      htmlFor="txtAddressName"
                    >
                      {translate("bank.bankAddressName")}
                    </label>

                    <div className="form-line">
                      <input
                        id="txtAddressName"
                        name="currentAddress.address.name"
                        className="form-control js-bank-address-change"
                        placeholder={translate(
                          "bank.bankAddressNamePlaceHolder",
                        )}
                        type="text"
                      />
                    </div>

                    {this.addressValidator.message(
                      "currentAddress.address.name",
                      this.state.currentAddress.address.name,
                      "required",
                      false,
                      { default: translate("bank.bankAddressNameRequired") },
                    )}
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="m-t-30">
                    <input
                      id="chkIsMaster"
                      name="currentAddress.address.isMaster"
                      type="checkbox"
                      checked={this.state.currentAddress.address.isMaster}
                      onChange={this.handleChange}
                    />
                    <label htmlFor="chkIsMaster">
                      {translate("bank.bankAddressIsMaster")}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-md-4">
                  <div className="input-group">
                    <label
                      className="validationRequired"
                      htmlFor="txtStreetName"
                    >
                      {translate("bank.bankAddressStreet")}
                    </label>

                    <div className="form-line">
                      <input
                        id="txtStreetName"
                        name="currentAddress.address.street"
                        className="form-control js-bank-address-change"
                        placeholder={translate(
                          "bank.bankAddressStreetPlaceHolder",
                        )}
                        type="text"
                      />
                    </div>

                    {this.addressValidator.message(
                      "currentAddress.address.street",
                      this.state.currentAddress.address.street,
                      "required",
                      false,
                      { default: translate("bank.bankAddressStreetRequired") },
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="input-group">
                    <label htmlFor="txtStreetNumber">
                      {translate("bank.bankAddressNumber")}
                    </label>

                    <div className="form-line">
                      <input
                        id="txtStreetNumber"
                        name="currentAddress.address.number"
                        className="form-control js-bank-address-change js-integer"
                        placeholder={translate(
                          "bank.bankAddressNumberPlaceHolder",
                        )}
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="input-group">
                    <label htmlFor="txtDistrict">
                      {translate("bank.bankAddressDistrict")}
                    </label>

                    <div className="form-line">
                      <input
                        id="txtDistrict"
                        name="currentAddress.address.district"
                        className="form-control js-bank-address-change"
                        placeholder={translate(
                          "bank.bankAddressDistrictPlaceHolder",
                        )}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-md-4">
                  <div className="input-group">
                    <label className="validationRequired" htmlFor="txtZipCode">
                      {translate("bank.bankAddressZipCode")}
                    </label>

                    <div className="form-line">
                      <input
                        id="txtZipCode"
                        name="currentAddress.address.zipCode"
                        className="form-control js-bank-address-change js-zipcode"
                        placeholder={translate(
                          "bank.bankAddressZipCodePlaceHolder",
                        )}
                        type="text"
                      />
                    </div>

                    {this.addressValidator.message(
                      "currentAddress.address.zipCode",
                      this.state.currentAddress.address.zipCode,
                      "required",
                      false,
                      { default: translate("bank.bankAddressZipCodeRequired") },
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="input-group">
                    <label className="validationRequired" htmlFor="txtCity">
                      {translate("bank.bankAddressCity")}
                    </label>

                    <div className="form-line">
                      <input
                        id="txtCity"
                        name="currentAddress.address.city"
                        className="form-control js-bank-address-change"
                        placeholder={translate(
                          "bank.bankAddressCityPlaceHolder",
                        )}
                        type="text"
                      />
                    </div>

                    {this.addressValidator.message(
                      "currentAddress.address.city",
                      this.state.currentAddress.address.city,
                      "required",
                      false,
                      { default: translate("bank.bankAddressCityRequired") },
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="input-group">
                    <label htmlFor="txtComplement">
                      {translate("bank.bankAddressComplement")}
                    </label>

                    <div className="form-line">
                      <input
                        id="txtComplement"
                        name="currentAddress.address.complement"
                        className="form-control js-bank-address-change"
                        placeholder={translate(
                          "bank.bankAddressComplementPlaceHolder",
                        )}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-md-6">
                  <div className="m-b-20">
                    <label
                      className="validationRequired"
                      htmlFor="cmbAddressCountry"
                    >
                      {translate("bank.bankAddressCountry")}
                    </label>

                    <RCSelect
                      id="cmbAddressCountry"
                      name="currentAddress.address.countryId"
                      hasEmptyOption={true}
                      isInt={true}
                      options={this.state.countries}
                      optionValue="id"
                      optionLabel="name"
                      placeholder={translate(
                        "bank.bankAddressCountryPlaceHolder",
                      )}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      value={this.state.currentAddress.address.countryId}
                    />

                    {this.addressValidator.message(
                      "currentAddress.address.countryId",
                      this.state.currentAddress.address.countryId,
                      "gt: 0",
                      false,
                      { default: translate("bank.bankAddressCountryRequired") },
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="m-b-20">
                    <label
                      htmlFor="cmbAddressState"
                      className={
                        this.state.addressStates &&
                        this.state.addressStates.length > 0
                          ? "validationRequired"
                          : ""
                      }
                    >
                      {translate("bank.bankAddressState")}
                    </label>

                    <div
                      className="preloader pl-size-xs form-preloader"
                      style={{
                        display: this.state.addressStatesLoading
                          ? "inline-block"
                          : "none",
                      }}
                    >
                      <div className="spinner-layer">
                        <div className="circle-clipper left">
                          <div className="circle"></div>
                        </div>
                      </div>
                    </div>

                    <RCSelect
                      id="cmbAddressState"
                      name="currentAddress.address.stateId"
                      hasEmptyOption={true}
                      isInt={true}
                      options={
                        this.state.addressStates != null
                          ? this.state.addressStates
                          : this.state.states
                      }
                      optionValue="id"
                      optionLabel="displayName"
                      placeholder={translate(
                        "bank.bankAddressStatePlaceHolder",
                      )}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                      value={this.state.currentAddress.address.stateId}
                    />

                    {this.addressValidator.message(
                      "cmbAddressState",
                      this.state.currentAddress.address.stateId,
                      "gt: 0",
                      false,
                      { default: translate("bank.bankAddressStateRequired") },
                    )}
                  </div>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn m-r-10"
                    onClick={this.resetAddress}
                    style={{
                      display:
                        this.state.currentAddress.rowIndex >= 0
                          ? "inline-block"
                          : "none",
                    }}
                  >
                    <i className="material-icons">&#xe5c4;</i>
                    <span>{translate("forms.buttonCancel")}</span>
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.saveAddress}
                  >
                    <i className="material-icons">&#xe145;</i>
                    <span>
                      {translate(
                        this.state.currentAddress.rowIndex < 0
                          ? "bank.bankAddressesAddNew"
                          : "bank.bankAddressesUpdate",
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <ReactTable
          data={this.state.addressList}
          columns={[
            {
              columns: [
                {
                  Header: translate("bank.bankAddressName"),
                  accessor: "name",
                },
                {
                  Header: translate("bank.bankAddressState"),
                  accessor: "stateName",
                },
                {
                  Header: translate("bank.bankAddressCity"),
                  accessor: "city",
                },
                {
                  Header: translate("bank.bankAddressDistrict"),
                  accessor: "district",
                },
                {
                  Header: translate("bank.bankAddressIsMaster"),
                  Cell: (row) => {
                    return (
                      <div className="align-center">
                        {row.original.isMaster && (
                          <i className="material-icons color-success">
                            &#xe86c;
                          </i>
                        )}
                        {!row.original.isMaster && (
                          <i className="material-icons color-danger">
                            &#xe5c9;
                          </i>
                        )}
                      </div>
                    );
                  },
                  maxWidth: 100,
                },
                session.auth([{ type: "Bank", value: "Save" }]) && {
                  Cell: (row) => (
                    <div className="align-center">
                      <button
                        className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10"
                        type="button"
                        disabled={this.state.currentAddress.rowIndex >= 0}
                        onClick={() => {
                          this.deleteAddress(row.index);
                        }}
                      >
                        <i className="material-icons">&#xe872;</i>
                      </button>

                      <button
                        type="button"
                        className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10"
                        disabled={this.state.currentAddress.rowIndex >= 0}
                        onClick={() => {
                          this.editAddress(row.index);
                        }}
                      >
                        <i className="material-icons">&#xe3c9;</i>
                      </button>
                    </div>
                  ),
                  maxWidth: 150,
                },
              ],
            },
          ]}
          SubComponent={(row) => {
            return (
              <div className="m-l-30 m-r-30 m-t-30">
                <div className="row">
                  <div className="col-md-3">
                    <p>
                      {translate("bank.bankAddressCountry")}:
                      <b> {row.original.countryName}</b>
                    </p>
                  </div>

                  <div className="col-md-4">
                    <p>
                      {translate("bank.bankAddressStreet")}:
                      <b> {row.original.street}</b>
                    </p>
                  </div>

                  <div className="col-md-5">
                    <p>
                      {translate("bank.bankAddressComplement")}:
                      <b> {row.original.complement}</b>
                    </p>
                  </div>

                  <div className="col-md-3">
                    <p>
                      {translate("bank.bankAddressZipCode")}:
                      <b> {row.original.zipCode}</b>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      {translate("bank.bankAddressNumber")}:
                      <b> {row.original.number}</b>
                    </p>
                  </div>
                </div>
              </div>
            );
          }}
          defaultPageSize={10}
          className="-striped -highlight m-b-40"
          previousText={translate("forms.previousText")}
          nextText={translate("forms.nextText")}
          noDataText={translate("forms.noDataText")}
          pageText={translate("forms.pageText")}
          ofText={translate("forms.ofText")}
          rowsText={translate("forms.rowsText")}
        />
      </div>
    );
  }
}
