import $ from "jquery";
import React from "react";
import { translate } from "react-internationalization";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import * as session from "../components/SessionValidator";
import * as enums from "../components/enums/user";
import RCSelect from "../components/select";
import { cpf_cnpj_Mask } from "../components/mask";
import { cnpj, cpf } from "../components/cpf-cnpj-validator";
import RCDatePicker from "../components/datePicker";
import { toast, ToastContainer } from "react-toastify";
import ParticipantComponent from "../components/contract/participantComponent";
import PaymentArrangementComponent from "../components/contract/paymentArrangementComponent";
import { SUPPLIER_SERVICE_URL } from "../settings";
import moment from "moment";
import { getRequest, postRequest } from "../service/RequestService";

class OptInPage extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.setValidators();

    this.setBinds();

    this.state = {
      protocol: this.props.match.params.id,

      participantId: 0,
      participantGrid: [],
      optInObjct: {
        id: 0,
        documentFinancier: "",
        documentFinancierType: 0,
        urStartDate: moment().format("YYYY-MM-DD"),
        urEndDate: moment().add(3, "M").format("YYYY-MM-DD"),
        urDocumentFinalUserReceiver: "",
        urDocumentFinalUserReceiverType: 0,
        status: 1,
        optInParticipant: [],
        optInPaymentArrangement: [],
      },
      selectAll: 0,
      selectedPayments: null,
      loading: false,
      loadingSubimit: false,
    };
  }

  setBinds() {
    this.handleChange = this.handleChange.bind(this);
    this.handlechangeMask = this.handlechangeMask.bind(this);
    this.handleAddParticipant = this.handleAddParticipant.bind(this);
    this.deleteParticipant = this.deleteParticipant.bind(this);
    this.resultPayments = this.resultPayments.bind(this);
  }

  setValidators() {
    this.validator = new SimpleReactValidator({
      documentType: {
        rule: (value) => {
          return ["", null, undefined].indexOf(value) < 0;
        },
      },

      documentFinancier: {
        rule: (value) => {
          let documentType = this.state.optInObjct.documentFinancierType;

          if (documentType == 0) {
            //cnpj
            return cnpj.isValid(value);
          } else if (documentType == 1) {
            //cpf
            return cpf.isValid(value);
          } else {
            return false;
          }
        },
      },

      documentFinalUser: {
        rule: (value) => {
          let documentType =
            this.state.optInObjct.urDocumentFinalUserReceiverType;

          if (documentType == 0) {
            //cnpj
            return cnpj.isValid(value);
          } else if (documentType == 1) {
            //cpf
            return cpf.isValid(value);
          } else {
            return false;
          }
        },
      },
    });
  }

  async componentDidMount() {
    var _this = this;

    $("body")
      .off("change", ".js-change")
      .on("change", ".js-change", function (event) {
        _this.handleChange(event);
      });

    this.loadData();
  }

  loadData() {
    this.setState({
      loading: true,
    });

    if (this.state.protocol) {
      var optInObjct = this.state.optInObjct;
      var participantGrid = this.state.participantGrid;
      var optInParticipant = {};

      getRequest(
        SUPPLIER_SERVICE_URL +
          "/api/OptIn/GetByProtocol/" +
          this.state.protocol,
      )
        .then((res) => {
          switch (res.code) {
            case 200:
              optInObjct = res.data;

              optInParticipant = optInObjct.optInParticipant;

              var len = optInObjct.optInParticipant.length;

              for (var i = 0; i < len; i++) {
                participantGrid.push(optInParticipant[i].participant);
              }

              var selectedPayments = optInObjct.optInPaymentArrangement;

              optInObjct.optInPaymentArrangement = [];

              this.setState({
                optInObjct: optInObjct,
                selectAll: 0,
                participantGrid: participantGrid,
                selectedPayments: selectedPayments,
                loading: false,
              });

              this.forceUpdate();
              break;

            case 400:
            case 409:
              var messages = res.data;
              messages.forEach((ex) => toast.warn(ex.message));
              break;

            case 500:
              toast.error("error");
              break;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      this.setState({
        selectedPayments: [],
        loading: false,
      });
    }
  }

  handlechangeMask(event) {
    event.target.value = cpf_cnpj_Mask(event.target.value);

    this.handleChange(event);
  }

  async handleChange(event) {
    session.validateSession();

    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    this.setState(this.state);
  }

  handleSubmit() {
    this.setState({
      loadingSubimit: true,
    });

    var optIn = this.state.optInObjct;

    var selectedPayments = this.state.selectedPayments;

    for (var i = 0; i < selectedPayments.length; i++) {
      var optInPaymentArrangement = {
        id: 0,
        optInId: this.state.optInObjct.id,
        paymentArrangementId: selectedPayments[i].id,
      };
      optIn.optInPaymentArrangement.push(optInPaymentArrangement);
    }

    var method = this.id ? "Update" : "Create";

    postRequest(SUPPLIER_SERVICE_URL + "/api/OptIn/" + method, optIn)
      .then((res) => {
        switch (res.code) {
          case 200:
            this.resetOptIn();

            this.setState({ loadingSubimit: false });

            this.forceUpdate();

            this.props.history.push("/optInList/");
            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("error");
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  resetOptIn() {
    this.state.optInObjct = {
      id: 0,
      documentFinancier: "",
      urDocumentFinalUserReceiver: "",
    };
  }

  saveOptIn() {
    if (this.state.optInObjct.optInParticipant.length <= 0) {
      toast.error(
        <ToastRedirect
          message="é necessário ter uma ou mais Crecendiadoras"
          tabToShow={$("#addParticipant")}
        />,
      );
      $("#participant-tab-link").addClass("invalid");
      return;
    }
    if (this.state.selectedPayments.length <= 0) {
      toast.error(
        <ToastRedirect
          message="é necessário ter um ou mais arranjos de pagamento"
          tabToShow={$("#addPaymentArrangement")}
        />,
      );
      $("#paymentArrangement-tab-link").addClass("invalid");
      return;
    }

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();

      return;
    }
    this.handleSubmit();
  }

  handleAddParticipant(participantSelected) {
    var list = this.state.optInObjct.optInParticipant;

    var optInParticipant = {
      id: 0,
      optInId: this.state.optInObjct.id,
      participantId: participantSelected.id,
    };

    list.push(optInParticipant);

    this.setState({
      optInParticipant: list,
    });
  }

  deleteParticipant(rowIndex) {
    var list = this.state.optInObjct.optInParticipant;

    list.splice(rowIndex, 1);

    this.setState({
      optInParticipant: list,
    });
  }

  resultPayments(selectedPayments) {
    this.setState({
      selectedPayments: selectedPayments,
    });
  }

  render() {
    var fetched = this.state != null && !this.state.loading;

    if (fetched) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <div className="card">
              <div className="header">
                <h2>
                  <ul className="nav nav-tabs tab-nav-right" role="tablist">
                    <li className="active" role="presentation">
                      <a href="#addOptIn" data-toggle="tab">
                        {translate("optIn.optInAddNew")}
                      </a>
                    </li>
                    <li role="presentation">
                      <a
                        id="participant-tab-link"
                        href="#addParticipant"
                        data-toggle="tab"
                      >
                        {translate("optIn.optInAcquirer")}
                      </a>
                    </li>
                    <li role="presentation">
                      <a
                        id="paymentArrangement-tab-link"
                        href="#addPaymentArrangement"
                        data-toggle="tab"
                      >
                        {translate("optIn.optInPaymentArrangement")}
                      </a>
                    </li>
                  </ul>
                </h2>
                <div className="header-dropdown">
                  <div
                    className="preloader pl-size-xs"
                    style={{ display: this.state.loading ? "block" : "none" }}
                  >
                    <div className="spinner-layer">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>
                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="body">
                <div className="tab-content">
                  <div
                    id="addOptIn"
                    className="tab-pane fade active in"
                    role="tabpanel"
                  >
                    <div className="row clearfix">
                      <div className="col-md-3">
                        <div className="m-b-20">
                          <label
                            htmlFor="cmbDocumentType"
                            className="validationRequired"
                          >
                            {translate("supplier.supplierDocumentType")}
                          </label>

                          <RCSelect
                            id="cmbDocumentFinancierType"
                            name="optInObjct.documentFinancierType"
                            hasEmptyOption={true}
                            isInt={true}
                            options={enums.getDocumentType()}
                            optionValue="index"
                            optionLabel="text"
                            placeholder={translate(
                              "supplier.supplierDocumentTypePlaceHolder",
                            )}
                            onChange={(event) => {
                              this.handleChange(event);
                            }}
                            value={this.state.optInObjct.documentFinancierType}
                          />

                          {this.validator.message(
                            "optInObjct.documentFinancierType",
                            this.state.optInObjct.documentFinancierType,
                            "required|documentType",
                            false,
                            {
                              default: translate(
                                "supplier.supplierDocumentTypeRequired",
                              ),
                            },
                          )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="input-group">
                          <label
                            className="validationRequired"
                            htmlFor="txtDocumentFinancier"
                          >
                            {translate("optIn.optInFinancierCNPJ")}
                          </label>
                          <div className="form-line">
                            <input
                              id="txtDocumentFinancier"
                              name="optInObjct.documentFinancier"
                              maxLength="18"
                              className="form-control js-change"
                              value={this.state.optInObjct.documentFinancier}
                              onChange={this.handlechangeMask}
                              autoComplete="off"
                              placeholder="CNPJ"
                              type="text"
                            />

                            {this.validator.message(
                              "optInObjct.documentFinancier",
                              this.state.optInObjct.documentFinancier,
                              "required|documentFinancier",
                              false,
                              {
                                default: translate(
                                  "supplier.supplierDocumentRequired",
                                ),
                                document:
                                  this.state.optInObjct
                                    .documentFinancierType === 0
                                    ? translate("supplier.supplierCNPJInvalid")
                                    : translate("supplier.supplierCPFInvalid"),
                              },
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="input-group">
                          <label htmlFor="dturStartDate">
                            {translate("documents.documentStartDate")}
                          </label>

                          <div className="form-line">
                            <RCDatePicker
                              id="dturStartDate"
                              name="optInObjct.urStartDate"
                              value={this.state.optInObjct.urStartDate}
                              onChange={this.handleChange}
                              disabled={this.state.protocol}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="input-group">
                          <label htmlFor="dturEndDate">
                            {translate("documents.documentEndDate")}
                          </label>

                          <div className="form-line">
                            <RCDatePicker
                              id="dturEndDate"
                              name="optInObjct.urEndDate"
                              value={this.state.optInObjct.urEndDate}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <div className="col-md-3">
                        <div className="m-b-20">
                          <label
                            htmlFor="cmbDocumentType"
                            className="validationRequired"
                          >
                            {translate("supplier.supplierDocumentType")}
                          </label>
                          <RCSelect
                            id="cmbDocumentType2"
                            name="optInObjct.urDocumentFinalUserReceiverType"
                            hasEmptyOption={true}
                            isInt={true}
                            options={enums.getDocumentType()}
                            optionValue="index"
                            optionLabel="text"
                            placeholder={translate(
                              "supplier.supplierDocumentTypePlaceHolder",
                            )}
                            onChange={(event) => {
                              this.handleChange(event);
                            }}
                            value={
                              this.state.optInObjct
                                .urDocumentFinalUserReceiverType
                            }
                          />
                          {this.validator.message(
                            "optInObjct.urDocumentFinalUserReceiverType",
                            this.state.optInObjct
                              .urDocumentFinalUserReceiverType,
                            "required|documentType",
                            false,
                            {
                              default: translate(
                                "supplier.supplierDocumentTypeRequired",
                              ),
                            },
                          )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="input-group">
                          <label
                            htmlFor="txtDocument"
                            className="validationRequired"
                          >
                            {translate("optIn.optInFinalUserCNPJ")}
                          </label>
                          <div className="form-line">
                            <input
                              id="txtDocument"
                              name="optInObjct.urDocumentFinalUserReceiver"
                              maxLength="18"
                              value={
                                this.state.optInObjct
                                  .urDocumentFinalUserReceiver
                              }
                              onChange={this.handlechangeMask}
                              className="form-control js-change"
                              autoComplete="off"
                              placeholder={translate(
                                "supplier.supplierDocumentPlaceHolder",
                              )}
                              type="text"
                              disabled={this.state.protocol}
                            />

                            {this.validator.message(
                              "optInObjct.urDocumentFinalUserReceiver",
                              this.state.optInObjct.urDocumentFinalUserReceiver,
                              "required|documentFinalUser",
                              false,
                              {
                                default: translate(
                                  "supplier.supplierDocumentRequired",
                                ),
                                document:
                                  this.state.optInObjct
                                    .urDocumentFinalUserReceiverType === 0
                                    ? translate("supplier.supplierCNPJInvalid")
                                    : translate("supplier.supplierCPFInvalid"),
                              },
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="addParticipant"
                    className="tab-pane fade in"
                    role="tabpanel"
                  >
                    {!this.state.loading && (
                      <ParticipantComponent
                        handleAddParticipant={(event) => {
                          this.handleAddParticipant(event);
                        }}
                        deleteParticipant={(event) => {
                          this.deleteParticipant(event);
                        }}
                        participantGrid={
                          this.state.participantGrid
                            ? this.state.participantGrid
                            : []
                        }
                      />
                    )}
                  </div>

                  <div
                    id="addPaymentArrangement"
                    className="tab-pane fade in"
                    role="tabpanel"
                  >
                    {this.state.selectedPayments != null &&
                      !this.state.loading && (
                        <PaymentArrangementComponent
                          selectedPayments={this.state.selectedPayments}
                          resultPayments={(event) => {
                            this.resultPayments(event);
                          }}
                        />
                      )}
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-12 formActions">
                    <Link
                      to="/optInList"
                      className="btn btn-default waves-effect"
                    >
                      <i className="material-icons">&#xe5c4;</i>
                      <span>{translate("forms.buttonReturn")}</span>
                    </Link>

                    <button
                      type="button"
                      className="btn btn-success waves-effect"
                      onClick={() => {
                        this.saveOptIn();
                      }}
                      disabled={this.state.loading}
                    >
                      <i className="material-icons">&#xe161;</i>
                      <span>{translate("optIn.optInSave")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer hideProgressBar />
        </div>
      );
    } else {
      return (
        <div className="preloader pl-size-lg align-center">
          <div className="spinner-layer">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>

            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const ToastRedirect = ({ tabToShow, message, closeToast }) => {
  function handleClick() {
    tabToShow.trigger("click");
    closeToast();
  }

  return <div onClick={handleClick}>{message}</div>;
};
export default OptInPage;
