import $ from "jquery";
import React from "react";
import { translate } from "react-internationalization";
import SimpleReactValidator from "simple-react-validator";
import { cnpj, cpf } from "../../components/cpf-cnpj-validator";
import RCDatePicker from "../../components/datePicker";
import * as enums from "../../components/enums/contract";
import * as enumsUser from "../../components/enums/user";
import { cpf_cnpj_Mask } from "../../components/mask";
import RCSelect from "../../components/select";
import * as session from "../../components/SessionValidator";
import ModalContractFilter from "../../components/contract/modal-contractFilter";
import { toast } from "react-toastify";
import ReactTable from "react-table";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";
import { TRANSACTION_SERVICE_URL } from "../../settings";
import moment from "moment";
import { getRequest, postRequest } from "../../service/RequestService";

class ContractPage extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.setValidators();
    this.setBinds();

    this.state = {
      openFilter: false,
      contractFilterId: null,

      cardContract: {
        id: 0,
        typeEffect: 0,
        typeService: 0,
        modality: 0,
        recovery: false,
        unconditionalAcceptance: 0,
        rate: 0,
        indexer: 0,
        walletCode: "",
        signatureDate: moment().format("YYYY-MM-DD"),
        dueDate: moment().format("YYYY-MM-DD"),
        documentContractor: "",
        documentContractorType: 0,
        documentHolder: "",
        documentHolderType: 0,
        balanceDue: 0,
        guaranteedOperationLimit: 0,
        minimumAmount: 0,
        contractFilter: [],
      },

      loading: false,
    };
  }

  setBinds() {
    this.handleChange = this.handleChange.bind(this);
    this.handlechangeMask = this.handlechangeMask.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteContractFilter =
      this.handleDeleteContractFilter.bind(this);
    this.editContractFilter = this.editContractFilter.bind(this);
  }

  setValue(element) {
    let keys = element.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      property = property[key];
      if (Array.isArray(property)) property = property[element.dataset.index];
    });

    $(element).val(property);
  }

  setValidators() {
    this.validator = new SimpleReactValidator({
      documentType: {
        rule: (value) => {
          return ["", null, undefined].indexOf(value) < 0;
        },
      },

      documentContractor: {
        rule: (value) => {
          let documentType = this.state.cardContract.documentContractorType;

          if (documentType == 0) {
            //cnpj
            return cnpj.isValid(value);
          } else if (documentType == 1) {
            //cpf
            return cpf.isValid(value);
          } else {
            return false;
          }
        },
      },

      documentHolder: {
        rule: (value) => {
          let documentType1 = this.state.cardContract.documentHolderType;

          if (documentType1 == 0) {
            //cnpj
            return cnpj.isValid(value);
          } else if (documentType1 == 1) {
            //cpf
            return cpf.isValid(value);
          } else {
            return false;
          }
        },
      },
    });
  }

  async componentDidMount() {
    var _this = this;

    $("body")
      .off("change", ".js-change")
      .on("change", ".js-change", function (event) {
        _this.handleChange(event);
      });
    $(".js-decimal").inputmask("decimal", {
      digits: 2,
      digitsOptional: false,
      max: 999999999,
      placeholder: "0.00",
      rightAlign: false,
      showMaskOnHover: false,
    });
    $(".js-change").each(function () {
      _this.setValue(this);
    });

    this.loadData();
  }

  handlechangeMask(event) {
    event.target.value = cpf_cnpj_Mask(event.target.value);

    this.handleChange(event);
  }

  async handleChange(event) {
    session.validateSession();

    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    this.setState(this.state);
  }

  handleChangeOpenModal() {
    this.setState({
      openFilter: true,
      contractFilterId: 0,
    });

    this.forceUpdate();
  }

  handleReturn() {
    this.setState({
      openFilter: false,
    });
    this.forceUpdate();
  }

  addContractFilter(contractFilter) {
    this.state.cardContract.contractFilter.push(contractFilter);
    this.forceUpdate();
  }

  saveContract() {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();

      return;
    }
    this.handleSubmit();
  }

  handleSubmit() {
    this.setState({
      loading: true,
    });

    var cardContract = this.state.cardContract;

    var method = this.id ? "Update" : "Create";

    postRequest(
      TRANSACTION_SERVICE_URL + "/api/CardContract/" + method,
      cardContract,
    )
      .then((res) => {
        switch (res.code) {
          case 200:
            this.resetCardContract();

            this.setState({ loading: false });

            this.forceUpdate();

            this.props.history.push("/contracts/");
            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("error");
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  resetCardContract() {
    this.state.cardContract = {
      typeEffect: 0,
      typeService: 0,
      modality: 0,
      recovery: false,
      unconditionalAcceptance: 0,
      rate: 0,
      indexer: 0,
      walletCode: "",
      signatureDate: "",
      dueDate: "",
      documentContractor: "",
      documentContractorType: 0,
      documentHolder: "",
      documentHolderType: 0,
      balanceDue: 0,
      guaranteedOperationLimit: 0,
      minimumAmount: 0,
      createDate: "",
    };
  }

  loadData() {
    if (this.id) {
      this.setState({
        loading: true,
      });

      var cardContract = this.state.cardContract;

      getRequest(TRANSACTION_SERVICE_URL + "/api/CardContract/" + this.id)
        .then((res) => {
          switch (res.code) {
            case 200:
              cardContract = res.data;

              this.setState({
                cardContract: cardContract,
                loading: false,
              });

              this.forceUpdate();
              break;

            case 400:
            case 409:
              var messages = res.data;
              messages.forEach((ex) => toast.warn(ex.message));
              break;

            case 500:
              toast.error("error");
              break;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  deleteContractFilter(rowIndex) {
    confirmAlert({
      buttons: [
        {
          label: translate("forms.buttonYes"),
          onClick: () => {
            let contractFilter = JSON.parse(
              JSON.stringify(this.state.cardContract.contractFilter[rowIndex]),
            );

            var contractFilterId = contractFilter.id;

            this.handleDeleteContractFilter(contractFilterId, rowIndex);
          },
        },
        {
          label: translate("forms.buttonNo"),
          onClick: () => {
            window.close();
          },
        },
      ],
      message: translate("optIn.optInDeleteTitle"),
      title: translate("optIn.optInDeleteQuestion"),
    });
  }

  handleDeleteContractFilter(contractFilterId, rowIndex) {
    var status = 0;

    this.setState({ loading: true });

    getRequest(
      TRANSACTION_SERVICE_URL +
        "/api/contractFilter/Delete/" +
        contractFilterId,
    ).then((res) => {
      this.setState({ loading: false });
      switch (res.code) {
        case 200:
          var items = this.state.cardContract.contractFilter;
          items.splice(rowIndex, 1);

          this.setState({
            contractFilter: items,
          });

          this.forceUpdate();

          break;

        case 400:
        case 409:
          var messages = res.data;
          messages.forEach((ex) => toast.warn(ex.message));
          break;

        case 500:
          toast.error("error");
          break;
      }
    });
  }

  editContractFilter(rowIndex) {
    let contractFilter = JSON.parse(
      JSON.stringify(this.state.cardContract.contractFilter[rowIndex]),
    );
    let contractFilterId = contractFilter.id;

    this.setState({
      openFilter: true,
      contractFilterId: contractFilterId,
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="card">
            <div>
              <div className="header">
                <h2>
                  {translate("contract.contractTitle")}
                  <small>{translate("contract.contractAddNew")}</small>
                </h2>
                <div className="header-dropdown">
                  <div
                    className="preloader pl-size-xs"
                    style={{ display: this.state.loading ? "block" : "none" }}
                  >
                    <div className="spinner-layer">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>

                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="body">
                <div className="row clearfix">
                  <div className="col-md-3">
                    <div className="m-b-20">
                      <label
                        htmlFor="cmbEffectType"
                        className="validationRequired"
                      >
                        {translate("contract.contractEffectType")}
                      </label>

                      <RCSelect
                        id="cmbEffectType"
                        name="cardContract.typeEffect"
                        hasEmptyOption={true}
                        isInt={true}
                        options={enums.getTypeEffect()}
                        optionValue="index"
                        optionLabel="text"
                        placeholder={translate(
                          "contract.contractEffectTypePlaceHolder",
                        )}
                        onChange={(event) => {
                          this.handleChange(event);
                        }}
                        value={this.state.cardContract.typeEffect}
                      />

                      {this.validator.message(
                        "cardContract.typeEffect",
                        this.state.cardContract.typeEffect,
                        "required",
                        false,
                        {
                          default: translate(
                            "contract.contractEffectTypeRequired",
                          ),
                        },
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="m-b-20">
                      <label
                        htmlFor="cmbServiceType"
                        className="validationRequired"
                      >
                        {translate("contract.contractServiceType")}
                      </label>

                      <RCSelect
                        id="cmbServiceType"
                        name="cardContract.typeService"
                        hasEmptyOption={true}
                        isInt={true}
                        options={enums.getTypeService()}
                        optionValue="index"
                        optionLabel="text"
                        placeholder={translate(
                          "contract.contractServiceTypePlaceHolder",
                        )}
                        onChange={(event) => {
                          this.handleChange(event);
                        }}
                        value={this.state.cardContract.typeService}
                      />

                      {this.validator.message(
                        "cardContract.typeService",
                        this.state.cardContract.typeService,
                        "required",
                        false,
                        {
                          default: translate(
                            "contract.contractServiceTypeRequired",
                          ),
                        },
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="m-b-20">
                      <label
                        htmlFor="cmbModality"
                        className="validationRequired"
                      >
                        {translate("contract.contractModality")}
                      </label>

                      <RCSelect
                        id="cmbModality"
                        name="cardContract.modality"
                        hasEmptyOption={true}
                        isInt={true}
                        options={enums.getModality()}
                        optionValue="index"
                        optionLabel="text"
                        placeholder={translate(
                          "contract.contractModalityPlaceHolder",
                        )}
                        onChange={(event) => {
                          this.handleChange(event);
                        }}
                        value={this.state.cardContract.modality}
                      />

                      {this.validator.message(
                        "cardContract.modality",
                        this.state.cardContract.modality,
                        "required",
                        false,
                        {
                          default: translate(
                            "contract.contractModalityRequired",
                          ),
                        },
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="m-b-20">
                      <label
                        htmlFor="cmbRenegotiation"
                        className="validationRequired"
                      >
                        {translate("contract.contractRenegotiation")}
                      </label>

                      <RCSelect
                        id="cmbRenegotiation"
                        name="cardContract.recovery"
                        hasEmptyOption={true}
                        isInt={true}
                        options={enums.getRecovery()}
                        optionValue="index"
                        optionLabel="text"
                        placeholder={translate(
                          "contract.contractRenegotiationPlaceHolder",
                        )}
                        onChange={(event) => {
                          this.handleChange(event);
                        }}
                        value={this.state.cardContract.recovery}
                      />

                      {this.validator.message(
                        "cardContract.recovery",
                        this.state.cardContract.recovery,
                        "required",
                        false,
                        {
                          default: translate(
                            "contract.contractRenegotiationRequired",
                          ),
                        },
                      )}
                    </div>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-4">
                    <div className="m-b-20">
                      <label
                        htmlFor="cmbAcceptOperation"
                        className="validationRequired"
                      >
                        {translate("contract.contractCmbQuestion")}
                      </label>

                      <RCSelect
                        id="cmbAcceptOperation"
                        name="cardContract.unconditionalAcceptance"
                        hasEmptyOption={true}
                        isInt={true}
                        options={enums.getAccept()}
                        optionValue="index"
                        optionLabel="text"
                        placeholder={translate(
                          "contract.contractCmbQuestionPlaceHolder",
                        )}
                        onChange={(event) => {
                          this.handleChange(event);
                        }}
                        value={this.state.cardContract.unconditionalAcceptance}
                      />
                      {this.validator.message(
                        "cardContract.unconditionalAcceptance",
                        this.state.cardContract.unconditionalAcceptance,
                        "required",
                        false,
                        {
                          default: translate(
                            "contract.contractRenegotiationRequired",
                          ),
                        },
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="m-b-20">
                      <label
                        htmlFor="cmbIndexer"
                        className="validationRequired"
                      >
                        {translate("contract.contractIndexer")}
                      </label>

                      <RCSelect
                        id="cmbIndexer"
                        name="cardContract.indexer"
                        hasEmptyOption={true}
                        isInt={true}
                        options={enums.getIndexer()}
                        optionValue="index"
                        optionLabel="text"
                        placeholder={translate(
                          "contract.contractIndexerPlaceHolder",
                        )}
                        onChange={(event) => {
                          this.handleChange(event);
                        }}
                        value={this.state.cardContract.indexer}
                      />

                      {this.validator.message(
                        "cardContract.indexer",
                        this.state.cardContract.indexer,
                        "required",
                        false,
                        {
                          default: translate(
                            "contract.contractIndexerRequired",
                          ),
                        },
                      )}
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="input-group">
                      <label htmlFor="dtSignDate">
                        {translate("contract.contractSignDate")}
                      </label>
                      <div className="form-line">
                        <RCDatePicker
                          id="dtSignDate"
                          name="cardContract.signatureDate"
                          value={this.state.cardContract.signatureDate}
                          onChange={this.handleChange}
                        />
                      </div>

                      {this.validator.message(
                        "cardContract.signatureDate",
                        this.state.cardContract.signatureDate,
                        "required",
                        false,
                        {
                          default: translate(
                            "contract.contractSignDateRequired",
                          ),
                        },
                      )}
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="input-group">
                      <label htmlFor="dtcontractDueDate">
                        {translate("contract.contractDueDate")}
                      </label>

                      <div className="form-line">
                        <RCDatePicker
                          id="dtcontractDueDate"
                          name="cardContract.dueDate"
                          value={this.state.cardContract.dueDate}
                          onChange={this.handleChange}
                        />
                      </div>

                      {this.validator.message(
                        "cardContract.dueDate",
                        this.state.cardContract.dueDate,
                        "required",
                        false,
                        {
                          default: translate(
                            "contract.contractDueDateRequired",
                          ),
                        },
                      )}
                    </div>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-md-2">
                    <div className="m-b-20">
                      <label
                        htmlFor="cmbDocumentType"
                        className="validationRequired"
                      >
                        {translate("contract.contractDocumentType")}
                      </label>

                      <RCSelect
                        id="cmbDocumentType2"
                        name="cardContract.documentContractorType"
                        hasEmptyOption={true}
                        isInt={true}
                        options={enumsUser.getDocumentType()}
                        optionValue="index"
                        optionLabel="text"
                        placeholder={translate(
                          "contract.contractDocumentTypePlaceHolder",
                        )}
                        onChange={(event) => {
                          this.handleChange(event);
                        }}
                        value={this.state.cardContract.documentContractorType}
                      />

                      {this.validator.message(
                        "cardContract.documentContractorType",
                        this.state.cardContract.documentContractorType,
                        "required|documentType",
                        false,
                        {
                          default: translate(
                            "contract.contractDocumentTypeRequired",
                          ),
                        },
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="m-b-5">
                      <label htmlFor="txtContractorDocument">
                        {translate("contract.contractContractorDocument")}
                      </label>

                      <div className="input-group">
                        <div className="form-line">
                          <input
                            id="txtContractorDocument"
                            name="cardContract.documentContractor"
                            maxLength="18"
                            className="form-control js-change"
                            value={this.state.cardContract.documentContractor}
                            onChange={this.handlechangeMask}
                            autoComplete="off"
                            placeholder={translate(
                              "contract.contractContractorDocumentPlaceHolder",
                            )}
                            type="text"
                          />
                        </div>

                        {this.validator.message(
                          "cardContract.documentContractor",
                          this.state.cardContract.documentContractor,
                          "required|documentContractor",
                          false,
                          {
                            default: translate(
                              "contract.contractDocumentRequired",
                            ),
                          },
                          {
                            default: translate(
                              "contract.contractDocumentInvalid",
                            ),
                          },
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="m-b-20">
                      <label
                        htmlFor="cmbDocumentType"
                        className="validationRequired"
                      >
                        {translate("contract.contractDocumentType")}
                      </label>

                      <RCSelect
                        id="cmbDocumentType2"
                        name="cardContract.documentHolderType"
                        hasEmptyOption={true}
                        isInt={true}
                        options={enumsUser.getDocumentType()}
                        optionValue="index"
                        optionLabel="text"
                        placeholder={translate(
                          "contract.contractDocumentTypePlaceHolder",
                        )}
                        onChange={(event) => {
                          this.handleChange(event);
                        }}
                        value={this.state.cardContract.documentHolderType}
                      />
                      {this.validator.message(
                        "cardContract.documentHolderType",
                        this.state.cardContract.documentHolderType,
                        "required|documentType",
                        false,
                        {
                          default: translate(
                            "contract.contractDocumentTypeRequired",
                          ),
                        },
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="m-b-5">
                      <label htmlFor="txtContractHoldDocument">
                        {translate("contract.contractHoldDocument")}
                      </label>

                      <div className="input-group">
                        <div className="form-line">
                          <input
                            id="txtContractHoldDocument"
                            name="cardContract.documentHolder"
                            maxLength="18"
                            value={this.state.cardContract.documentHolder}
                            onChange={this.handlechangeMask}
                            className="form-control js-change"
                            autoComplete="off"
                            placeholder={translate(
                              "contract.contractHoldDocumentPlaceHolder",
                            )}
                            type="text"
                          />
                        </div>

                        {this.validator.message(
                          "cardContract.documentHolder",
                          this.state.cardContract.documentHolder,
                          "required|documentHolder",
                          false,
                          {
                            default: translate(
                              "contract.contractDocumentRequired",
                            ),
                          },
                          {
                            default: translate(
                              "contract.contractDocumentInvalid",
                            ),
                          },
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-md-4">
                    <h4>{translate("contract.creditInformation")}</h4>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-md-4">
                    <div className="input-group">
                      <label
                        className="validationRequired"
                        htmlFor="txtDebitBalance"
                      >
                        {translate("contract.contractDebitBalance")}
                      </label>
                      <div className="form-line">
                        <input
                          id="txtDebitBalance"
                          maxLength="15"
                          className="form-control js-change js-decimal"
                          name="cardContract.balanceDue"
                          value={this.state.cardContract.balanceDue}
                          placeholder={translate(
                            "contract.contractPlaceHolder",
                          )}
                          type="text"
                        />

                        {this.validator.message(
                          "cardContract.balanceDue",
                          this.state.cardContract.balanceDue,
                          "required",
                          false,
                          {
                            default: translate(
                              "contract.contractDebitBalanceRequired",
                            ),
                          },
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <label
                        className="validationRequired"
                        htmlFor="txtOperationLimit"
                      >
                        {translate("contract.contractGuaranteedOperationLimit")}
                      </label>
                      <div className="form-line">
                        <input
                          id="txtOperationLimit"
                          maxLength="15"
                          className="form-control js-change js-decimal"
                          name="cardContract.guaranteedOperationLimit"
                          value={
                            this.state.cardContract.guaranteedOperationLimit
                          }
                          placeholder={translate(
                            "contract.contractPlaceHolder",
                          )}
                          type="text"
                        />
                      </div>

                      {this.validator.message(
                        "cardContract.guaranteedOperationLimit",
                        this.state.cardContract.guaranteedOperationLimit,
                        "required",
                        false,
                        {
                          default: translate(
                            "contract.contractGuaranteedOperationLimitRequired",
                          ),
                        },
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <label
                        className="validationRequired"
                        htmlFor="txtMinimumAmount"
                      >
                        {translate("contract.contractMinimumAmountToBeKept")}
                      </label>
                      <div className="form-line">
                        <input
                          id="txtMinimumAmount"
                          maxLength="15"
                          className="form-control js-change js-decimal"
                          name="cardContract.minimumAmount"
                          value={this.state.cardContract.minimumAmount}
                          placeholder={translate(
                            "contract.contractPlaceHolder",
                          )}
                          type="text"
                        />
                      </div>

                      {this.validator.message(
                        "cardContract.minimumAmount",
                        this.state.cardContract.minimumAmount,
                        "required",
                        false,
                        {
                          default: translate(
                            "contract.contractMinimumAmountToBeKeptRequired",
                          ),
                        },
                      )}
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-md-3 m-t-20">
                    <button
                      type="button"
                      className="btn btn-block btn-primary right"
                      onClick={() => {
                        this.handleChangeOpenModal();
                      }}
                    >
                      <i className="material-icons">&#xe145;</i>
                      <span>{translate("contractFilter.newFilter")}</span>
                    </button>
                  </div>
                </div>

                {this.state.cardContract.contractFilter && (
                  <ReactTable
                    data={this.state.cardContract.contractFilter}
                    columns={[
                      {
                        columns: [
                          {
                            Header: "ID",
                            id: "id",
                            maxWidth: 60,
                            accessor: (data) => {
                              return <span title={data.id}> {data.id} </span>;
                            },
                          },
                          {
                            Header: translate("contractFilter.filterFinalUser"),
                            id: "documentFinalUserReceiver",
                            width: 200,
                            accessor: (data) => {
                              return (
                                <span title={data.documentFinalUserReceiver}>
                                  {" "}
                                  {data.documentFinalUserReceiver}{" "}
                                </span>
                              );
                            },
                          },
                          {
                            Header: translate("contractFilter.filterHolder"),
                            id: "paymentTitularName",
                            accessor: (data) => {
                              return (
                                <span title={data.paymentTitularName}>
                                  {" "}
                                  {data.paymentTitularName}{" "}
                                </span>
                              );
                            },
                          },
                          {
                            Header: translate(
                              "contractFilter.filterDivisionRule",
                            ),
                            id: "divisionRule",
                            accessor: (data) => {
                              return (
                                <span
                                  title={
                                    enums.getDivisonRule().find((x) => {
                                      return (
                                        x.index === parseInt(data.divisionRules)
                                      );
                                    }).text
                                  }
                                >
                                  {" "}
                                  {
                                    enums.getDivisonRule().find((x) => {
                                      return (
                                        x.index === parseInt(data.divisionRules)
                                      );
                                    }).text
                                  }{" "}
                                </span>
                              );
                            },
                          },
                          {
                            Header: translate(
                              "contractFilter.filterDistributionType",
                            ),
                            id: "distributionType",
                            accessor: (data) => {
                              return (
                                <span
                                  title={
                                    enums.getDistributionType().find((x) => {
                                      return (
                                        x.index ===
                                        parseInt(data.distributionType)
                                      );
                                    }).text
                                  }
                                >
                                  {" "}
                                  {
                                    enums.getDistributionType().find((x) => {
                                      return (
                                        x.index ===
                                        parseInt(data.distributionType)
                                      );
                                    }).text
                                  }{" "}
                                </span>
                              );
                            },
                          },
                          {
                            Header: translate("contractFilter.startDate"),
                            id: "startDate",
                            maxWidth: 140,
                            accessor: (data) => {
                              return moment(
                                data.startDate,
                                "YYYY-MM-DD",
                              ).format("DD-MM-YYYY");
                            },
                          },
                          {
                            Header: translate("contractFilter.endDate"),
                            id: "endDate",
                            maxWidth: 140,
                            accessor: (data) => {
                              return moment(data.endDate, "YYYY-MM-DD").format(
                                "DD-MM-YYYY",
                              );
                            },
                          },
                          {
                            Cell: (row) => (
                              <div className="align-center">
                                <button
                                  className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10"
                                  type="button"
                                  onClick={() => {
                                    this.deleteContractFilter(row.index);
                                  }}
                                  disabled={this.state.loading}
                                >
                                  <i className="material-icons">&#xe872;</i>
                                </button>

                                <button
                                  className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10"
                                  type="button"
                                  onClick={() => {
                                    this.editContractFilter(row.index);
                                  }}
                                  disabled={this.state.loading}
                                >
                                  <i className="material-icons">&#xe3c9;</i>
                                </button>
                              </div>
                            ),
                            maxWidth: 150,
                          },
                        ],
                      },
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight m-b-40"
                    previousText={translate("forms.previousText")}
                    nextText={translate("forms.nextText")}
                    noDataText={translate("forms.noDataText")}
                    pageText={translate("forms.pageText")}
                    ofText={translate("forms.ofText")}
                    rowsText={translate("forms.rowsText")}
                    pages={this.state.tablePageCount}
                    page={this.state.tablePage}
                    pageSize={this.state.tablePageSize}
                    onPageSizeChange={(pageSize, page) =>
                      this.loadNextResults(page, pageSize)
                    }
                    onPageChange={(page) => this.loadNextResults(page)}
                  />
                )}

                <div className="row clearfix">
                  <div className="col-md-12 formActions">
                    <Link
                      to="/contracts"
                      className="btn btn-default waves-effect"
                    >
                      <i className="material-icons">&#xe5c4;</i>
                      <span>{translate("forms.buttonReturn")}</span>
                    </Link>

                    <button
                      type="button"
                      className="btn btn-success waves-effect m-l-10"
                      onClick={() => {
                        this.saveContract();
                      }}
                      disabled={this.state.loading}
                    >
                      <i className="material-icons">&#xe161;</i>
                      <span>{translate("contract.contractSave")}</span>
                    </button>
                  </div>
                </div>

                {this.state.openFilter && (
                  <ModalContractFilter
                    cardContractId={this.id}
                    contractFilterId={this.state.contractFilterId}
                    openFilter={this.state.openFilter}
                    handleReturn={(event) => {
                      this.handleReturn(event);
                    }}
                    addContractFilter={(event) => {
                      this.addContractFilter(event);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContractPage;
