import React from "react";
import Modal from "react-responsive-modal";
import { translate } from "react-internationalization";
import $ from "jquery";
import * as enums from "../enums/user";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import { TRANSACTION_SERVICE_URL } from "../../settings";
import moment from "moment";
import { getRequest } from "../../service/RequestService";

export default class ModalSignDocument extends React.Component {
  constructor(props) {
    super(props);

    this.handleReturn = this.handleReturn.bind(this);
    this.handleSendToken = this.handleSendToken.bind(this);
    this.handleToSign = this.handleToSign.bind(this);
    this.handleStepToken = this.handleStepToken.bind(this);
    this.setValidators();

    this.state = {
      loading: false,
      token: "",
      stepToken: false,
      sendedToken: false,
      stepDone: false,
    };
  }

  async componentDidMount() {
    let _this = this;

    $("body")
      .off("change", ".js-change")
      .on("change", ".js-change", function (event) {
        _this.setValue(event);
      });
  }

  setValidators() {
    this.validator = new SimpleReactValidator({
      token: {
        rule: function (val) {
          return val !== "" && val.length === 8;
        },
      },
    });
  }

  setValue(event) {
    var target = this.state,
      childs = event.target.name.split(".");

    childs.forEach(function (child) {
      if (typeof target[child] === "object") {
        target = target[child];
      } else {
        target[child] =
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value;
      }
    });

    this.setState(this.state);
  }

  handleReturn() {
    this.state.stepToken = false;
    this.forceUpdate();
  }

  handleStepToken() {
    if (this.state.stepToken) {
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();

        return;
      }

      this.handleToSign();
    } else {
      if (this.state.sendedToken) {
        this.state.stepToken = true;
        this.forceUpdate();
      } else this.handleSendToken();
    }
  }

  handleSendToken() {
    this.setState({ loading: true });
    this.forceUpdate();

    getRequest(
      TRANSACTION_SERVICE_URL + "/api/transaction/sendToken/" + this.props.id,
    ).then((res) => {
      this.state.loading = false;
      this.forceUpdate();

      switch (res.code) {
        case 200:
          if (res.data) {
            this.state.sendedToken = true;
            this.state.stepToken = true;
            this.forceUpdate();
          } else toast.error("Erro no envio do token.");

          break;

        case 400:
        case 409:
          var messages = res.data;
          messages.forEach((ex) => toast.warn(ex.message));
          break;

        case 500:
          toast.error("Erro no envio do token.");
          break;
      }
    });
  }

  handleReSendToken() {
    this.setState({ loading: true });
    this.forceUpdate();

    getRequest(
      TRANSACTION_SERVICE_URL + "/api/transaction/sendToken/" + this.props.id,
    ).then((res) => {
      this.state.loading = false;
      this.forceUpdate();

      switch (res.code) {
        case 200:
          if (res.data) {
            toast.success(
              translate("transaction.transactionSignDataTokenSended"),
            );
          } else toast.error("Erro no envio do token.");

          break;

        case 409:
          var messages = res.data;
          messages.forEach((ex) => toast.warn(ex.message));
          break;

        case 500:
          toast.error("Erro no envio do token.");
          break;
      }
    });
  }

  handleToSign() {
    this.setState({ loading: true });
    this.forceUpdate();

    getRequest(
      TRANSACTION_SERVICE_URL +
        "/api/transaction/toSign/" +
        this.props.id +
        "/" +
        this.state.token,
    )
      .then((res) => {
        this.state.loading = false;
        this.forceUpdate();

        switch (res.code) {
          case 200:
            if (res.data) {
              this.props.toSign(res.data);
              this.state.stepDone = true;
              this.forceUpdate();
            } else toast.error("Error.");

            break;

          case 400:
          case 409:
            var messages = res.data;
            messages.forEach((ex) => toast.warn(ex.message));
            break;

          case 500:
            toast.error("error");
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    var fetched = this.state != null && this.props.signer != null;

    if (fetched) {
      return (
        <div>
          <Modal
            open={this.props.openSign}
            closeOnEsc={true}
            onClose={() => {
              this.props.closeSign();
            }}
            onEntered={() => {
              this.forceUpdate();
            }}
            closeIconSize={25}
            center={true}
            styles={{ modal: { width: "600px" } }}
          >
            <div>
              <div style={{ textAlign: "center" }}>
                <h2>
                  {this.state.stepDone
                    ? "Você terminou!"
                    : !this.state.stepToken
                      ? translate("transaction.transactionSignDataTitle")
                      : translate("transaction.transactionSignDataTokenTitle")}
                </h2>
              </div>

              <div
                className="col-md-12"
                style={{
                  display: !this.state.stepDone ? "block" : "none",
                  textAlign: "center",
                }}
              >
                <small>
                  <span>
                    {translate("transaction.transactionSignDataStep")}{" "}
                    {!this.state.stepToken ? "1" : "2"}{" "}
                    {translate("transaction.transactionSignDataStepOf")} 2
                  </span>
                </small>
                <hr />
              </div>

              <div
                className="col-md-12"
                style={{
                  display:
                    !this.state.stepDone && !this.state.stepToken
                      ? "block"
                      : "none",
                }}
              >
                <div className="input-group">
                  {translate("transaction.transactionSignDataName")}:{" "}
                  <h4>{this.props.signer.name}</h4>
                </div>
                <div className="input-group">
                  {
                    enums.getDocumentType().find((x) => {
                      return (
                        x.index === parseInt(this.props.signer.documentType)
                      );
                    }).text
                  }
                  : <h4>{this.props.signer.document}</h4>
                </div>
                {parseInt(this.props.signer.documentType) === 1 && (
                  <div className="input-group">
                    {translate("transaction.transactionSignDataBirthDate")}:{" "}
                    <h4>
                      {moment(this.props.signer.birthDate, "YYYY-MM-DD").format(
                        "DD/MM/YYYY",
                      )}
                    </h4>
                  </div>
                )}
              </div>

              <div
                className="col-md-12"
                style={{
                  display:
                    !this.state.stepDone && this.state.stepToken
                      ? "block"
                      : "none",
                }}
              >
                <div className="input-group" style={{ textAlign: "center" }}>
                  <h4>
                    {translate("transaction.transactionSignDataSendedToken")}:{" "}
                  </h4>
                </div>
                <div className="input-group" style={{ textAlign: "center" }}>
                  <h4>{this.props.signer.email}</h4>
                </div>
                <div className="col-md-12">
                  <span style={{ fontSize: "16px" }}>
                    {translate("transaction.transactionSignDataInsertToken")}
                  </span>
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="material-icons">&#xe0da;</i>
                    </span>
                    <div className="form-line">
                      <input
                        type="text"
                        id="txtToken"
                        className="form-control js-change"
                        name="token"
                      />
                    </div>

                    {this.validator.message(
                      "token",
                      this.state.token,
                      "required|token",
                      false,
                      {
                        default: translate(
                          "transaction.transactionSignDataTokenRequired",
                        ),
                        token: translate(
                          "transaction.transactionSignDataTokenInvalid",
                        ),
                      },
                    )}
                  </div>
                </div>
              </div>

              <div
                className="col-md-12"
                style={{ display: this.state.stepDone ? "block" : "none" }}
              >
                <div
                  className="col-md-12"
                  style={{
                    display: this.state.stepDone ? "block" : "none",
                    textAlign: "center",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <img src="doc-sign.png" width="150" alt="" />
                </div>

                <div className="input-group" style={{ textAlign: "center" }}>
                  <h4>{translate("transaction.transactionSignDataThanks")}</h4>
                  <span>
                    {translate(
                      "transaction.transactionSignDataProcessConcluded",
                    )}
                  </span>
                </div>
              </div>

              <div
                className="row m-t-20"
                style={{ display: !this.state.stepDone ? "block" : "none" }}
              >
                <div className="col-xs-12 align-right">
                  <div
                    className="preloader pl-size-xs form-preloader"
                    style={{
                      display: this.state.loading ? "inline-block" : "none",
                      position: "relative",
                      top: "7px",
                      marginRight: "20px",
                    }}
                  >
                    <div className="spinner-layer">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-default waves-effect m-r-10"
                    style={{
                      display:
                        !this.state.stepDone && this.state.stepToken
                          ? "inline-block"
                          : "none",
                    }}
                    disabled={this.state.loading}
                    onClick={() => {
                      this.handleReturn();
                    }}
                  >
                    <i className="material-icons">&#xe5c4;</i>
                    <span>{translate("forms.buttonReturn")}</span>
                  </button>

                  <button
                    type="button"
                    className="btn btn-warning waves-effect"
                    disabled={this.state.loading}
                    onClick={() => {
                      this.handleStepToken();
                    }}
                  >
                    {this.state.stepToken && (
                      <i className="material-icons">&#xe862;</i>
                    )}
                    {!this.state.stepToken && (
                      <i className="material-icons">&#xe5c8;</i>
                    )}

                    <span>
                      {this.state.stepToken
                        ? translate("document.documentSign")
                        : translate("forms.next")}
                    </span>
                  </button>
                </div>
              </div>

              <div className="col-md-12" style={{ textAlign: "center" }}>
                <hr />

                <div
                  className="col-md-12"
                  style={{
                    display:
                      !this.state.stepDone && this.state.stepToken
                        ? "block"
                        : "none",
                    paddingBottom: "20px",
                  }}
                >
                  <span>
                    {translate("transaction.transactionSignDataNoToken")}
                  </span>
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      this.handleReSendToken();
                    }}
                    className="waves-effect waves-block"
                    style={{ paddingLeft: "10px" }}
                  >
                    {translate("transaction.transactionSignDataResendToken")}
                  </a>
                </div>

                <small>
                  <i
                    className="material-icons"
                    style={{
                      position: "relative",
                      top: "5px",
                      paddingRight: "5px",
                      fontSize: "20px",
                    }}
                  >
                    &#xe897;
                  </i>
                  <span>
                    {translate(
                      "transaction.transactionSignDataSafeEnvironment",
                    )}
                  </span>
                </small>
              </div>
            </div>
          </Modal>

          <ToastContainer hideProgressBar />
        </div>
      );
    }
  }
}
