import React from "react";
import Modal from "react-responsive-modal";
import { translate } from "react-internationalization";
import ReactTable from "react-table";
import * as session from "../SessionValidator";
import { TRANSACTION_SERVICE_URL } from "../../settings";
import moment from "moment";
import { getRequest } from "../../service/RequestService";

export default class ModalMoneySource extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      moneySourceOpen: false,
      assignee: {},
      assignees: [],
    };
  }

  async componentDidMount() {
    if (
      this.props.transaction.status == 0 ||
      this.props.transaction.status == 7
    )
      //pendente = 0 em analise = 7
      this.getAssignees();
    else {
      let assignee = {
        name:
          this.props.transaction.assignee.length > 0
            ? this.props.transaction.assignee[0].legalName
            : "",
        rate: this.props.transaction.moneySourceRate,
      };

      this.setState({ assignee: assignee });
      this.forceUpdate();
    }
  }

  async getAssignees() {
    if (
      this.props.transaction.customerId &&
      this.props.transaction.customerId > 0
    ) {
      this.setState({ loading: true });

      await getRequest(
        TRANSACTION_SERVICE_URL +
          "/api/transaction/ListAssigneeByCustomerId?transactionId=" +
          this.props.transaction.id,
      ).then((res) => {
        this.setState({ loading: false });

        if (res.data) {
          if (res.data && res.data.length > 0) {
            let assigneeId =
              this.props.transaction.bankId > 0
                ? this.props.transaction.bankId
                : this.props.transaction.customerId;

            let assignee = res.data.find((x) => {
              return (
                x.id === assigneeId &&
                x.moneySource === this.props.transaction.moneySource
              );
            });
            this.state.assignee = assignee ? assignee : res.data[0];

            this.props.handleChangeMoneySource(this.state.assignee);
          } else {
            this.state.assignee = {
              name: "",
              portalRate: 0,
              balanceAvailable: 0,
              balanceConsumed: 0,
              BalanceDueDate: null,
              moneySource: 0,
            };
          }
        }

        this.setState({ assignees: res.data });
        this.forceUpdate();
      });
    } else {
      let assignee = {
        name: "",
        portalRate: 0,
        balanceAvailable: 0,
        balanceConsumed: 0,
        BalanceDueDate: null,
        moneySource: 0,
      };

      this.setState({
        assignee: assignee,
      });

      this.forceUpdate();
    }
  }

  handleEditMoneySource() {
    this.state.moneySourceOpen = true;

    this.forceUpdate();
  }

  handleReturnMoneySource() {
    this.state.moneySourceOpen = false;

    this.forceUpdate();
  }

  handleChangeMoneySource(assignee) {
    this.props.handleChangeMoneySource(assignee);

    this.state.assignee = assignee;
    this.forceUpdate();

    this.handleReturnMoneySource();
  }

  render() {
    var fetched =
      this.state != null && this.state.assignees != null && !this.state.loading;

    if (fetched) {
      return (
        <div>
          <div className="col-md-12">
            <div
              className="m-b-5"
              style={{ padding: "15px", border: "1px solid #ddd" }}
            >
              <div>
                <h4>{translate("transaction.transactionAssignee")}</h4>
              </div>

              <p>
                <b>{translate("transaction.transactionAssigneeName")}: </b>
                {this.state.assignee.name}
                <b> {translate("transaction.transactionAssigneeRate")}: </b>
                {this.state.assignee.rate}
              </p>

              {(session.isAdmin() || session.isOperator()) &&
                (this.props.transaction.status == 0 ||
                  this.props.transaction.status == 7) && (
                  <div>
                    <p>
                      <b>
                        {" "}
                        {translate(
                          "transaction.transactionAssigneeBalanceAvailable",
                        )}
                        :{" "}
                      </b>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(this.state.assignee.balanceAvailable)}
                    </p>
                    <p>
                      <b>
                        {" "}
                        {translate(
                          "transaction.transactionAssigneeBalanceConsumed",
                        )}
                        :{" "}
                      </b>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(this.state.assignee.balanceConsumed)}
                    </p>
                    <p>
                      <b>
                        {" "}
                        {translate(
                          "transaction.transactionAssigneeBalanceDueDate",
                        )}
                        :{" "}
                      </b>
                      {moment(
                        this.state.assignee.balanceDueDate,
                        "YYYY-MM-DD",
                      ).format("DD-MM-YYYY")}
                    </p>

                    <button
                      className="btn bg-deep-purple waves-effect"
                      type="button"
                      disabled={this.props.loading}
                      onClick={() => {
                        this.handleEditMoneySource();
                      }}
                    >
                      <i className="material-icons">&#xe8d5;</i>
                      <span>
                        {translate("transaction.transactionChangeAssignee")}
                      </span>
                    </button>
                  </div>
                )}
            </div>
          </div>

          <Modal
            open={this.state.moneySourceOpen}
            closeOnEsc={false}
            onClose={() => {}}
            onEntered={() => {
              this.forceUpdate();
            }}
            closeIconSize={0}
            center={true}
            styles={{ modal: { maxWidth: "80%" } }}
          >
            <div>
              <div style={{ width: 9999 }}></div>

              <div>
                <h4>{translate("transaction.transactionAssignee")}</h4>
                <hr />
              </div>

              <div>
                <ReactTable
                  data={this.state.assignees}
                  columns={[
                    {
                      columns: [
                        {
                          Header: translate(
                            "transaction.transactionAssigneeName",
                          ),
                          id: "name",
                          accessor: (data) => {
                            return <span title={data.name}> {data.name} </span>;
                          },
                        },
                        {
                          Header: translate(
                            "transaction.transactionAssigneeRate",
                          ),
                          Cell: (row) => {
                            return row.original.rate + "%";
                          },
                          maxWidth: 100,
                        },
                        {
                          Header: translate(
                            "transaction.transactionAssigneeBalanceAvailable",
                          ),
                          id: "balanceAvailable",
                          accessor: (data) => {
                            return new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(data.balanceAvailable);
                          },
                          maxWidth: 200,
                        },
                        {
                          Header: translate(
                            "transaction.transactionAssigneeBalanceConsumed",
                          ),
                          id: "balanceConsumed",
                          accessor: (data) => {
                            return new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(data.balanceConsumed);
                          },
                          maxWidth: 200,
                        },
                        {
                          Header: translate(
                            "transaction.transactionAssigneeBalanceDueDate",
                          ),
                          id: "balanceDueDate",
                          accessor: (data) => {
                            return moment(
                              data.balanceDueDate,
                              "YYYY-MM-DD",
                            ).format("DD-MM-YYYY");
                          },
                        },
                      ],
                    },
                  ]}
                  defaultPageSize={5}
                  className="-striped -highlight"
                  loading={this.props.loading}
                  previousText={translate("forms.previousText")}
                  nextText={translate("forms.nextText")}
                  noDataText={translate("forms.noDataText")}
                  pageText={translate("forms.pageText")}
                  ofText={translate("forms.ofText")}
                  rowsText={translate("forms.rowsText")}
                  getTrProps={(state, row, column) => {
                    return {
                      onClick: () => {
                        if (!this.props.loading)
                          this.handleChangeMoneySource(row.original);
                      },
                      style: { cursor: "pointer" },
                    };
                  }}
                />
              </div>

              <div className="row m-t-20">
                <div className="col-xs-12 align-right">
                  <button
                    type="button"
                    className="btn btn-default waves-effect"
                    disabled={this.props.loading}
                    onClick={() => {
                      this.handleReturnMoneySource();
                    }}
                  >
                    <i className="material-icons">&#xe5c4;</i>
                    <span>{translate("forms.buttonReturn")}</span>
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    } else {
      return (
        <div className="col-md-12">
          <div
            className="m-b-5"
            style={{ padding: "15px", border: "1px solid #ddd" }}
          >
            <div className="preloader pl-size-lg align-center">
              <div className="spinner-layer">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div>

                <div className="circle-clipper right">
                  <div className="circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
