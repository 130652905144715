import React from "react";
import Modal from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";
import { translate } from "react-internationalization";
import ReactTable from "react-table";
import * as session from "../SessionValidator";
import QueryString from "query-string";
import { cpf_cnpj_Mask } from "../mask";
import { CLIENT_ID, SUPPLIER_SERVICE_URL } from "../../settings";
import { getRequest } from "../../service/RequestService";

export default class ModalSupplierSearch extends React.Component {
  constructor(props) {
    super(props);

    this.change = this.change.bind(this);
    this.loadNextResults = this.loadNextResults.bind(this);
    this.handleSearchButton = this.handleSearchButton.bind(this);
    this.handlechangeMask = this.handlechangeMask.bind(this);

    this.state = {
      suppliers: [],
      loading: true,
      searchName: "",
      searchDocument: "",

      query: {},
      tablePage: 0,
      tablePageSize: 5,
      tablePageCount: 0,
      numberOfResults: 0,
      currentPage: 0,
      rowCount: 0,
      selectedCustomerId: 0,
    };
  }

  async componentDidMount() {
    this.loadSuppliers();
  }

  async componentDidUpdate() {
    if (
      this.props.customerId > 0 &&
      this.props.customerId != this.state.selectedCustomerId
    ) {
      this.state.selectedCustomerId = this.props.customerId;

      this.handleSearchButton();
    }
  }

  handlechangeMask(event) {
    event.target.value = cpf_cnpj_Mask(event.target.value);
    this.change(event);
  }

  change(event) {
    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }
        property[key] = value;
      }
    });

    this.setState(this.state);
  }

  loadNextResults(page, pageSize) {
    if (pageSize === undefined) pageSize = this.state.tablePageSize;

    this.setState({ tablePage: page, tablePageSize: pageSize });
    let numberOfResults = this.state.numberOfResults;
    let numberOfRows = pageSize * (page + 1);
    let currentPage = this.state.currentPage;

    if (numberOfResults <= numberOfRows) {
      let nextPage = currentPage + 1;
      this.setState({ currentPage: nextPage });
      this.loadSuppliers();
    }

    let tablePageCount = Math.ceil(this.state.rowCount / pageSize);
    this.setState({ tablePageCount: tablePageCount });
  }

  handleSearchButton() {
    this.state.suppliers = [];
    this.state.currentPage = 0;
    this.state.numberOfResults = 0;
    this.state.tablePage = 0;
    this.state.tablePageSize = 5;
    this.state.tablePageCount = 0;
    this.state.rowCount = 0;

    this.setState(this.state);
    this.forceUpdate();

    this.loadSuppliers();
  }

  async loadSuppliers() {
    let numberOfResults = this.state.numberOfResults;
    let suppliers = this.state.suppliers;
    this.state.query.customerId = this.props.customerId;
    this.state.query.page = this.state.currentPage + 1;
    this.state.query.pageSize = this.state.tablePageSize;
    this.state.query.name = this.state.searchName;
    this.state.query.document = this.state.searchDocument;

    this.setState({ loading: true });

    await getRequest(
      SUPPLIER_SERVICE_URL +
        "/api/supplier/ListByCustomerId/?" +
        QueryString.stringify(this.state.query),
      { ClientId: CLIENT_ID },
    ).then((res) => {
      switch (res.code) {
        case 200:
          if (!res.data) res.data = [];

          if (res.data)
            res.data.result = res.data.result.sort(function (a, b) {
              return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            });

          suppliers = suppliers.concat(res.data.result);

          numberOfResults =
            numberOfResults +
            (res.data.result == null ? 0 : res.data.result.length);

          this.setState({
            suppliers: suppliers,
            numberOfResults: numberOfResults,
            currentPage: res.data.currentPage,
            tablePageCount: res.data.pageCount,
            rowCount: res.data.rowCount,
          });

          break;

        case 400:
        case 409:
          var messages = res.data;
          messages.forEach((ex) => toast.warn(ex.message));
          break;

        case 500:
          toast.error("error");
          break;
      }

      this.handleResult(suppliers);

      this.setState({ loading: false });
      this.forceUpdate();
    });
  }

  handleReturn() {
    this.clearFields();
    this.props.handleReturn();
  }

  handleChange(supplier) {
    this.clearFields();
    this.props.handleChange(supplier.id);
  }

  handleResult(suppliers) {
    this.props.handleResult(suppliers);
  }

  clearFields() {
    this.state.searchName = null;
    this.state.searchDocument = null;
  }

  render() {
    var fetched = this.state != null && this.state.suppliers != null;

    if (fetched) {
      return (
        <div>
          {/* Suppliers */}
          <Modal
            open={this.props.SearchOpen}
            closeOnEsc={false}
            onClose={() => {}}
            onEntered={() => {
              this.forceUpdate();
            }}
            closeIconSize={0}
            center={true}
            styles={{ modal: { maxWidth: "80%" } }}
          >
            <div>
              <div style={{ width: 9999 }}></div>

              <div>
                <h4>{translate("supplier.suppliersTitle")}</h4>
                <hr />
              </div>

              <div className="body">
                <div className="row clearfix m-t-20">
                  <div className="col-md-6">
                    <div className="input-group">
                      <label htmlFor="txtName">
                        {translate("supplier.supplierLegalName")}
                      </label>

                      <div className="form-line">
                        <input
                          id="txtName"
                          name="searchName"
                          className="form-control"
                          type="text"
                          onChange={this.change}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="input-group">
                      <label htmlFor="txtDocument">
                        {translate("supplier.supplierDocumentPlaceHolder")}
                      </label>

                      <div className="form-line">
                        <input
                          id="txtDocument"
                          name="searchDocument"
                          maxLength="18"
                          autoComplete="off"
                          className="form-control"
                          type="text"
                          onChange={this.handlechangeMask}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 pull-left m-t-20">
                    <button
                      className="btn btn-block btn-primary"
                      disabled={this.state.loading}
                      type="button"
                      onClick={(event) => {
                        this.handleSearchButton(event);
                      }}
                    >
                      <i className="material-icons">&#xe8b6;</i>
                      <span>{translate("forms.buttonSearch")}</span>
                    </button>
                  </div>
                </div>

                <ReactTable
                  data={this.state.suppliers}
                  columns={[
                    {
                      columns: [
                        {
                          Header: "ID",
                          accessor: "id",
                          maxWidth: 80,
                        },
                        {
                          Header: translate("supplier.supplierName"),
                          accessor: "name",
                        },
                      ],
                    },
                  ]}
                  defaultPageSize={5}
                  className="-striped -highlight"
                  loading={this.state.loading}
                  previousText={translate("forms.previousText")}
                  nextText={translate("forms.nextText")}
                  noDataText={translate("forms.noDataText")}
                  pageText={translate("forms.pageText")}
                  ofText={translate("forms.ofText")}
                  rowsText={translate("forms.rowsText")}
                  pages={this.state.tablePageCount}
                  page={this.state.tablePage}
                  pageSize={this.state.tablePageSize}
                  onPageSizeChange={(pageSize, page) =>
                    this.loadNextResults(page, pageSize)
                  }
                  onPageChange={(page) => this.loadNextResults(page)}
                  getTrProps={(state, row, column) => {
                    return {
                      onClick: () => {
                        if (!this.state.loading)
                          this.handleChange(row.original);
                      },
                      style: { cursor: "pointer" },
                    };
                  }}
                />
              </div>

              <div className="row m-t-20">
                <div className="col-xs-12 align-right">
                  <button
                    type="button"
                    className="btn btn-default waves-effect"
                    disabled={this.state.loading}
                    onClick={() => {
                      this.handleReturn();
                    }}
                  >
                    <i className="material-icons">&#xe5c4;</i>
                    <span>{translate("forms.buttonReturn")}</span>
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          <ToastContainer hideProgressBar />
        </div>
      );
    } else {
      return (
        <div className="col-md-12">
          <div
            className="m-b-5"
            style={{ padding: "15px", border: "1px solid #ddd" }}
          >
            <div className="preloader pl-size-lg align-center">
              <div className="spinner-layer">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div>

                <div className="circle-clipper right">
                  <div className="circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
