import React from "react";
import ReactTable from "react-table";
import { translate } from "react-internationalization";
import * as session from "../SessionValidator";
import SimpleReactValidator from "simple-react-validator";
import RCSelect from "../select";
import $ from "jquery";
import ModalOptInSearch from "../supplier/modal-optIn-search";

export default class ParticipantComponent extends React.Component {
  constructor(props) {
    super(props);

    this.setValidators();

    this.state = {
      participantId: 0,
      participants: [],
      participantGrid: this.props.participantGrid
        ? this.props.participantGrid
        : null,
      optInObjct: {
        optInParticipant: [],
      },
      participantSearchOpen: false,
    };

    this.setBinds();
  }

  setValidators() {
    this.validator = new SimpleReactValidator({
      uniqueCredencial: {
        rule: () => {
          var participantGrid = this.state.participantGrid;
          var participantId = this.state.participantId;

          var participantIds = participantGrid.map((x) => {
            return x.id;
          });

          return !(
            participantId ==
            participantIds.find((x) => {
              return x === participantId;
            })
          );
        },
      },
    });
  }

  handleAddParticipant() {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();

      return;
    }

    this.addParticipant();
  }

  addParticipant() {
    var participantId = this.state.participantId;
    var participantList = this.state.optInObjct.optInParticipant;
    var participants = this.state.participants;
    var participantSelected = participants.find((x) => {
      return x.id === participantId;
    });
    var participantGrid = this.state.participantGrid;

    participantList.push(participantId);
    participantGrid.push(participantSelected);

    participants = participants.filter((x) => x.id != participantId);

    this.state.participants = participants;

    this.props.handleAddParticipant(participantSelected);

    this.resetParticipant();
  }

  resetParticipant() {
    this.setState({
      participantId: null,
    });
  }

  handleChangeParticipantSearch(participantId) {
    this.state.participantId = participantId;
    this.state.participantSearchOpen = false;
    this.forceUpdate();
  }

  handleReturnParticipantSearch() {
    this.state.participantSearchOpen = false;
    this.forceUpdate();
  }

  handleResultParticipantSearch(participants) {
    this.setState({ participants: participants, loading: false });
    this.forceUpdate();
  }

  deleteParticipant(rowIndex, deletedParticipant) {
    var items = this.state.participantGrid;
    items.splice(rowIndex, 1);
    this.state.participantGrid = items;
    var idList = this.state.optInObjct.optInParticipant;
    idList.splice(rowIndex, 1);
    this.state.optInObjct.optInParticipant = idList;

    var participants = this.state.participants;

    participants.push(deletedParticipant);

    this.props.deleteParticipant(rowIndex);

    this.setState({
      optInParticipant: idList,
      participantGrid: items,
    });
  }

  async handleChange(event) {
    session.validateSession();

    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    this.setState(this.state);
  }

  setBinds() {
    this.handleAddParticipant = this.handleAddParticipant.bind(this);
    this.deleteParticipant = this.deleteParticipant.bind(this);
    this.addParticipant = this.addParticipant.bind(this);
  }

  setValue(element) {
    let keys = element.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      property = property[key];
      if (Array.isArray(property)) property = property[element.dataset.index];
    });

    $(element).val(property);
  }

  render() {
    return (
      <div className="body">
        <div className="row clearfix">
          <div className="col-md-5">
            <div className="m-b-20">
              <label htmlFor="cmbParticipant" className="validationRequired">
                {translate("optIn.optInAcquirer")}
              </label>

              <RCSelect
                id="cmbParticipant"
                name="participantId"
                hasEmptyOption={true}
                isInt={true}
                options={this.state.participants}
                optionValue="id"
                optionLabel="legalName"
                placeholder={translate("optIn.optInAcquirerPlaceHolder")}
                onChange={(event) => {
                  this.handleChange(event);
                }}
                value={this.state.participantId}
              />

              {this.validator.message(
                "participantId",
                this.state.participantId,
                "uniqueCredencial",
                false,
                { default: translate("optIn.optInAcquirerValidator") },
              )}
            </div>
          </div>

          <div
            className="col-md-1 m-t-20 p-l-0"
            style={{ display: session.isSupplier() ? "none" : "block" }}
          >
            <button
              type="button"
              className="btn btn-default waves-effect"
              onClick={() => {
                this.state.participantSearchOpen = true;
                this.forceUpdate();
              }}
            >
              <i className="material-icons">&#xe8b6;</i>
            </button>
            {this.state.participantSearchOpen && (
              <ModalOptInSearch
                SearchOpen={this.state.participantSearchOpen}
                handleChange={(event) => {
                  this.handleChangeParticipantSearch(event);
                }}
                handleResult={(event) => {
                  this.handleResultParticipantSearch(event);
                }}
                handleReturn={() => {
                  this.handleReturnParticipantSearch();
                }}
              />
            )}
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-md-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleAddParticipant}
            >
              <i className="material-icons">&#xe145;</i>
              <span>{translate("optIn.optInAddNewParticipant")}</span>
            </button>
          </div>
        </div>
        <div className="row clearfix">
          <ReactTable
            data={this.state.participantGrid}
            columns={[
              {
                columns: [
                  {
                    Header: translate("customer.customerContactName"),
                    id: "legalName",
                    accessor: (data) => {
                      return (
                        <span title={data.legalName}> {data.legalName} </span>
                      );
                    },
                  },
                  {
                    Header: translate("supplier.supplierDocument"),
                    id: "document",
                    accessor: (data) => {
                      return (
                        <span title={data.document}> {data.document} </span>
                      );
                    },
                  },
                  {
                    Cell: (row) => (
                      <div className="align-center">
                        <button
                          className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10"
                          type="button"
                          onClick={() => {
                            this.deleteParticipant(row.index, row.original);
                          }}
                        >
                          <i className="material-icons">&#xe872;</i>
                        </button>
                      </div>
                    ),
                    maxWidth: 150,
                  },
                ],
              },
            ]}
            defaultPageSize={10}
            className="-striped -highlight m-b-40"
            previousText={translate("forms.previousText")}
            nextText={translate("forms.nextText")}
            noDataText={translate("forms.noDataText")}
            pageText={translate("forms.pageText")}
            ofText={translate("forms.ofText")}
            rowsText={translate("forms.rowsText")}
          />
        </div>
      </div>
    );
  }
}
