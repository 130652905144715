import * as session from "../components/SessionValidator";

const fetchtConfig = {
  method: "",
  mode: "cors",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
    Authorization: session.getToken(),
  },
  credentials: "same-origin",
  redirect: "follow", // manual, *follow, error
  referrer: "no-referrer", // no-referrer, *client
};

const validAuth = (response) => {
  if (response.status === 401) {
    session.sessionExpired();
    return [];
  }

  if (response.status === 403) {
    session.accessDenied();
    return [];
  }

  if (response.status === 404) {
    return [];
  }

  return response.json();
};

export const getRequest = async (url, headers = {}) => {
  const getConfig = { ...fetchtConfig };
  getConfig.method = "GET";

  getConfig.headers = Object.assign(getConfig.headers, headers);

  const response = await fetch(url, getConfig);

  return validAuth(response);
};

export const postRequest = async (url, body, headers = {}) => {
  const postConfig = { ...fetchtConfig };
  postConfig.method = "POST";
  postConfig.body = JSON.stringify(body);

  postConfig.headers = Object.assign(postConfig.headers, headers);

  const response = await fetch(url, postConfig);

  return validAuth(response);
};

export const putRequest = async (url, body, headers = {}) => {
  const putConfig = { ...fetchtConfig };
  putConfig.method = "PUT";
  putConfig.body = JSON.stringify(body);

  putConfig.headers = Object.assign(putConfig.headers, headers);

  const response = await fetch(url, putConfig);

  return validAuth(response);
};
