/* eslint-disable default-case */
import React from "react";
import { translate } from "react-internationalization";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import SimpleReactValidator from "simple-react-validator";
import $ from "jquery";
import * as session from "../../components/SessionValidator";
import { post } from "axios";
import ReactTable from "react-table";
import { CUSTOMER_SERVICE_URL } from "../../settings";
import moment from "moment";
import { postRequest } from "../../service/RequestService";

class ImportNFPage extends React.Component {
  constructor(props) {
    super(props);

    this.setBinds();
    this.setValidators();

    this.state = {
      loading: false,
      importNF: {
        file: [],
        fileXml: [],
      },
      resultError: [],
      resultXml: [],
    };
  }

  async componentDidUpdate() {}

  async componentDidMount() {
    let _this = this;

    $("body").on("change", ".js-change", function (event) {
      _this.handleChange(event);
    });
  }

  async handleChange(event) {
    session.validateSession();

    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    this.setState(this.state);
  }

  async handleChangeDate(id, rowIndex) {
    var component = document.getElementById(id);
    this.state.resultXml[rowIndex].dueDate = component.value;

    this.setState(this.state);
  }

  setValidators() {
    this.importNFValidator = new SimpleReactValidator({
      attachmentInvalid: {
        rule: () => {
          if ($("#fileAttachments").length <= 0) return false;

          let files = $("#fileAttachments")[0].files;

          if (files.length <= 0) return false;

          if (/(.xls|.xlsx)/i.test(files[0].name.toLowerCase()) === false)
            return false;

          return true;
        },
      },

      attachmentRequired: {
        rule: () => {
          if ($("#fileAttachments").length <= 0) return false;

          let files = $("#fileAttachments")[0].files;

          if (files.length <= 0) return false;

          return true;
        },
      },
    });

    this.loadNFXmlValidator = new SimpleReactValidator({
      attachmentInvalidXml: {
        rule: () => {
          if ($("#fileAttachmentsXml").length <= 0) return false;

          let files = $("#fileAttachmentsXml")[0].files;

          if (files.length <= 0) return false;

          if (/(.xml)/i.test(files[0].name.toLowerCase()) === false)
            return false;

          return true;
        },
      },

      attachmentRequiredXml: {
        rule: () => {
          if ($("#fileAttachmentsXml").length <= 0) return false;

          let files = $("#fileAttachmentsXml")[0].files;

          if (files.length <= 0) return false;

          return true;
        },
      },
    });

    this.importNFXmlValidator = new SimpleReactValidator({});
  }

  setBinds() {
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLoadXml = this.handleLoadXml.bind(this);
    this.handleSubmitXml = this.handleSubmitXml.bind(this);
  }

  handleLoadXml() {
    if (!this.loadNFXmlValidator.allValid()) {
      this.loadNFXmlValidator.showMessages();
      this.forceUpdate();

      return;
    } else {
      var self = this;

      if (self.state.resultXml && self.state.resultXml.length > 0) {
        self.state.resultXml.forEach(
          (item) =>
            (self.importNFXmlValidator.fields[item.invoiceNumber] = true),
        );
      }

      this.setState({ loading: true, resultError: [], resultXml: [] });

      let status;

      const url =
        CUSTOMER_SERVICE_URL + "/api/Invoice/ConvertFileXmlToInvoiceList";
      const formData = new FormData();

      let files = this.fileXml.files;

      for (let i = 0; i < files.length; i++)
        formData.append(files[i].name, files[i]);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: session.getToken(),
        },
      };

      post(url, formData, config)
        .then(function (res) {
          self.setState({ loading: false });
          status = res.status;

          switch (status) {
            case 200:
              self.setState({ resultXml: res.data.data });
              self.fileXml.value = null;

              self.forceUpdate();

              break;
            case 401:
              session.sessionExpired();
              res = [];

              return res;

            case 403:
              session.accessDenied();
              res = [];
              return res;

              break;
          }
        })
        .catch(function (error) {
          self.setState({ loading: false });

          console.log(error);

          if (error.response === undefined) {
            toast.error("Erro na comunicação com o servidor.");
            return;
          }

          status = error.response.status;

          switch (status) {
            case 401:
              session.sessionExpired();
              error = [];
              return error;
            case 403:
              session.accessDenied();
              error = [];
              return error;
            case 400:
            case 409:
              var messages = error.response.data.data;
              if (messages) {
                messages.forEach((ex) => self.state.resultError.push(ex));
                self.forceUpdate();
              }
              break;

            case 500:
              toast.error("Erro na comunicação com o servidor. 500");
              break;
          }
        });
    }
  }

  handleSubmitXml() {
    if (!this.importNFXmlValidator.allValid()) {
      this.importNFXmlValidator.showMessages();
      this.forceUpdate();

      return;
    } else {
      var invoices = this.state.resultXml;

      this.setState({ loading: true });

      let status;
      var self = this;

      postRequest(CUSTOMER_SERVICE_URL + "/api/Invoice/NormalImport", invoices)
        .then((res) => {
          this.setState({ loading: false });

          switch (res.code) {
            case 200:
              confirmAlert({
                buttons: [
                  {
                    label: translate("forms.buttonYes"),
                    onClick: () => {
                      this.file.value = null;
                      this.fileXml.value = null;

                      this.setState({ resultError: [], resultXml: [] });

                      this.forceUpdate();
                    },
                  },
                  {
                    label: translate("forms.buttonNo"),
                    onClick: () => {
                      this.props.history.push("/");
                    },
                  },
                ],
                message: translate(
                  "importNF.importNFDocumentProcessedQuestion",
                ),
                title: translate(
                  "importNF.importNFDocumentProcessedQuestionTitle",
                ),
                closeOnClickOutside: false,
                closeOnEscape: false,
              });

              break;

            case 400:
            case 409:
              var messages = res.data;
              if (messages) {
                messages.forEach((ex) => self.state.resultError.push(ex));
                this.forceUpdate();
              }
              break;

            case 500:
              toast.error("Erro na comunicação com o servidor. 500");
              break;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  handleSubmit() {
    if (!this.importNFValidator.allValid()) {
      this.importNFValidator.showMessages();
      this.forceUpdate();

      return;
    } else {
      this.setState({ loading: true, resultError: [], resultXml: [] });

      let status;

      const url = CUSTOMER_SERVICE_URL + "/api/Invoice/ImportFile";
      const formData = new FormData();
      formData.append("file", this.file.files[0]);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: session.getToken(),
        },
      };

      var self = this;

      post(url, formData, config)
        .then(function (res) {
          self.setState({ loading: false });
          status = res.status;
          switch (status) {
            case 200:
              confirmAlert({
                buttons: [
                  {
                    label: translate("forms.buttonYes"),
                    onClick: () => {
                      self.file.value = null;
                      self.fileXml.value = null;

                      self.setState({ resultError: [], resultXml: [] });

                      self.forceUpdate();
                    },
                  },
                  {
                    label: translate("forms.buttonNo"),
                    onClick: () => {
                      self.props.history.push("/");
                    },
                  },
                ],
                message: translate(
                  "importNF.importNFDocumentProcessedQuestion",
                ),
                title: translate(
                  "importNF.importNFDocumentProcessedQuestionTitle",
                ),
                closeOnClickOutside: false,
                closeOnEscape: false,
              });
              break;
            case 401:
              session.sessionExpired();
              res = [];

              return res;

            case 403:
              session.accessDenied();
              res = [];
              return res;

              break;
          }
        })
        .catch(function (error) {
          self.setState({ loading: false });

          console.log(error);

          if (error.response === undefined) {
            toast.error("Erro na comunicação com o servidor.");
            return;
          }

          status = error.response.status;

          switch (status) {
            case 401:
              session.sessionExpired();
              error = [];
              return error;
            case 403:
              session.accessDenied();
              error = [];
              return error;
            case 400:
            case 409:
              var messages = error.response.data.data;
              if (messages) {
                messages.forEach((ex) => self.state.resultError.push(ex));
                self.forceUpdate();
              }
              break;

            case 500:
              toast.error("Erro na comunicação com o servidor.");
              break;
          }
        });
    }
  }

  render() {
    var fetched = true;

    if (fetched) {
      return (
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="card">
              <div className="header">
                <h2>
                  {translate("importNF.importNFTitle")}
                  <small>
                    {translate("importNF.importNFTitleDescription")}
                  </small>
                </h2>

                <h5>
                  <a download href="/file.xlsx">
                    {translate("importNF.importNFFileExample")}
                  </a>
                </h5>

                <ul className="header-dropdown">
                  <div
                    className="preloader pl-size-xs"
                    style={{ display: this.state.loading ? "block" : "none" }}
                  >
                    <div className="spinner-layer">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div>

                      <div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>

              <div className="body">
                <div className="row clearfix">
                  <div className="col-md-6">
                    <div className="row clearfix">
                      <div className="col-md-8">
                        <div className="row clearfix">
                          <div className="col-md-12">
                            <div className="fallback">
                              <input
                                className="js-change"
                                name="fileAttachments"
                                id="fileAttachments"
                                type="file"
                                ref={(x) => (this.file = x)}
                                accept=".xls, .xlsx"
                              />
                              {this.importNFValidator.message(
                                "fileAttachments",
                                this.state.importNF.file,
                                "attachmentRequired|attachmentInvalid",
                                false,
                                {
                                  default: translate(
                                    "importNF.importNFAttachmentsRequired",
                                  ),
                                  attachmentInvalid: translate(
                                    "importNF.importNFAttachmentsInvalid",
                                  ),
                                },
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <button
                        className="btn btn-success m-t-15 waves-effect m-l-15"
                        disabled={this.state.loading}
                        onClick={this.handleSubmit}
                        type="button"
                      >
                        <i className="material-icons">&#xe873;</i>
                        <span>{translate("forms.buttonProcessFileExcel")}</span>
                      </button>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row clearfix">
                      <div className="col-md-8">
                        <div className="row clearfix">
                          <div className="col-md-12">
                            <div className="fallback">
                              <input
                                className="js-change"
                                name="fileAttachmentsXml"
                                id="fileAttachmentsXml"
                                type="file"
                                multiple
                                ref={(x) => (this.fileXml = x)}
                                accept=".xml"
                              />
                              {this.loadNFXmlValidator.message(
                                "fileAttachmentsXml",
                                this.state.importNF.fileXml,
                                "attachmentRequiredXml|attachmentInvalidXml",
                                false,
                                {
                                  default: translate(
                                    "importNF.importNFAttachmentsRequired",
                                  ),
                                  attachmentInvalid: translate(
                                    "importNF.importNFAttachmentsInvalid",
                                  ),
                                },
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row clearfix">
                      <button
                        className="btn btn-success m-t-15 waves-effect m-l-15"
                        disabled={this.state.loading}
                        onClick={this.handleLoadXml}
                        type="button"
                      >
                        <i className="material-icons">&#xe873;</i>
                        <span>{translate("forms.buttonLoadFileXml")}</span>
                      </button>

                      <button
                        className="btn btn-success m-t-15 waves-effect m-l-15"
                        style={{
                          display:
                            this.state.resultXml.length > 0
                              ? "inline-block"
                              : "none",
                        }}
                        disabled={this.state.loading}
                        onClick={this.handleSubmitXml}
                        type="button"
                      >
                        <i className="material-icons">&#xe873;</i>
                        <span>{translate("forms.buttonImportFileXml")}</span>
                      </button>
                    </div>
                  </div>

                  <div className="row clearfix">
                    <div className="col-md-12">
                      <ReactTable
                        style={{
                          width: "100%",
                          display:
                            this.state.resultError.length > 0
                              ? "inline-block"
                              : "none",
                        }}
                        data={this.state.resultError}
                        columns={[
                          {
                            columns: [
                              {
                                Header: translate("importNF.importNFError"),
                                accessor: "message",
                              },
                            ],
                          },
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight m-b-40"
                        previousText={translate("forms.previousText")}
                        nextText={translate("forms.nextText")}
                        noDataText={translate("forms.noDataText")}
                        pageText={translate("forms.pageText")}
                        ofText={translate("forms.ofText")}
                        rowsText={translate("forms.rowsText")}
                      />

                      <ReactTable
                        style={{
                          width: "100%",
                          display:
                            this.state.resultXml.length > 0
                              ? "inline-block"
                              : "none",
                        }}
                        data={this.state.resultXml}
                        columns={[
                          {
                            columns: [
                              {
                                Header: translate(
                                  "importNF.importNFInvoiceNumber",
                                ),
                                accessor: "invoiceNumber",
                                width: 110,
                              },
                              {
                                Header: translate("invoice.invoiceInstallment"),
                                id: "installment",
                                maxWidth: 90,
                                accessor: (data) => {
                                  return (
                                    data.installment +
                                    " / " +
                                    data.totalInstallment
                                  );
                                },
                              },
                              {
                                Header: translate(
                                  "importNF.importNFCustomerDocument",
                                ),
                                accessor: "customerDocument",
                              },
                              {
                                Header: translate(
                                  "importNF.importNFSupplierDocument",
                                ),
                                accessor: "supplierDocument",
                              },
                              {
                                Header: translate("importNF.importNFAmount"),
                                id: "amount",
                                accessor: (data) => {
                                  return new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }).format(data.amount);
                                },
                                width: 140,
                              },
                              {
                                Header: translate("importNF.importNFDate"),
                                id: "date",
                                accessor: (data) => {
                                  return moment(data.date, "YYYY-MM-DD").format(
                                    "DD-MM-YYYY",
                                  );
                                },
                                width: 105,
                              },
                              {
                                Header: translate("importNF.importNFDueDate"),
                                id: "dueDate",
                                accessor: (data) => {
                                  return moment(
                                    data.dueDate,
                                    "YYYY-MM-DD",
                                  ).format("DD-MM-YYYY");
                                },
                                width: 120,
                              },
                              {
                                Header: translate("importNF.importNFNfeKey"),
                                accessor: "nfeKey",
                                width: 367,
                              },
                            ],
                          },
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight m-b-40"
                        previousText={translate("forms.previousText")}
                        nextText={translate("forms.nextText")}
                        noDataText={translate("forms.noDataText")}
                        pageText={translate("forms.pageText")}
                        ofText={translate("forms.ofText")}
                        rowsText={translate("forms.rowsText")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer hideProgressBar />
        </div>
      );
    } else {
      return (
        <div className="preloader pl-size-lg align-center">
          <div className="spinner-layer">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>

            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ImportNFPage;
