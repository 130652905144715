import React from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { translate } from "react-internationalization";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as session from "../components/SessionValidator";
import Modal from "react-responsive-modal";
import { loginSession, removeSession } from "../utils/localStorageUtils";
import { ACCOUNT_SERVICE_URL, CLIENT_ID } from "../settings";
import { postRequest } from "../service/RequestService";

const $ = window.jQuery;

const toastLoginConfig = (autoClose = 2000) => {
  return {
    autoClose,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };
};
class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: { email: "", password: "" },
      msg: "",
      loading: false,
      modalOpen: false,
    };

    this.sendAuth = this.sendAuth.bind(this);
    this.setToken = this.setToken.bind(this);

    localStorage.removeItem("loginExpired");

    if (localStorage.getItem("email") != null) {
      removeSession(localStorage);
    }
  }

  setToken(token) {
    let sessionTimeOut = new Date();

    sessionTimeOut.setHours(sessionTimeOut.getHours() + 2);
    localStorage.setItem("sessionTimeOut", sessionTimeOut);
    localStorage.setItem("authp", token);

    this.setState({ token: token });
  }

  componentDidMount() {
    $("#leftsidebar").hide();
    $(".content").removeClass("content");
    $("#mainNav").hide();

    if (!localStorage.getItem("acceptTerm")) {
      this.setState({ modalOpen: true });
    }
  }

  componentWillUnmount() {
    $("#mainNav").slideDown("fast");
  }

  handleReturnModal(status) {
    localStorage.setItem("acceptTerm", status);
    this.setState({ modalOpen: false });
  }

  sendAuth(event) {
    event.preventDefault();

    if (!this.email.value) {
      toast.error(translate("login.loginEmailRequired"), toastLoginConfig());
      return;
    }

    if (!this.password.value) {
      toast.error(translate("login.loginPasswordRequired"), toastLoginConfig());
      return;
    }

    if (localStorage.getItem("clickHour")) localStorage.removeItem("clickHour");

    this.setState({ loading: true });

    postRequest(
      ACCOUNT_SERVICE_URL + "/api/Auth",
      { email: this.email.value.trim(), password: this.password.value },
      { ClientId: CLIENT_ID }
    ).then((res) => {
      const response = res.data;
      switch (res.code) {
        case 200:
          this.setToken(response.token);
          loginSession(localStorage, response);
          window.location.href = "/";
          break;
        case 400:
          toast.error(response[0].message, toastLoginConfig(4000));
          break;
        case 422:
          localStorage.setItem("email", this.email.value.trim());

          const attempts = `${response[0].parameter} de 5 tentativas. Após 5 tentativas, este usuário será bloqueado`;

          toast.error(response[0].message, toastLoginConfig(4000));
          toast.error(attempts, toastLoginConfig(4000));
          break;
        case 500:
          break;
        case 423:
          session.userLocked();
          break;
      }

      this.setState({ loading: false });
    });
  }

  render() {
    const divStyle = {
      display: this.state.loading ? "block" : "none",
    };

    return (
      <section className="login-wrap">
        <div className="login-page bg-deep-purple">
          <div id="loginBox" className="login-box">
            <div className="logo">
              <img src="logo.png" />
              <small></small>
            </div>
            <div className="card">
              <div className="body">
                <form method="POST" onSubmit={this.sendAuth}>
                  <div className="msg">
                    <h2></h2>
                  </div>
                  <span>{this.state.msg}</span>
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="material-icons">&#xe0be;</i>
                    </span>
                    <div className="form-line">
                      <input
                        type="text"
                        id="txtEmail"
                        className="form-control"
                        name="email"
                        placeholder={translate("login.loginEmail")}
                        autoFocus
                        ref={(input) => (this.email = input)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <span className="input-group-addon">
                      <i className="material-icons">&#xe897;</i>
                    </span>
                    <div className="form-line">
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder={translate("login.loginPassword")}
                        ref={(input) => (this.password = input)}
                      />
                    </div>
                  </div>
                  <div className="row clearfix">
                    <div className="col-md-8">
                      <div className="preloader pl-size-xs" style={divStyle}>
                        <div className="spinner-layer">
                          <div className="circle-clipper left">
                            <div className="circle"></div>
                          </div>
                          <div className="circle-clipper right">
                            <div className="circle"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <button className="btn bg-deep-purple" type="submit">
                        <span>{translate("login.loginSignIn")} </span>
                        <i className="material-icons">&#xe5c8;</i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={this.state.modalOpen}
          closeOnEsc={false}
          onClose={() => {}}
          onEntered={() => {
            this.forceUpdate();
          }}
          closeIconSize={0}
          center={true}
          styles={{ modal: { maxWidth: "60%" } }}
        >
          <div>
            <div style={{ width: 9999 }}></div>

            <div className="modal-header">
              <h4 className="modal-title">Política de Privacidade</h4>
            </div>
            <div className="modal-body">
              <p>
                Este website utiliza cookies para melhor dimensionar seu
                interesse e atender plenamente sua necessidade. Ao continuar a
                navegação você concorda com esse uso.{" "}
                <a target="blank" href="/acceptTerm">
                  Consulte nossa Política de Privacidade.
                </a>
              </p>
              <p>
                Não rastrearemos suas informações quando você visitar nosso
                site, porém, para cumprir suas preferências, precisaremos usar
                apenas um pequeno cookie, para que você não seja solicitado a
                tomar essa decisão novamente.
              </p>
            </div>
            <div className="align-right">
              <button
                type="button"
                className="btn btn-link waves-effect"
                onClick={() => {
                  this.handleReturnModal(true);
                }}
              >
                Aceitar
              </button>
              <button
                type="button"
                className="btn btn-link waves-effect"
                onClick={() => {
                  this.handleReturnModal(false);
                }}
                data-dismiss="modal"
              >
                Recusar
              </button>
            </div>
          </div>
        </Modal>
        <ToastContainer hideProgressBar />
      </section>
    );
  }
}

export default LoginPage;
