import React from "react";
import { submitReportDailyLog } from "../../pages/helper.js";
import ReactTable from "react-table";
import { translate } from "react-internationalization";
import * as session from "../SessionValidator";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import RCSelect from "../select";
import $ from "jquery";
import * as enumsBank from "../enums/bank";
import { CUSTOMER_SERVICE_URL } from "../../settings.js";
import moment from "moment";
import { getRequest, postRequest } from "../../service/RequestService.js";

export default class BankCustomerComponent extends React.Component {
  constructor(props) {
    super(props);

    this.setValidators();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTypingEmail = this.handleTypingEmail.bind(this);
    this.saveBank = this.saveBank.bind(this);
    this.editBank = this.editBank.bind(this);
    this.deleteBank = this.deleteBank.bind(this);
    this.resetBank = this.resetBank.bind(this);

    this.state = {
      currentBank: {
        rowIndex: -1,
        bankAccount: {
          id: 0,
          name: "",
          bankNumber: "",
          agencyNumber: "",
          accountNumber: "",
          notes: "",
          isActive: true,
          isMaster: false,
          customerId: 0,
        },
      },

      bankList: [],
      loading: false,

      reportDailyLog: {
        startDate: moment().format(),
        objectAfter: "",
        objectBefore: "",
      },
    };
  }

  async componentDidUpdate() {
    let _this = this;

    $(".js-customer-bank-change").each(function () {
      _this.setValue(this);
    });
    $(".js-phone-number").inputmask({
      greedy: false,
      mask: "(99) 9999[9]-9999",
      showMaskOnHover: false,
    });
  }

  async componentDidMount() {
    let _this = this;

    $("body").on("change", ".js-customer-bank-change", function (event) {
      _this.handleChange(event);
    });

    $(".js-phone-number").inputmask({
      greedy: false,
      mask: "(99) 9999[9]-9999",
      showMaskOnHover: false,
    });

    if (this.props.customerId) {
      await getRequest(
        CUSTOMER_SERVICE_URL +
          "/api/customer/ListCustomerBanks/" +
          this.props.customerId,
      ).then((res) => {
        res.data = res.data.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        });

        this.setState({ bankList: res.data });
        this.forceUpdate();
      });
    } else {
      let currentBank = {
        rowIndex: -1,
        bankAccount: {
          id: 0,
          name: "",
          BankNumber: "",
          agencyNumber: "",
          accountNumber: "",
          notes: "",
          isActive: true,
          isMaster: false,
          customerId: 0,
        },
      };

      let bankList = [];

      this.setState({
        currentBank: currentBank,
        bankList: bankList,
      });
      this.forceUpdate();
    }
  }

  async handleChange(event) {
    session.validateSession();

    let keys = event.target.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      if (property[key] !== null && typeof property[key] === "object") {
        property = property[key];
        if (Array.isArray(property))
          property = property[event.target.dataset.index];
      } else {
        let value;
        if (event.target.type === "checkbox") {
          value = event.target.checked;
        } else {
          value = event.target.value;
        }

        property[key] = value;
      }
    });

    this.forceUpdate();

    this.setState(this.state);
  }

  setValue(element) {
    let keys = element.name.split("."),
      property = this.state;

    keys.forEach((key) => {
      property = property[key];
      if (Array.isArray(property)) property = property[element.dataset.index];
    });

    $(element).val(property);
  }

  handleTypingEmail(e) {
    e.target.value = e.target.value.toLowerCase();
  }

  resetBank() {
    this.state.currentBank = {
      rowIndex: -1,
      bankAccount: {
        id: 0,
        name: "",
        BankNumber: "",
        agencyNumber: "",
        accountNumber: "",
        notes: "",
        isActive: true,
        isMaster: false,
        customerId: 0,
      },
    };

    if (this.bankAccountValidator !== undefined) {
      this.bankAccountValidator.hideMessages();
    }

    this.setState(this.state);
  }

  saveBank() {
    if (!this.bankAccountValidator.allValid()) {
      this.bankAccountValidator.showMessages();
      this.forceUpdate();
      return;
    }

    this.handleSubmit();
  }

  editBank(rowIndex) {
    let reportDailyLog = this.state.reportDailyLog;

    this.state.currentBank.bankAccount = JSON.parse(
      JSON.stringify(this.state.bankList[rowIndex]),
    );
    this.state.currentBank.rowIndex = rowIndex;

    reportDailyLog.objectBefore = JSON.stringify(
      this.state.currentBank.bankAccount,
    );

    this.setState(this.state);
    this.forceUpdate();
  }

  handleSubmit() {
    let reportDailyLog = this.state.reportDailyLog;

    this.setState({ loading: true });

    var method =
      this.state.currentBank.bankAccount.id > 0 ? "UpdateBank" : "AddBank";

    this.state.currentBank.bankAccount.customerId = this.props.customerId;

    postRequest(
      CUSTOMER_SERVICE_URL + "/api/customer/" + method,
      this.state.currentBank.bankAccount,
    ).then((res) => {
      switch (res.code) {
        case 200:
          var bank = JSON.parse(
            JSON.stringify(this.state.currentBank.bankAccount),
          );

          reportDailyLog.action = 3; // 3 = updatecustomer
          reportDailyLog.objectAfter = JSON.stringify(bank);
          submitReportDailyLog(reportDailyLog);

          bank.id = res.data.id;

          if (bank.isMaster) {
            //desabilita todos
            for (var i = 0; i < this.state.bankList.length; i++) {
              this.state.bankList[i].isMaster = false;
            }
          }

          if (this.state.currentBank.rowIndex < 0) {
            this.state.bankList.push(bank);
          } else {
            this.state.bankList[this.state.currentBank.rowIndex] = bank;
          }

          this.resetBank();

          this.setState({ loading: false });

          this.forceUpdate();
          break;

        case 400:
        case 409:
          var messages = res.data;
          messages.forEach((ex) => toast.warn(ex.message));
          break;

        case 500:
          toast.error("error");
          break;
      }
    });
  }

  deleteBank(rowIndex) {
    confirmAlert({
      buttons: [
        {
          label: translate("forms.buttonYes"),
          onClick: () => {
            let bank = JSON.parse(
              JSON.stringify(this.state.bankList[rowIndex]),
            );

            let bankId = bank.id;

            this.handleDeleteBank(bankId, rowIndex);
          },
        },
        {
          label: translate("forms.buttonNo"),
          onClick: () => {
            window.close();
          },
        },
      ],
      message: translate("customer.customerSetupAccountDeleteTitle"),
      title: translate("customer.customerSetupAccountDeleteQuestion"),
    });
  }

  handleDeleteBank(bankId, rowIndex) {
    let reportDailyLog = this.state.reportDailyLog;
    var status = 0;

    this.setState({ loading: true });

    getRequest(
      CUSTOMER_SERVICE_URL + "/api/customer/DeleteBank/" + bankId,
    ).then((res) => {
      this.setState({ loading: false });

      switch (res.code) {
        case 200:
          let bank = JSON.parse(JSON.stringify(this.state.bankList[rowIndex]));

          var items = this.state.bankList;
          items.splice(rowIndex, 1);
          this.state.bankList = items;

          reportDailyLog.action = 3; // 3 = updatecustomer
          reportDailyLog.objectAfter = JSON.stringify(bank);
          submitReportDailyLog(reportDailyLog);

          this.forceUpdate();

          break;

        case 400:
        case 409:
          var messages = res.data;
          messages.forEach((ex) => toast.warn(ex.message));
          break;

        case 500:
          toast.error("error");
          break;
      }
    });
  }

  setValidators() {
    this.validator = new SimpleReactValidator({
      typePerson: {
        rule: (value) => {
          return ["", null, undefined].indexOf(value) < 0;
        },
      },
    });

    this.bankAccountValidator = new SimpleReactValidator({
      bankNumber: {
        rule: (value) => {
          return ["", null, undefined].indexOf(value) < 0;
        },
      },
    });
  }

  render() {
    return (
      <div>
        {session.auth([{ type: "Customer", value: "Save" }]) && (
          <div>
            <div>
              <div className="m-b-40">
                <small>
                  {translate("customer.customerSetupAccountTitleDescription")}
                </small>
              </div>
            </div>

            <div className="row clearfix">
              <div className="col-md-4">
                <div className="m-b-20">
                  <label htmlFor="cmbBankNumber" className="validationRequired">
                    {translate("customer.customerSetupAccountBankNumber")}
                  </label>

                  <RCSelect
                    id="cmbBankNumber"
                    name="currentBank.bankAccount.bankNumber"
                    hasEmptyOption={true}
                    isInt={false}
                    options={enumsBank.getBankNumber()}
                    optionValue="index"
                    optionLabel="text"
                    placeholder={translate(
                      "customer.customerSetupAccountBankNumberPlaceHolder",
                    )}
                    onChange={(event) => {
                      this.handleChange(event);
                    }}
                    value={this.state.currentBank.bankAccount.bankNumber}
                  />

                  {this.bankAccountValidator.message(
                    "cmbBankNumber",
                    this.state.currentBank.bankAccount.bankNumber,
                    "required|bankNumber",
                    false,
                    {
                      default: translate(
                        "customer.customerSetupAccountBankNumberRequired",
                      ),
                    },
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="input-group">
                  <label
                    className="validationRequired"
                    htmlFor="txtBankAccountName"
                  >
                    {translate("customer.customerSetupAccountName")}
                  </label>

                  <div className="form-line">
                    <input
                      id="txtBankAccountName"
                      maxLength="200"
                      className="form-control js-customer-bank-change"
                      name="currentBank.bankAccount.name"
                      placeholder={translate(
                        "customer.customerSetupAccountNamePlaceHolder",
                      )}
                      type="text"
                    />
                  </div>

                  {this.bankAccountValidator.message(
                    "currentBank.bankAccount.name",
                    this.state.currentBank.bankAccount.name,
                    "required",
                    false,
                    {
                      default: translate(
                        "customer.customerSetupAccountNameRequired",
                      ),
                    },
                  )}
                </div>
              </div>

              <div className="col-md-2">
                <div className="m-t-30">
                  <input
                    id="chkBankAccountIsMaster"
                    name="currentBank.bankAccount.isMaster"
                    type="checkbox"
                    checked={this.state.currentBank.bankAccount.isMaster}
                    onChange={this.handleChange}
                  />
                  <label htmlFor="chkBankAccountIsMaster">
                    {translate("customer.customerSetupAccountIsMaster")}
                  </label>
                </div>
              </div>

              <div className="col-md-2">
                <div className="m-t-30">
                  <input
                    id="chkBankAccountIsActive"
                    name="currentBank.bankAccount.isActive"
                    type="checkbox"
                    checked={this.state.currentBank.bankAccount.isActive}
                    onChange={this.handleChange}
                  />
                  <label htmlFor="chkBankAccountIsActive">
                    {translate("customer.customerSetupAccountIsActive")}
                  </label>
                </div>
              </div>
            </div>

            <div className="row clearfix">
              <div className="col-md-3">
                <div className="input-group">
                  <label
                    className="validationRequired"
                    htmlFor="txtBankAccountAgencyNumber"
                  >
                    {translate("customer.customerSetupAccountAgencyNumber")}
                  </label>

                  <div className="form-line">
                    <input
                      id="txtBankAccountAgencyNumber"
                      maxLength="4"
                      className="form-control js-customer-bank-change js-integer"
                      name="currentBank.bankAccount.agencyNumber"
                      placeholder={translate(
                        "customer.customerSetupAccountAgencyNumberPlaceHolder",
                      )}
                      type="text"
                    />
                  </div>

                  {this.bankAccountValidator.message(
                    "currentBank.bankAccount.agencyNumber",
                    this.state.currentBank.bankAccount.agencyNumber,
                    "required",
                    false,
                    {
                      default: translate(
                        "customer.customerSetupAccountAgencyNumberRequired",
                      ),
                    },
                  )}
                </div>
              </div>

              <div className="col-md-3">
                <div className="input-group">
                  <label htmlFor="txtBankAccountNumber">
                    {translate("customer.customerSetupAccountNumber")}
                  </label>

                  <div className="form-line">
                    <input
                      id="txtBankAccountNumber"
                      maxLength="20"
                      className="form-control js-customer-bank-change js-integer"
                      name="currentBank.bankAccount.accountNumber"
                      placeholder={translate(
                        "customer.customerSetupAccountNumberPlaceHolder",
                      )}
                      type="text"
                    />
                  </div>

                  {this.bankAccountValidator.message(
                    "currentBank.bankAccount.accountNumber",
                    this.state.currentBank.bankAccount.accountNumber,
                    "required",
                    false,
                    {
                      default: translate(
                        "customer.customerSetupAccountNumberRequired",
                      ),
                    },
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="input-group">
                  <label htmlFor="txtcurrentBankNotes">
                    {translate("customer.customerSetupAccountNotes")}
                  </label>

                  <div className="form-line">
                    <input
                      id="txtBankAccountsNotes"
                      className="form-control js-customer-bank-change"
                      name="currentBank.bankAccount.notes"
                      placeholder={translate(
                        "customer.customerSetupAccountNotesPlaceHolder",
                      )}
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row clearfix">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn m-r-10"
                  onClick={this.resetBank}
                  style={{
                    display:
                      this.state.currentBank.rowIndex >= 0
                        ? "inline-block"
                        : "none",
                  }}
                >
                  <i className="material-icons">&#xe5c4;</i>
                  <span>{translate("forms.buttonCancel")}</span>
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.saveBank}
                >
                  <i className="material-icons">&#xe145;</i>
                  <span>
                    {translate(
                      this.state.currentBank.rowIndex < 0
                        ? "customer.customerSetupAccountsAddNew"
                        : "customer.customerSetupAccountsUpdate",
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}

        <ReactTable
          data={this.state.bankList}
          columns={[
            {
              columns: [
                {
                  Header: translate("customer.customerSetupAccountName"),
                  id: "name",
                  accessor: (data) => {
                    return <span title={data.name}> {data.name} </span>;
                  },
                },
                {
                  Header: translate("customer.customerSetupAccountBankNumber"),
                  id: "bankNumber",
                  accessor: (data) => {
                    return (
                      <span
                        title={
                          enumsBank.getBankNumber().find((x) => {
                            return x.index === data.bankNumber;
                          }).text
                        }
                      >
                        {" "}
                        {
                          enumsBank.getBankNumber().find((x) => {
                            return x.index === data.bankNumber;
                          }).text
                        }{" "}
                      </span>
                    );
                  },
                },
                {
                  Header: translate(
                    "customer.customerSetupAccountAgencyNumber",
                  ),
                  accessor: "agencyNumber",
                  maxWidth: 100,
                },
                {
                  Header: translate("customer.customerSetupAccountNumber"),
                  accessor: "accountNumber",
                  maxWidth: 100,
                },
                {
                  Header: translate("customer.customerSetupAccountNotes"),
                  id: "notes",
                  accessor: (data) => {
                    return <span title={data.notes}> {data.notes} </span>;
                  },
                },
                {
                  Header: translate("customer.customerSetupAccountIsMaster"),
                  Cell: (row) => {
                    return (
                      <div className="align-center">
                        {row.original.isMaster && (
                          <i className="material-icons color-success">
                            &#xe86c;
                          </i>
                        )}
                        {!row.original.isMaster && (
                          <i className="material-icons color-danger">
                            &#xe5c9;
                          </i>
                        )}
                      </div>
                    );
                  },
                  maxWidth: 100,
                },
                {
                  Header: translate("customer.customerSetupAccountIsActive"),
                  Cell: (row) => {
                    return (
                      <div className="align-center">
                        {row.original.isActive && (
                          <i className="material-icons color-success">
                            &#xe86c;
                          </i>
                        )}
                        {!row.original.isActive && (
                          <i className="material-icons color-danger">
                            &#xe5c9;
                          </i>
                        )}
                      </div>
                    );
                  },
                  maxWidth: 100,
                },
                session.auth([{ type: "Customer", value: "Save" }]) && {
                  Cell: (row) => (
                    <div className="align-center">
                      <button
                        className="btn btn-danger btn-circle waves-effect waves-circle waves-float m-r-10"
                        type="button"
                        disabled={this.state.currentBank.rowIndex >= 0}
                        onClick={() => {
                          this.deleteBank(row.index);
                        }}
                      >
                        <i className="material-icons">&#xe872;</i>
                      </button>

                      <button
                        type="button"
                        className="btn bg-deep-purple btn-circle waves-effect waves-circle waves-float m-r-10"
                        disabled={this.state.currentBank.rowIndex >= 0}
                        onClick={() => {
                          this.editBank(row.index);
                        }}
                      >
                        <i className="material-icons">&#xe3c9;</i>
                      </button>
                    </div>
                  ),
                  maxWidth: 150,
                },
              ],
            },
          ]}
          defaultPageSize={10}
          className="-striped -highlight m-b-40"
          previousText={translate("forms.previousText")}
          nextText={translate("forms.nextText")}
          noDataText={translate("forms.noDataText")}
          pageText={translate("forms.pageText")}
          ofText={translate("forms.ofText")}
          rowsText={translate("forms.rowsText")}
        />
      </div>
    );
  }
}
